// Common styles for all devices
export const categoriesContainerStyles = {
  width: '100%',
  padding: theme => theme.spacing(2, 0),
  backgroundColor: '#F7F7F7',
  position: 'relative'
};

export const titleStyles = {
  fontSize: '32px',
  fontWeight: 700,
  color: '#0A3954',
  marginBottom: theme => theme.spacing(3),
  textAlign: 'left'
};

export const carouselContainerStyles = {
  overflowX: 'auto',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none'
  },
  '-ms-overflow-style': 'none'
};

export const carouselContentStyles = {
  display: 'flex',
  padding: '0',
  '& > :first-of-type': {
    marginLeft: 0
  }
};

export const categoryCardStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 1.5,
  cursor: 'pointer',
  transition: 'transform 0.2s ease',
  '&:hover': {
    transform: 'translateY(-8px)'
  },
  flex: '0 0 auto'
};

export const circleImageStyles = {
  borderRadius: '50%',
  overflow: 'hidden',
  backgroundColor: 'white',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.05)',
  '& img': {
    width: '70%',
    height: '70%',
    objectFit: 'contain'
  }
};

export const categoryNameStyles = {
  fontWeight: 600,
  color: '#0A3954',
  textAlign: 'center',
  lineHeight: 1.2
};

export const visuallyHiddenStyles = {
  position: 'absolute', 
  width: '1px', 
  height: '1px', 
  padding: 0, 
  margin: '-1px', 
  overflow: 'hidden', 
  clip: 'rect(0, 0, 0, 0)', 
  whiteSpace: 'nowrap', 
  borderWidth: 0
}; 