/**
 * Common styles for the Header component
 * These styles are shared across all devices
 */

export const appBarStyles = {
  backgroundColor: 'secondary.700',
  color: 'text.200',
  height: '80px',
  position: 'sticky',
  top: 0,
  left: 0,
  right: 0,
  width: '100%',
  zIndex: 1100,
  transition: 'top 0.3s ease-in-out',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
};

export const toolbarStyles = {
  height: '100%',
  minHeight: '80px !important',
  padding: '0 !important',
  justifyContent: 'space-between',
};

export const logoWrapperStyles = {
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  '& img': {
    width: '200px',
    height: '70px',
    marginRight: '8px',
    objectFit: 'contain',
  },
};

export const logoButtonStyles = {
  background: 'none',
  border: 'none',
  padding: 0,
  cursor: 'pointer',
  display: 'flex'
};

export const navButtonsContainerStyles = {
  display: 'flex',
  alignItems: 'center',
};

export const homeButtonStyles = {
  backgroundColor: 'primary.main',
  color: 'white',
  borderRadius: '24px',
  '&:hover': {
    backgroundColor: 'primary.dark',
  },
};

export const devButtonStyles = {
  backgroundColor: '#FF4444',
  color: 'white',
  marginLeft: 2,
  '&:hover': {
    backgroundColor: '#CC0000',
  },
};

export const navigationButtonStyles = {
  color: 'inherit',
  ml: 2,
};

export const drawerPaperStyles = {
  bgcolor: 'secondary.700',
  color: 'white',
  position: 'relative',
};

export const drawerCloseButtonStyles = {
  position: 'absolute',
  right: 8,
  top: 8,
  color: 'white',
  zIndex: 1,
};

export const drawerListStyles = {
  width: '100%', 
  bgcolor: 'secondary.700', 
  color: 'white', 
  flexGrow: 1,
  pt: 6,
};

export const drawerIconStyles = {
  width: '24px',
  height: '24px',
}; 