import {React, useState, useEffect} from 'react';
import { 
  AppBar, 
  Toolbar, 
  Button, 
  IconButton, 
  List, 
  useMediaQuery, 
  Container, 
  Grid, 
  Box, 
  Divider, 
  Drawer, 
  ListItem, 
  ListItemIcon, 
  ListItemText, 
  ListItemButton 
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthProvider';
import LoginModal from '../../../modules/auth/components/LoginModal';
import ProfileModal from '../../../modules/customer/components/ProfileModal';
import ProfileContent from '../../../modules/customer/components/ProfileContent';
import LanguageSwitcher from '../LanguageSwitcher';
import { useTranslation } from 'react-i18next';
import { BANNER_VISIBILITY_EVENT } from '../../../modules/dashboard/components/NotificationBanner';

// Importamos todos los estilos
import {
  // Estilos comunes
  appBarStyles,
  toolbarStyles,
  logoWrapperStyles,
  homeButtonStyles,
  devButtonStyles,
  navButtonsContainerStyles,
  drawerPaperStyles,
  logoButtonStyles,
  drawerCloseButtonStyles,
  drawerListStyles,
  drawerIconStyles,
  navigationButtonStyles,
  
  // Estilos móviles
  mobileAppBarStyles,
  mobileToolbarStyles,
  mobileLogoWrapperStyles,
  mobileDrawerStyles,
  mobileNavButtonStyles,
  mobileLanguageSwitcherContainerStyles,
  mobileDevButtonStyles,
  
  // Estilos tablet
  tabletAppBarStyles,
  tabletToolbarStyles,
  tabletLogoWrapperStyles,
  tabletDrawerStyles,
  tabletDevButtonStyles,
  
  // Estilos desktop
  desktopAppBarStyles,
  desktopToolbarStyles,
  desktopLogoWrapperStyles,
  desktopNavButtonStyles,
  desktopLanguageSwitcherContainerStyles,
  desktopDevButtonStyles
} from './styles';

// Importamos activos (assets)
import LogoImage from '../../../assets/dashboard_logo.svg';
import InvoiceIcon from '../../../assets/icon_invoice.svg';
import ProfileIcon from '../../../assets/icon_profile.svg';

/**
 * Componente Header - Barra de navegación principal de la aplicación
 * 
 * Este componente se adapta a diferentes tamaños de pantalla y proporciona
 * navegación principal, acceso a perfil y opciones de idioma.
 * 
 * @returns {JSX.Element} Componente Header
 */
const Header = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const navigate = useNavigate();
  
  // Estados
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [profileModalOpen, setProfileModalOpen] = useState(false);
  const [profileAnchorEl, setProfileAnchorEl] = useState(null);
  const [showProfileInDrawer, setShowProfileInDrawer] = useState(false);
  const [pendingNavigation, setPendingNavigation] = useState(null);
  const [pendingProfileAction, setPendingProfileAction] = useState(false);
  const [bannerVisible, setBannerVisible] = useState(false);
  const [bannerDeviceInfo, setBannerDeviceInfo] = useState({
    isMobile: false,
    isTablet: false,
    isDesktop: true
  });
  
  const { user, clearAuthState } = useAuth();

  // Detectar cambios en la visibilidad del banner
  useEffect(() => {
    const handleBannerVisibility = (event) => {
      console.log('🔄 Header: Banner visibility changed:', event.detail);
      
      // Actualizar estado con los valores del evento
      setBannerVisible(event.detail.visible);
      setBannerDeviceInfo({
        isMobile: event.detail.isMobile || false,
        isTablet: event.detail.isTablet || false,
        isDesktop: event.detail.isDesktop || true
      });
    };

    window.addEventListener(BANNER_VISIBILITY_EVENT, handleBannerVisibility);

    // Verificar el estado del banner al montar el componente
    const event = new CustomEvent(BANNER_VISIBILITY_EVENT, { 
      detail: { 
        visible: bannerVisible, 
        height: bannerVisible ? (isMobile ? 85 : isTablet ? 75 : 65) : 0,
        isMobile,
        isTablet,
        isDesktop
      }
    });
    window.dispatchEvent(event);

    return () => {
      window.removeEventListener(BANNER_VISIBILITY_EVENT, handleBannerVisibility);
    };
  }, [bannerVisible, isMobile, isTablet, isDesktop]);

  // Limpiar estados cuando se cierra el drawer
  useEffect(() => {
    if (!drawerOpen) {
      setShowProfileInDrawer(false);
    }
  }, [drawerOpen]);

  /**
   * Limpia la sesión del usuario
   */
  const handleClearSession = async () => {
    try {
      await clearAuthState();
      console.log('✅ Session cleared successfully');
      setTimeout(() => {
        window.location.reload();
      }, 100);
    } catch (error) {
      console.error('Error clearing session:', error);
    }
  };

  /**
   * Abre el menú lateral
   */
  const handleDrawerOpen = () => {
    setDrawerOpen(true);
    if (user && (isMobile || isTablet)) {
      setShowProfileInDrawer(true);
    }
  };

  /**
   * Maneja el clic en el perfil
   */
  const handleProfileClick = (event) => {
    if (user) {
      if (isMobile || isTablet) {
        setShowProfileInDrawer(true);
        setDrawerOpen(true);
      } else {
        setProfileAnchorEl(event.currentTarget);
        setProfileModalOpen(true);
      }
    } else {
      setPendingProfileAction(true);
      setLoginModalOpen(true);
    }
  };

  /**
   * Cierra el modal de perfil
   */
  const handleProfileModalClose = () => {
    setProfileModalOpen(false);
    setProfileAnchorEl(null);
  };

  /**
   * Maneja el clic en elementos del drawer
   */
  const handleDrawerItemClick = (path) => {
    if (path === '/profile') {
      handleProfileClick();
    } else {
      handleNavigationWithAuth(path);
    }
    setDrawerOpen(false);
  };

  /**
   * Maneja la navegación con autenticación
   */
  const handleNavigationWithAuth = (path) => {
    console.log('🚀 Attempting to navigate to:', path);
    console.log('👤 Current user:', user);
    
    if (path === '/profile') {
      handleProfileClick();
      return;
    }
    
    const protectedRoutes = ['/customerDetail', '/invoices'];
    
    if (protectedRoutes.includes(path)) {
      if (user) {
        console.log('✅ User is authenticated, navigating to:', path);
        navigate(path);
      } else {
        console.log('🔒 Route is protected, showing login modal');
        setPendingNavigation(path);
        setLoginModalOpen(true);
      }
    } else {
      console.log('🔓 Route is public, navigating to:', path);
      navigate(path);
    }
  };

  /**
   * Cierra el modal de login
   */
  const handleLoginModalClose = () => {
    console.log('❌ Login modal closed, clearing pending actions');
    setLoginModalOpen(false);
    setPendingNavigation(null);
    setPendingProfileAction(false);
  };

  /**
   * Maneja el éxito del login
   */
  const handleLoginSuccess = () => {
    console.log('✅ Login successful');
    setLoginModalOpen(false);
    
    if (pendingProfileAction) {
      console.log('🔄 Opening profile after login');
      if (isMobile || isTablet) {
        setShowProfileInDrawer(true);
        setDrawerOpen(true);
      } else {
        setProfileModalOpen(true);
      }
      setPendingProfileAction(false);
    } else if (pendingNavigation) {
      console.log('🔄 Navigating to pending route:', pendingNavigation);
      navigate(pendingNavigation);
      setPendingNavigation(null);
    }
  };

  /**
   * Maneja el clic en el logo
   */
  const handleLogoClick = () => {
    navigate("/");
  };

  // Unir estilos base con estilos específicos del dispositivo
  const getAppBarSx = () => ({
    ...appBarStyles,
    ...(isMobile ? mobileAppBarStyles : {}),
    ...(isTablet ? tabletAppBarStyles : {}),
    ...(isDesktop ? desktopAppBarStyles : {}),
    // Ajustar posición según visibilidad del banner
    top: bannerVisible 
      ? (bannerDeviceInfo.isMobile ? '85px' : bannerDeviceInfo.isTablet ? '75px' : '65px') 
      : 0,
    position: 'sticky',
    zIndex: 1100,
    width: '100%',
  });

  const getToolbarSx = () => ({
    ...toolbarStyles,
    ...(isMobile ? mobileToolbarStyles : {}),
    ...(isTablet ? tabletToolbarStyles : {}),
    ...(isDesktop ? desktopToolbarStyles : {})
  });

  const getLogoWrapperSx = () => ({
    ...logoWrapperStyles,
    ...(isMobile ? mobileLogoWrapperStyles : {}),
    ...(isTablet ? tabletLogoWrapperStyles : {}),
    ...(isDesktop ? desktopLogoWrapperStyles : {})
  });

  const getDrawerSx = () => ({
    '& .MuiDrawer-paper': {
      ...drawerPaperStyles,
      ...(isMobile ? mobileDrawerStyles : {}),
      ...(isTablet ? tabletDrawerStyles : {})
    }
  });

  const getDevButtonSx = () => ({
    ...devButtonStyles,
    ...(isMobile ? mobileDevButtonStyles : {}),
    ...(isTablet ? tabletDevButtonStyles : {}),
    ...(isDesktop ? desktopDevButtonStyles : {})
  });

  return (
    <>
      <AppBar 
        elevation={4} 
        sx={getAppBarSx()}
      >
        <Container maxWidth="lg" sx={{ height: '100%' }}>
          <Toolbar sx={getToolbarSx()}>
            <Grid 
              container 
              alignItems="center" 
              spacing={isMobile ? 0 : 4} // Sin espaciado en móvil para mejor alineación
              sx={isMobile ? { 
                height: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
              } : {}}
            >
              <Grid 
                item 
                xs={isMobile ? 6 : 'auto'} // Ancho fijo en móvil
                sx={isMobile ? { 
                  display: 'flex', 
                  justifyContent: 'flex-start', // Alinear al inicio (izquierda) 
                  alignItems: 'center',
                  height: '75px' 
                } : {}}
              >
                <Box sx={isMobile ? { 
                  display: 'flex', 
                  justifyContent: 'flex-start', // Alinear al inicio (izquierda)
                  alignItems: 'center',
                  height: '100%'
                } : {}}>
                  <Box sx={getLogoWrapperSx()}>
                    <button
                      onClick={handleLogoClick}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' || e.key === ' ') {
                          handleLogoClick();
                        }
                      }}
                      style={{ 
                        background: 'none',
                        border: 'none',
                        padding: 0,
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start', // Alinear al inicio (izquierda)
                        height: '100%'
                      }}
                      aria-label={t('common.goToHomepage')}
                    >
                      <img 
                        src={LogoImage} 
                        alt={t('a11y.companyLogo')} 
                      />
                    </button>
                  </Box>
                </Box>
              </Grid>
              <Grid item>
                {isDesktop && (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Button sx={homeButtonStyles} component={Link} to="/" variant="contained">
                      {t('navigation.home')}
                    </Button>
                    {process.env.NODE_ENV === 'development' && (
                      <Button
                        onClick={handleClearSession}
                        size="small"
                        sx={getDevButtonSx()}
                      >
                        {t('common.clearSession')}
                      </Button>
                    )}
                  </Box>
                )}
              </Grid>
            </Grid>
            {isMobile || isTablet ? (
              <>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {process.env.NODE_ENV === 'development' && (
                    <Button
                      onClick={handleClearSession}
                      size="small"
                      sx={getDevButtonSx()}
                    >
                      {t('common.clear')}
                    </Button>
                  )}
                  <Box sx={isMobile ? mobileLanguageSwitcherContainerStyles : (isTablet ? {} : desktopLanguageSwitcherContainerStyles)}>
                    <LanguageSwitcher />
                  </Box>
                  <IconButton
                    edge="end"
                    color="inherit"
                    aria-label={t('common.menu')}
                    onClick={handleDrawerOpen}
                    sx={{ ml: 1 }}
                  >
                    <MenuIcon />
                  </IconButton>
                </Box>
                <Drawer
                  anchor="right"
                  open={drawerOpen}
                  onClose={() => setDrawerOpen(false)}
                  sx={getDrawerSx()}
                >
                  <IconButton
                    onClick={() => setDrawerOpen(false)}
                    aria-label={t('common.closeMenu')}
                    sx={drawerCloseButtonStyles}
                  >
                    <CloseIcon />
                  </IconButton>

                  <List sx={drawerListStyles}>
                    <ListItem button onClick={() => handleDrawerItemClick('/invoices')}>
                      <ListItemIcon>
                        <img src={InvoiceIcon} alt={t('a11y.invoiceIcon')} style={drawerIconStyles} />
                      </ListItemIcon>
                      <ListItemText primary={t('navigation.invoices')} />
                    </ListItem>
                    <Divider />
                    {!showProfileInDrawer && (
                      <ListItem>
                        <ListItemButton onClick={handleProfileClick}>
                          <ListItemIcon>
                            <img src={ProfileIcon} alt={t('a11y.profileIcon')} style={drawerIconStyles} />
                          </ListItemIcon>
                          <ListItemText primary={t('navigation.profile')} />
                        </ListItemButton>
                      </ListItem>
                    )}
                  </List>

                  {showProfileInDrawer && (
                    <Box sx={{ mt: 'auto', width: '100%' }}>
                      <ProfileContent
                        onLogout={async () => {
                          try {
                            console.log('🔒 Iniciando logout completo desde drawer móvil');
                            
                            setDrawerOpen(false);
                            console.log('✅ Drawer cerrado');
                            
                            localStorage.clear();
                            sessionStorage.clear();
                            console.log('✅ Storage completamente limpiado');
                            
                            await clearAuthState();
                            console.log('✅ Estado de autenticación limpiado');
                            
                            if (window.queryClient) {
                              try {
                                window.queryClient.clear();
                                console.log('✅ Caché de React Query completamente limpiada');
                              } catch (cacheError) {
                                console.error('Error al limpiar caché:', cacheError);
                              }
                            }
                            
                            try {
                              window.dispatchEvent(new Event('storage'));
                              console.log('✅ Evento storage disparado');
                            } catch (eventError) {
                              console.error('Error al disparar evento storage:', eventError);
                            }
                            
                            console.log('⏱️ Esperando para asegurar limpieza completa...');
                            
                            setTimeout(() => {
                              console.log('🔄 Redirigiendo con recarga completa...');
                              window.location.href = '/?logout=true&mobile=true&t=' + new Date().getTime();
                            }, 500);
                          } catch (error) {
                            console.error('❌ Error durante logout desde drawer móvil:', error);
                            window.location.href = '/?error=true&t=' + new Date().getTime();
                          }
                        }}
                        isDrawer={true}
                      />
                    </Box>
                  )}
                </Drawer>
              </>
            ) : (
              <Box sx={navButtonsContainerStyles}>
                <LanguageSwitcher />
                <Button
                  onClick={() => handleNavigationWithAuth('/invoices')}
                  color="inherit"
                  startIcon={<img src={InvoiceIcon} alt={t('a11y.invoiceIcon')} width="24" height="24" />}
                  sx={{ ml: 2, ...(desktopNavButtonStyles || {}) }}
                >
                  {t('navigation.invoices')}
                </Button>
                <Button
                  onClick={handleProfileClick}
                  color="inherit"
                  startIcon={<img src={ProfileIcon} alt={t('a11y.profileIcon')} width="24" height="24" />}
                  sx={{ ml: 2, ...(desktopNavButtonStyles || {}) }}
                >
                  {t('navigation.profile')}
                </Button>
              </Box>
            )}
          </Toolbar>
        </Container>
      </AppBar>

      <LoginModal 
        open={loginModalOpen}
        onClose={handleLoginModalClose}
        onLoginSuccess={handleLoginSuccess}
      />

      {!isMobile && !isTablet && (
        <ProfileModal
          open={profileModalOpen}
          onClose={handleProfileModalClose}
          anchorEl={profileAnchorEl}
        />
      )}
    </>
  );
};

export default Header; 