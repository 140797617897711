export class CustomerDetailModel {
    constructor(data) {
        this.balance = data.balance;
        this.companyName = data.companyName;
        this.dateCreated = new Date(data.dateCreated);
        this.email = data.email;
        this.isInactive = data.isInactive;
        this.isPerson = data.isPerson;
        this.lastModifiedDate = new Date(data.lastModifiedDate);
        this.phone = data.phone;
        this.subsidiary = data.subsidiary ? {
            id: data.subsidiary.id,
            refName: data.subsidiary.refName,
            links: data.subsidiary.links
        } : {};
        this.addressBook = data.addressBook ? {
            links: data.addressBook.links
        } : {};
    }
}