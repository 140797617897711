import { auth } from '../../../shared/utils/firebase/firebase-config';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';
import { apiClient } from '../../../shared/services/apiClient';
import axios from 'axios';

export const USER_STATUS = {
  REGISTERED: 'USER_REGISTERED',
  NEW_USER: 'NEW_USER',
  NETSUITE_USER: 'NETSUITE_CLIENT_ONLY'
};

export const UI_ACTIONS = {
  SHOW_PASSWORD_FIELD: 'SHOW_PASSWORD_FIELD',
  ENABLE_LOGIN_BUTTON: 'ENABLE_LOGIN_BUTTON',
  SHOW_FORGOT_PASSWORD_LINK: 'SHOW_FORGOT_PASSWORD_LINK',
  PREFILL_EMAIL: 'PREFILL_EMAIL',
  SHOW_REGISTRATION_FORM: 'SHOW_REGISTRATION_FORM',
  SHOW_NETSUITE_FORM: 'SHOW_NETSUITE_FORM'
};

export const checkUserStatus = async (email) => {
  try {
    console.log('🔍 Checking user status for:', email);
    const response = await apiClient.get(`/login/check/${email}`);
    
    // Si el usuario está registrado
    if (response.data.code === USER_STATUS.REGISTERED) {
      console.log('✅ User is registered');
      return USER_STATUS.REGISTERED;
    }
    
    // Si el usuario es de Netsuite
    if (response.data.code === USER_STATUS.NETSUITE_USER) {
      console.log('✅ User is a Netsuite client');
      return USER_STATUS.NETSUITE_USER;
    }

    console.log('❓ Unknown user status:', response.data.code);
    return USER_STATUS.NEW_USER;

  } catch (error) {
    console.error('❌ Error checking user status:', error);
    
    // Si el usuario es nuevo (409 Conflict)
    if (error.response?.status === 409) {
      console.log('✨ New user detected');
      return USER_STATUS.NEW_USER;
    }

    // Para cualquier otro error, lo propagamos
    throw new Error(error.response?.data?.message || 'Error checking user status');
  }
};

// Función para guardar el token en localStorage
const saveToken = (token) => {
  localStorage.setItem('auth_token', token);
};

// Función para obtener el token de localStorage
export const getToken = () => {
  return localStorage.getItem('auth_token');
};

// Función para eliminar el token de localStorage (logout)
export const removeToken = () => {
  localStorage.removeItem('auth_token');
};

// Función para verificar si el usuario está autenticado
export const isAuthenticated = () => {
  const token = getToken();
  return !!token; // Retorna true si existe un token
};

// Función para iniciar sesión con Firebase
export const loginUser = async (email, password) => {
  try {
    console.log('🔐 Attempting Firebase login for:', email);
     
    // 1. Autenticar con Firebase
    const firebaseResponse = await signInWithEmailAndPassword(auth, email, password);
    const firebaseUser = firebaseResponse.user;
    console.log('✅ Firebase login successful:', { email, uid: firebaseUser.uid });

    // 2. Obtener token de Firebase
    const token = await firebaseUser.getIdToken();
    
    // Guardar el token para uso posterior
    saveToken(token);
    
    return {
      uid: firebaseUser.uid,
      email: firebaseUser.email,
      token
    };
  } catch (error) {
    console.error('❌ Login error:', error);
    
    // Manejar errores específicos de Firebase
    if (error.code) {
      switch (error.code) {
        case 'auth/invalid-credential':
          throw new Error('Credenciales inválidas');
        case 'auth/user-disabled':
          throw new Error('Esta cuenta ha sido deshabilitada');
        case 'auth/too-many-requests':
          throw new Error('Demasiados intentos fallidos. Por favor, inténtalo más tarde');
        default:
          throw new Error(error.message || 'Credenciales inválidas');
      }
    }
    
    throw new Error('Error durante el inicio de sesión');
  }
};

// Función para registrar un nuevo usuario con Firebase
export const registerUser = async (userData) => {
  try {
    const { email, password, firstName, lastName, acceptTerms, ...additionalData } = userData;
    
    // 1. Crear usuario en Firebase
    const firebaseResponse = await createUserWithEmailAndPassword(auth, email, password);
    const firebaseUser = firebaseResponse.user;
    const firebaseUid = firebaseUser.uid;
    console.log('✅ Firebase user created:', { email, uid: firebaseUid });

    // 2. Obtener token de Firebase
    const token = await firebaseUser.getIdToken();
    
    // 3. Guardar el token
    saveToken(token);
    
    // 4. Crear o actualizar perfil de usuario en nuestro backend
    try {
      await apiClient.post('/users/profile', {
        uid: firebaseUid,
        email,
        firstName,
        lastName,
        ...additionalData
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      console.log('✅ User profile created/updated in backend');
    } catch (profileError) {
      console.error('❌ Error creating user profile:', profileError);
      // No lanzamos error aquí para no interrumpir el flujo si el perfil falla
    }

    return {
      uid: firebaseUid,
      email: firebaseUser.email,
      token,
      firstName,
      lastName
    };
  } catch (error) {
    console.error('❌ Registration error:', error);
    
    // Manejar errores específicos de Firebase
    if (error.code) {
      switch (error.code) {
        case 'auth/email-already-in-use':
          throw new Error('Este correo electrónico ya está en uso');
        case 'auth/invalid-email':
          throw new Error('Correo electrónico inválido');
        case 'auth/operation-not-allowed':
          throw new Error('Operación no permitida');
        case 'auth/weak-password':
          throw new Error('La contraseña es demasiado débil');
        default:
          throw new Error(error.message || 'Error al registrar usuario');
      }
    }
    
    throw new Error('Error al registrar usuario');
  }
};

// Función para solicitar recuperación de contraseña
export const forgotPassword = async (email) => {
  try {
    // Usar la función de Firebase para resetear contraseña
    await auth.sendPasswordResetEmail(email);
    return { success: true, message: 'Se ha enviado un correo para restablecer tu contraseña' };
  } catch (error) {
    console.error('❌ Forgot password error:', error);
    
    if (error.code) {
      switch (error.code) {
        case 'auth/invalid-email':
          throw new Error('Correo electrónico inválido');
        case 'auth/user-not-found':
          throw new Error('No existe una cuenta con este correo electrónico');
        default:
          throw new Error(error.message || 'Error al solicitar recuperación de contraseña');
      }
    }
    
    throw new Error('Error al solicitar recuperación de contraseña');
  }
};

// Función para restablecer contraseña
export const resetPassword = async (actionCode, newPassword) => {
  try {
    // Verificar el código de acción
    const email = await auth.verifyPasswordResetCode(actionCode);
    
    // Confirmar el restablecimiento de contraseña
    await auth.confirmPasswordReset(actionCode, newPassword);
    
    return { 
      success: true, 
      email,
      message: 'Contraseña restablecida correctamente' 
    };
  } catch (error) {
    console.error('❌ Reset password error:', error);
    
    if (error.code) {
      switch (error.code) {
        case 'auth/expired-action-code':
          throw new Error('El enlace ha expirado');
        case 'auth/invalid-action-code':
          throw new Error('El enlace es inválido');
        case 'auth/user-disabled':
          throw new Error('La cuenta de usuario está deshabilitada');
        case 'auth/user-not-found':
          throw new Error('No se encontró el usuario');
        case 'auth/weak-password':
          throw new Error('La contraseña es demasiado débil');
        default:
          throw new Error(error.message || 'Error al restablecer contraseña');
      }
    }
    
    throw new Error('Error al restablecer contraseña');
  }
};

// Función para obtener el perfil del usuario actual
export const getCurrentUser = async () => {
  try {
    const token = getToken();
    
    if (!token) {
      throw new Error('No hay token de autenticación');
    }
    
    // Obtener el usuario actual de Firebase
    const currentUser = auth.currentUser;
    if (!currentUser) {
      throw new Error('No hay usuario autenticado');
    }
    
    // Obtener datos adicionales del perfil desde nuestro backend
    try {
      const response = await apiClient.get('/users/profile', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      
      return {
        ...response.data,
        uid: currentUser.uid,
        email: currentUser.email
      };
    } catch (profileError) {
      console.error('❌ Error fetching user profile:', profileError);
      
      // Si hay error al obtener el perfil, al menos devolvemos los datos básicos
      return {
        uid: currentUser.uid,
        email: currentUser.email
      };
    }
  } catch (error) {
    console.error('❌ Get current user error:', error);
    
    if (error.response && error.response.status === 401) {
      // Token expirado o inválido
      removeToken();
      throw new Error('Sesión expirada. Por favor, inicia sesión nuevamente.');
    }
    
    throw error;
  }
};

// Función para actualizar el perfil del usuario
export const updateUserProfile = async (userData) => {
  try {
    const token = getToken();
    
    if (!token) {
      throw new Error('No hay token de autenticación');
    }
    
    // Obtener el usuario actual de Firebase
    const currentUser = auth.currentUser;
    if (!currentUser) {
      throw new Error('No hay usuario autenticado');
    }
    
    // Actualizar email en Firebase si se proporciona uno nuevo
    if (userData.email && userData.email !== currentUser.email) {
      await currentUser.updateEmail(userData.email);
    }
    
    // Actualizar contraseña en Firebase si se proporciona
    if (userData.password) {
      await currentUser.updatePassword(userData.password);
    }
    
    // Actualizar perfil en nuestro backend
    const response = await apiClient.put('/users/profile', userData, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    
    return response.data;
  } catch (error) {
    console.error('❌ Update profile error:', error);
    
    if (error.code) {
      switch (error.code) {
        case 'auth/requires-recent-login':
          throw new Error('Esta operación es sensible y requiere autenticación reciente. Inicia sesión nuevamente.');
        case 'auth/email-already-in-use':
          throw new Error('El correo electrónico ya está en uso por otra cuenta.');
        case 'auth/invalid-email':
          throw new Error('El correo electrónico no es válido.');
        case 'auth/weak-password':
          throw new Error('La contraseña es demasiado débil.');
        default:
          throw new Error(error.message || 'Error al actualizar perfil');
      }
    }
    
    throw new Error('Error al actualizar perfil');
  }
};

// Función para cerrar sesión
export const logout = async () => {
  try {
    // Cerrar sesión en Firebase
    await auth.signOut();
    
    // Eliminar token
    removeToken();
    
    return { success: true };
  } catch (error) {
    console.error('❌ Logout error:', error);
    
    // Aún así, eliminamos el token local
    removeToken();
    
    throw new Error('Error al cerrar sesión');
  }
};

export const subscribeToNewsletter = async ({ email, newsletter_subscriber, subscription_source }) => {
  try {
    console.log('📧 Subscribing to newsletter:', { email, newsletter_subscriber, subscription_source });
    const response = await apiClient.post('/newsletter/subscribe', {
      email,
      newsletter_subscriber,
      subscription_source
    });
    console.log('✅ Newsletter subscription successful:', response.data);
    return response.data;
  } catch (error) {
    console.error('❌ Newsletter subscription error:', error.response?.data || error);
    throw new Error(error.response?.data?.message || 'Error subscribing to newsletter');
  }
};

export default {
  loginUser,
  registerUser,
  forgotPassword,
  resetPassword,
  getCurrentUser,
  updateUserProfile,
  isAuthenticated,
  getToken,
  removeToken,
  logout,
  checkUserStatus,
  subscribeToNewsletter
};