import React from 'react';
import { Box, Container, Divider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { DarkBox } from './StyledComponents';
import { QuickLinksAccordion } from './QuickLinks';
import { HoursAccordion } from './HoursOfOperation';
import { ContactAccordion } from './ContactInfo';
import { AppStoreLogos, SocialMediaIcons } from './Logos';
import { CopyrightSection } from './Copyright';
import { NewsletterMobile } from './Newsletter';
import { appPromoText } from '../utils/footerData';

// Componente para textos SEO ocultos pero accesibles para lectores de pantalla
const SeoTextComponent = styled.span`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
`;

const MobileFooterContent = () => {
  const { t } = useTranslation();
  
  return (
    <Box>
      <Box sx={{ 
        bgcolor: 'white',
        display: 'flex', 
        flexDirection: 'column',
        padding: 3,
        gap: 2,
      }}>
        <Container maxWidth="md" sx={{ padding: '0 24px' }}>
          <Divider 
            orientation='horizontal' 
            flexItem 
            sx={{ 
              bgcolor: '#E99B25', 
              mb: 2, 
              width: '40%',
            }}
          />
          
          <Typography
            variant="h5"
            gutterBottom
            sx={{ 
              color: '#1D232E', 
              fontSize: 16.42, 
              fontFamily: 'Open Sans', 
              fontWeight: '800', 
              wordWrap: 'break-word',
              textAlign: 'center',
              mb: 2
            }}
          >
            {t(appPromoText.title)}
            <SeoTextComponent>{t(appPromoText.seoTitle)}</SeoTextComponent>
            <SeoTextComponent>{t(appPromoText.seoDescription)}</SeoTextComponent>
          </Typography>
          <Box sx={{ display: 'flex', width: '100%' }}>
            <AppStoreLogos spacing={2} />
          </Box>
          <Box sx={{ display: 'flex', width: '100%' }}>
            <SocialMediaIcons spacing={2} color="primary" iconSize={24} />
          </Box>
        </Container>
      </Box>
      <DarkBox isMobile={true}>
        <Container maxWidth="lg" sx={{ padding: '0 16px' }}>
          <NewsletterMobile />
          <QuickLinksAccordion />
          <HoursAccordion />
          <ContactAccordion />
          <CopyrightSection />
        </Container>
      </DarkBox>
    </Box>
  );
};

export default MobileFooterContent; 