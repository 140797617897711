import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAR29CIbmfqy1lAPn0-z9p7RxTh0zAiepo",
  authDomain: "syl-apps-sandbox.firebaseapp.com",
  databaseURL: "https://syl-apps-sandbox-default-rtdb.firebaseio.com",
  projectId: "syl-apps-sandbox",
  storageBucket: "syl-apps-sandbox.appspot.com",
  messagingSenderId: "92076094545",
  appId: "1:92076094545:web:ea8e484769824b8cc63470",
  measurementId: "G-FZPQ0DB9L0"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { app, auth, db };