import React from 'react';
import { Card, CardContent, CardMedia, Typography, Box, Button, useTheme, useMediaQuery } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PropTypes from 'prop-types';
import contactService from '../../../../../shared/services/contactService';
import {
  commonOfferCardStyles,
  commonOfferCardMediaStyles,
  commonOfferCardContentStyles,
  commonOfferCardTitleStyles,
  commonOfferCardPriceStyles,
  mobileOfferCardStyles,
  mobileOfferCardMediaStyles,
  mobileOfferCardContentStyles,
  mobileOfferCardTitleStyles,
  mobileOfferCardPriceStyles,
  tabletOfferCardStyles,
  tabletOfferCardMediaStyles,
  tabletOfferCardContentStyles,
  tabletOfferCardTitleStyles,
  tabletOfferCardPriceStyles,
  desktopOfferCardStyles,
  desktopOfferCardMediaStyles,
  desktopOfferCardContentStyles,
  desktopOfferCardTitleStyles,
  desktopOfferCardPriceStyles,
  // Para compatibilidad con versiones anteriores
  offerCardStyles,
  offerCardMediaStyles,
  offerCardContentStyles,
  offerCardTitleStyles,
  offerCardPriceStyles
} from '../styles';

/**
 * OfferCard Component
 * 
 * Displays a card with an image, title, and price for special offers.
 * Responsive design adapts to different screen sizes.
 * @param {Object} props - Component props
 * @param {string} props.cardTitleOne - Name of the product
 * @param {string} [props.cardTitleTwo] - Price of the product
 * @param {string} props.imageCard - URL of product image
 * @param {string} [props.CTA] - Call to action message for WhatsApp
 */
export const OfferCard = ({
  cardTitleOne,
  cardTitleTwo,
  imageCard,
  CTA
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleContactClick = () => {
    // Si hay un mensaje CTA y estamos en móvil, abrimos WhatsApp
    if (CTA && isMobile) {
      contactService.openWhatsApp(CTA);
    }
  };

  return (
    <Card sx={{
      ...offerCardStyles,
      position: 'relative',
      height: isMobile ? '100%' : 'auto'
    }}>
      <CardMedia
        component="img"
        image={imageCard}
        alt={cardTitleOne}
        sx={offerCardMediaStyles}
      />
      <CardContent sx={{
        ...offerCardContentStyles,
        display: 'flex',
        flexDirection: 'column',
        height: isMobile ? '120px' : 'auto'
      }}>
        <Typography sx={{
          ...offerCardTitleStyles,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: 2,
          WebkitBoxOrient: 'vertical',
          minHeight: '40px'
        }}>
          {cardTitleOne}
        </Typography>
        
        {cardTitleTwo && (
          <Typography 
            variant="h6" 
            color="primary" 
            sx={offerCardPriceStyles}
          >
            {cardTitleTwo}
          </Typography>
        )}

        {CTA && isMobile && (
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'center', 
            mt: 'auto',
            mb: 1
          }}>
            <Button
              variant="contained"
              size="small"
              startIcon={<WhatsAppIcon />}
              onClick={handleContactClick}
              sx={{
                borderRadius: '20px',
                bgcolor: '#25D366',
                '&:hover': {
                  bgcolor: '#128C7E'
                },
                fontSize: '0.75rem',
                mt: 1
              }}
            >
              Chat now
            </Button>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

OfferCard.propTypes = {
  cardTitleOne: PropTypes.string.isRequired,
  cardTitleTwo: PropTypes.string,
  imageCard: PropTypes.string.isRequired,
  CTA: PropTypes.string
}; 