import { useState, useEffect } from 'react';

/**
 * Custom hook to detect device type and operating system
 * @returns {Object} Object containing device detection flags
 */
const useDeviceDetection = () => {
  const [isAppleDevice, setIsAppleDevice] = useState(false);
  const [isAppleTablet, setIsAppleTablet] = useState(false);
  const [isAndroidDevice, setIsAndroidDevice] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    
    // Check if it's an Apple device (iOS or macOS)
    const isApple = /iphone|ipad|ipod|macintosh/.test(userAgent) && !window.MSStream;
    setIsAppleDevice(isApple);
    
    // Check specifically for iPad or iPad-like device (including iPad Pro)
    // Note: Modern iPads with iOS 13+ might report as Macintosh
    const isIpad = /ipad/.test(userAgent) || 
                  (/macintosh/.test(userAgent) && 
                   'ontouchend' in document && 
                   navigator.maxTouchPoints > 1);
    setIsAppleTablet(isIpad);
    
    // Check if it's an Android device
    const isAndroid = /android/.test(userAgent);
    setIsAndroidDevice(isAndroid);
  }, []);

  return {
    isAppleDevice,
    isAppleTablet,
    isAndroidDevice,
    // Helper for our specific use case
    shouldShowOnlyAppleStore: isAppleDevice || isAppleTablet
  };
};

export default useDeviceDetection; 