/**
 * BrandCard Component
 * Displays a single brand card with image and name
 */

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';

// Import styles directly from their source files
// Common styles
import {
  commonBrandCardStyles,
  commonBrandImageContainerStyles,
  commonBrandImageStyles,
  commonBrandNameStyles,
  commonBrandTypographyStyles
} from '../styles/common';

// Mobile styles
import {
  mobileBrandCardStyles,
  mobileBrandImageContainerStyles,
  mobileBrandNameStyles,
  mobileBrandTypographyStyles
} from '../styles/mobile';

// Tablet styles
import {
  tabletBrandCardStyles,
  tabletBrandImageContainerStyles,
  tabletBrandNameStyles,
  tabletBrandTypographyStyles,
  // iPad specific styles
  iPadMiniBrandCardStyles,
  iPadMiniBrandImageContainerStyles,
  iPadMiniBrandTypographyStyles,
  iPadAirBrandCardStyles,
  iPadAirBrandImageContainerStyles,
  iPadAirBrandTypographyStyles,
  iPadProBrandCardStyles,
  iPadProBrandImageContainerStyles,
  iPadProBrandTypographyStyles
} from '../styles/tablet';

// Desktop styles
import {
  desktopBrandCardStyles,
  desktopBrandImageContainerStyles,
  desktopBrandNameStyles,
  desktopBrandTypographyStyles
} from '../styles/desktop';

const BrandCard = ({ 
  brand, 
  deviceType, 
  tabletType, 
  isFirstItem, 
  useAlternativeLayout = false, 
  isCurrentBrand = false,
  sx = {} 
}) => {
  
  const navigate = useNavigate();
  const location = useLocation();
  
  // Función para manejar el clic en la marca
  const handleBrandClick = () => {
    // Si es la marca actual, no hacer nada
    if (isCurrentBrand) {
      return;
    }
    
    const newPath = `/brands/${brand.key}`;
    
    // Verificar si ya estamos en una página de marca
    const isAlreadyInBrandsPage = location.pathname.startsWith('/brands/');
    
    // Si ya estamos en una página de marca, usar replace para evitar acumular entradas en el historial
    if (isAlreadyInBrandsPage) {
      navigate(newPath, { replace: true });
    } else {
      navigate(newPath);
    }
  };
  
  // Get device-specific styles
  const getBrandCardStyles = () => {
    if (deviceType === 'mobile') {
      if (useAlternativeLayout) {
        return { 
          ...commonBrandCardStyles, 
          ...mobileBrandCardStyles,
          minWidth: 0, // Ensure cards can shrink properly in flexbox
          maxWidth: '100%' // Ensure cards don't overflow container
        };
      }
      return { ...commonBrandCardStyles, ...mobileBrandCardStyles };
    }
    
    if (deviceType === 'tablet') {
      const baseStyles = {
        ...commonBrandCardStyles, 
        ...tabletBrandCardStyles
      };
      
      // For 3-column layout in tablet
      if (useAlternativeLayout) {
        const alternativeStyles = {
          width: 'calc(33.333% - 14px)',
          height: '130px',
          minWidth: 0,
          maxWidth: '100%',
          flexGrow: 0,
          flexShrink: 0,
          margin: 0
        };
        
        if (tabletType === 'iPadMini') {
          return { ...baseStyles, ...iPadMiniBrandCardStyles, ...alternativeStyles, height: '125px' };
        }
        if (tabletType === 'iPadAir') {
          return { ...baseStyles, ...iPadAirBrandCardStyles, ...alternativeStyles };
        }
        if (tabletType === 'iPadPro') {
          return { ...baseStyles, ...iPadProBrandCardStyles, ...alternativeStyles, height: '140px' };
        }
        return { ...baseStyles, ...alternativeStyles };
      }
      
      // Original tablet styles
      if (tabletType === 'iPadMini') {
        return { ...baseStyles, ...iPadMiniBrandCardStyles };
      }
      if (tabletType === 'iPadAir') {
        return { ...baseStyles, ...iPadAirBrandCardStyles };
      }
      if (tabletType === 'iPadPro') {
        return { ...baseStyles, ...iPadProBrandCardStyles };
      }
      return baseStyles;
    }
    
    return { ...commonBrandCardStyles, ...desktopBrandCardStyles };
  };
  
  const getBrandImageContainerStyles = () => {
    if (deviceType === 'mobile') {
      return { ...commonBrandImageContainerStyles, ...mobileBrandImageContainerStyles };
    }
    
    if (deviceType === 'tablet') {
      if (tabletType === 'iPadMini') {
        return { ...commonBrandImageContainerStyles, ...tabletBrandImageContainerStyles, ...iPadMiniBrandImageContainerStyles };
      }
      if (tabletType === 'iPadAir') {
        return { ...commonBrandImageContainerStyles, ...tabletBrandImageContainerStyles, ...iPadAirBrandImageContainerStyles };
      }
      if (tabletType === 'iPadPro') {
        return { ...commonBrandImageContainerStyles, ...tabletBrandImageContainerStyles, ...iPadProBrandImageContainerStyles };
      }
      return { ...commonBrandImageContainerStyles, ...tabletBrandImageContainerStyles };
    }
    
    return { ...commonBrandImageContainerStyles, ...desktopBrandImageContainerStyles };
  };
  
  const getBrandNameStyles = () => {
    if (deviceType === 'mobile') {
      if (useAlternativeLayout) {
        return { 
          ...commonBrandNameStyles, 
          ...mobileBrandNameStyles,
          height: '40px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        };
      }
      return { ...commonBrandNameStyles, ...mobileBrandNameStyles };
    }
    
    if (deviceType === 'tablet') {
      if (useAlternativeLayout) {
        return { 
          ...commonBrandNameStyles, 
          ...tabletBrandNameStyles,
          height: '40px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        };
      }
      return { ...commonBrandNameStyles, ...tabletBrandNameStyles };
    }
    
    return { ...commonBrandNameStyles, ...desktopBrandNameStyles };
  };
  
  const getBrandTypographyStyles = () => {
    if (deviceType === 'mobile') {
      if (useAlternativeLayout) {
        return { 
          ...commonBrandTypographyStyles, 
          ...mobileBrandTypographyStyles,
          textAlign: 'center',
          fontSize: '0.75rem',
          lineHeight: 1.2
        };
      }
      return { ...commonBrandTypographyStyles, ...mobileBrandTypographyStyles };
    }
    
    if (deviceType === 'tablet') {
      const baseTypographyStyles = {
        ...commonBrandTypographyStyles,
        ...tabletBrandTypographyStyles
      };
      
      if (useAlternativeLayout) {
        const alternativeTypographyStyles = {
          textAlign: 'center',
          fontSize: '0.875rem',
          lineHeight: 1.2
        };
        
        if (tabletType === 'iPadMini') {
          return { ...baseTypographyStyles, ...iPadMiniBrandTypographyStyles, ...alternativeTypographyStyles, fontSize: '0.8125rem' };
        }
        if (tabletType === 'iPadAir') {
          return { ...baseTypographyStyles, ...iPadAirBrandTypographyStyles, ...alternativeTypographyStyles };
        }
        if (tabletType === 'iPadPro') {
          return { ...baseTypographyStyles, ...iPadProBrandTypographyStyles, ...alternativeTypographyStyles, fontSize: '0.9375rem' };
        }
        return { ...baseTypographyStyles, ...alternativeTypographyStyles };
      }
      
      if (tabletType === 'iPadMini') {
        return { ...baseTypographyStyles, ...iPadMiniBrandTypographyStyles };
      }
      if (tabletType === 'iPadAir') {
        return { ...baseTypographyStyles, ...iPadAirBrandTypographyStyles };
      }
      if (tabletType === 'iPadPro') {
        return { ...baseTypographyStyles, ...iPadProBrandTypographyStyles };
      }
      return baseTypographyStyles;
    }
    
    return { ...commonBrandTypographyStyles, ...desktopBrandTypographyStyles };
  };
  
  // Apply first item style if specified and add styles for current brand
  const cardStyles = {
    ...getBrandCardStyles(),
    ...(isFirstItem && { marginLeft: 0 }),
    ...(isCurrentBrand && {
      // Estilos para la marca actualmente seleccionada
      opacity: 0.7,
      pointerEvents: 'none', // Desactivar interacciones
      cursor: 'default',
      boxShadow: 'none',
      border: '2px solid #ccc'
    }),
    ...sx,
    cursor: isCurrentBrand ? 'default' : 'pointer',
    transition: 'transform 0.2s, opacity 0.2s',
    '&:hover': {
      transform: isCurrentBrand ? 'none' : 'translateY(-4px)',
      opacity: isCurrentBrand ? 0.7 : 1
    }
  };
  
  return (
    <Box sx={cardStyles} onClick={handleBrandClick}>
      <Box sx={getBrandImageContainerStyles()}>
        <Box 
          component="img"
          src={brand.image} 
          alt={brand.alt} 
          sx={{
            ...commonBrandImageStyles,
            ...(isCurrentBrand && { filter: 'grayscale(50%)' })
          }}
        />
      </Box>
      <Box sx={getBrandNameStyles()}>
        <Typography 
          variant="h6" 
          sx={getBrandTypographyStyles()}
        >
          {brand.name}
        </Typography>
      </Box>
    </Box>
  );
};

BrandCard.propTypes = {
  brand: PropTypes.shape({
    id: PropTypes.number.isRequired,
    key: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired
  }).isRequired,
  deviceType: PropTypes.oneOf(['mobile', 'tablet', 'desktop']).isRequired,
  tabletType: PropTypes.oneOf(['iPadMini', 'iPadAir', 'iPadPro', 'generic']),
  isFirstItem: PropTypes.bool,
  useAlternativeLayout: PropTypes.bool,
  isCurrentBrand: PropTypes.bool,
  sx: PropTypes.object
};

BrandCard.defaultProps = {
  tabletType: 'generic',
  isFirstItem: false,
  useAlternativeLayout: false,
  isCurrentBrand: false,
  sx: {}
};

export default memo(BrandCard);