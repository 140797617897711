/**
 * Mobile styles for the NotificationBanner component
 * These styles are for mobile devices and extra small screens
 */

export const mobileContainerStyles = {
  py: 2,
  px: 2,
  minHeight: '85px',
  maxHeight: 'none',
  position: 'relative',
};

export const mobileTitleStyles = {
  fontSize: '14px',
  mb: 1,
  padding: '0 20px',
  textAlign: 'center',
  width: '100%',
  mx: 'auto',
};

export const mobileDescriptionStyles = {
  fontSize: '12px',
  padding: '0 15px',
  textAlign: 'center',
  width: '100%',
  maxWidth: 'calc(100% - 30px)',
  mx: 'auto',
};

export const mobileCloseButtonStyles = {
  padding: 0.5,
  right: 6,
  top: '50%',
  transform: 'translateY(-50%)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& .MuiSvgIcon-root': {
    fontSize: '1.5rem',
  },
}; 