import React from 'react';
import { Typography, Box, Grid, AccordionSummary, AccordionDetails } from '@mui/material';
import { StyledAccordion, StyledExpandIcon } from './StyledComponents';
import { BrandLogos } from './Logos';

export const QuickLinksAccordion = () => (
  <StyledAccordion sx={{ padding: 0, mb: 1 }}>
    <AccordionSummary 
      expandIcon={<StyledExpandIcon />}
      sx={{ 
        padding: '8px 0',
        minHeight: '48px',
        '& .MuiAccordionSummary-content': {
          margin: '8px 0'
        }
      }}
    >
      <Typography 
        sx={{ 
          color: '#E7ECF3', 
          fontSize: 16, 
          fontFamily: 'Open Sans', 
          fontWeight: '700', 
          wordWrap: 'break-word',
          textAlign: 'left'
        }}
      >
        Quick Links
      </Typography>
    </AccordionSummary>
    <AccordionDetails sx={{ padding: '0 16px 16px' }}>
      <BrandLogos height={40} marginLeft="10px" isMobile={false} />
    </AccordionDetails>
  </StyledAccordion>
);

export const QuickLinksTablet = () => (
  <Grid item xs={12} sm={3}>
    <Typography 
      variant="h5semiBold" 
      color='text.200' 
      gutterBottom
      sx={{ 
        color: '#E7ECF3', 
        fontSize: 16, 
        fontFamily: 'Open Sans', 
        fontWeight: '700', 
        wordWrap: 'break-word',
        mb: 3
      }}
    >
      Quick Links
    </Typography>
    <Box mt={2}>
      <BrandLogos height={50} marginLeft="10px" />
    </Box>
  </Grid>
);

export const QuickLinksDesktop = () => (
  <Grid item xs={12} sm={6} md={4}>
    <Typography 
      gutterBottom
      sx={{ 
        color: '#E7ECF3', 
        fontSize: 16, 
        fontFamily: 'Open Sans', 
        fontWeight: '700', 
        wordWrap: 'break-word',
        mb: 3
      }}
    >
      Quick Links
    </Typography>
    <Box sx={{ mt: 2 }}>
      <BrandLogos height={50} marginLeft="10px" />
    </Box>
  </Grid>
); 