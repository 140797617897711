import { useMediaQuery, useTheme } from '@mui/material';

/**
 * Hook personalizado para manejar la lógica responsiva del Footer
 * @returns {Object} Objeto con flags para los diferentes breakpoints
 */
export const useResponsiveFooter = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  return {
    theme,
    isMobile,
    isTablet,
    isDesktop
  };
};

export default useResponsiveFooter; 