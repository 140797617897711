import { useMediaQuery, useTheme } from '@mui/material';

/**
 * Custom hook para detectar tipos de dispositivo
 * Centraliza toda la lógica de breakpoints en un solo lugar
 * @returns {Object} Objeto con banderas de tipo de dispositivo
 */
export const useDeviceDetection = () => {
  const theme = useTheme();
  
  // Detección básica de dispositivos
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  
  // Detección específica para iPad Mini (744px)
  const isIpadMini = useMediaQuery('(min-width: 740px) and (max-width: 768px)');
  
  // Detección específica para iPad Air (820px)
  const isIpadAir = useMediaQuery('(min-width: 800px) and (max-width: 834px)');
  
  // Cualquier tipo de iPad
  const isIpadMiniOrAir = isIpadMini || isIpadAir;
  
  // Configuración de elementos dependientes del dispositivo
  const showPagination = !isMobile;
  
  return {
    isMobile,
    isTablet,
    isDesktop,
    isIpadMini,
    isIpadAir,
    isIpadMiniOrAir,
    showPagination
  };
}; 