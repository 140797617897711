/**
 * Tablet styles
 * Styles specific to tablet devices for the ProductCard component
 */

// Tablet-specific styles for the product card
export const tabletProductCardStyles = {
  width: '100%',
  height: '100%',
  marginBottom: '8px'
};

// Tablet-specific styles for the product image
export const tabletProductImageStyles = {
  height: 'auto',
  width: '100%'
};

// Tablet-specific styles for the content container
export const tabletContentContainerStyles = {
  height: 'auto',
  padding: '14px 16px'
};

// Tablet-specific styles for the product name
export const tabletProductNameStyles = {
  fontSize: '0.95rem'
};

// Tablet-specific styles for the product price
export const tabletProductPriceStyles = {
  fontSize: '1.125rem'
};

// Tablet-specific styles for the product details
export const tabletProductDetailsStyles = {
  padding: '6px 0'
};

// iPad Mini specific styles
export const iPadMiniProductCardStyles = {
  width: '100%',
  height: '100%'
};

export const iPadMiniProductImageStyles = {
  height: 'auto',
  width: '100%'
};

// iPad Air specific styles
export const iPadAirProductCardStyles = {
  width: '100%',
  height: '100%'
};

export const iPadAirProductImageStyles = {
  height: 'auto',
  width: '100%'
};

// iPad Pro specific styles
export const iPadProProductCardStyles = {
  width: '100%',
  height: '100%'
};

export const iPadProProductImageStyles = {
  height: 'auto',
  width: '100%'
}; 