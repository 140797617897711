import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Paper, Container, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import ReviewHeader from './ReviewHeader';

// Styled components
const PageContainer = styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  backgroundColor: theme.palette.background.default,
  paddingTop: '100px', // To account for the fixed header
  paddingBottom: '40px',
  display: 'flex',
  flexDirection: 'column',
}));

const ContentPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: theme.spacing(2),
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
  background: theme.palette.background.paper,
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(3),
  },
}));

const QRCodeContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(3),
  },
}));

const QRCodeImage = styled('img')(({ theme }) => ({
  maxWidth: '300px',
  width: '100%',
  height: 'auto',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    maxWidth: '250px',
  },
}));

const ReviewLanding = ({ location }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  // Define location-specific content
  const locationTitle = location === 'tampa' ? 'Tampa' : 'Winter Haven';
  
  // In a real app, you would use actual QR code images for each location
  // Here we're using placeholders - you'll need to replace these with actual images
  const qrCodeImage = location === 'tampa' 
    ? '/images/review/tampa.png' // Replace with actual path
    : '/images/review/winter.png'; // Replace with actual path

  // Split the title to properly render the line break
  const titleWithParams = t('review.title', { location: locationTitle });
  const titleParts = titleWithParams.split('\n');

  return (
    <>
      <ReviewHeader />
      <PageContainer>
        <Container maxWidth="md">
          <ContentPaper elevation={3}>
            <Typography 
              variant={isMobile ? 'h4' : 'h3'} 
              component="h1" 
              align="center" 
              color="primary" 
              gutterBottom
            >
              {titleParts[0]}
              {titleParts.length > 1 && (
                <>
                  <br />
                  {titleParts[1]}
                </>
              )}
            </Typography>
            
            <Typography 
              variant="h6" 
              component="h2" 
              align="center" 
              color="textSecondary" 
              gutterBottom
              sx={{ mb: 4 }}
            >
              {t('review.subtitle')}
            </Typography>
            
            <Typography 
              variant="body1" 
              component="p" 
              align="center" 
              paragraph
              sx={{ maxWidth: '600px', mx: 'auto' }}
            >
              {t('review.description')}
            </Typography>
            
            <QRCodeContainer>
              <QRCodeImage 
                src={qrCodeImage} 
                alt={t('review.qrCodeAlt', { location: locationTitle })} 
              />
            </QRCodeContainer>
            
            <Box sx={{ mt: 4, textAlign: 'center' }}>
              <Typography 
                variant="body2" 
                color="textSecondary"
              >
                {t('review.scanInstructions')}
              </Typography>
            </Box>
          </ContentPaper>
        </Container>
      </PageContainer>
    </>
  );
};

// Add PropTypes validation
ReviewLanding.propTypes = {
  location: PropTypes.oneOf(['tampa', 'winter']).isRequired,
};

export default ReviewLanding; 