/**
 * TabletCarousel component
 * Tablet-specific implementation of the CategoriesCarousel
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Container } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CategoryCard from './CategoryCard';
import {
  categoriesContainerStyles,
  tabletCategoriesContainerStyles,
  tabletCarouselContainerStyles,
  tabletCarouselContentStyles,
  iPadMiniCarouselContentStyles,
  iPadAirCarouselContentStyles,
  iPadProCarouselContentStyles,
  visuallyHiddenStyles
} from '../styles';

const TabletCarousel = ({ categories, tabletType }) => {
  const { t } = useTranslation();
  
  // Determine which carousel content styles to use based on tablet type
  let carouselContentStyles = { ...tabletCarouselContentStyles };
  
  if (tabletType === 'iPadMini') {
    carouselContentStyles = { ...carouselContentStyles, ...iPadMiniCarouselContentStyles };
  } else if (tabletType === 'iPadAir') {
    carouselContentStyles = { ...carouselContentStyles, ...iPadAirCarouselContentStyles };
  } else if (tabletType === 'iPadPro') {
    carouselContentStyles = { ...carouselContentStyles, ...iPadProCarouselContentStyles };
  }
  
  return (
    <Box sx={{ ...categoriesContainerStyles, ...tabletCategoriesContainerStyles }}>
      <Container maxWidth={false} disableGutters={true} sx={{ px: 0, py: 1, width: '100%', maxWidth: '100%' }}>
        {/* SEO description (visually hidden) */}
        <Typography sx={visuallyHiddenStyles}>
          {t('dashboard.categoriesCarousel.description')}
        </Typography>
        <Box sx={tabletCarouselContainerStyles}>
          <Box sx={carouselContentStyles}>
            {categories.map((category, index) => (
              <CategoryCard
                key={category.id}
                category={category}
                deviceType="tablet"
                tabletType={tabletType}
                isFirstItem={index === 0}
              />
            ))}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

TabletCarousel.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      key: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired
    })
  ).isRequired,
  tabletType: PropTypes.oneOf(['iPadMini', 'iPadAir', 'iPadPro', 'generic']).isRequired
};

export default TabletCarousel; 