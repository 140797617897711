import React from 'react';
import { Box, Typography, List, ListItem, ListItemText, Grid } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useTranslation } from 'react-i18next';
import {
  containerStyles,
  contentWrapperStyles,
  heroImageStyles,
  titleSectionStyles,
  titleStyles,
  subtitleStyles, 
  listItemStyles,
  listItemIconStyles,
  featureTextStyles,
  descriptionTextStyles,
  orangeDividerStyles
} from './styles';

// Hidden SEO styles for screen readers
const hiddenSeoStyles = {
  position: 'absolute', 
  width: '1px', 
  height: '1px', 
  padding: 0, 
  margin: '-1px', 
  overflow: 'hidden', 
  clip: 'rect(0, 0, 0, 0)', 
  whiteSpace: 'nowrap', 
  borderWidth: 0
};

const WhyChooseSYLLayout = () => {
  const { t } = useTranslation();

  // Define benefits using translations
  const benefits = [
    {
      id: 'safety-focused',
      title: t('dashboard.whyChooseSYL.benefits.safetyFocused.title'),
      description: t('dashboard.whyChooseSYL.benefits.safetyFocused.description'),
      seoTitle: t('dashboard.whyChooseSYL.benefits.safetyFocused.seoTitle'),
      seoDescription: t('dashboard.whyChooseSYL.benefits.safetyFocused.seoDescription')
    },
    {
      id: 'durable-materials',
      title: t('dashboard.whyChooseSYL.benefits.durableMaterials.title'),
      description: t('dashboard.whyChooseSYL.benefits.durableMaterials.description'),
      seoTitle: t('dashboard.whyChooseSYL.benefits.durableMaterials.seoTitle'),
      seoDescription: t('dashboard.whyChooseSYL.benefits.durableMaterials.seoDescription')
    },
    {
      id: 'strategic-partnership',
      title: t('dashboard.whyChooseSYL.benefits.strategicPartnership.title'),
      description: t('dashboard.whyChooseSYL.benefits.strategicPartnership.description'),
      seoTitle: t('dashboard.whyChooseSYL.benefits.strategicPartnership.seoTitle'),
      seoDescription: t('dashboard.whyChooseSYL.benefits.strategicPartnership.seoDescription')
    }
  ];

  return (
    <Box sx={containerStyles}>
      {/* Orange divider positioned in the background */}
      <Box sx={orangeDividerStyles} />
      
      {/* Removed Container to allow full width with custom margins */}
      <Box sx={contentWrapperStyles}>
        {/* Top Hero Image Section */}
        <Box 
          component="img"
          src="/images/why-choose-syl/why-choose-syl-home.webp" 
          alt={t('dashboard.whyChooseSYL.heroImageAlt')}
          sx={heroImageStyles}
        />
        
        {/* Main Content Section */}
        <Grid container spacing={4}>
          {/* Left Section - Title and Bullet Points */}
          <Grid item xs={12} md={5}>
            <Box sx={titleSectionStyles}>
              {/* Main UX Title */}
              <Typography variant="h2" sx={titleStyles}>
                {t('dashboard.whyChooseSYL.title')}
              </Typography>
              
              {/* Hidden SEO Title - accessible for screen readers */}
              <Typography sx={hiddenSeoStyles}>
                {t('dashboard.whyChooseSYL.seoTitle')}
              </Typography>
              
              {/* Hidden SEO Description - accessible for screen readers */}
              <Typography sx={hiddenSeoStyles}>
                {t('dashboard.whyChooseSYL.seoDescription')}
              </Typography>
              
              <List>
                {benefits.map((benefit) => (
                  <ListItem key={benefit.id} sx={listItemStyles}>
                    <CheckCircleOutlineIcon sx={listItemIconStyles} />
                    <Box>
                      {/* Visible benefit title */}
                      <ListItemText 
                        primary={benefit.title} 
                        primaryTypographyProps={{ sx: featureTextStyles }} 
                      />
                      
                      {/* Hidden detailed description - accessible for screen readers */}
                      <Typography sx={hiddenSeoStyles}>
                        {benefit.description}
                      </Typography>
                      
                      {/* Hidden SEO title - accessible for screen readers */}
                      <Typography sx={hiddenSeoStyles}>
                        {benefit.seoTitle}
                      </Typography>
                      
                      {/* Hidden SEO description - accessible for screen readers */}
                      <Typography sx={hiddenSeoStyles}>
                        {benefit.seoDescription}
                      </Typography>
                    </Box>
                  </ListItem>
                ))}
              </List>
            </Box>
          </Grid>
          
          {/* Right Section - Description Text */}
          <Grid item xs={12} md={7}>
            <Box sx={{ p: { xs: 3, md: 4 }, pt: { xs: 0, md: 4 } }}>
              {/* Visible subtitle */}
              <Typography sx={subtitleStyles}>
                {t('dashboard.whyChooseSYL.subtitle')}
              </Typography>
              
              {/* Hidden SEO subtitle - accessible for screen readers */}
              <Typography sx={hiddenSeoStyles}>
                {t('dashboard.whyChooseSYL.subtitleSeo')}
              </Typography>
              
              {/* Hidden SEO subtitle description - accessible for screen readers */}
              <Typography sx={hiddenSeoStyles}>
                {t('dashboard.whyChooseSYL.subtitleSeoDescription')}
              </Typography>
              
              {/* Visible description */}
              <Typography sx={descriptionTextStyles}>
                {t('dashboard.whyChooseSYL.description')}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default WhyChooseSYLLayout; 