import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AppStoreButton from '../../../../../../assets/app_store_icon.webp';
import GooglePlayButton from '../../../../../../assets/google_play_icon.webp';
import {
  ImageContainer,
  ContentContainer,
  AppButtonsContainer,
  AppButton
} from './styles';

const RightContent = () => {
  const { t } = useTranslation();
  
  return (
    <>
      <ImageContainer>
        <img 
          src="/images/login/Login.webp" 
          alt={t('auth.rightContent.mainImageAlt')}
          style={{ 
            width: 'auto',
            maxWidth: '100%', 
            height: 'auto',
            objectFit: 'contain',
            margin: 0
          }} 
        />
      </ImageContainer>
      
      <ContentContainer>
        <Typography 
          variant="h6" 
          gutterBottom 
          sx={{ 
            fontWeight: 600,
            mb: 1,
            fontSize: { xs: '1rem', sm: '1.1rem', md: '1.25rem' }
          }}
        >
          {t('auth.rightContent.appPromo')}
        </Typography>
        
        <AppButtonsContainer>
          <a 
            href="https://play.google.com/store/apps/details?id=com.sylroofing.supply&pcampaignid=web_share" 
            target="_blank" 
            rel="noopener noreferrer"
          >
            <AppButton 
              src={GooglePlayButton}
              alt={t('auth.rightContent.googlePlayAlt')}
            />
          </a>
          <a 
            href="https://apps.apple.com/es/app/syl-roofing-supply/id6599861493?l=en-GB" 
            target="_blank" 
            rel="noopener noreferrer"
          >
            <AppButton 
              src={AppStoreButton}
              alt={t('auth.rightContent.appStoreAlt')}
            />
          </a>
        </AppButtonsContainer>
      </ContentContainer>
    </>
  );
};

export default RightContent; 