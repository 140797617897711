/**
 * BrandHeader Desktop Styles
 * Estilos específicos para escritorio
 */

export const desktopContainerStyles = {
  padding: 3,
};

export const desktopBrandInfoContainerStyles = {
  padding: '10px 0',
};

export const desktopBrandImageStyles = {
  width: '100px',
  height: '100px',
  boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
};

export const desktopBrandContentStyles = {
  maxWidth: '70%',
};

export const desktopBrandTitleStyles = {
  fontSize: '1.375rem', // 22px
};

export const desktopBrandDescriptionStyles = {
  fontSize: '1rem',
  lineHeight: 1.8,
  maxWidth: '95%',
};

export const desktopFiltersContainerStyles = {
  gap: 2,
  flexWrap: 'wrap',
  padding: '15px 0',
};

// Estilos de desktop para el modal
export const desktopModalStyles = {
  width: '50%',
  maxWidth: '600px',
  padding: 4,
};

export const desktopModalImageStyles = {
  width: '120px',
  height: '120px',
  marginRight: 2,
};

export const desktopModalTitleStyles = {
  fontSize: '1.5rem',
  marginBottom: 2,
};

export const desktopModalDescriptionStyles = {
  fontSize: '1rem',
  lineHeight: 1.8,
};

// Nuevos estilos para el banner en desktop
export const desktopBannerContainerStyles = {
  width: '100%',
  position: 'relative',
  overflow: 'hidden',
  margin: '0 auto',
  marginTop: 0,
  borderRadius: 1,
};

export const desktopBannerImageStyles = {
  width: '100%',
  display: 'block',
  maxHeight: '500px',
  objectFit: 'contain',
  objectPosition: 'center center',
  backgroundColor: '#f5f5f5',
};

// Estilo para el contenedor del logo en desktop
export const desktopBrandHeaderBoxStyles = {
  marginTop: 4,
}; 