import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, CircularProgress } from '@mui/material';
import { StyledTextField, ActionButton, StyledTypography } from '../../styles';
import LoginBoxIllustration from '../../../../../../assets/login_newsletter.webp';

const NewsletterSubscriptionContent = ({ email: initialEmail, onClose, onSubscribe }) => {
  const [email, setEmail] = useState(initialEmail || '');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const nameInputRef = useRef(null);
  
  // Enfocar el campo de nombre al montar el componente
  useEffect(() => {
    if (nameInputRef.current) {
      nameInputRef.current.focus();
    }
  }, []);

  // Función para validar el formato del email
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setEmail(value);
    
    // Validación en tiempo real
    if (value && !validateEmail(value)) {
      setError('Please enter a valid email address');
    } else {
      setError(null);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!email) {
      setError('Email is required');
      return;
    }

    if (!validateEmail(email)) {
      setError('Please enter a valid email address');
      return;
    }
    
    setLoading(true);
    setError(null);

    try {
      const subscriptionData = {
        email,
        newsletter_subscriber: "true",
        subscription_source: "web"
      };
      
      console.log('📧 Attempting to subscribe with data:', subscriptionData);
      await onSubscribe(subscriptionData);
      console.log('✅ Newsletter subscription successful');
      onClose();
    } catch (error) {
      console.error('❌ Newsletter subscription error:', error);
      setError(error.message || 'An error occurred while subscribing. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: {xs: 1, sm: 2},
        height: '100%',
        justifyContent: 'center'
      }}
    >
      <Box 
        sx={{ 
          display: 'flex', 
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: 'center', 
          gap: { xs: 1, sm: 4 }
        }}
      >
        <Box 
          sx={{ 
            width: { xs: '150px', sm: '200px', md: '200px' },
            height: { xs: '150px', sm: '200px', md: '200px' },
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            order: { xs: -1, sm: 2 }
          }}
        >
          <img
            src={LoginBoxIllustration}
            alt="Newsletter illustration"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain'
            }}
          />
        </Box>

        <Box 
          sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            gap: 2,
            flex: 1,
            textAlign: { xs: 'center', sm: 'left' }
          }}
        >
          <Typography variant="h6" component="h6" sx={StyledTypography.title}>
            <span className="highlight">Stay Updated</span> with Our<br />Newsletter
          </Typography>

          <Typography variant="subtitle1" sx={StyledTypography.subtitle}>
            Subscribe to receive exclusive offers, latest news and expert advice.
          </Typography>
        </Box>
      </Box>

      <StyledTextField
        fullWidth
        type="email"
        placeholder="Email"
        value={email}
        onChange={handleChange}
        error={!!error}
        helperText={error}
        disabled={loading || !!initialEmail}
        inputRef={nameInputRef}
      />

      <ActionButton
        fullWidth
        type="submit"
        variant="contained"
        disabled={loading || !!error}
      >
        {loading ? <CircularProgress size={24} color="inherit" /> : 'Subscribe newsletter'}
      </ActionButton>
    </Box>
  );
};

export default NewsletterSubscriptionContent; 