/**
 * ProductCard Component
 * Displays a product card with image and details according to Figma design
 */

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

// Import styles
import {
  // Common styles
  commonProductCardStyles,
  commonProductImageStyles,
  commonContentContainerStyles,
  commonProductNameStyles,
  commonProductPriceStyles,
  
  // Mobile styles
  mobileProductCardStyles,
  mobileProductImageStyles,
  mobileContentContainerStyles,
  mobileProductNameStyles,
  mobileProductPriceStyles,
  
  // Tablet styles
  tabletProductCardStyles,
  tabletProductImageStyles,
  tabletContentContainerStyles,
  tabletProductNameStyles,
  tabletProductPriceStyles,
  iPadMiniProductCardStyles,
  iPadMiniProductImageStyles,
  iPadAirProductCardStyles,
  iPadAirProductImageStyles,
  iPadProProductCardStyles,
  iPadProProductImageStyles,
  
  // Desktop styles
  desktopProductCardStyles,
  desktopProductImageStyles,
  desktopContentContainerStyles,
  desktopProductNameStyles,
  desktopProductPriceStyles,
} from './styles';

const ProductCard = ({ product, deviceType, tabletType, sx = {} }) => {
  const navigate = useNavigate();
  
  // Función para manejar el clic en el producto
  const handleProductClick = () => {
    navigate(`/products/${product.id}`);
  };
  
  // Get device-specific styles
  const getProductCardStyles = () => {
    if (deviceType === 'mobile') {
      return { ...commonProductCardStyles, ...mobileProductCardStyles };
    }
    
    if (deviceType === 'tablet') {
      if (tabletType === 'iPadMini') {
        return { ...commonProductCardStyles, ...tabletProductCardStyles, ...iPadMiniProductCardStyles };
      }
      if (tabletType === 'iPadAir') {
        return { ...commonProductCardStyles, ...tabletProductCardStyles, ...iPadAirProductCardStyles };
      }
      if (tabletType === 'iPadPro') {
        return { ...commonProductCardStyles, ...tabletProductCardStyles, ...iPadProProductCardStyles };
      }
      return { ...commonProductCardStyles, ...tabletProductCardStyles };
    }
    
    return { ...commonProductCardStyles, ...desktopProductCardStyles };
  };
  
  const getProductImageStyles = () => {
    if (deviceType === 'mobile') {
      return { ...commonProductImageStyles, ...mobileProductImageStyles };
    }
    
    if (deviceType === 'tablet') {
      if (tabletType === 'iPadMini') {
        return { ...commonProductImageStyles, ...tabletProductImageStyles, ...iPadMiniProductImageStyles };
      }
      if (tabletType === 'iPadAir') {
        return { ...commonProductImageStyles, ...tabletProductImageStyles, ...iPadAirProductImageStyles };
      }
      if (tabletType === 'iPadPro') {
        return { ...commonProductImageStyles, ...tabletProductImageStyles, ...iPadProProductImageStyles };
      }
      return { ...commonProductImageStyles, ...tabletProductImageStyles };
    }
    
    return { ...commonProductImageStyles, ...desktopProductImageStyles };
  };
  
  const getContentContainerStyles = () => {
    if (deviceType === 'mobile') {
      return { ...commonContentContainerStyles, ...mobileContentContainerStyles };
    }
    
    if (deviceType === 'tablet') {
      return { ...commonContentContainerStyles, ...tabletContentContainerStyles };
    }
    
    return { ...commonContentContainerStyles, ...desktopContentContainerStyles };
  };
  
  const getProductNameStyles = () => {
    if (deviceType === 'mobile') {
      return { ...commonProductNameStyles, ...mobileProductNameStyles };
    }
    
    if (deviceType === 'tablet') {
      return { ...commonProductNameStyles, ...tabletProductNameStyles };
    }
    
    return { ...commonProductNameStyles, ...desktopProductNameStyles };
  };
  
  const getProductPriceStyles = () => {
    if (deviceType === 'mobile') {
      return { ...commonProductPriceStyles, ...mobileProductPriceStyles };
    }
    
    if (deviceType === 'tablet') {
      return { ...commonProductPriceStyles, ...tabletProductPriceStyles };
    }
    
    return { ...commonProductPriceStyles, ...desktopProductPriceStyles };
  };
  
  // Apply custom styles
  const cardStyles = {
    ...getProductCardStyles(),
    ...sx
  };
  
  // Formatear el precio con el símbolo de la moneda
  const formatPrice = (price, currency = '$') => {
    return `${currency}${price.toFixed(2)}`;
  };
  
  return (
    <Box 
      sx={cardStyles} 
      onClick={handleProductClick}
    >
      <Box 
        component="img"
        src={product.image} 
        alt={product.name} 
        sx={getProductImageStyles()}
      />
      <Box sx={getContentContainerStyles()}>
        <Typography sx={getProductNameStyles()}>
          {product.name}
        </Typography>
        <Typography sx={getProductPriceStyles()}>
          {formatPrice(product.price)}
        </Typography>
      </Box>
    </Box>
  );
};

ProductCard.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    image: PropTypes.string.isRequired
  }).isRequired,
  deviceType: PropTypes.oneOf(['mobile', 'tablet', 'desktop']).isRequired,
  tabletType: PropTypes.oneOf(['iPadMini', 'iPadAir', 'iPadPro', 'generic']),
  sx: PropTypes.object
};

ProductCard.defaultProps = {
  tabletType: 'generic',
  sx: {}
};

export default memo(ProductCard); 