/**
 * DesktopCarousel Component
 * Desktop-specific implementation of the BrandsCarousel
 */

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import BrandCard from './BrandCard';

// Import styles directly from their source files
import {
  commonContainerStyles,
  commonSeoDescriptionStyles,
  commonTitleStyles
} from '../styles/common';

import {
  desktopContainerStyles,
  desktopTitleStyles,
  desktopCarouselContainerStyles
} from '../styles/desktop';

const DesktopCarousel = ({ brands }) => {
  const { t } = useTranslation();
  
  return (
    <Box sx={{ ...commonContainerStyles, ...desktopContainerStyles }}>
      {/* Title - only shown on desktop */}
      <Typography 
        variant="h2" 
        component="h2" 
        sx={{ ...commonTitleStyles, ...desktopTitleStyles }}
      >
        {t('dashboard.brandsCarousel.title')}
      </Typography>
      
      {/* Hidden SEO description */}
      <Typography 
        component="p" 
        sx={commonSeoDescriptionStyles}
      >
        {t('dashboard.brandsCarousel.description')}
      </Typography>
      
      <Box sx={desktopCarouselContainerStyles}>
        {brands.map((brand) => (
          <BrandCard 
            key={brand.id} 
            brand={brand} 
            deviceType="desktop"
          />
        ))}
      </Box>
    </Box>
  );
};

DesktopCarousel.propTypes = {
  brands: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      key: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired
    })
  ).isRequired
};

export default memo(DesktopCarousel); 