import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  TextField, 
  Button,
  Grid,
  styled,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Snackbar,
  Alert
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { toast, Toaster } from 'react-hot-toast';
import {
  containerStyles,
  leftColumnStyles,
  titleStyles,
  descriptionStyles,
  imageContainerStyles,
  imageStyles,
  formContainerStyles,
  buttonStyles,
  StyledTextFieldStyles,
  contentContainerStyles
} from './styles';
import contactService from '../../../../shared/services/contactService';

const StyledTextField = styled(TextField)(({ theme }) => StyledTextFieldStyles(theme));

// Utility function to detect mobile devices
const isMobileDevice = () => {
  return /Android|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};

const ContactForm = () => {
  const { t } = useTranslation();
  
  const [formData, setFormData] = useState({
    firstName: '',
    email: '',
    phoneNumber: '',
    message: ''
  });
  
  // Estado para manejar errores, procesamiento y notificaciones
  const [processing, setProcessing] = useState(false);
  const [formError, setFormError] = useState('');
  const [notification, setNotification] = useState({ open: false, message: '', severity: 'success' });
  const [contactMethod, setContactMethod] = useState('');
  const [deviceType, setDeviceType] = useState('');

  // Detectar el tipo de dispositivo al cargar el componente
  useEffect(() => {
    // Determinar el tipo de dispositivo
    const userAgent = navigator.userAgent;
    if (/Android|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)) {
      setDeviceType('mobile');
      setContactMethod('whatsapp');
    } else if (/iPad|tablet|Tablet/i.test(userAgent)) {
      setDeviceType('tablet');
      setContactMethod('email');
    } else {
      setDeviceType('desktop');
      setContactMethod('email');
    }
  }, []);

  const handleMethodChange = (event) => {
    setContactMethod(event.target.value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
    // Resetear error cuando el usuario cambia algo
    if (formError) setFormError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setProcessing(true);
    setFormError('');
    
    try {
      // Utilizamos el servicio de contacto para elegir automáticamente el método adecuado
      let result;
      
      if (contactMethod === 'email') {
        result = await contactService.sendEmail(formData);
        if (result) {
          toast.success(t('dashboard.contactForm.success.email'));
        } else {
          setFormError(t('dashboard.contactForm.errors.emailFailed'));
        }
      } else {
        result = contactService.openWhatsApp(contactService.createMessage(formData));
        if (result) {
          toast.success(t('dashboard.contactForm.success.whatsapp'));
        } else {
          setFormError(t('dashboard.contactForm.errors.whatsappFailed'));
        }
      }

      if (result) {
        // Reset form on success
        setFormData({
          firstName: '',
          email: '',
          phoneNumber: '',
          message: ''
        });
      }
    } catch (error) {
      console.error('Error contacting customer service:', error);
      setFormError(t('dashboard.contactForm.errors.generalError'));
    } finally {
      setProcessing(false);
    }
  };

  const handleCloseNotification = () => {
    setNotification({ ...notification, open: false });
  };

  // Texto del botón dinámico basado en el método de contacto
  const getButtonText = () => {
    if (processing) {
      return t('dashboard.contactForm.processingButton');
    }
    
    return contactMethod === 'whatsapp' 
      ? t('dashboard.contactForm.submitWhatsAppButton') 
      : t('dashboard.contactForm.submitEmailButton');
  };

  return (
    <Box sx={containerStyles}>
      {/* Toast notifications */}
      <Toaster position="top-center" />
      
      <Snackbar 
        open={notification.open} 
        autoHideDuration={6000} 
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseNotification} severity={notification.severity}>
          {notification.message}
        </Alert>
      </Snackbar>
      
      <Grid container spacing={{ xs: 0, md: 3 }}>
        {/* Left Column - Content */}
        <Grid item xs={12} md={6}>
          <Box sx={leftColumnStyles}>
            {/* Content container for title and description */}
            <Box sx={contentContainerStyles}>
              <Typography variant="h3" sx={titleStyles}>
                {t('dashboard.contactForm.title')}
              </Typography>
              <Typography variant="body1" sx={descriptionStyles}>
                {t('dashboard.contactForm.description')}
              </Typography>
              
              {/* Hidden SEO content */}
              <Typography 
                sx={{ 
                  position: 'absolute', 
                  width: '1px', 
                  height: '1px', 
                  padding: 0, 
                  margin: '-1px', 
                  overflow: 'hidden', 
                  clip: 'rect(0, 0, 0, 0)', 
                  whiteSpace: 'nowrap', 
                  borderWidth: 0 
                }}
              >
                {t('dashboard.contactForm.seoTitle')}
              </Typography>
              <Typography 
                sx={{ 
                  position: 'absolute', 
                  width: '1px', 
                  height: '1px', 
                  padding: 0, 
                  margin: '-1px', 
                  overflow: 'hidden', 
                  clip: 'rect(0, 0, 0, 0)', 
                  whiteSpace: 'nowrap', 
                  borderWidth: 0 
                }}
              >
                {t('dashboard.contactForm.seoDescription')}
              </Typography>
            </Box>
            
            {/* Image container */}
            <Box sx={imageContainerStyles}>
              <Box 
                component="img"
                src="/images/contact-image.webp"
                alt={t('dashboard.contactForm.imageAlt')}
                sx={imageStyles}
              />
            </Box>
          </Box>
        </Grid>

        {/* Right Column - Form */}
        <Grid item xs={12} md={6}>
          <Box sx={formContainerStyles} component="form" onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <StyledTextField
                  fullWidth
                  label={t('dashboard.contactForm.fields.firstName')}
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  variant="standard"
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <StyledTextField
                  fullWidth
                  label={t('dashboard.contactForm.fields.email')}
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  variant="standard"
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <StyledTextField
                  fullWidth
                  label={t('dashboard.contactForm.fields.phoneNumber')}
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  variant="standard"
                  placeholder={t('dashboard.contactForm.fields.phoneNumberPlaceholder')}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <StyledTextField
                  fullWidth
                  label={t('dashboard.contactForm.fields.message')}
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  variant="standard"
                  multiline
                  rows={3}
                />
              </Grid>
              
              {/* Mostrar opciones de contacto solo en tablet o desktop */}
              {(deviceType === 'tablet' || deviceType === 'desktop') && (
                <Grid item xs={12}>
                  <Typography variant="subtitle2" gutterBottom>
                    {t('dashboard.contactForm.contactMethodLabel')}
                  </Typography>
                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      name="contactMethod"
                      value={contactMethod}
                      onChange={handleMethodChange}
                    >
                      <FormControlLabel 
                        value="whatsapp" 
                        control={<Radio />} 
                        label={t('dashboard.contactForm.methods.whatsapp')} 
                      />
                      <FormControlLabel 
                        value="email" 
                        control={<Radio />} 
                        label={t('dashboard.contactForm.methods.email')} 
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              )}
              
              <Grid item xs={12} sx={{ textAlign: 'center' }}>
                {formError && (
                  <Typography color="error" sx={{ mb: 2 }}>
                    {formError}
                  </Typography>
                )}
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  sx={buttonStyles}
                  fullWidth
                  disabled={processing}
                >
                  {getButtonText()}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContactForm; 