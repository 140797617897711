/**
 * Gets the initials from company name or email
 * @param {string} companyName - The company name
 * @param {string} email - The email address (used as fallback)
 * @returns {string} The initials
 */
export const getInitials = (companyName, email) => {
  if (companyName) {
    const words = companyName.split(" ");
    
    if (words.length >= 2) {
      const firstInitial = words[0].charAt(0);
      const secondInitial = words[1].charAt(0);
      return (firstInitial + secondInitial).toUpperCase();
    } else if (words.length === 1) {
      return words[0].substring(0, 2).toUpperCase();
    }
  }
  
  if (email) {
    const username = email.split("@")[0];
    const words = username.split(".");
    
    if (words.length >= 2) {
      const firstInitial = words[0].charAt(0);
      const secondInitial = words[1].charAt(0);
      return (firstInitial + secondInitial).toUpperCase();
    } else {
      return username.substring(0, 2).toUpperCase();
    }
  }
  
  return "UN";
}; 