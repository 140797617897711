import React from 'react';
import PropTypes from 'prop-types';
import { Routes, Route, Navigate } from 'react-router-dom';
import RegisterPage from '../modules/auth/pages/RegisterPage';
import LoginPage from '../modules/auth/pages/LoginPage';
import CustomerDetail from '../modules/customer/pages/CustomerDetail';
import DashboardHome from '../modules/dashboard/pages/DashboardHome';
import InvoicesPage from '../modules/invoices/pages/InvoicesPage';
import TampaLocationPage from '../modules/locations/pages/TampaLocationPage';
import OrlandoLocationPage from '../modules/locations/pages/OrlandoLocationPage';
import TampaReviewPage from '../modules/review/pages/TampaReviewPage';
import WinterReviewPage from '../modules/review/pages/WinterReviewPage';
import BrandProductsPage from '../modules/brands/pages/BrandProductsPage';
import { useAuth } from '../shared/context/AuthProvider';

const PrivateRoute = ({ children }) => {
    const { user } = useAuth();
    return user ? children : <Navigate to="/login" replace />;
};

PrivateRoute.propTypes = {
    children: PropTypes.node.isRequired
};

const AppRoutes = () => {
  return (
    <Routes>
      {/* Rutas públicas */}
      <Route path="/" element={<DashboardHome />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path='/signup' element={<RegisterPage />}/>
      
      {/* Rutas de ubicaciones */}
      <Route path='/locations/tampa' element={<TampaLocationPage />} />
      <Route path='/locations/orlando' element={<OrlandoLocationPage />} />
      
      {/* Rutas para reseñas de Google (públicas) */}
      <Route path='/review-tampa' element={<TampaReviewPage />} />
      <Route path='/review-winter' element={<WinterReviewPage />} />
      
      {/* Ruta para la página de productos de marca */}
      <Route 
        path="/brands/:brandId" 
        element={<BrandProductsPage />} 
      />
      
      {/* Rutas protegidas */}
      <Route 
        path='/customerDetail' 
        element={
          <PrivateRoute>
            <CustomerDetail />
          </PrivateRoute>
        }
      />
      <Route 
        path='/invoices' 
        element={
          <PrivateRoute>
            <InvoicesPage />
          </PrivateRoute>
        }
      />
      
      {/* Ruta por defecto */}
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default AppRoutes;