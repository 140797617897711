/**
 * Device detection utilities
 * 
 * Provides hooks and functions to detect device types and specific tablet models
 */
import { useMediaQuery, useTheme } from '@mui/material';

/**
 * Custom hook for device detection
 * Returns the current device type and specific tablet model if applicable
 * 
 * @returns {Object} Device type information
 */
export const useDeviceDetection = () => {
  const theme = useTheme();

  // Basic device detection
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  // Specific tablet model detection
  const isIpadMini = useMediaQuery('(min-width: 740px) and (max-width: 768px)');
  const isIpadAir = useMediaQuery('(min-width: 800px) and (max-width: 834px)');
  const isIpadPro = useMediaQuery('(min-width: 1000px) and (max-width: 1024px)');
  
  // Any iPad model
  const isAnyIpad = isIpadMini || isIpadAir || isIpadPro;
  
  // Determine tablet type
  let tabletType = null;
  if (isTablet || isAnyIpad) {
    if (isIpadMini) tabletType = 'iPadMini';
    else if (isIpadAir) tabletType = 'iPadAir';
    else if (isIpadPro) tabletType = 'iPadPro';
    else tabletType = 'generic';
  }

  return {
    isMobile,
    isTablet: isTablet || isAnyIpad,  // Consider iPads as tablets
    isDesktop,
    tabletType
  };
}; 