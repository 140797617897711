/**
 * CategoryCard component
 * Displays a single category with an image and name
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import {
  categoryCardStyles,
  circleImageStyles,
  categoryNameStyles,
  // Mobile styles
  mobileCategoryCardStyles,
  mobileCircleImageStyles,
  mobileCategoryNameStyles,
  // Tablet styles
  tabletCategoryCardStyles,
  tabletCircleImageStyles,
  tabletCategoryNameStyles,
  // iPad Mini styles
  iPadMiniCategoryCardStyles,
  iPadMiniCircleImageStyles,
  iPadMiniCategoryNameStyles,
  // iPad Air styles
  iPadAirCategoryCardStyles,
  iPadAirCircleImageStyles,
  iPadAirCategoryNameStyles,
  // iPad Pro styles
  iPadProCategoryCardStyles,
  iPadProCircleImageStyles,
  iPadProCategoryNameStyles,
  // Desktop styles
  desktopCategoryCardStyles,
  desktopCircleImageStyles,
  desktopCategoryNameStyles
} from '../styles';

const CategoryCard = ({ category, deviceType, tabletType, isFirstItem }) => {
  // Determine which style sets to apply based on device
  let cardStyles = { ...categoryCardStyles };
  let imageStyles = { ...circleImageStyles };
  let nameStyles = { ...categoryNameStyles };

  if (deviceType === 'mobile') {
    cardStyles = { ...cardStyles, ...mobileCategoryCardStyles };
    imageStyles = { ...imageStyles, ...mobileCircleImageStyles };
    nameStyles = { ...nameStyles, ...mobileCategoryNameStyles };
  } else if (deviceType === 'tablet') {
    // Apply tablet-specific styles based on tablet type
    if (tabletType === 'iPadMini') {
      cardStyles = { ...cardStyles, ...iPadMiniCategoryCardStyles };
      imageStyles = { ...imageStyles, ...iPadMiniCircleImageStyles };
      nameStyles = { ...nameStyles, ...iPadMiniCategoryNameStyles };
    } else if (tabletType === 'iPadAir') {
      cardStyles = { ...cardStyles, ...iPadAirCategoryCardStyles };
      imageStyles = { ...imageStyles, ...iPadAirCircleImageStyles };
      nameStyles = { ...nameStyles, ...iPadAirCategoryNameStyles };
    } else if (tabletType === 'iPadPro') {
      cardStyles = { ...cardStyles, ...iPadProCategoryCardStyles };
      imageStyles = { ...imageStyles, ...iPadProCircleImageStyles };
      nameStyles = { ...nameStyles, ...iPadProCategoryNameStyles };
    } else {
      // Generic tablet
      cardStyles = { ...cardStyles, ...tabletCategoryCardStyles };
      imageStyles = { ...imageStyles, ...tabletCircleImageStyles };
      nameStyles = { ...nameStyles, ...tabletCategoryNameStyles };
    }
  } else {
    // Desktop
    cardStyles = { ...cardStyles, ...desktopCategoryCardStyles };
    imageStyles = { ...imageStyles, ...desktopCircleImageStyles };
    nameStyles = { ...nameStyles, ...desktopCategoryNameStyles };
  }

  // Add margin to first item based on device (only for horizontal layouts)
  if (isFirstItem && deviceType !== 'mobile') {
    if (deviceType === 'tablet') {
      cardStyles = { ...cardStyles, marginLeft: 2 };
    }
  }

  return (
    <Box sx={cardStyles}>
      <Box sx={{
        ...imageStyles,
        margin: deviceType === 'mobile' ? '0 auto' : undefined
      }}>
        <img src={category.image} alt={category.alt} />
      </Box>
      <Typography sx={{
        ...nameStyles,
        textAlign: deviceType === 'mobile' ? 'center' : 'center'
      }}>
        {category.name}
      </Typography>
    </Box>
  );
};

CategoryCard.propTypes = {
  category: PropTypes.shape({
    id: PropTypes.number.isRequired,
    key: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired
  }).isRequired,
  deviceType: PropTypes.oneOf(['mobile', 'tablet', 'desktop']).isRequired,
  tabletType: PropTypes.oneOf(['iPadMini', 'iPadAir', 'iPadPro', 'generic', null]),
  isFirstItem: PropTypes.bool
};

CategoryCard.defaultProps = {
  tabletType: null,
  isFirstItem: false
};

export default CategoryCard; 