import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL;

// Logs para debugging
console.log('Environment:', process.env.NODE_ENV);
console.log('API URL:', process.env.REACT_APP_API_URL);
console.log('Using BASE_URL:', BASE_URL);

if (!BASE_URL) {
  console.error('API URL not configured. Please check your environment variables.');
}

export const apiClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Request interceptor
apiClient.interceptors.request.use(
  async (config) => {
    // Get auth user from sessionStorage
    const authUser = sessionStorage.getItem('authUser');
    if (authUser) {
      const { token, tokenExpiration } = JSON.parse(authUser);
      
      // Check if token is expired
      if (tokenExpiration > new Date().getTime()) {
        config.headers.Authorization = `Bearer ${token}`;
      } else {
        // Remove expired auth data
        sessionStorage.removeItem('authUser');
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor
apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    // Manejar errores de autenticación (401) y token expirado (403 en algunos casos)
    if (error.response?.status === 401 || error.response?.status === 403) {
      console.log(`🔒 Error de autenticación en API: ${error.response.status}`);
      console.log('📝 URL que causó el error:', error.config.url);
      
      // Limpiar datos de autenticación completamente
      console.log('🧹 Limpiando completamente datos de autenticación...');
      localStorage.clear();
      sessionStorage.clear();
      
      // Detectar si es un dispositivo móvil
      const isMobile = window.innerWidth < 768;
      console.log('📱 ¿Es dispositivo móvil?', isMobile);
      
      // Forzar un evento de storage para sincronizar otras pestañas
      try {
        window.dispatchEvent(new Event('storage'));
        console.log('✅ Evento storage disparado');
      } catch (eventError) {
        console.error('Error al disparar evento storage:', eventError);
      }
      
      // En dispositivos móviles, añadir un pequeño retraso antes de redirigir
      if (isMobile) {
        console.log('📱 Detectado dispositivo móvil, añadiendo retraso para limpieza de sesión');
        
        // Limpiar cualquier caché de React Query
        if (window.queryClient) {
          try {
            window.queryClient.clear();  // Limpiar toda la caché
            console.log('✅ Caché de React Query completamente limpiada');
          } catch (cacheError) {
            console.error('Error al limpiar caché:', cacheError);
          }
        }
        
        // Añadir un timestamp para evitar caché del navegador
        const timestamp = new Date().getTime();
        
        setTimeout(() => {
          console.log('🔄 Redirigiendo a la página de login...');
          window.location.href = `/login?session_expired=true&mobile=true&t=${timestamp}`;
        }, 500);
      } else {
        console.log('🔄 Redirigiendo a la página de login...');
        const timestamp = new Date().getTime();
        window.location.href = `/login?session_expired=true&t=${timestamp}`;
      }
    }
    return Promise.reject(error);
  }
);

export default apiClient; 