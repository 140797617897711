// Desktop-specific styles
export const desktopCategoriesContainerStyles = {
  width: '100%'
};

export const desktopTitleStyles = {
  display: 'block',
  paddingLeft: 0
};

export const desktopCarouselContainerStyles = {
  paddingBottom: 2,
  width: '100%',
  overflowX: 'auto',
  overflowY: 'hidden', // Prevent vertical scrolling
  scrollbarWidth: 'thin', // Better scrollbar for better UX with more cards
  '&::-webkit-scrollbar': {
    height: '6px' // Thinner scrollbar for webkit browsers
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(0,0,0,0.2)', // Visible but subtle scrollbar thumb
    borderRadius: '3px'
  }
};

export const desktopCarouselContentStyles = {
  display: 'flex',
  flexDirection: 'row', // Ensure horizontal alignment
  flexWrap: 'nowrap', // Prevent wrapping to new line
  gap: 4,
  paddingLeft: 0,
  paddingRight: 2, // Add padding to the right for better scrolling UX
  width: 'max-content', // Allow content to determine width for proper scrolling
  '& > :first-of-type': {
    marginLeft: 0
  }
};

export const desktopCategoryCardStyles = {
  width: '150px'
};

export const desktopCircleImageStyles = {
  width: '130px',
  height: '130px'
};

export const desktopCategoryNameStyles = {
  fontSize: '16px',
  maxWidth: '130px'
}; 