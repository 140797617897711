/**
 * Desktop styles
 * Styles specific to desktop devices
 */

// Desktop-specific styles for the container
export const desktopContainerStyles = {
  padding: 0,
  width: '100%',
  backgroundColor: '#FFFFFF',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
};

// Desktop-specific styles for the title
export const desktopTitleStyles = {
  fontSize: '2rem',
  fontWeight: 600,
  textAlign: 'center',
  marginBottom: '32px',
  color: '#0A3954',
  width: '100%'
};

// Desktop-specific styles for the carousel container
export const desktopCarouselContainerStyles = {
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(140px, 1fr))',
  gap: '16px',
  width: '100%',
  maxWidth: '100%',
  margin: '0 auto',
  padding: '24px 8px'
};

// Desktop-specific styles for slider settings
export const desktopSliderSettings = {
  dots: false,
  infinite: true,
  speed: 8000,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 0,
  cssEase: 'linear',
  arrows: false,
  swipeToSlide: true,
  pauseOnHover: true
};

// Desktop-specific styles for brand cards
export const desktopBrandCardStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: '#FFFFFF',
  borderRadius: '8px',
  border: '1px solid rgba(0, 0, 0, 0.08)',
  width: '140px',
  height: '120px',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
  transition: 'transform 0.2s, box-shadow 0.2s',
  cursor: 'pointer',
  overflow: 'hidden',
  padding: 0,
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
  }
};

// Desktop-specific styles for brand image container
export const desktopBrandImageContainerStyles = {
  width: '100%',
  height: '80px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '12px',
  backgroundColor: '#FFFFFF'
};

// Desktop-specific styles for brand name container
export const desktopBrandNameStyles = {
  width: '100%',
  textAlign: 'center',
  backgroundColor: '#0A3954',
  padding: '8px'
};

// Desktop-specific styles for brand name typography
export const desktopBrandTypographyStyles = {
  fontSize: '0.875rem',
  fontWeight: 500,
  lineHeight: 1.2,
  color: '#FFFFFF'
}; 