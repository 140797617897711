// Mobile-specific styles
export const mobileCategoriesContainerStyles = {
  width: '100%',
  maxWidth: '100%',
  paddingBottom: 3
};

export const mobileTitleStyles = {
  display: 'none',
  paddingLeft: 2
};

export const mobileCarouselContainerStyles = {
  paddingBottom: 1,
  width: '100%',
  maxWidth: '100%',
  overflowY: 'auto',
  overflowX: 'hidden',
  maxHeight: '100%'
};

export const mobileCarouselContentStyles = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  gap: 2,
  padding: '0 16px 16px',
  width: '100%',
  boxSizing: 'border-box'
};

export const mobileCategoryCardStyles = {
  width: 'calc(50% - 8px)',
  marginBottom: 2
};

export const mobileCircleImageStyles = {
  width: '100%',
  maxWidth: '90px',
  height: '90px'
};

export const mobileCategoryNameStyles = {
  fontSize: '14px',
  maxWidth: '110px'
}; 