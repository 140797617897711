import React from 'react';
import {
  Box,
  Popper,
  Paper,
  Fade,
  ClickAwayListener,
  useTheme,
  useMediaQuery
} from '@mui/material';
import { useAuth } from '../../../../shared/context/AuthProvider';
import ProfileContent from '../ProfileContent';

const ProfileModal = ({ open, onClose, anchorEl, isDrawer = false }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { logout } = useAuth();

  const handleLogout = async () => {
    try {
      console.log('🔒 ProfileModal: handleLogout iniciado');
      
      // 1. Cerrar el modal primero para mejorar la experiencia de usuario
      onClose();
      console.log('✅ Modal cerrado');
      
      // 2. Limpiar completamente localStorage y sessionStorage
      localStorage.clear();
      sessionStorage.clear();
      console.log('✅ Storage completamente limpiado');
      
      // 3. Llamar a la función de logout del contexto de autenticación
      await logout();
      console.log('✅ Función logout del contexto ejecutada');
      
      // 4. Forzar un evento de storage para sincronizar otras pestañas
      try {
        window.dispatchEvent(new Event('storage'));
        console.log('✅ Evento storage disparado');
      } catch (eventError) {
        console.error('Error al disparar evento storage:', eventError);
      }
      
      // 5. Limpiar caché de React Query si está disponible
      if (window.queryClient) {
        try {
          window.queryClient.clear();
          console.log('✅ Caché de React Query limpiada');
        } catch (cacheError) {
          console.error('Error al limpiar caché:', cacheError);
        }
      }
      
      // 6. Añadir un timestamp para evitar caché del navegador
      const timestamp = new Date().getTime();
      
      // 7. Añadir un retraso antes de redirigir para asegurar que todas las operaciones de limpieza se completen
      console.log('⏱️ Esperando para asegurar limpieza completa...');
      setTimeout(() => {
        // Forzar una recarga completa para asegurar que todos los estados se reseteen
        console.log('🔄 Redirigiendo con recarga completa...');
        window.location.href = `/?logout=true&t=${timestamp}`;
      }, 500);
    } catch (error) {
      console.error('❌ Error durante logout desde modal:', error);
      // En caso de error, intentar una redirección forzada
      window.location.href = '/?error=true&t=' + new Date().getTime();
    }
  };

  const modalContent = (
    <Box sx={{ 
      width: { xs: 'calc(100vw - 32px)', sm: '320px' },
      maxHeight: { xs: 'calc(100vh - 100px)', sm: 'auto' },
      overflow: 'auto'
    }}>
      <ProfileContent onLogout={handleLogout} isDrawer={isDrawer} onClose={onClose} />
    </Box>
  );

  if (isMobile || isDrawer) {
    return (
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          bgcolor: 'background.paper',
          display: open ? 'block' : 'none'
        }}
      >
        {modalContent}
      </Box>
    );
  }

  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      placement="bottom-end"
      transition
      style={{
        zIndex: theme.zIndex.modal,
        marginTop: '8px'
      }}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Paper
            elevation={6}
            sx={{
              borderRadius: 2,
              overflow: 'hidden',
              backgroundColor: 'white'
            }}
          >
            <ClickAwayListener onClickAway={onClose}>
              {modalContent}
            </ClickAwayListener>
          </Paper>
        </Fade>
      )}
    </Popper>
  );
};

export default ProfileModal; 