export const containerStyles = {
  width: '100%',
  py: { xs: 3, md: 6 },
  mt: { xs: 0, md: 'auto' },
  backgroundColor: '#FFFFFF',
  boxShadow: '0px 0px 59.73px 29.87px rgba(0, 0, 0, 0.03)',
  maxWidth: { xs: '100%', md: 'auto' },
  px: { xs: 0, sm: '45px', md: 0 }
};

export const leftColumnStyles = {
  backgroundColor: theme => theme.palette.secondary[700],
  borderRadius: { xs: 0, sm: '8px', md: 2 },
  p: { xs: '24px 0 0 0', sm: 0, md: 4 },
  height: { xs: '100%', sm: '300px', md: '100%' },
  display: 'flex',
  flexDirection: { xs: 'column', sm: 'row', md: 'column' },
  justifyContent: 'center',
  alignItems: { xs: 'flex-start', sm: 'center', md: 'flex-start' },
  overflow: { xs: 'hidden', sm: 'hidden', md: 'visible' },
  position: 'relative',
  gap: { xs: 0, sm: 0, md: 0 }
};

export const contentContainerStyles = {
  display: { xs: 'block', sm: 'flex', md: 'block' },
  flexDirection: 'column',
  justifyContent: 'center',
  width: { xs: '100%', sm: '50%', md: '100%' },
  height: { sm: '100%', md: 'auto' },
  py: { sm: 2, md: 0 },
  px: { xs: 3, sm: 3, md: 4 }
};

export const titleStyles = {
  color: 'white',
  fontWeight: 600,
  mb: 2,
  fontSize: { xs: '27.87px', sm: '24px', md: '2.5rem' },
  fontFamily: 'Open Sans, sans-serif',
  lineHeight: { xs: '41.87px', sm: '32px', md: 1.2 },
  wordWrap: 'break-word',
  px: { xs: 3, md: 0 }
};

export const descriptionStyles = {
  color: '#C9C9C9',
  mb: 3,
  fontSize: { xs: '1.1rem', sm: '1rem', md: '1.1rem' },
  lineHeight: 1.6,
  px: { xs: 3, md: 0 },
  fontFamily: 'Open Sans, sans-serif'
};

export const imageContainerStyles = {
  width: { xs: '100vw', sm: '50%', md: '100%' },
  position: { xs: 'relative', sm: 'static', md: 'static' },
  left: { xs: '50%', sm: 'auto', md: 'auto' },
  transform: { xs: 'translateX(-50%)', sm: 'none', md: 'none' },
  mt: { xs: 'auto', sm: 0, md: 'auto' },
  display: 'flex',
  alignItems: 'center',
  justifyContent: { xs: 'center', sm: 'center', md: 'center' },
  height: { xs: 'auto', sm: '100%', md: 'auto' },
  padding: 0,
  overflow: 'hidden'
};

export const imageStyles = {
  width: '100%',
  height: '100%',
  maxHeight: { xs: 'auto', sm: 'none', md: 'auto' },
  borderRadius: { xs: 0, sm: '0 8px 8px 0', md: 2 },
  display: 'block',
  objectFit: 'cover',
  objectPosition: 'center'
};

export const formContainerStyles = {
  backgroundColor: 'white',
  borderRadius: { xs: 0, md: 2 },
  p: { xs: 3, md: 4 },
  height: '100%',
  mt: { xs: 0, sm: 3, md: 0 }
};

export const buttonStyles = {
  py: 2,
  px: 4,
  width: '100%',
  backgroundColor: '#F26522',
  color: 'white',
  fontWeight: 600,
  fontSize: '1.1rem',
  borderRadius: '30px',
  boxShadow: 'none',
  textAlign: 'center',
  '&:hover': {
    backgroundColor: '#d54d0d',
    boxShadow: 'none',
  },
  transition: 'all 0.2s ease'
};

export const StyledTextFieldStyles = (theme) => ({
  '& .MuiInputBase-root': {
    '&:before': {
      borderBottom: '1px solid #E0E0E0'
    },
    '&:hover:not(.Mui-disabled):before': {
      borderBottom: '2px solid #E0E0E0'
    }
  },
  '& .MuiInputBase-input': {
    padding: '8px 0',
    fontSize: '1rem',
    '&::placeholder': {
      color: '#9E9E9E',
      opacity: 1
    }
  },
  '& .MuiInputLabel-root': {
    color: '#9E9E9E',
    fontSize: '1rem',
    '&.Mui-focused': {
      color: theme.palette.primary.main
    }
  },
  // Estilos específicos para tablets
  [theme.breakpoints.only('sm')]: {
    '& .MuiInputBase-input': {
      fontSize: '0.95rem',
    },
    '& .MuiInputLabel-root': {
      fontSize: '0.95rem',
    }
  }
}); 