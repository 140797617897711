import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import ReviewLanding from '../components/ReviewLanding';

const WinterReviewPage = () => {
  const { t, i18n } = useTranslation();
  
  useEffect(() => {
    // This ensures the language detector is triggered on component mount
    // It will check the browser language if not already set in localStorage
    const detectLanguage = async () => {
      try {
        // This forces a language detection check
        await i18n.changeLanguage(i18n.language);
        console.log('Language detected:', i18n.language);
      } catch (error) {
        console.error('Error detecting language:', error);
      }
    };
    
    detectLanguage();
  }, [i18n]);
  
  return (
    <>
      <Helmet>
        <title>{t('review.winterPageTitle', 'Winter Haven Review - SYL Customer')}</title>
        <meta name="description" content={t('review.winterMetaDescription', 'Leave a review for SYL Winter Haven location')} />
        <html lang={i18n.language} /> {/* Set the HTML lang attribute based on detected language */}
      </Helmet>
      <ReviewLanding location="winter" />
    </>
  );
};

export default WinterReviewPage; 