import React, { useCallback } from 'react';
import { Box } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import { useTranslation } from 'react-i18next';
import contactService from '../../../../shared/services/contactService';
import PropTypes from 'prop-types';
import 'swiper/css';
import 'swiper/css/pagination';

// Import utilities
import { useDeviceDetection } from './utils/deviceUtils';

// Import subcomponents
import { MobileView, TabletView, DesktopView } from './components';

// Import modular styles
import {
  containerStyles,
  contentWrapperStyles,
  swiperContainerStyles,
  paginationStyles
} from './styles/index';

// Default banners data structure - will be overridden with translated content
export const testBanners = [
  {
    id: 'premium-materials',
    imageUrl: '/images/hero-banner/hero-banner-tools.webp', // Default image
    imageUrlEn: '/images/hero-banner/hero-banner-tools-en.webp', // English image
    imageUrlEs: '/images/hero-banner/hero-banner-tools-es.webp' // Spanish image
  },
  {
    id: 'protect-roofs',
    imageUrl: '/images/hero-banner/hero-banner-rooftop-en.webp', // Default image
    imageUrlEn: '/images/hero-banner/hero-banner-rooftop-en.webp', // English image
    imageUrlEs: '/images/hero-banner/hero-banner-rooftop-es.webp' // Spanish image
  },
  {
    id: 'roofing-materials',
    imageUrl: '/images/hero-banner/hero-banner-truck.webp', // Default image
    imageUrlEn: '/images/hero-banner/hero-banner-truck-en.webp', // English image
    imageUrlEs: '/images/hero-banner/hero-banner-truck-es.webp' // Spanish image
  }
];

/**
 * HeroBanner Component - A responsive carousel with intelligent device adaptation
 * 
 * This component implements a "component switching" approach to provide an optimal
 * experience on each device type, along with a modular style structure.
 * 
 * Features:
 * - Full adaptability to multiple devices (mobile, tablet, desktop)
 * - Specific optimization for iPad Mini and iPad Air
 * - Support for internationalization (i18n) with language-specific images and text
 * - Modular style structure for better maintenance
 * 
 * The style structure is organized into:
 * - /styles/common.js - Shared styles across devices
 * - /styles/mobile.js - Mobile-specific styles
 * - /styles/tablet.js - Tablet-specific styles
 * - /styles/desktop.js - Desktop-specific styles
 * 
 * @param {Object} props - Component properties
 * @param {Array} props.banners - List of banners to display (optional, testBanners will be used by default)
 * @returns {React.ReactElement} HeroBanner component
 */
const HeroBanner = ({ banners = testBanners }) => {
  const { t, i18n } = useTranslation();
  
  // Use custom hook for device detection
  const {
    isMobile,
    isTablet,
    isDesktop,
    isIpadMini,
    isIpadAir,
    showPagination
  } = useDeviceDetection();
  
  // Debug log to verify translations
  console.log('Current language:', i18n.language);
  console.log('Banner 1 translation:', t('dashboard.heroBanner1.mainMessage'));
  console.log('Banner 2 translation:', t('dashboard.heroBanner2.title'));
  
  // Function to handle WhatsApp click - Memoized to optimize performance
  const handleWhatsAppClick = useCallback(() => {
    // Create message based on current language
    const isEnglish = i18n.language === 'en' || i18n.language.startsWith('en-');
    
    // Prepare the message based on the current language
    const message = isEnglish 
      ? `Hello SYL Roofing Supply! I'm interested in your premium roofing products.`
      : `¡Hola SYL Roofing Supply! Estoy interesado en sus productos premium para techos.`;
    
    // Use contactService to open WhatsApp with the message
    contactService.openWhatsApp(message);
  }, [i18n.language]);

  return (
    <Box sx={containerStyles}>
      <Box sx={{ 
        ...swiperContainerStyles,
        px: { xs: 0, sm: 1, md: 2 }
      }}>
        <Swiper
          modules={[Pagination, Autoplay]}
          spaceBetween={0}
          slidesPerView={1}
          pagination={showPagination ? {
            clickable: true,
            el: '.swiper-pagination',
            type: 'bullets'
          } : false}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          loop={true}
        >
          {banners.map((banner, index) => (
            <SwiperSlide key={index}>
              <Box sx={{
                ...contentWrapperStyles,
                padding: { xs: 0, sm: 3, md: 6 }
              }}>
                {/* Mobile view - Only visible on xs */}
                <MobileView 
                  banner={banner} 
                  index={index} 
                  onButtonClick={handleWhatsAppClick} 
                />

                {/* Tablet view - Only visible on sm */}
                <TabletView 
                  banner={banner} 
                  index={index}
                  isIpadMini={isIpadMini}
                  isIpadAir={isIpadAir}
                />

                {/* Desktop view - Only visible on md and above */}
                <DesktopView 
                  banner={banner} 
                  index={index}
                />
              </Box>
            </SwiperSlide>
          ))}
          {showPagination && (
            <Box 
              className="swiper-pagination" 
              sx={paginationStyles}
            />
          )}
        </Swiper>
      </Box>
    </Box>
  );
};

HeroBanner.propTypes = {
  banners: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      imageUrl: PropTypes.string.isRequired,
      imageUrlEn: PropTypes.string,
      imageUrlEs: PropTypes.string
    })
  )
};

export default HeroBanner; 