import React, { useEffect, useState } from 'react';
import { CssBaseline, Box, ThemeProvider } from "@mui/material";
import { FormProvider } from '../shared/context/FormContext.js';
import { CustomerDetailProvider } from '../shared/context/CustomerDetailContext.js';
import { app } from '../shared/utils/firebase/firebase-config.js';
import { AuthProvider, useAuth } from '../shared/context/AuthProvider.js';
import AppRoutes from './AppRoutes.js';
import Footer from '../shared/components/Footer';
import Header from '../shared/components/Header';
import NotificationBanner, { BANNER_VISIBILITY_EVENT } from '../modules/dashboard/components/NotificationBanner';
import customTheme from '../shared/styles/customTheme.js';
import globalStyles from '../shared/styles/globalStyle.js';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useMediaQuery } from '@mui/material';
import { useLocation } from 'react-router-dom';
// Importamos el proveedor de Helmet para SEO
import { HelmetProvider } from 'react-helmet-async';

// Crear una instancia de QueryClient
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      // Añadir manejo de errores global para queries
      onError: (error) => {
        console.log('🔴 Error en React Query:', error);
        // Si es un error de autenticación, manejarlo adecuadamente
        if (error?.response?.status === 401 || error?.response?.status === 403) {
          console.log('🔒 Error de autenticación en React Query');
        }
      },
    },
    mutations: {
      // Añadir manejo de errores global para mutaciones
      onError: (error) => {
        console.log('🔴 Error en React Query mutation:', error);
        // Si es un error de autenticación, manejarlo adecuadamente
        if (error?.response?.status === 401 || error?.response?.status === 403) {
          console.log('🔒 Error de autenticación en React Query mutation');
        }
      },
    }
  },
});

// Componente envoltorio para el contenido principal
const MainContent = () => {
  const { isAuthenticated } = useAuth(); 
  const location = useLocation();
  const [bannerHeight, setBannerHeight] = useState(0);
  const [deviceInfo, setDeviceInfo] = useState({
    isMobile: false,
    isTablet: false,
    isDesktop: true
  });
  
  // Check if the current route is a review route
  const isReviewRoute = location.pathname.includes('/review-');

  // Escuchar eventos de visibilidad del banner
  useEffect(() => {
    const handleBannerVisibility = (event) => {
      console.log('🔄 MainContent: Banner visibility changed:', event.detail);
      setBannerHeight(event.detail.height);
      setDeviceInfo({
        isMobile: event.detail.isMobile || false,
        isTablet: event.detail.isTablet || false,
        isDesktop: event.detail.isDesktop || true
      });
    };

    // Añadir listener para el evento de visibilidad del banner
    window.addEventListener(BANNER_VISIBILITY_EVENT, handleBannerVisibility);

    // Limpiar listener al desmontar
    return () => {
      window.removeEventListener(BANNER_VISIBILITY_EVENT, handleBannerVisibility);
    };
  }, []);

  console.log('🔄 MainContent: Rendering, isReviewRoute:', isReviewRoute, 'bannerHeight:', bannerHeight, 'device:', deviceInfo);

  return (
    <>
      {!isReviewRoute && (
        <>
          {console.log('🔄 MainContent: About to render NotificationBanner')}
          <NotificationBanner />
          {console.log('🔄 MainContent: About to render Header')}
          <Header isLoggedIn={isAuthenticated} />
        </>
      )}
      <Box 
        component="main" 
        sx={{ 
          flex: 1, 
          backgroundColor: 'background.default',
          position: 'relative',
          zIndex: 1,
          width: '100%',
        }}
      >
        <CustomerDetailProvider>
          <FormProvider>
            <AppRoutes />
          </FormProvider>
        </CustomerDetailProvider>
      </Box>
    </>
  );
};

function App() {
  const isMobile = useMediaQuery('(max-width:600px)');
  const location = useLocation();
  
  // Check if the current route is a review route
  const isReviewRoute = location.pathname.includes('/review-');

  useEffect(() => {
    if (app) {
      console.log('Firebase initialized successfully');
    } else {
      console.log('Firebase initialization failed');
    }
    
    // Hacer que queryClient esté disponible globalmente
    window.queryClient = queryClient;
    
    // Detectar si es un dispositivo móvil
    const isMobileDevice = window.innerWidth < 768;
    if (isMobileDevice) {
      console.log('📱 Detectado dispositivo móvil en App.js');
      
      // Configurar un listener para eventos de storage para sincronizar el estado de autenticación
      const handleStorageChange = () => {
        console.log('🔄 Evento de storage detectado en App.js');
        // Invalidar queries relacionadas con la autenticación
        queryClient.invalidateQueries(['customerDetail']);
        queryClient.invalidateQueries(['auth']);
      };
      
      window.addEventListener('storage', handleStorageChange);
      
      // Limpiar el listener al desmontar
      return () => {
        window.removeEventListener('storage', handleStorageChange);
      };
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={customTheme}>
        <CssBaseline />
        {globalStyles}
        <AuthProvider>
          <HelmetProvider>
            <Box sx={{ 
              minHeight: '100vh',
              width: '100%',
              overflowX: 'hidden',
              display: 'flex',
              flexDirection: 'column'
            }}>
              <MainContent />
              {!isReviewRoute && <Footer />}
            </Box>
          </HelmetProvider>
        </AuthProvider>
      </ThemeProvider>
      {!isMobile && <ReactQueryDevtools initialIsOpen={false} />}
    </QueryClientProvider>
  );
}

export default App;