import { useState } from 'react';
import { checkUserStatus, loginUser, subscribeToNewsletter, USER_STATUS } from '../services/authService';

export const STEPS = {
  EMAIL_CHECK: 'EMAIL_CHECK',
  REGISTERED_USER: 'USER_REGISTERED',
  NEW_USER: 'NEW_USER',
  NETSUITE_USER: 'NETSUITE_USER'
};

export const useAuthModal = (onClose, onLoginSuccess) => {
  const [step, setStep] = useState(STEPS.EMAIL_CHECK);
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleEmailCheck = async (emailValue) => {
    try {
      setLoading(true);
      setError(null);
      setEmail(emailValue);

      const userStatus = await checkUserStatus(emailValue);
      console.log('🔍 User status:', userStatus);

      switch (userStatus) {
        case USER_STATUS.REGISTERED:
          setStep(STEPS.REGISTERED_USER);
          break;
        case USER_STATUS.NEW_USER:
          setStep(STEPS.NEW_USER);
          break;
        case USER_STATUS.NETSUITE_USER:
          setStep(STEPS.NETSUITE_USER);
          break;
        default:
          setError('Invalid user status received');
      }
    } catch (error) {
      console.error('❌ Email check error:', error);
      setError(error.message || 'An error occurred while checking your email');
    } finally {
      setLoading(false);
    }
  };

  const handleLogin = async (password) => {
    try {
      setLoading(true);
      setError(null);

      await loginUser({ email, password });
      
      if (onLoginSuccess) {
        onLoginSuccess();
      }
      
      onClose();
    } catch (error) {
      console.error('❌ Login error:', error);
      setError(error.message || 'An error occurred while logging in');
    } finally {
      setLoading(false);
    }
  };

  const handleSubscribe = async (subscriptionData) => {
    try {
      setLoading(true);
      setError(null);
      
      await subscribeToNewsletter(subscriptionData);
      
      onClose();
    } catch (error) {
      console.error('❌ Newsletter subscription error:', error);
      setError(error.message || 'An error occurred while subscribing to the newsletter');
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return {
    step,
    email,
    loading,
    error,
    handleEmailCheck,
    handleLogin,
    handleSubscribe
  };
}; 