/**
 * Tablet styles
 * Styles specific to tablet devices, including iPad Mini, iPad Air, and iPad Pro
 */

// Tablet-specific styles for the container
export const tabletContainerStyles = {
  padding: '24px 0',
  width: '100%',
  backgroundColor: '#FFFFFF',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
};

// Tablet-specific styles for the title
export const tabletTitleStyles = {
  fontSize: '1.75rem',
  fontWeight: 600,
  textAlign: 'center',
  marginBottom: '24px',
  color: '#0A3954',
  width: '100%',
  padding: '0 24px'
};

// Tablet-specific styles for the grid layout
export const tabletGridStyles = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: '20px',
  padding: '20px',
  justifyContent: 'flex-start',
  width: '100%'
};

// Tablet-specific styles for the carousel container
export const tabletCarouselContainerStyles = {
  '.slick-track': {
    display: 'flex',
    gap: '12px'
  },
  
  '.slick-slide': {
    margin: '0 8px'
  },

  '.slick-list': {
    margin: '0 -8px',
    overflow: 'visible',
    paddingLeft: 0,
    paddingRight: 0
  },

  width: '100%',
  maxWidth: '100vw',
  overflowX: 'auto',
  scrollbarWidth: 'thin',
  '&::-webkit-scrollbar': {
    height: '6px'
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(0,0,0,0.2)',
    borderRadius: '3px'
  },
  paddingLeft: 0,
  paddingRight: 0
};

// Tablet-specific styles for brand cards
export const tabletBrandCardStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: '#FFFFFF',
  borderRadius: '8px',
  border: '1px solid rgba(0, 0, 0, 0.08)',
  width: 'calc(33.333% - 14px)',
  height: '130px',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
  transition: 'transform 0.2s, box-shadow 0.2s',
  cursor: 'pointer',
  overflow: 'hidden',
  padding: 0,
  margin: 0,
  flexGrow: 0,
  flexShrink: 0,
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
  }
};

// Tablet-specific styles for brand image container
export const tabletBrandImageContainerStyles = {
  width: '100%',
  height: '90px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '12px',
  backgroundColor: '#FFFFFF'
};

// Tablet-specific styles for brand name container
export const tabletBrandNameStyles = {
  width: '100%',
  textAlign: 'center',
  backgroundColor: '#0A3954',
  padding: '8px',
  height: '40px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};

// Tablet-specific styles for brand typography
export const tabletBrandTypographyStyles = {
  fontSize: '0.875rem',
  fontWeight: 500,
  lineHeight: 1.2,
  color: '#FFFFFF',
  textAlign: 'center'
};

// iPad Mini specific styles
export const iPadMiniBrandCardStyles = {
  width: 'calc(33.333% - 14px)',
  height: '125px'
};

export const iPadMiniBrandImageContainerStyles = {
  height: '85px'
};

export const iPadMiniBrandTypographyStyles = {
  fontSize: '0.8125rem'
};

// iPad Air specific styles
export const iPadAirBrandCardStyles = {
  width: 'calc(33.333% - 14px)',
  height: '130px'
};

export const iPadAirBrandImageContainerStyles = {
  height: '90px'
};

export const iPadAirBrandTypographyStyles = {
  fontSize: '0.875rem'
};

// iPad Pro specific styles
export const iPadProBrandCardStyles = {
  width: 'calc(33.333% - 14px)',
  height: '140px'
};

export const iPadProBrandImageContainerStyles = {
  height: '100px'
};

export const iPadProBrandTypographyStyles = {
  fontSize: '0.9375rem'
}; 