import React, {createContext, useCallback, useContext, useState } from "react";
import { getCustomerDetail } from "../services/customerService";

const CustomerDetailContext = createContext();

export const CustomerDetailProvider = ({ children }) => {
    const [userDetail, setDetail] = useState(null);
    const [UID, setUID] = useState(null);

    const loadDetails = useCallback(async () => {
        if (!UID) {
            console.error("UID NULL");
            return
        }
        try {
            const response = await getCustomerDetail(UID);
            console.log("Customer detail context", response);
            setDetail(response);
        } catch (error) {
            console.error("Failed to fetch customer details:", error);
        }
    }, [UID]);

    return (
        <CustomerDetailContext.Provider value={{ userDetail, loadDetails, setUID }}>
            {children}
        </CustomerDetailContext.Provider>
    );
};

export const useCustomerDetailContext = () => useContext(CustomerDetailContext);