import React, { useState, useEffect, useRef } from 'react';
import { Typography, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ActionButton } from '../../styles';
import LoginBoxIllustration from '../../../../../../assets/login_box_illustration.webp';
import {
  StyledContainer,
  ContentContainer,
  TextContainer,
  ImageContainer,
  StyledImage,
  StyledTypography,
  StyledTextField
} from './styles';

const EmailCheckContent = ({ onClose, onEmailCheck }) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const emailInputRef = useRef(null);
  
  // Focus email field on component mount
  useEffect(() => {
    if (emailInputRef.current) {
      emailInputRef.current.focus();
    }
  }, []);

  // Email format validation function
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setEmail(value);
    
    // Real-time validation
    if (value && !validateEmail(value)) {
      setError(t('auth.emailCheck.errors.invalid'));
    } else {
      setError(null);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!email) {
      setError(t('auth.emailCheck.errors.required'));
      return;
    }

    if (!validateEmail(email)) {
      setError(t('auth.emailCheck.errors.invalid'));
      return;
    }
    
    setLoading(true);
    setError(null);

    try {
      await onEmailCheck(email);
    } catch (error) {
      setError(error.message || t('auth.emailCheck.errors.generic'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyledContainer component="form" onSubmit={handleSubmit}>
      <ContentContainer>
        <TextContainer>
          <Typography 
            variant="h6" 
            component="h6" 
            sx={StyledTypography.title}
            dangerouslySetInnerHTML={{ __html: t('auth.emailCheck.title') }}
          />

          <Typography variant="subtitle1" sx={StyledTypography.subtitle}>
            {t('auth.emailCheck.subtitle')}
          </Typography>
        </TextContainer>

        <ImageContainer>
          <StyledImage 
            src={LoginBoxIllustration}
            alt={t('a11y.companyLogo')} 
          />
        </ImageContainer>
      </ContentContainer>

      <StyledTextField
        fullWidth
        type="email"
        placeholder={t('auth.emailCheck.emailPlaceholder')}
        value={email}
        onChange={handleChange}
        error={!!error}
        helperText={error}
        disabled={loading}
        inputRef={emailInputRef}
      />

      <ActionButton
        fullWidth
        type="submit"
        variant="contained"
        disabled={loading || !!error}
      >
        {loading ? <CircularProgress size={24} color="inherit" /> : t('auth.emailCheck.continueButton')}
      </ActionButton>
    </StyledContainer>
  );
};

export default EmailCheckContent; 