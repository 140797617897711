/**
 * BrandInfo Component
 * Muestra información detallada sobre la marca
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Button, Modal, IconButton, Link } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import Markdown from 'markdown-to-jsx';
import LaunchIcon from '@mui/icons-material/Launch';

import {
  // Estilos base
  brandInfoContainerStyles,
  brandImageStyles,
  brandContentStyles,
  brandDescriptionStyles,
  brandTitleStyles,
  brandBannerContainerStyles,
  brandBannerImageStyles,
  // Estilos para el modal y botón
  moreButtonStyles,
  modalStyles,
  modalHeaderStyles,
  modalImageStyles,
  modalTitleStyles,
  modalDescriptionStyles,
  truncatedDescriptionStyles,
  // Estilos específicos para dispositivos móviles
  mobileBrandInfoContainerStyles,
  mobileBrandImageStyles,
  mobileBrandContentStyles,
  mobileBrandDescriptionStyles,
  mobileModalStyles,
  mobileModalImageStyles,
  mobileModalTitleStyles,
  mobileModalDescriptionStyles,
  mobileContainerStyles,
  // Estilos específicos para tablets
  tabletBrandImageStyles,
  tabletBrandDescriptionStyles,
  tabletModalStyles,
  tabletModalImageStyles,
  tabletModalTitleStyles,
  tabletModalDescriptionStyles,
  tabletBannerContainerStyles,
  tabletBannerImageStyles,
  tabletBrandHeaderBoxStyles,
  // Estilos específicos para escritorio
  desktopBrandInfoContainerStyles,
  desktopBrandImageStyles,
  desktopBrandContentStyles,
  desktopBrandDescriptionStyles,
  desktopModalStyles,
  desktopModalImageStyles,
  desktopModalTitleStyles,
  desktopModalDescriptionStyles,
  desktopBannerContainerStyles,
  desktopBannerImageStyles,
  desktopBrandHeaderBoxStyles,
  // Estilos específicos para banners
  mobileBannerContainerStyles,
  mobileBannerImageStyles,
} from '../styles/index';

import { getTabletSpecificStyles } from '../styles/tablet';

const BrandInfo = ({ brand, deviceType, tabletType }) => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  
  // Calcular estilos según el dispositivo
  const getResponsiveStyles = () => {
    let styles = {
      containerStyles: brandInfoContainerStyles,
      imageStyles: {
        ...brandImageStyles,
        borderRadius: '4px',
        width: '70px',
        height: '70px',
        objectFit: 'contain'
      },
      contentStyles: brandContentStyles,
      titleStyles: {
        ...brandTitleStyles,
      },
      descriptionStyles: brandDescriptionStyles,
      bannerContainerStyles: brandBannerContainerStyles,
      bannerImageStyles: brandBannerImageStyles,
      // Estilos para el modal
      modalStyles: {
        ...modalStyles,
      },
      modalImageStyles: {
        ...modalImageStyles,
      },
      modalTitleStyles: {
        ...modalTitleStyles,
      },
      modalDescriptionStyles: {
        ...modalDescriptionStyles,
      }
    };
    
    if (deviceType === 'mobile') {
      styles = {
        ...styles,
        containerStyles: { 
          ...mobileContainerStyles 
        },
        imageStyles: { 
          ...styles.imageStyles, 
          ...mobileBrandImageStyles,
          borderRadius: '4px',
          width: '60px',
          height: '60px',
        },
        contentStyles: { ...styles.contentStyles, ...mobileBrandContentStyles },
        descriptionStyles: { ...styles.descriptionStyles, ...mobileBrandDescriptionStyles },
        bannerContainerStyles: {
          ...mobileBannerContainerStyles,
        },
        bannerImageStyles: {
          ...mobileBannerImageStyles,
        },
        // Estilos móviles para el modal
        modalStyles: {
          ...styles.modalStyles,
          ...mobileModalStyles,
        },
        modalImageStyles: {
          ...styles.modalImageStyles,
          ...mobileModalImageStyles,
        },
        modalTitleStyles: {
          ...styles.modalTitleStyles,
          ...mobileModalTitleStyles,
        },
        modalDescriptionStyles: {
          ...styles.modalDescriptionStyles,
          ...mobileModalDescriptionStyles,
        }
      };
    } else if (deviceType === 'tablet') {
      // Obtener estilos específicos del modelo de tablet
      const specificTabletStyles = getTabletSpecificStyles(tabletType);
      
      styles = {
        ...styles,
        imageStyles: { 
          ...styles.imageStyles, 
          ...tabletBrandImageStyles,
          ...specificTabletStyles.brandImageStyles,
          borderRadius: '4px',
          width: '80px',
          height: '80px',
        },
        titleStyles: { 
          ...styles.titleStyles,
          fontSize: 20, // Ligeramente más grande en tablet
        },
        descriptionStyles: { ...styles.descriptionStyles, ...tabletBrandDescriptionStyles },
        bannerContainerStyles: {
          ...styles.bannerContainerStyles,
          ...tabletBannerContainerStyles,
        },
        bannerImageStyles: {
          ...styles.bannerImageStyles,
          ...tabletBannerImageStyles,
        },
        // Estilos tablet para el modal
        modalStyles: {
          ...styles.modalStyles,
          ...tabletModalStyles,
        },
        modalImageStyles: {
          ...styles.modalImageStyles,
          ...tabletModalImageStyles,
        },
        modalTitleStyles: {
          ...styles.modalTitleStyles,
          ...tabletModalTitleStyles,
        },
        modalDescriptionStyles: {
          ...styles.modalDescriptionStyles,
          ...tabletModalDescriptionStyles,
        }
      };
    } else {
      // Desktop
      styles = {
        ...styles,
        containerStyles: { ...styles.containerStyles, ...desktopBrandInfoContainerStyles },
        imageStyles: { 
          ...styles.imageStyles, 
          ...desktopBrandImageStyles,
          borderRadius: '4px',
          width: '90px',
          height: '90px',
        },
        contentStyles: { ...styles.contentStyles, ...desktopBrandContentStyles },
        titleStyles: { 
          ...styles.titleStyles,
          fontSize: 22, // Más grande en desktop
        },
        descriptionStyles: { ...styles.descriptionStyles, ...desktopBrandDescriptionStyles },
        bannerContainerStyles: {
          ...styles.bannerContainerStyles,
          ...desktopBannerContainerStyles,
        },
        bannerImageStyles: {
          ...styles.bannerImageStyles,
          ...desktopBannerImageStyles,
        },
        // Estilos desktop para el modal
        modalStyles: {
          ...styles.modalStyles,
          ...desktopModalStyles,
        },
        modalImageStyles: {
          ...styles.modalImageStyles,
          ...desktopModalImageStyles,
        },
        modalTitleStyles: {
          ...styles.modalTitleStyles,
          ...desktopModalTitleStyles,
        },
        modalDescriptionStyles: {
          ...styles.modalDescriptionStyles,
          ...desktopModalDescriptionStyles,
        }
      };
    }
    
    return styles;
  };
  
  const styles = getResponsiveStyles();
  
  // Nuevos estilos para la estructura solicitada
  const brandHeaderBoxStyles = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexShrink: 0,
    marginRight: deviceType === 'mobile' ? 0 : 3,
    width: deviceType === 'mobile' ? '100%' : 'auto',
    marginBottom: deviceType === 'mobile' ? 2 : 0,
    ...(deviceType === 'tablet' && tabletBrandHeaderBoxStyles),
    ...(deviceType === 'desktop' && desktopBrandHeaderBoxStyles),
  };
  
  const brandMetaBoxStyles = {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 2,
    justifyContent: 'center'
  };
  
  const newContainerStyles = {
    ...styles.containerStyles,
    flexDirection: deviceType === 'mobile' ? 'column' : 'row',
    alignItems: deviceType === 'mobile' ? 'flex-start' : 'center',
  };
  
  const descriptionBoxStyles = {
    flexGrow: 1,
    maxWidth: deviceType === 'mobile' ? '100%' : '60%',
    marginLeft: deviceType === 'mobile' ? 0 : 4, // Aumentando el margen izquierdo en tablet y desktop
    position: 'relative', // Para posicionar el botón "More"
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  // Función para renderizar la información adicional de la marca
  const renderAdditionalInfo = () => {
    if (!brand.additionalInfo) return null;
    
    const { warranty, rewardsProgram, website } = brand.additionalInfo;
    
    return (
      <Box sx={{ mt: 4, maxHeight: '400px', overflowY: 'auto' }}>
        <Typography variant="h5" sx={{ 
          color: '#0A3954', 
          fontSize: deviceType === 'mobile' ? '1.1rem' : '1.3rem',
          mb: 2,
          fontWeight: 700,
          borderBottom: '2px solid',
          borderColor: 'primary.main',
          pb: 1
        }}>
          {t('brands.additionalInfo', 'Additional Information')}
        </Typography>
        
        {warranty && (
          <Box sx={{ mb: 4 }}>
            <Typography variant="h6" sx={{ 
              color: '#0A3954', 
              fontSize: deviceType === 'mobile' ? '1rem' : '1.2rem',
              mb: 1.5,
              fontWeight: 600,
              borderBottom: '1px solid',
              borderColor: 'divider',
              pb: 0.75,
              display: 'flex',
              alignItems: 'center'
            }}>
              <Box component="span" sx={{ 
                width: '12px', 
                height: '12px', 
                borderRadius: '50%', 
                bgcolor: 'primary.main',
                mr: 1,
                display: 'inline-block'
              }}/>
              {t('brands.warranty', 'Warranty Information')}
            </Typography>
            <Markdown options={{
              overrides: {
                p: { component: Typography, props: { variant: 'body2', sx: { mb: 1.5, lineHeight: 1.6 } } },
                a: { component: Link, props: { target: '_blank', rel: 'noopener', color: 'primary' } }
              }
            }}>
              {warranty}
            </Markdown>
          </Box>
        )}
        
        {rewardsProgram && (
          <Box sx={{ mb: 4 }}>
            <Typography variant="h6" sx={{ 
              color: '#0A3954', 
              fontSize: deviceType === 'mobile' ? '1rem' : '1.2rem',
              mb: 1.5,
              fontWeight: 600,
              borderBottom: '1px solid',
              borderColor: 'divider',
              pb: 0.75,
              display: 'flex',
              alignItems: 'center'
            }}>
              <Box component="span" sx={{ 
                width: '12px', 
                height: '12px', 
                borderRadius: '50%', 
                bgcolor: 'primary.main',
                mr: 1,
                display: 'inline-block'
              }}/>
              {t('brands.rewards', 'Rewards Program')}
            </Typography>
            <Markdown options={{
              overrides: {
                p: { component: Typography, props: { variant: 'body2', sx: { mb: 1.5, lineHeight: 1.6 } } },
                a: { component: Link, props: { target: '_blank', rel: 'noopener', color: 'primary' } }
              }
            }}>
              {rewardsProgram}
            </Markdown>
          </Box>
        )}
        
        {website && (
          <Box sx={{ mt: 3, display: 'flex', alignItems: 'center', mb: 2 }}>
            <Button 
              variant="contained" 
              color="primary"
              startIcon={<LaunchIcon />}
              href={website}
              target="_blank"
              rel="noopener"
              size={deviceType === 'mobile' ? 'small' : 'medium'}
              sx={{
                borderRadius: '24px',
                px: 2.5,
                py: 1,
                textTransform: 'none',
                fontWeight: 500,
                boxShadow: 2,
                '&:hover': {
                  boxShadow: 4
                }
              }}
            >
              {t('brands.visitWebsite', 'Visit Official Website')}
            </Button>
          </Box>
        )}
      </Box>
    );
  };

  return (
    <>
      {/* Banner Image */}
      {brand.bannerImage && (
        <Box sx={styles.bannerContainerStyles}>
          <Box
            component="img"
            src={brand.bannerImage}
            alt={`${brand.name} banner`}
            sx={styles.bannerImageStyles}
          />
        </Box>
      )}

      <Box sx={deviceType === 'mobile' ? mobileBrandInfoContainerStyles : newContainerStyles}>
        {/* Bloque izquierdo: imagen y título */}
        <Box sx={brandHeaderBoxStyles}>
          {brand.logo && (
            <img
              src={brand.logo}
              alt=""
              style={styles.imageStyles}
            />
          )}
          
          <Box sx={brandMetaBoxStyles}>
            <Typography sx={styles.titleStyles}>
              {brand.name}
            </Typography>
          </Box>
        </Box>
        
        {/* Bloque derecho: descripción */}
        {brand.description && (
          <Box sx={descriptionBoxStyles}>
            <Typography variant="body1" sx={{...styles.descriptionStyles, ...truncatedDescriptionStyles}}>
              {brand.description}
            </Typography>
            <Button 
              variant="text" 
              size="small" 
              sx={moreButtonStyles}
              onClick={handleOpenModal}
            >
              {t('common.more', 'More')}
            </Button>
          </Box>
        )}

        {/* Modal con descripción completa */}
        <Modal
          open={modalOpen}
          onClose={handleCloseModal}
          aria-labelledby="brand-description-modal"
          aria-describedby="full-brand-description"
        >
          <Box sx={{
            ...styles.modalStyles,
            maxHeight: '90vh',
            overflowY: 'auto'
          }}>
            <Box sx={modalHeaderStyles}>
              <Typography variant="h6" sx={styles.modalTitleStyles}>
                {brand.name}
              </Typography>
              <IconButton 
                aria-label="close" 
                onClick={handleCloseModal}
                size="small"
              >
                <CloseIcon />
              </IconButton>
            </Box>
            
            <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 3 }}>
              {brand.logo && (
                <Box 
                  component="img" 
                  src={brand.logo} 
                  alt=""
                  sx={styles.modalImageStyles}
                />
              )}
              <Typography variant="body1" sx={styles.modalDescriptionStyles}>
                {brand.description}
              </Typography>
            </Box>
            
            {/* Información adicional de la marca */}
            {renderAdditionalInfo()}
          </Box>
        </Modal>
      </Box>
    </>
  );
};

BrandInfo.propTypes = {
  brand: PropTypes.shape({
    name: PropTypes.string.isRequired,
    logo: PropTypes.string,
    bannerImage: PropTypes.string,
    description: PropTypes.string,
    verified: PropTypes.bool,
    additionalInfo: PropTypes.shape({
      warranty: PropTypes.string,
      rewardsProgram: PropTypes.string,
      website: PropTypes.string
    })
  }).isRequired,
  deviceType: PropTypes.oneOf(['mobile', 'tablet', 'desktop']).isRequired,
  tabletType: PropTypes.oneOf(['generic', 'iPadMini', 'iPadAir', 'iPadPro'])
};

BrandInfo.defaultProps = {
  tabletType: 'generic'
};

export default BrandInfo; 