// Estilos específicos para desktop (md y superior)
export const desktopContainerStyles = {
  py: 8,
  px: 0
};

export const desktopTitleStyles = {
  fontSize: '32px',
  mb: 2
};

export const desktopSubtitleStyles = {
  fontSize: '18px',
  mb: 6,
  maxWidth: '800px',
  px: 0
};

export const desktopStepsContainerStyles = {
  flexDirection: 'row',
  gap: 6,
  mb: 6
};

export const desktopStepItemStyles = {
  px: 0
};

export const desktopStepImageWrapperStyles = {
  width: '180px',
  height: '180px',
  mb: 3
};

export const desktopStepNumberStyles = {
  fontSize: '18px',
  mb: 1
};

export const desktopStepDescriptionStyles = {
  fontSize: '14px',
  maxWidth: '250px',
  lineHeight: 1.4
};

export const desktopButtonWrapperStyles = {
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  px: 0
};

export const desktopButtonStyles = {
  fontSize: '16px',
  py: 1.5,
  px: 4
}; 