import React, { useEffect, useState } from 'react';
import { Typography, Box } from '@mui/material';
import BrandsCarousel from '../components/BrandsCarousel';
import RecentNews from '../components/RecentNews';
import ValuePropositionLayout from '../components/ValuePropositionLayout';
import CategoriesCarousel from '../components/CategoriesCarousel';
import HeroBanner from '../components/HeroBanner';
import ContactForm from '../components/ContactForm';
import DashboardSection from '../components/DashboardSection';
import { getDashboardData } from '../services/dashboardService';
import PropTypes from 'prop-types';
import SpecialPromotionLayout from '../components/SpecialPromotionLayout';
import LimitedTimeOfferLayout from '../components/LimitedTimeOfferLayout';
import ReviewsLayout from '../components/ReviewsLayout';
import DeliveryProcessLayout from '../components/DeliveryProcessLayout';
import WhyChooseSYLLayout from '../components/WhyChooseSYLLayout';
import SEO from '../../../shared/components/SEO';
import FeaturedBrandShowcase from '../components/FeaturedBrandShowcase';

const DashboardContent = ({ dashboardData, isOffline }) => {
  const { layouts = {}, processedFlashPromotionLayout = [] } = dashboardData || {};
  const { 
    SpecialPromotionLayout: specialPromoLayout,
    LimitedTimeOfferLayout: limitedTimeLayout
  } = layouts;

  return (
    <Box sx={{ width: '100%' }}>
      <DashboardSection noPadding fullWidth>
        <HeroBanner />
      </DashboardSection>

      <DashboardSection background="#F7F7F7" sx={{ mt: { xs: 0, md: '80px' } }}>
        <CategoriesCarousel />
      </DashboardSection>

      <DashboardSection>
        <BrandsCarousel />
      </DashboardSection>

      <DashboardSection>
        <FeaturedBrandShowcase />
      </DashboardSection>

      <DashboardSection>
        <ContactForm />
      </DashboardSection>

      {!isOffline && (
        <>
          {specialPromoLayout?.offerCards?.length > 0 && (
            <DashboardSection>
              <SpecialPromotionLayout
                offerCards={specialPromoLayout.offerCards}
                promotionalCard={specialPromoLayout.promotionalCard}
              />
            </DashboardSection>
          )}

          {(processedFlashPromotionLayout?.length > 0 || limitedTimeLayout?.limitedTimeOfferCard?.length > 0) && (
            <DashboardSection noPadding>
              <LimitedTimeOfferLayout
                offers={processedFlashPromotionLayout.length > 0 ? processedFlashPromotionLayout : undefined}
                limitedTimeOfferCard={limitedTimeLayout?.limitedTimeOfferCard}
              />
            </DashboardSection>
          )}
        </>
      )}

      <DashboardSection noPadding sx={{ mt: { xs: 4, md: 8 } }}>
        <ReviewsLayout />
      </DashboardSection>

      <DashboardSection noPadding>
        <ValuePropositionLayout />
      </DashboardSection>

      <DashboardSection noPadding>
        <DeliveryProcessLayout />
      </DashboardSection>

      <DashboardSection noPadding>
        <WhyChooseSYLLayout />
      </DashboardSection>

      <DashboardSection>
        <RecentNews />
      </DashboardSection>
    </Box>
  );
};

const DashboardHome = () => {
  const [dashboardData, setDashboardData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isOffline, setIsOffline] = useState(false);

  useEffect(() => {
    const fetchDashboard = async () => {
      try {
        const data = await getDashboardData();
        setDashboardData(data);
        setIsOffline(Object.keys(data.layouts || {}).length === 0);
      } catch (err) {
        setError(err.message);
        setIsOffline(err.message.includes('offline'));
      } finally {
        setIsLoading(false);
      }
    };

    fetchDashboard();
  }, []);

  if (isLoading) return <Typography>Loading...</Typography>;
  if (error && !isOffline) return <Typography>Error: {error}</Typography>;

  return (
    <>
      <SEO 
        titleEs="Distribuidor Mayorista de Materiales para Techos"
        descriptionEs="SYL Roofing Supply es su proveedor líder en materiales para techos en Florida. Atención personalizada en español, entrega al día siguiente y precios competitivos para contratistas. Visite nuestras sucursales en Tampa y Orlando o solicite cotización hoy."
        keywordsEs="distribuidor techos orlando, materiales para techos tampa, distribuidor mayorista florida, envío rápido, atención en español, precios competitivos, contratistas"
        
        titleEn="Wholesale Distributor of Roofing Materials"
        descriptionEn="SYL Roofing Supply is your leading provider of roofing materials in Florida. Visit our locations in Tampa and Orlando or request a quote today. Competitive prices and next-day delivery for contractors."
        keywordsEn="roofing supplies tampa fl, roofing materials orlando, wholesale roofing distributor florida, next-day delivery, contractor supplies, competitive pricing"
        
        canonicalUrl="/"
        ogImage="/images/og-image.jpg"
      />
      <Box sx={{ width: '100%' }}>
        <DashboardContent dashboardData={dashboardData} isOffline={isOffline} />
      </Box>
    </>
  );
};

DashboardContent.propTypes = {
  dashboardData: PropTypes.shape({
    layouts: PropTypes.shape({
      SpecialPromotionLayout: PropTypes.shape({
        offerCards: PropTypes.arrayOf(
          PropTypes.shape({
            cardTitleOne: PropTypes.string.isRequired,
            cardTitleTwo: PropTypes.string,
            imageCard: PropTypes.string.isRequired
          })
        ),
        promotionalCard: PropTypes.shape({
          cardPromoTitleOne: PropTypes.string.isRequired,
          cardPromoTitleTwo: PropTypes.string.isRequired,
          imageCard: PropTypes.string.isRequired
        })
      }),
      LimitedTimeOfferLayout: PropTypes.shape({
        limitedTimeOfferCard: PropTypes.arrayOf(
          PropTypes.shape({
            cardTitle: PropTypes.string.isRequired,
            imageCard: PropTypes.string.isRequired
          })
        )
      })
    })
  }),
  isOffline: PropTypes.bool
};

DashboardContent.defaultProps = {
  dashboardData: {
    layouts: {}
  },
  isOffline: false
};

export default DashboardHome; 