import { apiClient } from '../../../shared/services/apiClient';
import { fetchDashboardData } from '../../../shared/services/firebaseApi';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';

const db = getFirestore();

/**
 * @typedef {Object} OfferCard
 * @property {string} cardTitleOne
 * @property {string} cardTitleTwo
 * @property {string} imageCard
 */

/**
 * @typedef {Object} PromotionalCard
 * @property {string} cardPromoTitleOne
 * @property {string} cardPromoTitleTwo
 * @property {string} imageCard
 * @property {string} [CTA] - Mensaje para el chat en WhatsApp (usado en móvil)
 * @property {string} [buttonText] - Texto para el botón
 */

/**
 * @typedef {Object} LimitedTimeOfferCard
 * @property {string} imageCard
 */

/**
 * @typedef {Object} FlashPromotionCard
 * @property {string} cardTitleOne - Nombre del producto (subtitle en el componente)
 * @property {string} cardTitleTwo - Precio del producto (title en el componente)
 * @property {string} imageCard - URL de la imagen
 * @property {string} CTA - Mensaje para el chat
 */

/**
 * @typedef {Object} LimitedTimeOfferLayout
 * @property {LimitedTimeOfferCard[]} limitedTimeOfferCard
 */

/**
 * @typedef {Object} FlashPromotionLayout
 * @property {FlashPromotionCard[]} offerCards
 */

/**
 * @typedef {Object} SpecialPromotionLayout
 * @property {OfferCard[]} offerCards
 * @property {PromotionalCard} promotionalCard
 */

/**
 * @typedef {Object} Layouts
 * @property {SpecialPromotionLayout} [SpecialPromotionLayout]
 * @property {LimitedTimeOfferLayout} [LimitedTimeOfferLayout]
 * @property {FlashPromotionLayout} [FlashPromotionLayout]
 */

/**
 * @typedef {Object} DashboardData
 * @property {Layouts} layouts
 * @property {string} name
 */

/**
 * Procesa los datos de FlashPromotionLayout para adaptarlos al formato del componente LimitedTimeOfferLayout
 * @param {FlashPromotionLayout} flashPromotionLayout 
 * @returns {Array} Array con datos adaptados para el componente
 */
const processFlashPromotionData = (flashPromotionLayout) => {
  if (!flashPromotionLayout || !flashPromotionLayout.offerCards) {
    return [];
  }

  return flashPromotionLayout.offerCards.map((card, index) => ({
    id: index + 1,
    title: card.cardTitleTwo,     // El precio va en el título
    subtitle: card.cardTitleOne,  // El nombre del producto va en el subtítulo
    imageCard: card.imageCard,
    ctaMessage: card.CTA || 'Chat with us'  // Mensaje para el CTA
  }));
};

/**
 * Fetches dashboard data from Firestore
 * @returns {Promise<DashboardData>}
 */
export const getDashboardData = async () => {
  try {
    console.log('\n📝 FETCHING DASHBOARD DATA ============================');

    const dashboardData = await fetchDashboardData();

    console.log('\n📥 RAW DATA FROM FIRESTORE ==========================');
    console.log(dashboardData);

    // Validate the structure of the data
    if (!dashboardData.layouts) {
      throw new Error('Invalid dashboard data structure: missing layouts');
    }

    // Procesamos los datos de FlashPromotionLayout si existen
    if (dashboardData.layouts.FlashPromotionLayout) {
      dashboardData.processedFlashPromotionLayout = processFlashPromotionData(
        dashboardData.layouts.FlashPromotionLayout
      );
    }

    console.log('\n✅ SUCCESSFULLY DECODED ===========================');
    console.log(dashboardData);

    return dashboardData;
  } catch (error) {
    console.log('\n❌ GENERAL ERROR ==================================');
    console.log('Error:', error);
    console.log('Localized description:', error.message);
    
    // Si estamos offline, devolvemos un objeto con layouts vacíos pero válidos
    if (error.message.includes('offline')) {
      return {
        layouts: {},
        name: 'Offline Dashboard'
      };
    }
    
    throw new Error('Failed to fetch dashboard data: ' + error.message);
  }
};

export const getBlogPosts = async () => {
  try {
    const response = await apiClient.get('/blog/posts?per_page=10');
    if (response.data.success) {
      return response.data.data.slice(0, 10);
    }
    throw new Error('Failed to fetch blog posts');
  } catch (error) {
    throw new Error('Failed to fetch blog posts');
  }
};

/**
 * Fetches the active banner from Firestore
 * @returns {Promise<Object|null>} The active banner data or null if not found
 */
export const getActiveBanner = async () => {
  try {
    console.log('🔍 getActiveBanner: Starting to fetch active banner from Firestore');
    
    const bannersRef = collection(db, "banners");
    const q = query(bannersRef, where("isActive", "==", true));
    console.log('🔍 getActiveBanner: Query created with filter isActive==true');
    
    const querySnapshot = await getDocs(q);
    console.log(`🔍 getActiveBanner: Query executed, found ${querySnapshot.size} documents`);
    
    if (!querySnapshot.empty) {
      // Get the first active banner (there should typically be only one)
      const activeBanner = querySnapshot.docs[0];
      const bannerData = {
        id: activeBanner.id,
        ...activeBanner.data()
      };
      
      console.log('✅ getActiveBanner: Active banner found:', {
        id: bannerData.id,
        title: bannerData.title,
        isActive: bannerData.isActive
      });
      
      return bannerData;
    }
    
    console.log('⚠️ getActiveBanner: No active banner found');
    return null;
  } catch (error) {
    console.error("❌ getActiveBanner: Error fetching active banner:", error);
    return null;
  }
}; 