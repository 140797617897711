import React from 'react';
import { Typography, Divider } from '@mui/material';
import { copyrightText } from '../utils/footerData';

export const CopyrightSection = ({ color = 'white', mt = 3, pb = 2 }) => (
  <>
    <Divider 
      orientation='horizontal' 
      color={color}
      sx={{
        mt: 2, 
        mb: 2,
        width: "100%",
        mx: "auto"
      }}
    />
    <Typography 
      sx={{ 
        color: '#A7B0C0', 
        fontSize: 12, 
        fontFamily: 'Open Sans', 
        fontWeight: '400', 
        lineHeight: '18px', 
        wordWrap: 'break-word',
        mt,
        pb,
        textAlign: { xs: 'left', sm: 'center' }
      }}
    >
      {copyrightText}
    </Typography>
  </>
); 