import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { DarkBox } from './StyledComponents';
import { QuickLinksDesktop } from './QuickLinks';
import { HoursDesktop } from './HoursOfOperation';
import { ContactDesktop } from './ContactInfo';
import { AppStoreLogos, SocialMediaIcons } from './Logos';
import { NewsletterDesktop } from './Newsletter';
import { appPromoText } from '../utils/footerData';

// Componente para textos SEO ocultos pero accesibles para lectores de pantalla
const SeoTextComponent = styled.span`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
`;

const DesktopFooterContent = () => {
  const { t } = useTranslation();
  
  return (
    <Box>
      <Box sx={{ bgcolor: 'text.200', pt: 10, pb: 20 }}>
        <Container maxWidth="lg">
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <Typography variant="h4semiBold" gutterBottom>
                {t(appPromoText.title)}
                <SeoTextComponent>{t(appPromoText.seoTitle)}</SeoTextComponent>
                <SeoTextComponent>{t(appPromoText.seoDescription)}</SeoTextComponent>
              </Typography>
              <AppStoreLogos spacing={2} />
              <SocialMediaIcons spacing={2} color="primary" iconSize={28} />
            </Grid>
            <NewsletterDesktop />
          </Grid>
        </Container>
      </Box>

      <DarkBox>
        <Container maxWidth="lg" sx={{ padding: 0 }}>
          <Grid container spacing={4}>
            <QuickLinksDesktop />
            <HoursDesktop />
            <ContactDesktop />
          </Grid>
        </Container>
      </DarkBox>
    </Box>
  );
};

export default DesktopFooterContent; 