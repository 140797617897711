/**
 * MobileCarousel component
 * Mobile-specific implementation of the CategoriesCarousel with a vertical grid layout (2 cards per row)
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Container } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CategoryCard from './CategoryCard';
import {
  categoriesContainerStyles,
  mobileCategoriesContainerStyles,
  mobileCarouselContainerStyles,
  mobileCarouselContentStyles,
  visuallyHiddenStyles
} from '../styles';

const MobileCarousel = ({ categories }) => {
  const { t } = useTranslation();
  
  return (
    <Box sx={{ ...categoriesContainerStyles, ...mobileCategoriesContainerStyles }}>
      <Container maxWidth={false} disableGutters={true} sx={{ px: 0, width: '100%', maxWidth: '100%' }}>
        {/* SEO description (visually hidden) */}
        <Typography sx={visuallyHiddenStyles}>
          {t('dashboard.categoriesCarousel.description')}
        </Typography>
        <Box sx={mobileCarouselContainerStyles}>
          <Box sx={mobileCarouselContentStyles}>
            {categories.map((category) => (
              <CategoryCard
                key={category.id}
                category={category}
                deviceType="mobile"
                isFirstItem={false} // No first item margin needed in grid layout
              />
            ))}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

MobileCarousel.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      key: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired
    })
  ).isRequired
};

export default MobileCarousel; 