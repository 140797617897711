import { Box, Accordion } from '@mui/material';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const DarkBox = styled(Box)(({ theme, isMobile }) => ({
  backgroundColor: theme.palette.secondary[700],
  color: 'white',
  padding: isMobile ? theme.spacing(3) : theme.spacing(10),
  borderRadius: isMobile ? 0 : theme.shape.borderRadius * 8,
  margin: isMobile ? theme.spacing(0) : theme.spacing(0, 'auto', 3),
  width: '100%',
  maxWidth: '1600px',
  boxSizing: 'border-box',
  position: 'relative',
  top: isMobile ? '0px' : '-100px',
  fontFamily: theme.typography.fontFamily
}));

export const DarkBoxTablet = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.secondary[700],
  color: 'white',
  padding: theme.spacing(10),
  borderRadius: 0,
  margin: theme.spacing(0),
  width: '100%',
  boxSizing: 'border-box',
  fontFamily: theme.typography.fontFamily
}));

export const StyledAccordion = styled(Accordion)({
  backgroundColor: 'transparent',
  color: 'inherit',
  boxShadow: 'none',
  '&:before': {
    display: 'none',
  },
  padding: 0
});

export const StyledExpandIcon = styled(ExpandMoreIcon)(({theme}) => ({
  color: theme.palette.primary[700]
})); 