/**
 * Desktop styles for the NotificationBanner component
 * These styles are for desktop devices and large screens
 */

export const desktopContainerStyles = {
  py: 2,
  px: 4,
  position: 'relative',
};

export const desktopTitleStyles = {
  fontSize: '16px',
  width: '100%',
  textAlign: 'center',
};

export const desktopDescriptionStyles = {
  fontSize: '12px',
  maxWidth: '80%',
  mx: 'auto',
  textAlign: 'center',
};

export const desktopCloseButtonStyles = {
  right: 24,
  top: '50%',
  transform: 'translateY(-50%)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& .MuiSvgIcon-root': {
    fontSize: '1.75rem',
  },
}; 