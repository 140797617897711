/**
 * BrandHeader Utilities
 * Funciones de utilidad para el componente BrandHeader
 */

/**
 * Extrae los filtros rápidos de los datos de la marca
 * @param {Object} brandData - Datos de la marca
 * @returns {Array} - Array de filtros rápidos
 */
export const extractQuickFilters = (brandData) => {
  if (!brandData || !brandData.categories) {
    return [];
  }
  
  // Convertir las categorías en filtros
  return brandData.categories.map(category => ({
    id: category.id,
    name: category.name
  }));
};

/**
 * Formatea la información de la marca para el componente BrandInfo
 * @param {Object} brandData - Datos crudos de la marca
 * @returns {Object} - Datos formateados de la marca
 */
export const formatBrandData = (brandData) => {
  if (!brandData) {
    return {
      name: '',
      logo: '',
      bannerImage: '',
      description: '',
      verified: false
    };
  }
  
  return {
    name: brandData.name || '',
    logo: brandData.logo || brandData.image || '',
    bannerImage: brandData.bannerImage || '',
    description: brandData.description || '',
    verified: brandData.verified || false,
    rating: brandData.rating || null,
    reviewCount: brandData.reviewCount || 0,
    location: brandData.location || ''
  };
}; 