/**
 * Tablet styles for the NotificationBanner component
 * These styles are for tablet devices and medium-sized screens
 */

export const tabletContainerStyles = {
  py: 2,
  px: 3,
  minHeight: '70px',
  position: 'relative',
};

export const tabletTitleStyles = {
  fontSize: '14px',
  mb: 0.75,
  width: '100%',
  textAlign: 'center',
};

export const tabletDescriptionStyles = {
  fontSize: '13px',
  maxWidth: '90%',
  mx: 'auto',
  textAlign: 'center',
};

export const tabletCloseButtonStyles = {
  right: 16,
  top: '50%',
  transform: 'translateY(-50%)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& .MuiSvgIcon-root': {
    fontSize: '1.5rem',
  },
}; 