import React from 'react';
import { Box, Typography, Card, CardContent, CardMedia, useTheme, useMediaQuery } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PropTypes from 'prop-types';
import contactService from '../../../../../../shared/services/contactService';
// Importar estilos del directorio principal de estilos
import {
  // Estilos comunes
  cardContainerStyles,
  leftPanelStyles,
  titleOutlineStyles,
  subtitleStyles,
  buttonWrapperStyles,
  iconWrapperStyles,
  iconStyles,
  buttonTextStyles,
  rightPanelStyles,
  imageStyles,
  // Estilos móviles
  mobileCardStyles,
  mobileCardMediaStyles,
  mobileCardContentStyles,
  mobilePriceStyles,
  mobileProductStyles
} from '../../styles';

export const OfferCard = ({ title, subtitle, imageCard, ctaMessage }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleContactClick = () => {
    // Si hay un mensaje CTA y estamos en móvil, abrimos WhatsApp
    if (ctaMessage && isMobile) {
      contactService.openWhatsApp(ctaMessage);
    }
  };

  // Versión móvil como card
  if (isMobile) {
    return (
      <Card sx={mobileCardStyles}>
        <CardMedia
          component="img"
          image={imageCard}
          alt={subtitle}
          sx={{
            ...mobileCardMediaStyles,
            height: '110px', // Altura optimizada para card móvil
          }}
        />
        <CardContent sx={{
          ...mobileCardContentStyles,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          padding: '16px',
          height: '170px', // Altura fija para el contenido
        }}>
          <Box>
            <Typography sx={{
              ...mobilePriceStyles,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              minHeight: '45px', // Altura mínima para 2 líneas
              lineHeight: '1.2',
              marginBottom: '4px'
            }}>
              {title}
            </Typography>
            <Typography sx={{
              ...mobileProductStyles,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              minHeight: '36px', // Altura mínima para 2 líneas
              lineHeight: '1.2'
            }}>
              {subtitle}
            </Typography>
          </Box>
          
          <Box 
            sx={{ 
              display: 'flex', 
              justifyContent: 'flex-start', 
              width: '100%',
              marginTop: 'auto',
              marginBottom: '0'
            }}
          >
            <Box 
              sx={{
                ...buttonWrapperStyles,
                cursor: 'pointer',
                '&:hover': {
                  opacity: 0.9
                },
                '&:active': {
                  opacity: 0.7
                },
                marginBottom: '0'
              }}
              onClick={handleContactClick}
            >
              <Box sx={iconWrapperStyles}>
                <WhatsAppIcon sx={iconStyles} />
              </Box>
              <Typography sx={buttonTextStyles}>
                Chat with us
              </Typography>
            </Box>
          </Box>
        </CardContent>
      </Card>
    );
  }

  // Versión desktop y tablet
  return (
    <Box sx={cardContainerStyles}>
      <Box sx={leftPanelStyles}>
        <Typography sx={titleOutlineStyles}>
          {title}
        </Typography>
        <Typography sx={subtitleStyles}>
          {subtitle}
        </Typography>
        <Box 
          sx={{
            ...buttonWrapperStyles,
            cursor: 'pointer',
            '&:hover': {
              opacity: 0.9
            },
            '&:active': {
              opacity: 0.7
            }
          }}
          title={ctaMessage || "Chat with us about this product"}
          onClick={handleContactClick}
        >
          <Box sx={iconWrapperStyles}>
            <PhoneIcon sx={iconStyles} />
          </Box>
          <Typography sx={buttonTextStyles}>
            Chat with us
          </Typography>
        </Box>
      </Box>

      <Box sx={rightPanelStyles}>
        <Box
          component="img"
          src={imageCard}
          alt={subtitle}
          sx={imageStyles}
        />
      </Box>
    </Box>
  );
};

OfferCard.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  imageCard: PropTypes.string.isRequired,
  ctaMessage: PropTypes.string
};

OfferCard.defaultProps = {
  ctaMessage: 'Chat with us about this product'
};

export default OfferCard; 