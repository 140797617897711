export const desktopContainerStyles = {
  width: '100%',
  py: 4,
  px: 4,
  bgcolor: '#F3F3F3',
  borderRadius: '4px',
  overflow: 'hidden',
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    bgcolor: '#F3F3F3',
    width: '100vw',
    marginLeft: 'calc(-50vw + 50%)',
    marginRight: 'calc(-50vw + 50%)',
    zIndex: -1,
    borderRadius: '4px'
  },
  display: 'flex',
  gap: 4
};

export const desktopFeaturedImageStyles = {
  width: '40%',
  height: '600px',
  borderRadius: '4px',
  overflow: 'hidden',
  boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
  flexShrink: 0
};

export const desktopProductGridStyles = {
  display: 'grid',
  gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
  gridTemplateRows: 'repeat(2, 1fr)',
  gap: 2,
  width: '100%',
  alignContent: 'flex-start',
  justifyContent: 'stretch',
  alignItems: 'stretch',
  justifyItems: 'stretch',
  px: 1,
  height: '600px',
  '& > *': {
    width: '100%',
    height: '90%',
    maxWidth: 'none'
  }
}; 