/**
 * Estilos específicos para vista tablet
 * Incluye ajustes específicos para iPad Mini e iPad Air
 */

// Función para adaptar valores según tipo de iPad
const getResponsiveValue = (ipadMini, ipadAir, defaultValue) => {
  return {
    ipadMini, 
    ipadAir, 
    default: defaultValue
  };
};

// Contenedor principal para tablet
export const tabletContainerStyles = { 
  display: { xs: 'none', sm: 'flex', md: 'none' },
  flexDirection: 'row',
  width: '100%',
  borderRadius: '16px',
  overflow: 'hidden',
  alignItems: 'center'
};

// Función para obtener gap según dispositivo
export const getTabletGap = (isIpadMini, isIpadAir) => 
  isIpadMini ? 2 : (isIpadAir ? 2.5 : 3);

// Función para obtener padding horizontal según dispositivo
export const getTabletPaddingX = (isIpadMini, isIpadAir) => 
  isIpadMini ? 1.5 : (isIpadAir ? 2 : 2);

// Función para obtener padding vertical según dispositivo
export const getTabletPaddingY = (isIpadMini, isIpadAir) => 
  isIpadMini ? 2 : (isIpadAir ? 2.5 : 3);

// Estilos para el contenido izquierdo
export const tabletLeftContentStyles = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: '100%'
};

// Función para obtener flex para contenido izquierdo
export const getTabletLeftContentFlex = (isIpadMini, isIpadAir) => 
  isIpadMini ? '0.95' : (isIpadAir ? '0.9' : '0.9');

// Función para obtener gap para contenido izquierdo
export const getTabletLeftContentGap = (isIpadMini, isIpadAir) => 
  isIpadMini ? 1.5 : (isIpadAir ? 2 : 2);

// Función para obtener padding derecho para contenido izquierdo
export const getTabletLeftContentPaddingRight = (isIpadAir) => 
  isIpadAir ? 1 : 0;

// Estilos para el wrapper del subtítulo en tablet
export const tabletSubtitleWrapperStyles = {
  borderLeft: '4px solid #FF5F25',
  marginTop: 0,
  display: 'flex',
  alignItems: 'center'
};

// Función para obtener padding del subtítulo
export const getTabletSubtitlePadding = (isIpadMini, isIpadAir) => 
  isIpadMini ? '0px 10px' : (isIpadAir ? '0px 12px' : '0px 12.6px');

// Estilos para el subtítulo en tablet
export const tabletSubtitleStyles = {
  width: 'auto',
  lineHeight: 1.3,
  display: 'flex',
  alignItems: 'center'
};

// Función para obtener tamaño de fuente del subtítulo
export const getTabletSubtitleFontSize = (isIpadMini, isIpadAir) => 
  isIpadMini ? '15px' : (isIpadAir ? '16px' : '18px');

// Estilos para el título en tablet
export const tabletTitleStyles = {
  lineHeight: 1.2,
  width: '100%',
  display: 'flex',
  alignItems: 'center'
};

// Función para obtener tamaño de fuente del título
export const getTabletTitleFontSize = (isIpadMini, isIpadAir) => 
  isIpadMini ? '26px' : (isIpadAir ? '28px' : '32px');

// Función para obtener margin top del título
export const getTabletTitleMarginTop = (isIpadMini, isIpadAir) => 
  isIpadMini ? 0.5 : (isIpadAir ? 0.75 : 1);

// Función para obtener padding derecho del título
export const getTabletTitlePaddingRight = (isIpadAir) => 
  isIpadAir ? 0.5 : 0;

// Estilos para el contenido derecho en tablet
export const tabletRightContentStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
  borderRadius: '8px'
};

// Función para obtener flex para contenido derecho
export const getTabletRightContentFlex = (isIpadMini, isIpadAir) => 
  isIpadMini ? '1.05' : (isIpadAir ? '1.1' : '1.1');

// Función para obtener altura para contenido derecho
export const getTabletRightContentHeight = (isIpadMini, isIpadAir) => 
  isIpadMini ? '240px' : (isIpadAir ? '260px' : '280px');

// Estilos para la imagen en tablet
export const tabletImageStyles = {
  width: '100%',
  height: '100%',
  objectFit: 'contain',
  objectPosition: 'center'
}; 