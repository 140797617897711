/**
 * CategoriesCarousel component
 * A responsive carousel that displays product categories with appropriate layout for each device
 */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MobileCarousel, TabletCarousel, DesktopCarousel } from './components';
import { useDeviceDetection, getTranslatedCategories } from './utils';

/**
 * CategoriesCarousel
 * Main component that renders the appropriate device-specific carousel
 * Uses device detection to determine which component to render
 */
const CategoriesCarousel = () => {
  const { t } = useTranslation();
  const { isMobile, isTablet, isDesktop, tabletType } = useDeviceDetection();
  
  // Memoize translated categories to prevent unnecessary recalculations
  const categories = useMemo(() => getTranslatedCategories(t), [t]);
  
  // Render the appropriate carousel based on device type
  if (isMobile) {
    return <MobileCarousel categories={categories} />;
  }
  
  if (isTablet) {
    return <TabletCarousel categories={categories} tabletType={tabletType || 'generic'} />;
  }
  
  return <DesktopCarousel categories={categories} />;
};

export default CategoriesCarousel; 