/**
 * BrandsCarousel Component
 * A responsive carousel that showcases trusted roofing brand partners
 * with device-specific optimizations
 */

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useDeviceDetection } from './utils/deviceDetection';
import { getTranslatedBrands } from './utils/brands';
import MobileCarousel from './components/MobileCarousel';
import TabletCarousel from './components/TabletCarousel';
import DesktopCarousel from './components/DesktopCarousel';

const BrandsCarousel = ({ 
  showTitle = true, 
  useAlternativeLayout = true,
  isInBrandProductsPage = false,
  currentBrandId = null
}) => {
  const { deviceType, tabletType } = useDeviceDetection();
  const { t } = useTranslation();
  
  // Get translated brands data
  const brands = useMemo(() => getTranslatedBrands(t), [t]);
  
  // Render the appropriate carousel based on device type
  const renderCarousel = () => {
    if (deviceType === 'mobile') {
      return (
        <MobileCarousel 
          brands={brands} 
          showTitle={showTitle} 
          useAlternativeLayout={useAlternativeLayout}
          isInBrandProductsPage={isInBrandProductsPage}
          currentBrandId={currentBrandId}
        />
      );
    }
    
    if (deviceType === 'tablet') {
      return (
        <TabletCarousel 
          brands={brands} 
          tabletType={tabletType} 
          showTitle={showTitle} 
          useAlternativeLayout={useAlternativeLayout}
          isInBrandProductsPage={isInBrandProductsPage}
          currentBrandId={currentBrandId}
        />
      );
    }
    
    return (
      <DesktopCarousel 
        brands={brands} 
        showTitle={showTitle}
        isInBrandProductsPage={isInBrandProductsPage}
        currentBrandId={currentBrandId}
      />
    );
  };
  
  return renderCarousel();
};

BrandsCarousel.propTypes = {
  showTitle: PropTypes.bool,
  useAlternativeLayout: PropTypes.bool,
  isInBrandProductsPage: PropTypes.bool,
  currentBrandId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

BrandsCarousel.defaultProps = {
  showTitle: true,
  useAlternativeLayout: true,
  isInBrandProductsPage: false,
  currentBrandId: null
};

export default BrandsCarousel; 