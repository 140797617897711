import React from 'react';
import { Box, useTheme, useMediaQuery } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import PropTypes from 'prop-types';
import 'swiper/css';
import 'swiper/css/pagination';
import OfferCard from './components/OfferCard';
import { testLimitedTimeOffers } from './utils/mockData';
import { containerStyles, slideWrapperStyles, swiperStyles } from './styles';
import { mobileSwiperStyles, mobileSlideWrapperStyles } from './styles/mobile';
import { tabletSwiperStyles, tabletSlideWrapperStyles } from './styles/tablet';
import { desktopSwiperStyles, desktopSlideWrapperStyles } from './styles/desktop';

/**
 * LimitedTimeOfferLayout
 * 
 * Este componente muestra un carrusel de ofertas por tiempo limitado.
 * En dispositivos móviles, muestra tarjetas pequeñas en formato de grid.
 * En tablets y desktop, muestra un carrusel de tarjetas grandes.
 * @param {Object} props - Las propiedades del componente
 * @param {Array} [props.offers] - Ofertas provenientes de la API
 */
const LimitedTimeOfferLayout = ({ offers }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  // Usar datos de la API o datos de prueba si no hay datos de la API
  const offerData = offers?.length > 0 ? offers : testLimitedTimeOffers;

  return (
    <Box sx={containerStyles}>
      <Swiper
        modules={[Autoplay, Pagination]}
        slidesPerView={isMobile ? 2 : 1}
        spaceBetween={isMobile ? -4 : 0}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        pagination={isMobile ? false : {
          clickable: true,
          dynamicBullets: true,
        }}
        loop={!isMobile}
        className="mySwiper"
        style={{
          ...swiperStyles,
          width: '100%',
          paddingBottom: isMobile 
            ? mobileSwiperStyles.paddingBottom 
            : isTablet 
              ? tabletSwiperStyles.paddingBottom 
              : desktopSwiperStyles.paddingBottom,
          paddingLeft: isMobile ? mobileSwiperStyles.paddingLeft : '0'
        }}
      >
        {offerData.map((offer) => (
          <SwiperSlide 
            key={offer.id}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginRight: isMobile ? '-15px' : '0'
            }}
          >
            <Box 
              sx={{
                ...slideWrapperStyles,
                padding: isMobile 
                  ? mobileSlideWrapperStyles.padding 
                  : isTablet 
                    ? tabletSlideWrapperStyles.padding 
                    : desktopSlideWrapperStyles.padding
              }}
            >
              <OfferCard {...offer} />
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

LimitedTimeOfferLayout.propTypes = {
  offers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      title: PropTypes.string.isRequired,
      subtitle: PropTypes.string.isRequired,
      imageCard: PropTypes.string.isRequired,
      ctaMessage: PropTypes.string
    })
  )
};

LimitedTimeOfferLayout.defaultProps = {
  offers: []
};

export default LimitedTimeOfferLayout; 