import React from 'react';
import { Box, Container } from '@mui/material';
import PropTypes from 'prop-types';

const DashboardSection = ({ children, noPadding, fullWidth, background, sx = {} }) => {
  return (
    <Box 
      sx={{ 
        width: '100%',
        backgroundColor: background || 'transparent',
        py: noPadding ? 0 : { xs: 4, md: 5 },
        display: 'flex',
        justifyContent: 'center',
        ...sx
      }}
    >
      <Container 
        maxWidth="lg" 
        disableGutters={fullWidth}
        sx={{ 
          px: fullWidth ? 0 : { xs: 2, sm: 3, md: 4 },
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Box
          sx={{
            width: '100%'
          }}
        >
          {children}
        </Box>
      </Container>
    </Box>
  );
};

DashboardSection.propTypes = {
  children: PropTypes.node.isRequired,
  noPadding: PropTypes.bool,
  fullWidth: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object
  ]),
  background: PropTypes.string,
  sx: PropTypes.object
};

DashboardSection.defaultProps = {
  noPadding: false,
  fullWidth: false,
  background: undefined,
  sx: {}
};

export default DashboardSection; 