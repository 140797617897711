import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton, useTheme, useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { getActiveBanner } from '../../services/dashboardService';

// Import styles
import {
  containerStyles,
  contentWrapperStyles,
  titleStyles,
  descriptionStyles,
  closeButtonStyles,
  mobileContainerStyles,
  mobileTitleStyles,
  mobileDescriptionStyles,
  mobileCloseButtonStyles,
  tabletContainerStyles,
  tabletTitleStyles,
  tabletDescriptionStyles,
  tabletCloseButtonStyles,
  desktopContainerStyles,
  desktopTitleStyles,
  desktopDescriptionStyles,
  desktopCloseButtonStyles
} from './styles';

// Nombre del evento para notificar cambios en la visibilidad del banner
export const BANNER_VISIBILITY_EVENT = 'bannerVisibilityChanged';

/**
 * NotificationBanner component displays an important notification at the top of the dashboard
 * It fetches data from Firestore and only shows when an active banner exists
 * Users can close the banner which will be stored in localStorage to prevent it from showing again
 */
export const NotificationBanner = () => {
  console.log('🔄 NotificationBanner: Component rendering');
  
  const [banner, setBanner] = useState(null);
  const [visible, setVisible] = useState(true);
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  
  // Define breakpoints
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  
  // Get current language
  const currentLanguage = i18n.language || 'en';
  const isSpanish = currentLanguage.startsWith('es');

  // Emitir evento cuando cambia la visibilidad
  useEffect(() => {
    // Solo emitimos el evento después de que se haya cargado el banner
    if (banner !== null) {
      // Calcular la altura del banner según el dispositivo
      let bannerHeight = 65; // Default (60px height + 5px margin)
      
      if (isMobile) {
        bannerHeight = 90; // 85px height + 5px margin for mobile - Aumentado para móviles
      } else if (isTablet) {
        bannerHeight = 75; // 70px height + 5px margin for tablets
      } else {
        bannerHeight = 65; // 60px height + 5px margin for desktop
      }
      
      console.log(`🔄 NotificationBanner: Emitting visibility event: visible=${visible}, height=${visible ? bannerHeight : 0}, device=${isMobile ? 'mobile' : isTablet ? 'tablet' : 'desktop'}`);
      
      const event = new CustomEvent(BANNER_VISIBILITY_EVENT, { 
        detail: { 
          visible, 
          height: visible ? bannerHeight : 0,
          isMobile,
          isTablet,
          isDesktop
        }
      });
      window.dispatchEvent(event);
    } else {
      // Si no hay banner, enviamos un evento con altura 0
      const event = new CustomEvent(BANNER_VISIBILITY_EVENT, { 
        detail: { 
          visible: false, 
          height: 0,
          isMobile,
          isTablet,
          isDesktop
        }
      });
      window.dispatchEvent(event);
    }
  }, [visible, banner, isMobile, isTablet, isDesktop]);

  // Fetch banner data
  useEffect(() => {
    console.log('🔄 NotificationBanner: useEffect running - fetching banner data');
    
    const fetchBanner = async () => {
      console.log('🔄 NotificationBanner: fetchBanner function called');
      const bannerData = await getActiveBanner();
      
      console.log('🔄 NotificationBanner: Banner data received:', bannerData);
      
      if (bannerData) {
        // Check if this banner has been dismissed before
        const dismissedBanners = JSON.parse(localStorage.getItem('dismissedBanners') || '{}');
        
        if (!dismissedBanners[bannerData.id]) {
          console.log('✅ NotificationBanner: Setting banner data - not dismissed');
          setBanner(bannerData);
        } else {
          console.log('⚠️ NotificationBanner: Banner already dismissed, hiding');
          setVisible(false);
          setBanner(null);
        }
      } else {
        console.log('⚠️ NotificationBanner: No banner data received, hiding banner');
        setVisible(false);
        setBanner(null);
      }
    };
    
    fetchBanner();
  }, []);
  
  // Handle close button click
  const handleClose = () => {
    console.log('🔄 NotificationBanner: Close button clicked');
    
    if (banner) {
      // Store dismissed banner ID in localStorage
      const dismissedBanners = JSON.parse(localStorage.getItem('dismissedBanners') || '{}');
      dismissedBanners[banner.id] = true;
      localStorage.setItem('dismissedBanners', JSON.stringify(dismissedBanners));
      console.log('🔄 NotificationBanner: Banner dismissed status saved to localStorage');
    }
    
    setVisible(false);
  };
  
  // If no banner data or not visible, don't render anything
  if (!banner || !visible) {
    console.log('⚠️ NotificationBanner: Banner not visible or no data, returning null');
    return null;
  }
  
  // Get the appropriate title and description based on language
  const title = isSpanish && banner.titleEs ? banner.titleEs : banner.title;
  const description = isSpanish && banner.descriptionEs ? banner.descriptionEs : banner.description;
  
  console.log('✅ NotificationBanner: Rendering banner with title:', title);
  
  // Combine styles based on device
  const combinedContainerStyles = {
    ...containerStyles,
    ...(isMobile && mobileContainerStyles),
    ...(isTablet && tabletContainerStyles),
    ...(isDesktop && desktopContainerStyles),
  };
  
  const combinedTitleStyles = {
    ...titleStyles,
    ...(isMobile && mobileTitleStyles),
    ...(isTablet && tabletTitleStyles),
    ...(isDesktop && desktopTitleStyles),
  };
  
  const combinedDescriptionStyles = {
    ...descriptionStyles,
    ...(isMobile && mobileDescriptionStyles),
    ...(isTablet && tabletDescriptionStyles),
    ...(isDesktop && desktopDescriptionStyles),
  };
  
  const combinedCloseButtonStyles = {
    ...closeButtonStyles,
    ...(isMobile && mobileCloseButtonStyles),
    ...(isTablet && tabletCloseButtonStyles),
    ...(isDesktop && desktopCloseButtonStyles),
  };
  
  return (
    <Box sx={combinedContainerStyles}>
      <Box sx={contentWrapperStyles}>
        <Typography 
          component="h2" 
          sx={combinedTitleStyles}
        >
          {title}
        </Typography>
        <Typography 
          component="p" 
          sx={combinedDescriptionStyles}
        >
          {description}
        </Typography>
      </Box>
      <IconButton 
        aria-label={isSpanish ? "Cerrar notificación" : "Close notification"} 
        onClick={handleClose}
        sx={combinedCloseButtonStyles}
      >
        <CloseIcon />
      </IconButton>
    </Box>
  );
};

export default NotificationBanner; 