/**
 * Mobile-specific styles for HeroBanner component
 * 
 * These styles implement a mobile-first approach with:
 * - Full-width edge-to-edge container and image
 * - Proper spacing for text content
 * - Centered button taking 90% of container width
 */

// Main container for mobile view - Full width with no horizontal padding
export const mobileContainerStyles = {
  display: { xs: 'flex', sm: 'none' },
  flexDirection: 'column',
  width: '100%',
  gap: 2,
  px: 0, // Remove horizontal padding to use full width
  pt: 1,
  pb: { xs: 3 },
  overflow: 'hidden'
};

// Image container for mobile - Edge-to-edge design
export const mobileImageContainerStyles = {
  width: '100%',
  height: { xs: '340px' },
  borderRadius: '0px', // Remove border radius for full-width effect
  overflow: 'hidden',
  mt: 1,
  mb: 2,
  mx: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#f9f9f9',
  boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.05)',
};

// Image styles for mobile - Full width, edge-to-edge design
export const mobileImageStyles = {
  width: '100%',
  height: '100%',
  objectFit: 'contain',
  objectPosition: 'center',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.02)'
  }
};

// Content container for mobile - With proper left padding for text
export const mobileContentContainerStyles = {
  width: '100%',
  textAlign: 'left', 
  px: { xs: 2 }, // Add padding to the content container for proper text spacing
  mt: 0,
};

// Subtitle wrapper for mobile - Left-justified
export const mobileSubtitleWrapperStyles = {
  borderLeft: '4px solid #FF5F25',
  justifyContent: 'flex-start',
  mb: 1,
  padding: '0px 12px',
  mx: { xs: 0 },
  maxWidth: 'fit-content',
  display: 'inline-flex',
  marginLeft: 0,
};

// Subtitle styles for mobile - Clear typography
export const mobileSubtitleStyles = {
  textAlign: 'left',
  width: 'auto',
  color: '#0A3954', 
  fontWeight: 600,
  fontSize: { xs: 16 },
  lineHeight: 1.3,
  letterSpacing: '0.01em',
  fontFamily: 'Open Sans',
  pr: 2, // Add right padding to ensure text isn't too close to screen edge
  maxWidth: '95%', // Ensure text doesn't overflow container
};

// Title styles for mobile - Left-justified with proper spacing
export const mobileTitleStyles = {
  textAlign: { xs: 'left' },
  width: '80%',
  fontSize: { xs: '24px' },
  mb: 3,
  mt: 1,
  lineHeight: 1.2,
  pr: 1, // Add right padding for consistency
  fontWeight: 700,
  color: '#0A3954',
  fontFamily: 'Open Sans'
};

// Button styles for mobile - Visual appearance only (no positioning)
export const mobileButtonStyles = {
  borderRadius: '71.31px',
  py: { xs: 1.25 },
  px: { xs: 3 },
  fontSize: { xs: '16px' },
  fontWeight: 600,
  boxShadow: '0px 2px 8px rgba(255, 95, 37, 0.25)',
  backgroundColor: '#FF5F25',
  color: '#ffffff',
  textTransform: 'none',
  fontFamily: 'Open Sans',
  '&:hover': {
    backgroundColor: '#E54E14'
  },
  transition: 'all 0.3s ease'
}; 