/**
 * Common styles
 * Shared styles for the ProductCard component across all devices
 */

// Common styles for the product card container
export const commonProductCardStyles = {
  width: '100%',
  height: '100%',
  background: 'white',
  borderRadius: 4,
  border: '1px solid #E0E0E0',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  transition: 'transform 0.2s, box-shadow 0.2s',
  boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.05)',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)'
  }
};

// Common styles for the product image
export const commonProductImageStyles = {
  width: '100%',
  aspectRatio: '1 / 1',
  objectFit: 'cover',
  borderTopLeftRadius: 4,
  borderTopRightRadius: 4
};

// Common styles for the content container
export const commonContentContainerStyles = {
  padding: '16px',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'white',
  minHeight: '100px'
};

// Common styles for the product name
export const commonProductNameStyles = {
  fontSize: '1rem',
  fontWeight: 500,
  color: '#333',
  lineHeight: 1.4,
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  height: 'auto',
  minHeight: 'unset'
};

// Common styles for the product price
export const commonProductPriceStyles = {
  fontSize: '1.5rem',
  fontWeight: 700,
  color: '#14213d',
  marginTop: '8px'
};

// Common styles for the product details
export const commonProductDetailsStyles = {
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
}; 