export const tabletContainerStyles = {
  width: '100%',
  py: 3,
  px: 3,
  bgcolor: '#F3F3F3',
  borderRadius: '4px',
  overflow: 'hidden',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    bgcolor: '#F3F3F3',
    zIndex: -1
  }
};

export const tabletFeaturedImageStyles = {
  width: '100%',
  height: '600px',
  borderRadius: '4px',
  overflow: 'hidden',
  boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
};

export const tabletProductGridStyles = {
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gap: 2,
  mt: 3,
  px: 2
}; 