import React from 'react';
import { Box, Typography, CircularProgress, Avatar, Container, useTheme, useMediaQuery } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { fetchInvoicesList } from '../services/invoiceService';
import { useAuth } from '../../../shared/context/AuthProvider';
import { getInitials } from '../../../shared/utils/stringUtils';
import InvoiceBanner from '../../../assets/invoice_banner.webp';
import InvoiceCard from '../components/InvoiceCard';

const InvoicesPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { user } = useAuth();

  const { data: invoicesData, isLoading, error } = useQuery({
    queryKey: ['invoices', user?.uid],
    queryFn: () => fetchInvoicesList(user?.uid),
    enabled: !!user?.uid
  });

  const { data: customerData } = useQuery({
    queryKey: ['customerDetail'],
    enabled: !!user?.uid
  });

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography color="error">
          Error loading invoices. Please try again later.
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ 
      bgcolor: { xs: '#C8D0D5', sm: 'transparent' },
      minHeight: '100vh',
      pb: { xs: 2, sm: 4 }
    }}>
      <Container 
        maxWidth="lg" 
        sx={{ 
          px: { xs: 0, sm: 3 }
        }}
      >
        {/* Banner Section */}
        <Box 
          sx={{ 
            bgcolor: 'secondary.700',
            color: 'white',
            py: { xs: 3, sm: 4 },
            position: 'relative',
            overflow: 'hidden',
            borderRadius: { xs: 0, sm: '24px' },
            mt: { xs: 0, sm: 3 },
            height: { xs: '180px', sm: '200px' },
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Box 
            sx={{ 
              display: 'flex',
              alignItems: 'center',
              gap: { xs: 2, sm: 3 },
              position: 'relative',
              zIndex: 1,
              width: '100%',
              px: { xs: 3, sm: 4 }
            }}
          >
            {/* Avatar - Oculto en móvil */}
            {!isMobile && (
              <Avatar 
                sx={{ 
                  width: { xs: 80, sm: 100 }, 
                  height: { xs: 80, sm: 100 }, 
                  bgcolor: '#5D6470',
                  color: 'white',
                  fontSize: { xs: '1.5rem', sm: '1.75rem' },
                  fontWeight: 500,
                  border: '4px solid #F8FAFD'
                }}
              >
                {getInitials(customerData?.companyName, customerData?.email)}
              </Avatar>
            )}

            {/* Company Info */}
            <Box sx={{ flex: 1 }}>
              <Typography 
                variant="h4" 
                sx={{ 
                  color: 'white',
                  fontWeight: 500,
                  mb: 1,
                  fontSize: { xs: '16px', sm: '18px' },
                  maxWidth: { xs: '180px', sm: '250px' },
                  whiteSpace: 'normal',
                  wordWrap: 'break-word',
                  lineHeight: { xs: "16px", sm: 'normal' }
                }}
              >
                {customerData?.companyName}
              </Typography>
              {customerData?.firstName && customerData?.lastName && (
                <Typography 
                  variant="h6" 
                  sx={{ 
                    color: '#F97316',
                    fontWeight: 400,
                    fontSize: { xs: '14px', sm: '18px' },
                    maxWidth: { xs: '180px', sm: '200px' },
                    whiteSpace: 'normal',
                    wordWrap: 'break-word',
                    opacity: { xs: 1, sm: 0.8 }
                  }}
                >
                  {`${customerData.firstName} ${customerData.lastName}`}
                </Typography>
              )}
            </Box>

            {/* Banner Image */}
            <Box 
              component="img"
              src={InvoiceBanner}
              alt="Invoice Banner"
              sx={{
                position: 'absolute',
                right: { xs: '15px', sm: '20px', md: 0 },
                top: '50%',
                transform: 'translateY(-40%)',
                height: { xs: '150px', sm: '180px', md: '200px' },
                objectFit: 'contain'
              }}
            />
          </Box>
        </Box>

        {/* Invoices List Section */}
        <Box sx={{ 
          mt: { xs: 2, sm: 4 },
          px: { xs: 0, sm: 0 },
          display: 'flex',
          flexDirection: 'column',
          gap: { xs: 2, sm: 3 }
        }}>
          {invoicesData?.items?.map((invoice) => (
            <InvoiceCard key={invoice.id} invoice={invoice} />
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default InvoicesPage; 