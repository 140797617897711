import React from 'react';
import {
  Box,
  Typography,
  Divider
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const InvoiceItems = ({ items }) => {
  const { t } = useTranslation();
  // Filtramos los items que no son subtotal
  const productItems = items.filter(item => item.itemType.id !== 'Subtotal');
  
  // Calculamos el subtotal
  const subtotalItem = items.find(item => item.itemType.id === 'Subtotal');
  const subtotalAmount = subtotalItem?.amount || 0;
  
  // Calculamos el total correctamente: subtotal + impuestos/fees
  // Excluimos los productos y el subtotal para obtener solo impuestos/fees
  const taxesAndFees = items.filter(item => 
    item.itemType.id !== 'Subtotal' && 
    !productItems.includes(item)
  );
  
  // Calculamos el total sumando el subtotal y todos los impuestos/fees
  const totalAmount = Number(subtotalAmount) + 
    taxesAndFees.reduce((sum, item) => sum + Number(item.amount || 0), 0);

  return (
    <Box>
      {/* Header */}
      <Box sx={{ mb: 2 }}>
        <Typography variant="subtitle1" sx={{ color: '#F97316', fontWeight: 500 }}>
          {t('invoice.itemDetails.itemName')}
        </Typography>
      </Box>

      {/* Items List */}
      {productItems.map((item, index) => (
        <Box key={item.line}>
          {/* Item */}
          <Box sx={{ py: 2 }}>
            {/* Item Name and Description */}
            <Box sx={{ mb: 1 }}>
              <Typography 
                variant="subtitle1" 
                sx={{ 
                  color: '#F97316',
                  fontSize: '1rem',
                  fontWeight: 500
                }}
              >
                {item.item.refName.split(' : ')[0]}
              </Typography>
              <Typography 
                variant="body2" 
                sx={{ 
                  color: '#1E293B',
                  fontSize: '0.875rem',
                  fontWeight: 500
                }}
              >
                {item.item.refName.split(' : ')[1]}
              </Typography>
            </Box>

            {/* Item Description and Amount */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
              <Box>
                <Typography variant="caption" sx={{ color: '#94A3B8', display: 'block', mb: 0.5 }}>
                  {t('invoice.itemDetails.itemDescription')}
                </Typography>
                <Typography variant="body2" sx={{ color: '#1E293B' }}>
                  {item.description || t('invoice.itemDetails.noDescription')}
                </Typography>
              </Box>
              <Box sx={{ textAlign: 'right' }}>
                <Typography variant="caption" sx={{ color: '#94A3B8', display: 'block', mb: 0.5 }}>
                  {t('invoice.itemDetails.itemAmount')}
                </Typography>
                <Typography variant="body1" sx={{ color: '#F97316', fontWeight: 600 }}>
                  ${Number(item.amount).toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                </Typography>
              </Box>
            </Box>
          </Box>

          {/* Divider */}
          {index < productItems.length - 1 && (
            <Divider sx={{ borderColor: '#E2E8F0' }} />
          )}
        </Box>
      ))}

      {/* Totals */}
      <Box sx={{ mt: 3, pt: 2, borderTop: '1px solid #E2E8F0' }}>
        {/* Subtotal - Oculto temporalmente */}
        {/*
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
          <Typography variant="body2" sx={{ color: '#94A3B8' }}>
            {t('invoice.itemDetails.subtotalAmount')}
          </Typography>
          <Typography variant="body2" sx={{ color: '#1E293B' }}>
            ${Number(subtotalAmount).toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })}
          </Typography>
        </Box>
        */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="body1" sx={{ fontWeight: 600 }}>
            {t('invoice.itemDetails.totalAmount')}
          </Typography>
          <Typography variant="body1" sx={{ color: '#F97316', fontWeight: 600 }}>
            ${Number(totalAmount).toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default InvoiceItems; 