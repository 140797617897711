/**
 * Common styles
 * Styles shared across all devices
 */

// Container styles
export const containerStyles = {
  width: '100%',
  backgroundColor: '#135C86',
  overflow: 'hidden'
};

export const containerContentStyles = {
  width: '100%'
};

export const cardsContainerStyles = {
  width: '100%',
  overflowX: 'auto',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none'
  },
  msOverflowStyle: 'none',
  py: 2
};

export const cardsWrapperStyles = {
  display: 'flex',
  gap: 2,
  minWidth: 'min-content'
};

// PromotionalCard common styles
export const commonPromotionalCardStyles = {
  display: 'flex', 
  backgroundColor: '#082E44', 
  overflow: 'hidden',
  width: '100%'
};

export const commonPromotionalImageWrapperStyles = {
  position: 'relative',
  overflow: 'hidden'
};

export const commonPromotionalImageStyles = {
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  display: 'block'
};

export const commonPromotionalContentStyles = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center'
};

export const commonPromotionalTitleStyles = {
  position: 'relative',
  letterSpacing: '-0.4px',
  display: 'inline-block',
  fontWeight: 900,
  color: '#f3f3f3'
};

export const commonPromotionalSubtitleStyles = {
  position: 'relative',
  fontWeight: 600,
  color: '#fff',
  display: 'inline-block'
};

export const commonPromotionalButtonWrapperStyles = {
  display: 'flex',
  width: '100%'
};

export const commonPromotionalButtonStyles = {
  backgroundColor: '#E96D25',
  color: '#F8FAFD',
  fontFamily: 'Open Sans',
  fontWeight: '600',
  textTransform: 'none',
  borderRadius: 1,
  '&:hover': {
    backgroundColor: '#d54d0d'
  }
};

// OfferCard common styles
export const commonOfferCardStyles = {
  display: 'flex', 
  flexDirection: 'column',
  borderRadius: 2,
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.05)',
  transition: 'transform 0.2s ease',
  flex: '0 0 auto',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.1)',
  }
};

export const commonOfferCardMediaStyles = {
  width: '100%',
  objectFit: 'contain',
  backgroundColor: 'white'
};

export const commonOfferCardContentStyles = {
  flex: 1,
  display: 'flex', 
  flexDirection: 'column', 
  justifyContent: 'space-between'
};

export const commonOfferCardTitleStyles = {
  fontWeight: 600,
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  lineHeight: 1.2,
  color: '#0A3954'
};

export const commonOfferCardPriceStyles = {
  fontWeight: 700,
  mt: 'auto'
}; 