/**
 * DesktopCarousel Component
 * Desktop-specific implementation of the BrandsCarousel with a horizontal layout
 */

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Box, Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import BrandCard from './BrandCard';

// Import styles directly from their source files
import {
  commonContainerStyles,
  commonSeoDescriptionStyles,
  commonTitleStyles
} from '../styles/common';

import {
  desktopContainerStyles,
  desktopTitleStyles,
  desktopCarouselContainerStyles
} from '../styles/desktop';

// Define wrapper styles para el carrusel horizontal
const desktopCarouselWrapperStyles = {
  display: 'flex',
  flexDirection: 'row',
  overflowX: 'auto',
  gap: '24px',
  padding: '24px 0',
  scrollbarWidth: 'none', // Firefox
  '&::-webkit-scrollbar': {
    display: 'none' // Chrome, Safari, Edge
  }
};

const DesktopCarousel = ({ 
  brands, 
  showTitle = true,
  isInBrandProductsPage = false,
  currentBrandId = null
}) => {
  const { t } = useTranslation();
  
  return (
    <Box sx={{ ...commonContainerStyles, ...desktopContainerStyles }}>
      <Container maxWidth="lg">
        {showTitle && (
          <Typography 
            variant="h2" 
            component="h2" 
            sx={{ ...commonTitleStyles, ...desktopTitleStyles }}
          >
            {t('dashboard.brandsCarousel.title')}
          </Typography>
        )}
        
        {/* Hidden SEO description */}
        <Typography 
          component="p" 
          sx={commonSeoDescriptionStyles}
        >
          {t('dashboard.brandsCarousel.description')}
        </Typography>
        
        <Box sx={desktopCarouselContainerStyles}>
          <Box 
            sx={desktopCarouselWrapperStyles}
          >
            {brands.map((brand, index) => (
              <BrandCard 
                key={brand.id} 
                brand={brand} 
                deviceType="desktop"
                isFirstItem={index === 0}
                isCurrentBrand={currentBrandId === brand.id}
              />
            ))}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

DesktopCarousel.propTypes = {
  brands: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      key: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired
    })
  ).isRequired,
  showTitle: PropTypes.bool,
  isInBrandProductsPage: PropTypes.bool,
  currentBrandId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

DesktopCarousel.defaultProps = {
  showTitle: true,
  isInBrandProductsPage: false,
  currentBrandId: null
};

export default memo(DesktopCarousel);