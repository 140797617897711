import React, { createContext, useContext, useState, useEffect } from "react";
import { auth } from '../utils/firebase/firebase-config';
import { onAuthStateChanged } from 'firebase/auth';
import { loginUser, registerUser } from '../../modules/auth/services/authService';

const AuthContext = createContext(null);

// Token expiration time (8 hours in milliseconds)
const TOKEN_EXPIRATION = 8 * 60 * 60 * 1000;

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    // Función para limpiar el estado de autenticación
    const clearAuthState = async () => {
        try {
            console.log('�� Iniciando limpieza completa del estado de autenticación...');
            
            // 1. Detectar si es un dispositivo móvil
            const isMobile = window.innerWidth < 768;
            console.log('📱 ¿Es dispositivo móvil?', isMobile);
            
            // 2. Cerrar sesión en Firebase
            if (auth.currentUser) {
                try {
                    await auth.signOut();
                    console.log('✅ Firebase signOut completado');
                } catch (firebaseError) {
                    console.error('Error en Firebase signOut:', firebaseError);
                }
            } else {
                console.log('ℹ️ No hay usuario de Firebase activo');
            }
            
            // 3. Limpiar estado local de React
            setUser(null);
            console.log('✅ Estado local de usuario limpiado');
            
            // 4. Limpiar sessionStorage (todos los items)
            try {
                sessionStorage.clear();
                console.log('✅ SessionStorage completamente limpiado');
            } catch (sessionError) {
                console.error('Error al limpiar sessionStorage:', sessionError);
            }
            
            // 5. Limpiar localStorage (todos los items)
            try {
                localStorage.clear();
                console.log('✅ LocalStorage completamente limpiado');
            } catch (localError) {
                console.error('Error al limpiar localStorage:', localError);
            }
            
            // 6. Forzar la actualización del estado de autenticación
            try {
                window.dispatchEvent(new Event('storage'));
                console.log('✅ Evento storage disparado');
            } catch (eventError) {
                console.error('Error al disparar evento storage:', eventError);
            }
            
            // 7. Limpiar cualquier caché de React Query
            if (window.queryClient) {
                try {
                    window.queryClient.clear();
                    console.log('✅ Caché de React Query completamente limpiada');
                } catch (cacheError) {
                    console.error('Error al limpiar caché:', cacheError);
                }
            }
            
            // 8. En dispositivos móviles, realizar pasos adicionales
            if (isMobile) {
                console.log('📱 Realizando pasos adicionales para dispositivos móviles');
                
                // Intentar invalidar cualquier caché del navegador
                try {
                    if ('caches' in window) {
                        caches.keys().then(cacheNames => {
                            cacheNames.forEach(cacheName => {
                                if (cacheName.includes('auth') || cacheName.includes('user')) {
                                    caches.delete(cacheName);
                                }
                            });
                        });
                        console.log('✅ Caché del navegador invalidada');
                    }
                } catch (cacheError) {
                    console.error('Error al invalidar caché del navegador:', cacheError);
                }
            }
            
            console.log('🧹 Estado de autenticación completamente limpiado');
            return true;
        } catch (error) {
            console.error('❌ Error crítico al limpiar estado de autenticación:', error);
            // Intentar los pasos básicos de limpieza en caso de error
            setUser(null);
            sessionStorage.clear();
            localStorage.clear();
            return false;
        }
    };

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
            if (currentUser) {
                try {
                    // Get the ID token with force refresh to ensure it's up to date
                    const token = await currentUser.getIdToken(true);
                    const userData = {
                        uid: currentUser.uid,
                        email: currentUser.email,
                        token,
                        tokenExpiration: new Date().getTime() + TOKEN_EXPIRATION
                    };
                    
                    // Store in sessionStorage (cleared when browser is closed)
                    sessionStorage.setItem('authUser', JSON.stringify(userData));
                    setUser(userData);
                } catch (error) {
                    console.error('Error getting user token:', error);
                    clearAuthState();
                }
            } else {
                clearAuthState();
            }
            setLoading(false);
        });

        // Check for stored user data on mount
        const storedUser = sessionStorage.getItem('authUser');
        if (storedUser) {
            const userData = JSON.parse(storedUser);
            // Check if token is expired
            if (userData.tokenExpiration > new Date().getTime()) {
                setUser(userData);
            } else {
                clearAuthState();
            }
        }

        return () => unsubscribe();
    }, []);

    const login = async (email, password) => {
        try {
            const userData = await loginUser(email, password);
            
            // Guardar datos en sessionStorage inmediatamente
            const authData = {
                ...userData,
                tokenExpiration: new Date().getTime() + TOKEN_EXPIRATION
            };
            
            sessionStorage.setItem('authUser', JSON.stringify(authData));
            setUser(authData);
            
            return userData.uid;
        } catch (error) {
            console.error("Login error:", error);
            clearAuthState();
            throw error;
        }
    };

    const signup = async (email, password) => {
        try {
            const user = await registerUser(email, password);
            return user;
        } catch (error) {
            console.error("Signup error:", error);
            clearAuthState();
            throw error;
        }
    };

    const logout = async () => {
        try {
            await auth.signOut();
            clearAuthState();
        } catch (error) {
            console.error("Logout error:", error);
            throw error;
        }
    };

    // Helper function to get current token
    const getToken = async () => {
        if (!user) return null;
        
        // Check if token is expired
        if (user.tokenExpiration <= new Date().getTime()) {
            try {
                const newToken = await auth.currentUser?.getIdToken(true);
                const userData = {
                    ...user,
                    token: newToken,
                    tokenExpiration: new Date().getTime() + TOKEN_EXPIRATION
                };
                sessionStorage.setItem('authUser', JSON.stringify(userData));
                setUser(userData);
                return newToken;
            } catch (error) {
                console.error('Error refreshing token:', error);
                clearAuthState();
                return null;
            }
        }
        
        return user.token;
    };

    const value = {
        user,
        login,
        signup,
        logout,
        loading,
        getToken,
        clearAuthState // Exportamos la función para poder usarla desde fuera
    };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};