export const containerStyles = {
  width: '100%', 
  position: 'relative',
  backgroundColor: '#F3F5F6',
  py: { xs: 6, md: 8 }
};

export const titleStyles = {
  fontFamily: 'Open Sans, sans-serif',
  fontSize: { xs: '1.75rem', md: '2.25rem' },
  fontWeight: 600,
  marginBottom: { xs: 3, md: 4 },
  color: '#0A3954',
  textAlign: { xs: 'center', md: 'left' }
};

export const slideWrapperStyles = {
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  px: { xs: 0, md: 0 }
};

export const cardContainerStyles = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '16px',
  overflow: 'hidden',
  backgroundColor: '#FFFFFF',
  height: { xs: '200px', md: '150px' },
  p: { xs: 3, md: 4 },
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.08)'
};

export const reviewTextStyles = {
  color: '#082E44',
  fontSize: { xs: '1rem', md: '1.25rem' },
  lineHeight: 1.6,
  mb: 3,
  fontStyle: 'italic',
  display: '-webkit-box',
  WebkitLineClamp: { xs: 6, md: 4 },
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
};

export const authorInfoStyles = {
  mt: 'auto',
  display: 'flex',
  flexDirection: 'column',
  gap: 0.5
};

export const authorNameStyles = {
  color: '#E96D25',
  fontSize: { xs: '1.1rem', md: '1.3rem' },
  fontWeight: 'bold'
};

export const locationStyles = {
  color: '#082E44',
  fontSize: { xs: '0.9rem', md: '1rem' },
  opacity: 0.8
};

export const paginationStyles = {
  position: 'static !important',
  mt: 2,
  mb: 4,
  display: { xs: 'none', md: 'flex' },
  justifyContent: 'center',
  gap: '6px'
};

export const swiperStyles = {
  '--swiper-pagination-color': '#135C86',
  '--swiper-pagination-bullet-size': '8px',
  '--swiper-pagination-bullet-inactive-color': '#D9D9D9',
  '--swiper-pagination-bullet-inactive-opacity': '1',
  '--swiper-pagination-bullet-horizontal-gap': '6px'
}; 