/**
 * Mobile styles
 * Styles specific to mobile devices for the ProductCard component
 */

// Mobile-specific styles for the product card
export const mobileProductCardStyles = {
  width: '100%',
  height: '100%',
  marginBottom: '8px'
};

// Mobile-specific styles for the product image
export const mobileProductImageStyles = {
  height: 'auto',
  width: '100%'
};

// Mobile-specific styles for the content container
export const mobileContentContainerStyles = {
  height: 'auto',
  padding: '12px 14px'
};

// Mobile-specific styles for the product name
export const mobileProductNameStyles = {
  fontSize: '0.875rem',
  fontWeight: 600,
  lineHeight: 1.3,
  minHeight: 'unset',
  height: 'auto',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  marginBottom: '6px'
};

// Mobile-specific styles for the product price
export const mobileProductPriceStyles = {
  fontSize: '1rem',
  marginTop: '4px'
};

// Mobile-specific styles for the product details
export const mobileProductDetailsStyles = {
  padding: '4px 0'
}; 