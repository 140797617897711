export const mobileContainerStyles = {
  width: '100%',
  py: 2,
  px: 1,
  mx: -1,
  bgcolor: '#F3F3F3',
  borderRadius: '4px',
  overflow: 'hidden',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    bgcolor: '#F3F3F3',
    zIndex: -1
  }
};

export const mobileFeaturedImageStyles = {
  width: '100%',
  height: '400px',
  borderRadius: 1,
  overflow: 'hidden',
  boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
};

export const mobileProductGridStyles = {
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: 1.5,
  px: 1,
  mt: 2
}; 