// Styles for WhyChooseSYLLayout component

export const containerStyles = {
  width: '100%',
  py: { xs: 4, md: 6 },
  backgroundColor: 'white',
  position: 'relative',
  overflow: 'hidden', // Ensure the orange divider doesn't cause overflow
  display: 'flex',
  justifyContent: 'center', // Center the content horizontally
};

export const contentWrapperStyles = {
  backgroundColor: '#F5F5F5',
  borderRadius: { xs: 0, md: '12px' },
  overflow: 'hidden',
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
  pb: 4,
  width: { xs: '100%', sm: '90%', md: '80%', lg: '88%' }, // Responsive width
  my: { xs: 2, md: 4 },
  position: 'relative',
  zIndex: 2, // Above the orange divider
  maxWidth: '1600px', // Prevent excessive width on very large screens
};

export const heroImageStyles = {
  width: '100%',
  height: { xs: '200px', sm: '280px', md: '400px' },
  objectFit: 'cover',
  objectPosition: 'center',
  display: 'block',
  // Fallback background in case the image doesn't load
  backgroundColor: '#d5d5d5',
  position: 'relative',
  zIndex: 2, // Above the orange divider
};

export const orangeDividerStyles = {
  position: 'absolute',
  width: '100%',
  height: { xs: '25px', md: '35px' }, // Responsive height
  backgroundColor: theme => theme.palette.primary.main, // Using the primary orange color from theme
  top: '50%',
  left: 0,
  transform: 'translateY(-50%)', // Center vertically
  zIndex: 1, // Behind the content and image
};

export const titleSectionStyles = {
  p: { xs: 3, md: 4 },
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'center', 
  position: 'relative',
  zIndex: 2, // Above the orange divider
};

export const titleStyles = {
  color: '#021B29',
  fontSize: '41.31px',
  fontWeight: 800,
  wordWrap: 'break-word',
  mb: 3,
  lineHeight: 1.2,
};

export const subtitleStyles = {
  fontSize: { xs: '1.1rem', md: '1.25rem' },
  color: '#333', // Dark text for readability
  mb: 3,
  fontWeight: 500,
  lineHeight: 1.6,
  position: 'relative',
  zIndex: 2, // Above the orange divider
};

export const descriptionTextStyles = {
  fontSize: { xs: '1rem', md: '1.1rem' },
  color: '#555',
  mt: 2,
  lineHeight: 1.6,
  position: 'relative',
  zIndex: 2, // Above the orange divider
};

export const listItemStyles = {
  px: 0,
  py: 1,
  alignItems: 'flex-start',
};

export const listItemIconStyles = {
  mr: 2,
  color: '#E96D25', // Specific orange color
  fontSize: { xs: '1.3rem', md: '1.5rem' }
};

export const featureTextStyles = {
  color: '#021B29',
  fontSize: '18px',
  fontWeight: 600,
  wordWrap: 'break-word',
}; 