import React from 'react';
import { Typography, Box, Grid, AccordionSummary, AccordionDetails } from '@mui/material';
import { StyledAccordion, StyledExpandIcon } from './StyledComponents';
import { hoursOfOperation } from '../utils/footerData';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

// Hidden SEO styles
const SeoText = styled(Typography)({
  position: 'absolute',
  width: '1px',
  height: '1px',
  padding: '0',
  margin: '-1px',
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap',
  borderWidth: '0'
});

export const HoursAccordion = () => {
  const { t } = useTranslation();
  
  return (
    <StyledAccordion sx={{ padding: 0, mb: 1 }}>
      <AccordionSummary 
        expandIcon={<StyledExpandIcon />}
        sx={{ 
          padding: '8px 0',
          minHeight: '48px',
          '& .MuiAccordionSummary-content': {
            margin: '8px 0'
          }
        }}
      >
        <Typography 
          sx={{ 
            color: '#E7ECF3', 
            fontSize: 16, 
            fontFamily: 'Open Sans', 
            fontWeight: '700', 
            wordWrap: 'break-word',
            textAlign: 'left'
          }}
        >
          {t('footer.hours.title')}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: '0 16px 16px', textAlign: 'left' }}>
        {/* Hidden SEO title and description */}
        <SeoText variant="h2">{t('footer.hours.seoTitle')}</SeoText>
        <SeoText component="p">{t('footer.hours.seoDescription')}</SeoText>
        
        {hoursOfOperation.map((item, index) => (
          <Box key={index} sx={{ mb: 1 }}>
            <Typography
              sx={{
                fontWeight: 'medium',
                fontSize: 14,
                mb: 0.5
              }}
            >
              {item.day === 'Monday-Friday' 
                ? t('footer.hours.days.mondayFriday')
                : item.day === 'Saturday' 
                  ? t('footer.hours.days.saturday') 
                  : t('footer.hours.days.sunday')}
            </Typography>
            <Typography
              sx={{
                color: '#A7B0C0',
                fontSize: 14,
                fontFamily: 'Open Sans',
                fontWeight: '400',
                lineHeight: '21px',
                wordWrap: 'break-word'
              }}
            >
              {item.hours === 'Closed' ? t('footer.hours.days.sundayHours') : item.hours}
            </Typography>
          </Box>
        ))}
      </AccordionDetails>
    </StyledAccordion>
  );
};

export const HoursTablet = () => {
  const { t } = useTranslation();
  
  return (
    <Grid item xs={12} sm={4}>
      <Typography variant="h5semiBold" color='text.200' gutterBottom>
        {t('footer.hours.title')}
      </Typography>
      
      {/* Hidden SEO title and description */}
      <SeoText variant="h2">{t('footer.hours.seoTitle')}</SeoText>
      <SeoText component="p">{t('footer.hours.seoDescription')}</SeoText>
      
      {hoursOfOperation.map((item, index) => (
        <Box
          key={index}
          mt={2}
          sx={{
            flexDirection: 'column',
            justifyContent: 'center'
          }}
        >
          <Typography variant="h5semiBold">
            {item.day === 'Monday-Friday' 
              ? t('footer.hours.days.mondayFriday')
              : item.day === 'Saturday' 
                ? t('footer.hours.days.saturday') 
                : t('footer.hours.days.sunday')}:
          </Typography>
          <Typography variant="body2">
            {item.hours === 'Closed' ? t('footer.hours.days.sundayHours') : item.hours}
          </Typography>
        </Box>
      ))}
    </Grid>
  );
};

export const HoursDesktop = () => {
  const { t } = useTranslation();
  
  return (
    <Grid item xs={12} sm={6} md={3}>
      <Typography 
        gutterBottom
        sx={{ 
          color: '#E7ECF3', 
          fontSize: 16, 
          fontFamily: 'Open Sans', 
          fontWeight: '700', 
          wordWrap: 'break-word',
          mb: 3
        }}
      >
        {t('footer.hours.title')}
      </Typography>
      
      {/* Hidden SEO title and description */}
      <SeoText variant="h2">{t('footer.hours.seoTitle')}</SeoText>
      <SeoText component="p">{t('footer.hours.seoDescription')}</SeoText>
      
      {hoursOfOperation.map((item, index) => (
        <Box key={index} sx={{ mb: 2 }}>
          <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
            {item.day === 'Monday-Friday' 
              ? t('footer.hours.days.mondayFriday')
              : item.day === 'Saturday' 
                ? t('footer.hours.days.saturday') 
                : t('footer.hours.days.sunday')}
          </Typography>
          <Typography 
            variant="body2"
            sx={{ 
              color: '#A7B0C0', 
              fontSize: 14, 
              fontFamily: 'Open Sans', 
              fontWeight: '400', 
              lineHeight: '21px', 
              wordWrap: 'break-word'
            }}
          >
            {item.hours === 'Closed' ? t('footer.hours.days.sundayHours') : item.hours}
          </Typography>
        </Box>
      ))}
    </Grid>
  );
}; 