// Importar los estilos de diferentes dispositivos
import * as commonStyles from './common';
import * as mobileStyles from './mobile';
import * as tabletStyles from './tablet';
import * as desktopStyles from './desktop';

// Exportar los estilos comunes
export const {
  srOnlyStyles
} = commonStyles;

// Exportar los estilos específicos para poder usarlos directamente
export const {
  mobileContainerStyles,
  mobileTitleStyles,
  mobileSubtitleStyles,
  mobileStepsContainerStyles,
  mobileStepItemStyles,
  mobileStepImageWrapperStyles,
  mobileStepNumberStyles,
  mobileStepDescriptionStyles,
  mobileButtonWrapperStyles,
  mobileButtonStyles
} = mobileStyles;

export const {
  tabletContainerStyles,
  tabletTitleStyles,
  tabletSubtitleStyles,
  tabletStepsContainerStyles,
  tabletStepItemStyles,
  tabletStepImageWrapperStyles,
  tabletStepNumberStyles,
  tabletStepDescriptionStyles,
  tabletButtonWrapperStyles,
  tabletButtonStyles
} = tabletStyles;

export const {
  desktopContainerStyles,
  desktopTitleStyles,
  desktopSubtitleStyles,
  desktopStepsContainerStyles,
  desktopStepItemStyles,
  desktopStepImageWrapperStyles,
  desktopStepNumberStyles,
  desktopStepDescriptionStyles,
  desktopButtonWrapperStyles,
  desktopButtonStyles
} = desktopStyles;

// Exportar las variables con los nombres originales para mantener la compatibilidad
export const containerStyles = {
  ...commonStyles.containerStyles,
  py: { xs: mobileContainerStyles.py, sm: tabletContainerStyles.py, md: desktopContainerStyles.py },
  px: { xs: mobileContainerStyles.px, sm: tabletContainerStyles.px, md: desktopContainerStyles.px }
};

export const contentWrapperStyles = commonStyles.contentWrapperStyles;

export const titleStyles = {
  ...commonStyles.titleStyles,
  fontSize: { xs: mobileTitleStyles.fontSize, sm: tabletTitleStyles.fontSize, md: desktopTitleStyles.fontSize },
  mb: { xs: mobileTitleStyles.mb, sm: tabletTitleStyles.mb, md: desktopTitleStyles.mb }
};

export const subtitleStyles = {
  ...commonStyles.subtitleStyles,
  fontSize: { xs: mobileSubtitleStyles.fontSize, sm: tabletSubtitleStyles.fontSize, md: desktopSubtitleStyles.fontSize },
  mb: { xs: mobileSubtitleStyles.mb, sm: tabletSubtitleStyles.mb, md: desktopSubtitleStyles.mb },
  maxWidth: { xs: mobileSubtitleStyles.maxWidth, sm: tabletSubtitleStyles.maxWidth, md: desktopSubtitleStyles.maxWidth },
  px: { xs: mobileSubtitleStyles.px, sm: tabletSubtitleStyles.px, md: desktopSubtitleStyles.px }
};

export const stepsContainerStyles = {
  ...commonStyles.stepsContainerStyles,
  flexDirection: { xs: mobileStepsContainerStyles.flexDirection, sm: tabletStepsContainerStyles.flexDirection, md: desktopStepsContainerStyles.flexDirection },
  gap: { xs: mobileStepsContainerStyles.gap, sm: tabletStepsContainerStyles.gap, md: desktopStepsContainerStyles.gap },
  mb: { xs: mobileStepsContainerStyles.mb, sm: tabletStepsContainerStyles.mb, md: desktopStepsContainerStyles.mb }
};

export const stepItemStyles = {
  ...commonStyles.stepItemStyles,
  px: { xs: mobileStepItemStyles.px, sm: tabletStepItemStyles.px, md: desktopStepItemStyles.px }
};

export const stepImageWrapperStyles = {
  ...commonStyles.stepImageWrapperStyles,
  width: { xs: mobileStepImageWrapperStyles.width, sm: tabletStepImageWrapperStyles.width, md: desktopStepImageWrapperStyles.width },
  height: { xs: mobileStepImageWrapperStyles.height, sm: tabletStepImageWrapperStyles.height, md: desktopStepImageWrapperStyles.height },
  mb: { xs: mobileStepImageWrapperStyles.mb, sm: tabletStepImageWrapperStyles.mb, md: desktopStepImageWrapperStyles.mb }
};

export const stepImageStyles = commonStyles.stepImageStyles;

export const stepNumberStyles = {
  ...commonStyles.stepNumberStyles,
  fontSize: { xs: mobileStepNumberStyles.fontSize, sm: tabletStepNumberStyles.fontSize, md: desktopStepNumberStyles.fontSize },
  mb: { xs: mobileStepNumberStyles.mb, sm: tabletStepNumberStyles.mb, md: desktopStepNumberStyles.mb }
};

export const stepDescriptionStyles = {
  ...commonStyles.stepDescriptionStyles,
  fontSize: { xs: mobileStepDescriptionStyles.fontSize, sm: tabletStepDescriptionStyles.fontSize, md: desktopStepDescriptionStyles.fontSize },
  maxWidth: { xs: mobileStepDescriptionStyles.maxWidth, sm: tabletStepDescriptionStyles.maxWidth, md: desktopStepDescriptionStyles.maxWidth },
  lineHeight: { xs: mobileStepDescriptionStyles.lineHeight, sm: tabletStepDescriptionStyles.lineHeight, md: desktopStepDescriptionStyles.lineHeight }
};

export const buttonWrapperStyles = {
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  px: { xs: mobileButtonWrapperStyles.px, sm: tabletButtonWrapperStyles.px, md: desktopButtonWrapperStyles.px }
};

export const buttonStyles = {
  ...commonStyles.buttonStyles,
  fontSize: { xs: mobileButtonStyles.fontSize, sm: tabletButtonStyles.fontSize, md: desktopButtonStyles.fontSize },
  py: { xs: mobileButtonStyles.py, sm: tabletButtonStyles.py, md: desktopButtonStyles.py },
  px: { xs: mobileButtonStyles.px, sm: tabletButtonStyles.px, md: desktopButtonStyles.px },
  width: { sm: tabletButtonStyles.width },
  mx: { sm: tabletButtonStyles.mx }
}; 