/**
 * BrandHeader Mobile Styles
 * Estilos específicos para dispositivos móviles
 */

export const mobileContainerStyles = {
  padding: 0,
  marginBottom: 2,
  backgroundColor: 'transparent',
  boxShadow: 'none',
  borderRadius: 0,
};

export const mobileBrandInfoContainerStyles = {
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '0 0px',
};

export const mobileBrandImageStyles = {
  width: '60px',
  height: '60px',
  marginBottom: 1.5,
  marginRight: 0,
  borderRadius: '4px',
};

export const mobileBrandContentStyles = {
  marginLeft: 0,
  width: '100%',
};

export const mobileBrandTitleStyles = {
  fontSize: '1.125rem',
  lineHeight: '1.5',
};

export const mobileBrandDescriptionStyles = {
  fontSize: '0.875rem',
  lineHeight: 1.4,
  display: '-webkit-box',
  WebkitLineClamp: 3,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

export const mobileFiltersContainerStyles = {
  overflowX: 'auto',
  flexWrap: 'nowrap',
  paddingBottom: 0.5,
  msOverflowStyle: 'none', // IE and Edge
  scrollbarWidth: 'none', // Firefox
  '&::-webkit-scrollbar': { // Chrome, Safari, Opera
    display: 'none',
  },
};

export const mobileFilterChipStyles = {
  margin: '0 4px',
  fontSize: '0.75rem',
  height: '28px',
};

export const mobileBackButtonStyles = {
  top: -4,
  left: -4,
};

// Estilos móviles para el modal
export const mobileModalStyles = {
  width: '95%',
  maxWidth: '100%',
  padding: 2.5,
  maxHeight: '95vh'
};

export const mobileModalImageStyles = {
  width: '70px',
  height: '70px',
  marginRight: 2,
  marginBottom: 0,
  flexShrink: 0
};

export const mobileModalTitleStyles = {
  fontSize: '1.2rem'
};

export const mobileModalDescriptionStyles = {
  fontSize: '0.875rem',
  lineHeight: 1.6
};

export const mobileBannerContainerStyles = {
  width: '100vw',
  position: 'relative',
  overflow: 'hidden',
  marginLeft: 'calc(-50vw + 50%)',
  marginRight: 'calc(-50vw + 50%)',
  marginBottom: 2,
};

export const mobileBannerImageStyles = {
  width: '100%',
  display: 'block',
  maxHeight: '250px',
  objectFit: 'contain',
  objectPosition: 'center center',
  backgroundColor: '#f5f5f5',
}; 