/**
 * Mobile styles
 * Styles specific to mobile devices
 */

// Mobile-specific styles for the container
export const mobileContainerStyles = {
  padding: '16px 0',
  width: '100%',
  backgroundColor: '#FFFFFF',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
};

// Mobile-specific styles for the grid container (replacing carousel)
export const mobileCarouselContainerStyles = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: '0 16px',
  boxSizing: 'border-box',
  overflow: 'visible'
};

// Mobile-specific styles for the grid layout
export const mobileGridStyles = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: '16px',
  padding: '16px',
  justifyContent: 'space-between',
  width: '100%'
};

// Mobile-specific styles for brand cards
export const mobileBrandCardStyles = {
  width: 'calc(50% - 8px)',
  height: '120px',
  padding: 0,
  margin: 0,
  flexGrow: 0,
  flexShrink: 0
};

// Mobile-specific styles for brand image container
export const mobileBrandImageContainerStyles = {
  height: '80px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};

// Mobile-specific styles for brand name container
export const mobileBrandNameStyles = {
  padding: '8px',
  height: '40px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};

// Mobile-specific styles for brand typography
export const mobileBrandTypographyStyles = {
  fontSize: '0.75rem',
  textAlign: 'center',
  lineHeight: 1.2
};

// Mobile-specific styles for the title
export const mobileTitleStyles = {
  fontSize: '1.5rem',
  fontWeight: 600,
  textAlign: 'center',
  marginBottom: '16px',
  color: '#0A3954',
  width: '100%',
  padding: '0 16px'
}; 