import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getImageForLanguage, getTranslatedContent } from '../utils/contentUtils';
import {
  tabletContainerStyles,
  tabletLeftContentStyles,
  tabletSubtitleWrapperStyles,
  tabletSubtitleStyles,
  tabletTitleStyles,
  tabletRightContentStyles,
  tabletImageStyles,
  getTabletGap,
  getTabletPaddingX,
  getTabletPaddingY,
  getTabletLeftContentFlex,
  getTabletLeftContentGap,
  getTabletLeftContentPaddingRight,
  getTabletSubtitlePadding,
  getTabletSubtitleFontSize,
  getTabletTitleFontSize,
  getTabletTitleMarginTop,
  getTabletTitlePaddingRight,
  getTabletRightContentFlex,
  getTabletRightContentHeight
} from '../styles/tablet';

/**
 * Componente para mostrar el banner en vista tablet
 * Adaptado específicamente para iPad Mini e iPad Air
 * 
 * @param {Object} props - Propiedades del componente
 * @param {Object} props.banner - Datos del banner a mostrar
 * @param {number} props.index - Índice del banner en el carrusel
 * @param {boolean} props.isIpadMini - Si el dispositivo es iPad Mini
 * @param {boolean} props.isIpadAir - Si el dispositivo es iPad Air
 * @returns {React.ReactElement} Componente TabletView
 */
const TabletView = ({ banner, index, isIpadMini, isIpadAir }) => {
  const { t, i18n } = useTranslation();
  const isMobile = false; // Esta vista nunca es móvil
  const translatedContent = getTranslatedContent(t, banner, index, isMobile);
  
  // Calcular estilos dinámicos basados en el tipo de iPad
  const gap = getTabletGap(isIpadMini, isIpadAir);
  const paddingX = getTabletPaddingX(isIpadMini, isIpadAir);
  const paddingY = getTabletPaddingY(isIpadMini, isIpadAir);
  const leftContentFlex = getTabletLeftContentFlex(isIpadMini, isIpadAir);
  const leftContentGap = getTabletLeftContentGap(isIpadMini, isIpadAir);
  const leftContentPaddingRight = getTabletLeftContentPaddingRight(isIpadAir);
  const subtitlePadding = getTabletSubtitlePadding(isIpadMini, isIpadAir);
  const subtitleFontSize = getTabletSubtitleFontSize(isIpadMini, isIpadAir);
  const titleFontSize = getTabletTitleFontSize(isIpadMini, isIpadAir);
  const titleMarginTop = getTabletTitleMarginTop(isIpadMini, isIpadAir);
  const titlePaddingRight = getTabletTitlePaddingRight(isIpadAir);
  const rightContentFlex = getTabletRightContentFlex(isIpadMini, isIpadAir);
  const rightContentHeight = getTabletRightContentHeight(isIpadMini, isIpadAir);
  
  return (
    <Box sx={{
      ...tabletContainerStyles,
      gap,
      px: paddingX,
      py: paddingY
    }}>
      {/* Left Content for Tablet */}
      <Box sx={{
        ...tabletLeftContentStyles,
        flex: leftContentFlex,
        gap: leftContentGap,
        pr: leftContentPaddingRight
      }}>
        <Box sx={{
          ...tabletSubtitleWrapperStyles,
          padding: subtitlePadding,
        }}>
          <Typography sx={{
            ...tabletSubtitleStyles,
            fontSize: subtitleFontSize
          }}>
            {translatedContent.subtitle}
          </Typography>
        </Box>
        
        <Typography sx={{
          ...tabletTitleStyles,
          fontSize: titleFontSize,
          marginTop: titleMarginTop,
          pr: titlePaddingRight
        }}>
          {translatedContent.title}
        </Typography>
      </Box>

      {/* Right Content for Tablet */}
      <Box sx={{
        ...tabletRightContentStyles,
        flex: rightContentFlex,
        height: rightContentHeight,
        maxHeight: rightContentHeight
      }}>
        <Box
          component="img"
          src={getImageForLanguage(banner, i18n.language)}
          alt={translatedContent.imageAlt}
          sx={tabletImageStyles}
        />
      </Box>
    </Box>
  );
};

TabletView.propTypes = {
  banner: PropTypes.shape({
    id: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    imageUrlEn: PropTypes.string,
    imageUrlEs: PropTypes.string
  }).isRequired,
  index: PropTypes.number.isRequired,
  isIpadMini: PropTypes.bool.isRequired,
  isIpadAir: PropTypes.bool.isRequired
};

export default TabletView; 