/**
 * Mobile styles
 * Styles specific to mobile devices
 */

// Mobile-specific styles for the container
export const mobileContainerStyles = {
  padding: '16px 0',
  width: '100%',
  backgroundColor: '#FFFFFF',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
};

// Mobile-specific styles for the title
export const mobileTitleStyles = {
  fontSize: '1.5rem',
  fontWeight: 600,
  textAlign: 'center',
  marginBottom: '16px',
  color: '#0A3954',
  width: '100%',
  padding: '0 16px'
};

// Mobile-specific styles for the grid layout
export const mobileGridStyles = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: '16px',
  padding: '16px',
  justifyContent: 'flex-start',
  width: '100%'
};

// Mobile-specific styles for brand cards
export const mobileBrandCardStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: '#FFFFFF',
  borderRadius: '8px',
  border: '1px solid rgba(0, 0, 0, 0.08)',
  width: 'calc(50% - 8px)',
  height: '120px',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
  transition: 'transform 0.2s, box-shadow 0.2s',
  cursor: 'pointer',
  overflow: 'hidden',
  padding: 0,
  margin: 0,
  flexGrow: 0,
  flexShrink: 0,
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
  }
};

// Mobile-specific styles for brand image container
export const mobileBrandImageContainerStyles = {
  width: '100%',
  height: '80px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '12px',
  backgroundColor: '#FFFFFF'
};

// Mobile-specific styles for brand name container
export const mobileBrandNameStyles = {
  width: '100%',
  textAlign: 'center',
  backgroundColor: '#0A3954',
  padding: '8px',
  height: '40px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};

// Mobile-specific styles for brand typography
export const mobileBrandTypographyStyles = {
  fontSize: '0.75rem',
  fontWeight: 500,
  lineHeight: 1.2,
  color: '#FFFFFF',
  textAlign: 'center'
}; 