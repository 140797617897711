import React from 'react';
import { useTranslation } from 'react-i18next';
import { 
  Box, 
  IconButton, 
  Menu, 
  MenuItem, 
  Tooltip, 
  Typography,
  Chip,
  Avatar
} from '@mui/material';
import TranslateIcon from '@mui/icons-material/Translate';
import { styled, alpha } from '@mui/material/styles';

// Componente estilizado para el chip de idioma
const LanguageChip = styled(Chip)(({ theme }) => ({
  backgroundColor: 'transparent',
  border: `1px solid ${alpha(theme.palette.primary.main, 0.7)}`,
  color: 'white',
  height: 32,
  '& .MuiChip-label': {
    paddingLeft: 8,
    paddingRight: 8,
    fontWeight: 600,
    fontSize: '0.85rem',
  },
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.2),
    borderColor: theme.palette.primary.main,
  },
  cursor: 'pointer',
  transition: 'all 0.2s ease-in-out',
}));

// Componente estilizado para el Avatar dentro del chip
const LanguageAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: 'white',
  width: 26,
  height: 26,
  fontSize: '0.8rem',
  fontWeight: 'bold',
}));

// Estilo para los items del menú
const StyledMenuItem = styled(MenuItem)(({ theme, selected }) => ({
  minWidth: 140,
  padding: theme.spacing(1, 2),
  '&.Mui-selected': {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
  },
  '&.Mui-selected:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.2),
  },
}));

const LanguageSwitcher = () => {
  const { i18n, t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const languages = [
    { code: 'en', label: 'English', shortLabel: 'EN' },
    { code: 'es', label: 'Español', shortLabel: 'ES' }
  ];

  // Encontrar el idioma actual para mostrar la etiqueta correcta
  const currentLanguage = languages.find(lang => lang.code === i18n.language) || languages[0];

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    handleClose();
  };

  return (
    <Box>
      <Tooltip title={t('a11y.languageSwitcher')}>
        <LanguageChip
          avatar={<LanguageAvatar>{currentLanguage.shortLabel}</LanguageAvatar>}
          label={currentLanguage.shortLabel}
          onClick={handleOpen}
          aria-controls={open ? 'language-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          aria-label={t('a11y.languageSwitcher')}
        />
      </Tooltip>
      <Menu
        id="language-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'language-button',
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {languages.map((language) => (
          <StyledMenuItem 
            key={language.code}
            onClick={() => changeLanguage(language.code)}
            selected={i18n.language === language.code}
          >
            <LanguageAvatar sx={{ marginRight: 1.5 }}>
              {language.shortLabel}
            </LanguageAvatar>
            <Typography variant="body2">
              {language.label}
            </Typography>
          </StyledMenuItem>
        ))}
      </Menu>
    </Box>
  );
};

// OPCIÓN ALTERNATIVA CON BANDERAS:
/*
const LanguageSwitcher = () => {
  const { i18n, t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const languages = [
    { 
      code: 'en', 
      label: 'English', 
      shortLabel: 'EN',
      flag: '🇺🇸' // Alternativa: importar imagen de bandera de EEUU
    },
    { 
      code: 'es', 
      label: 'Español', 
      shortLabel: 'ES',
      flag: '🇪🇸' // Alternativa: importar imagen de bandera de España
    }
  ];

  const currentLanguage = languages.find(lang => lang.code === i18n.language) || languages[0];

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    handleClose();
  };

  return (
    <Box>
      <Tooltip title={t('a11y.languageSwitcher')}>
        <LanguageChip
          avatar={
            <LanguageAvatar sx={{ fontSize: '1rem' }}>
              {currentLanguage.flag}
            </LanguageAvatar>
          }
          label={currentLanguage.shortLabel}
          onClick={handleOpen}
          aria-controls={open ? 'language-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          aria-label={t('a11y.languageSwitcher')}
        />
      </Tooltip>
      <Menu
        id="language-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'language-button',
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {languages.map((language) => (
          <StyledMenuItem 
            key={language.code}
            onClick={() => changeLanguage(language.code)}
            selected={i18n.language === language.code}
          >
            <LanguageAvatar sx={{ marginRight: 1.5, fontSize: '1rem' }}>
              {language.flag}
            </LanguageAvatar>
            <Typography variant="body2">
              {language.label}
            </Typography>
          </StyledMenuItem>
        ))}
      </Menu>
    </Box>
  );
};
*/

export default LanguageSwitcher; 