import React from 'react';
import { Typography, Box, Grid, AccordionSummary, AccordionDetails } from '@mui/material';
import { StyledAccordion, StyledExpandIcon } from './StyledComponents';
import { contactInfo } from '../utils/footerData';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

// Hidden SEO styles
const SeoText = styled(Typography)({
  position: 'absolute',
  width: '1px',
  height: '1px',
  padding: '0',
  margin: '-1px',
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap',
  borderWidth: '0'
});

export const ContactAccordion = () => {
  const { t } = useTranslation();
  
  return (
    <StyledAccordion sx={{ padding: 0, mb: 1 }}>
      <AccordionSummary 
        expandIcon={<StyledExpandIcon />}
        sx={{ 
          padding: '8px 0',
          minHeight: '48px',
          '& .MuiAccordionSummary-content': {
            margin: '8px 0'
          }
        }}
      >
        <Typography 
          sx={{ 
            color: '#E7ECF3', 
            fontSize: 16, 
            fontFamily: 'Open Sans', 
            fontWeight: '700', 
            wordWrap: 'break-word',
            textAlign: 'left'
          }}
        >
          {t('footer.contact.contactUs')}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: '0 16px 16px', textAlign: 'left' }}>
        {/* Hidden SEO title and description */}
        <SeoText variant="h2">{t('footer.contact.seoTitle')}</SeoText>
        <SeoText component="p">{t('footer.contact.seoDescription')}</SeoText>
        
        <Box sx={{ mb: 2 }}>
          <Typography 
            sx={{ 
              fontWeight: 'medium',
              fontSize: 14,
              mb: 0.5
            }}
          >
            {t('footer.contact.email')}
          </Typography>
          <Typography 
            sx={{ 
              color: '#A7B0C0', 
              fontSize: 14, 
              fontFamily: 'Open Sans', 
              fontWeight: '400', 
              lineHeight: '21px', 
              wordWrap: 'break-word'
            }}
          >
            {contactInfo.email}
          </Typography>
        </Box>
        
        <Box sx={{ mb: 2 }}>
          <Typography 
            sx={{ 
              fontWeight: 'medium',
              fontSize: 14,
              mb: 0.5
            }}
          >
            {t('footer.contact.phone')}
          </Typography>
          <Typography 
            sx={{ 
              color: '#A7B0C0', 
              fontSize: 14, 
              fontFamily: 'Open Sans', 
              fontWeight: '400', 
              lineHeight: '21px', 
              wordWrap: 'break-word'
            }}
          >
            {contactInfo.phone}
          </Typography>
        </Box>
        
        {/* Hidden SEO for locations */}
        <SeoText variant="h3">{t('footer.contact.seoLocations.title')}</SeoText>
        <SeoText component="p">{t('footer.contact.seoLocations.description')}</SeoText>
        
        <Typography 
          sx={{ 
            fontWeight: 'medium',
            fontSize: 14,
            mb: 1
          }}
        >
          {t('footer.contact.locations')}
        </Typography>
        
        {contactInfo.locations.map((location, index) => (
          <Box key={index} sx={{ mb: 2 }}>
            <Typography 
              sx={{ 
                fontWeight: 'medium',
                fontSize: 14,
                mb: 0.5
              }}
            >
              {location.name === 'Tampa' 
                ? `${t('footer.contact.location.tampa')} ${t('footer.contact.location.name')}:` 
                : `${t('footer.contact.location.orlando')} ${t('footer.contact.location.name')}:`}
            </Typography>
            <Typography 
              sx={{ 
                color: '#A7B0C0', 
                fontSize: 14, 
                fontFamily: 'Open Sans', 
                fontWeight: '400', 
                lineHeight: '21px', 
                wordWrap: 'break-word'
              }}
            >
              {location.address}
            </Typography>
          </Box>
        ))}
      </AccordionDetails>
    </StyledAccordion>
  );
};

export const ContactTablet = () => {
  const { t } = useTranslation();
  
  return (
    <Grid item xs={12} sm={4}>
      <Typography variant="h5semiBold" color='text.200' gutterBottom>
        {t('footer.contact.contactUs')}
      </Typography>
      
      {/* Hidden SEO title and description */}
      <SeoText variant="h2">{t('footer.contact.seoTitle')}</SeoText>
      <SeoText component="p">{t('footer.contact.seoDescription')}</SeoText>
      
      <Box
        mt={2}
        sx={{
          flexDirection: 'column',
          justifyContent: 'center'
        }}
      >
        <Typography variant="h5semiBold">{t('footer.contact.email')}:</Typography>
        <Typography variant="body2">{contactInfo.email}</Typography>
      </Box>
      <Box
        mt={2}
        sx={{
          flexDirection: 'column',
          justifyContent: 'center'
        }}
      >
        <Typography variant="h5semiBold">{t('footer.contact.phone')}:</Typography>
        <Typography variant="body2">{contactInfo.phone}</Typography>
      </Box>
      
      {/* Hidden SEO for locations */}
      <SeoText variant="h3">{t('footer.contact.seoLocations.title')}</SeoText>
      <SeoText component="p">{t('footer.contact.seoLocations.description')}</SeoText>
      
      {contactInfo.locations.map((location, index) => (
        <Box
          key={index}
          mt={2}
          sx={{
            flexDirection: 'column',
            justifyContent: 'center'
          }}
        >
          <Typography variant="h5semiBold">
            {location.name === 'Tampa' 
              ? `${t('footer.contact.location.tampa')} ${t('footer.contact.location.name')}:` 
              : `${t('footer.contact.location.orlando')} ${t('footer.contact.location.name')}:`}
          </Typography>
          <Typography variant="body2">{location.address}</Typography>
        </Box>
      ))}
    </Grid>
  );
};

export const ContactDesktop = () => {
  const { t } = useTranslation();
  
  return (
    <Grid item xs={12} sm={6} md={4}>
      <Typography 
        gutterBottom
        sx={{ 
          color: '#E7ECF3', 
          fontSize: 16, 
          fontFamily: 'Open Sans', 
          fontWeight: '700', 
          wordWrap: 'break-word',
          mb: 3
        }}
      >
        {t('footer.contact.contactUs')}
      </Typography>
      
      {/* Hidden SEO title and description */}
      <SeoText variant="h2">{t('footer.contact.seoTitle')}</SeoText>
      <SeoText component="p">{t('footer.contact.seoDescription')}</SeoText>
      
      <Box sx={{ mb: 2 }}>
        <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
          {t('footer.contact.email')}
        </Typography>
        <Typography 
          variant="body2"
          sx={{ 
            color: '#A7B0C0', 
            fontSize: 14, 
            fontFamily: 'Open Sans', 
            fontWeight: '400', 
            lineHeight: '21px', 
            wordWrap: 'break-word'
          }}
        >
          {contactInfo.email}
        </Typography>
      </Box>
      
      <Box sx={{ mb: 2 }}>
        <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
          {t('footer.contact.phone')}
        </Typography>
        <Typography 
          variant="body2"
          sx={{ 
            color: '#A7B0C0', 
            fontSize: 14, 
            fontFamily: 'Open Sans', 
            fontWeight: '400', 
            lineHeight: '21px', 
            wordWrap: 'break-word'
          }}
        >
          {contactInfo.phone}
        </Typography>
      </Box>
      
      {/* Hidden SEO for locations */}
      <SeoText variant="h3">{t('footer.contact.seoLocations.title')}</SeoText>
      <SeoText component="p">{t('footer.contact.seoLocations.description')}</SeoText>
      
      {contactInfo.locations.map((location, index) => (
        <Box key={index} sx={{ mb: 2 }}>
          <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
            {location.name === 'Tampa' 
              ? `${t('footer.contact.location.tampa')} ${t('footer.contact.location.name')}:` 
              : `${t('footer.contact.location.orlando')} ${t('footer.contact.location.name')}:`}
          </Typography>
          <Typography 
            variant="body2"
            sx={{ 
              color: '#A7B0C0', 
              fontSize: 14, 
              fontFamily: 'Open Sans', 
              fontWeight: '400', 
              lineHeight: '21px', 
              wordWrap: 'break-word'
            }}
          >
            {location.address}
          </Typography>
        </Box>
      ))}
    </Grid>
  );
}; 