/**
 * Common styles
 * Shared styles across all device types
 */

// Common styles for the root container
export const commonContainerStyles = {
  width: '100%'
};

// Common styles for the carousel container
export const commonCarouselContainerStyles = {
  width: '100%',
  boxSizing: 'border-box'
};

// Common styles for the brand cards
export const commonBrandCardStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: '#FFFFFF',
  borderRadius: '8px',
  border: '1px solid rgba(0, 0, 0, 0.08)',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
  overflow: 'hidden',
  transition: 'transform 0.2s, box-shadow 0.2s',
  cursor: 'pointer',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
  }
};

// Common styles for the brand image container
export const commonBrandImageContainerStyles = {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '12px',
  backgroundColor: '#FFFFFF'
};

// Common styles for the brand image
export const commonBrandImageStyles = {
  maxWidth: '100%',
  maxHeight: '100%',
  objectFit: 'contain'
};

// Common styles for the brand name container
export const commonBrandNameStyles = {
  width: '100%',
  textAlign: 'center',
  backgroundColor: '#0A3954',
  padding: '8px'
};

// Common styles for the brand name typography
export const commonBrandTypographyStyles = {
  fontWeight: 500,
  lineHeight: 1.2,
  color: '#FFFFFF'
};

// Common styles for the title
export const commonTitleStyles = {
  color: '#0A3954',
  fontWeight: 600,
  textAlign: 'center'
};

// Common styles for the hidden SEO description
export const commonSeoDescriptionStyles = {
  position: 'absolute',
  width: '1px',
  height: '1px',
  padding: 0,
  margin: '-1px',
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap',
  border: 0
}; 