import React, { useState, useEffect, useRef } from 'react';
import { Typography, CircularProgress, InputAdornment, IconButton } from '@mui/material';
import { StyledTextField, ActionButton, StyledTypography } from '../../styles';
import LoginUserIllustration from '../../../../../../assets/login_registered_illustration.webp';
import { useAuth } from '../../../../../../shared/context/AuthProvider';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import {
  FormContainer,
  HeaderContainer,
  IllustrationContainer,
  IllustrationImage,
  ContentBox
} from './styles';

const RegisteredUserContent = ({ email, onClose, onLoginSuccess }) => {
  const { t } = useTranslation();
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { login } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const passwordInputRef = useRef(null);
  
  // Enfocar el campo de contraseña al montar el componente
  useEffect(() => {
    if (passwordInputRef.current) {
      passwordInputRef.current.focus();
    }
  }, []);

  // Log when component renders
  console.log('🔐 RegisteredUserContent rendered with email:', email);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!password) {
      setError(t('auth.login.errors.passwordRequired'));
      return;
    }
    
    // Log form submission
    console.log('📝 Form submitted with email:', email, 'and password length:', password.length);
    
    setLoading(true);
    setError(null);

    try {
      // Attempt Firebase authentication
      const uid = await login(email, password);
      console.log('✅ Firebase login successful, uid:', uid);
      
      // Get the stored auth data to verify everything is set
      const authUser = sessionStorage.getItem('authUser');
      if (!authUser) {
        throw new Error('Authentication data not found after login');
      }
      
      // Log successful authentication
      console.log('🔑 User authenticated successfully:', { email, uid });
      
      // Llamar al callback de éxito si existe
      if (onLoginSuccess) {
        onLoginSuccess();
      }
      
      // Cerrar el modal
      if (onClose) {
        onClose();
      }
      
    } catch (error) {
      console.error('❌ Login error:', error);
      
      // Handle specific Firebase auth errors
      switch (error.code) {
        case 'auth/invalid-credential':
          setError(t('auth.login.errors.invalidCredentials'));
          break;
        case 'auth/user-disabled':
          setError(t('auth.login.errors.accountDisabled'));
          break;
        case 'auth/too-many-requests':
          setError(t('auth.login.errors.tooManyAttempts'));
          break;
        default:
          setError(t('auth.login.errors.default'));
      }
    } finally {
      setLoading(false);
    }
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <FormContainer 
      component="form"
      onSubmit={handleSubmit}
      isMobile={isMobile}
    >
      <HeaderContainer>
        <IllustrationContainer>
          <IllustrationImage
            src={LoginUserIllustration}
            alt={t('auth.login.illustrationAlt')}
            style={{ maxWidth: '80%', maxHeight: '80%' }}
          />
        </IllustrationContainer>

        <ContentBox>
          <Typography variant="h6" component="h6" sx={StyledTypography.title}>
            <span className="highlight">{t('auth.login.title.highlight')}</span> {t('auth.login.title.main')}
          </Typography>

          <Typography variant="subtitle1" sx={StyledTypography.subtitle}>
            {t('auth.login.subtitle')}
          </Typography>
        </ContentBox>
      </HeaderContainer>

      <StyledTextField
        fullWidth
        type="email"
        placeholder={t('auth.login.emailPlaceholder')}
        value={email}
        disabled
      />

      <StyledTextField
        fullWidth
        type={showPassword ? 'text' : 'password'}
        placeholder={t('auth.login.passwordPlaceholder')}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        error={!!error}
        helperText={error}
        inputRef={passwordInputRef}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label={t('auth.login.togglePasswordVisibility')}
                onClick={handleTogglePassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <ActionButton
        fullWidth
        type="submit"
        variant="contained"
        disabled={loading}
      >
        {loading ? <CircularProgress size={24} color="inherit" /> : t('auth.login.loginButton')}
      </ActionButton>

      <Typography 
        variant="body2" 
        sx={StyledTypography.link}
        onClick={() => {/* handle forgot password */}}
      >
        {t('auth.login.forgotPassword')}
      </Typography>
    </FormContainer>
  );
};

export default RegisteredUserContent; 