import React from 'react';
import { Box, Container, Typography, Grid, Paper, Button } from '@mui/material';
import SEO from '../../../shared/components/SEO';
import { generateLocalBusinessSchema } from '../../../shared/utils/seo/schemaGenerators';
import DashboardSection from '../../dashboard/components/DashboardSection';
import HeroBanner from '../../dashboard/components/HeroBanner';
import CategoriesCarousel from '../../dashboard/components/CategoriesCarousel';
import BrandsCarousel from '../../dashboard/components/BrandsCarousel';
import WhyChooseSYLLayout from '../../dashboard/components/WhyChooseSYLLayout';
import ReviewsLayout from '../../dashboard/components/ReviewsLayout';

// Schema específico para la ubicación de Tampa
const tampaLocationSchema = generateLocalBusinessSchema({
  name: 'SYL Roofing Supply - Tampa',
  url: 'https://sylroofingsupply.com/locations/tampa',
  areaServed: [
    {
      '@type': 'City',
      'name': 'Tampa',
      'containedInPlace': {
        '@type': 'State',
        'name': 'Florida'
      }
    }
  ],
  address: {
    '@type': 'PostalAddress',
    'streetAddress': '2005 N 43 St',
    'addressLocality': 'Tampa',
    'addressRegion': 'FL',
    'postalCode': '33605',
    'addressCountry': 'US'
  },
  geo: {
    '@type': 'GeoCoordinates',
    'latitude': 27.9959,
    'longitude': -82.4138
  }
});

const TampaLocationPage = () => {
  return (
    <>
      <SEO 
        // Título y metadatos en español
        titleEs="Materiales para Techos en Tampa, FL" 
        descriptionEs="SYL Roofing Supply en Tampa: Amplio inventario de materiales para techos y construcción, con entrega al día siguiente y precios competitivos. Visítenos en 2005 N 43 St, Tampa FL 33605."
        keywordsEs="distribuidor techos tampa, materiales para techos florida, envío rápido, atención en español, 33605, contratistas, mejores precios"
        
        // Título y metadatos en inglés
        titleEn="Roofing Materials in Tampa, FL"
        descriptionEn="SYL Roofing Supply in Tampa: Wide inventory of roofing and building materials, with next-day delivery and competitive prices. Visit us at 2005 N 43 St, Tampa FL 33605."
        keywordsEn="tampa roofing supplies, roofing materials florida, wholesale roofing tampa fl, next-day delivery, contractors, 33605, competitive pricing"
        
        // Valores predeterminados (se usarán como fallback)
        canonicalUrl="/locations/tampa"
        location="Tampa, FL"
        schema={tampaLocationSchema}
      />

      <DashboardSection noPadding fullWidth>
        <HeroBanner banners={[
          {
            subtitle: 'Su distribuidor de confianza para materiales de techos en Tampa',
            title: 'Materiales de alta calidad con entrega en Tampa',
            imageUrl: '/images/hero-banner/hero-banner-tampa.webp'
          }
        ]} />
      </DashboardSection>

      <DashboardSection background="#F7F7F7">
        <Container maxWidth="lg">
          <Typography 
            variant="h1" 
            component="h1" 
            sx={{ 
              fontWeight: 800, 
              fontSize: {xs: '32px', md: '40px'},
              color: '#0A3954',
              textAlign: 'center',
              mb: 2
            }}
          >
            Distribuidores de Materiales para Techos en Tampa, FL
          </Typography>
          
          <Typography 
            variant="h2" 
            component="h2" 
            sx={{ 
              fontWeight: 600, 
              fontSize: {xs: '22px', md: '28px'},
              color: '#0A3954',
              textAlign: 'center',
              mb: 4
            }}
          >
            Su proveedor local de soluciones para techos
          </Typography>

          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Typography variant="body1" paragraph>
                <strong>SYL Roofing Supply</strong> en Tampa le ofrece una amplia selección de materiales para techos de las mejores marcas del mercado. Nuestra sucursal en Tampa está ubicada estratégicamente para servir a contratistas y constructores en toda el área de la Bahía de Tampa.
              </Typography>
              
              <Typography variant="body1" paragraph>
                Nos destacamos por nuestra <strong>atención bilingüe</strong> y nuestro compromiso de ofrecer productos de alta calidad con <strong>entrega al día siguiente</strong>. Entendemos las necesidades específicas de los contratistas que trabajan en el clima de Florida.
              </Typography>
              
              <Typography variant="body1" paragraph>
                Visite nuestra tienda en <strong>2005 N 43 St Tampa FL 33605</strong> o llámenos al <strong>407-534-2698</strong> para una cotización personalizada.
              </Typography>
              
              <Button 
                variant="contained" 
                color="primary" 
                size="large"
                sx={{ mt: 2 }}
              >
                Solicitar Cotización
              </Button>
            </Grid>
            
            <Grid item xs={12} md={6}>
              <Paper elevation={3} sx={{ p: 3, borderRadius: 2 }}>
                <Typography variant="h5" sx={{ mb: 2, fontWeight: 700 }}>
                  Información de Contacto
                </Typography>
                
                <Typography variant="body1" sx={{ mb: 1 }}>
                  <strong>Dirección:</strong> 2005 N 43 St Tampa FL 33605
                </Typography>
                
                <Typography variant="body1" sx={{ mb: 1 }}>
                  <strong>Teléfono:</strong> 407-534-2698
                </Typography>
                
                <Typography variant="body1" sx={{ mb: 1 }}>
                  <strong>Email:</strong> quote@sylroofingsupply.com
                </Typography>
                
                <Typography variant="body1" sx={{ mb: 1 }}>
                  <strong>Horario:</strong>
                </Typography>
                
                <Typography variant="body2" sx={{ mb: 0.5 }}>
                  Lunes a Viernes: 7:00 am - 6:00 pm
                </Typography>
                
                <Typography variant="body2" sx={{ mb: 0.5 }}>
                  Sábado: 7:00 am - 3:00 pm
                </Typography>
                
                <Typography variant="body2">
                  Domingo: Cerrado
                </Typography>
                
                <Box
                  component="iframe"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3523.727655386992!2d-82.416!3d27.996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88c2c7b0228c17f3%3A0x1d5b7b9e46348e2!2s2005%20N%2043rd%20St%2C%20Tampa%2C%20FL%2033605!5e0!3m2!1sen!2sus!4v1651234567890!5m2!1sen!2sus"
                  width="100%"
                  height="250"
                  style={{ border: 0, borderRadius: '8px', marginTop: '16px' }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  title="Ubicación de SYL Roofing Supply en Tampa"
                />
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </DashboardSection>

      <DashboardSection>
        <CategoriesCarousel />
      </DashboardSection>

      <DashboardSection>
        <BrandsCarousel />
      </DashboardSection>

      <DashboardSection noPadding fullWidth>
        <ReviewsLayout />
      </DashboardSection>

      <DashboardSection noPadding fullWidth>
        <WhyChooseSYLLayout />
      </DashboardSection>

      <DashboardSection background="#F7F7F7">
        <Container maxWidth="lg">
          <Typography 
            variant="h3" 
            component="h2" 
            sx={{ 
              fontWeight: 700, 
              fontSize: {xs: '24px', md: '32px'},
              color: '#0A3954',
              mb: 3
            }}
          >
            Productos populares en Tampa
          </Typography>
          
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Paper elevation={2} sx={{ p: 2, height: '100%' }}>
                <Typography variant="h6" sx={{ fontWeight: 600, mb: 1 }}>
                  Tejas Arquitectónicas
                </Typography>
                <Typography variant="body2">
                  Variedad de colores y estilos resistentes al clima de Florida. Ideales para zonas residenciales de Tampa.
                </Typography>
              </Paper>
            </Grid>
            
            <Grid item xs={12} md={4}>
              <Paper elevation={2} sx={{ p: 2, height: '100%' }}>
                <Typography variant="h6" sx={{ fontWeight: 600, mb: 1 }}>
                  Membranas Impermeabilizantes
                </Typography>
                <Typography variant="body2">
                  Protección adicional contra las lluvias torrenciales comunes en Tampa. Garantizan un sellado perfecto.
                </Typography>
              </Paper>
            </Grid>
            
            <Grid item xs={12} md={4}>
              <Paper elevation={2} sx={{ p: 2, height: '100%' }}>
                <Typography variant="h6" sx={{ fontWeight: 600, mb: 1 }}>
                  Sistemas de Ventilación
                </Typography>
                <Typography variant="body2">
                  Esenciales para mantener la eficiencia energética durante los cálidos veranos de Tampa.
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </DashboardSection>
    </>
  );
};

export default TampaLocationPage; 