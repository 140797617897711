// Estilos específicos para desktop
export const desktopContainerStyles = {
  mt: '50px',
  borderRadius: '24px'
};

export const desktopSlideWrapperStyles = {
  maxWidth: '1200px',
  padding: '34px'
};

export const desktopSwiperStyles = {
  paddingBottom: '30px'
};

// Estilos del OfferCard para desktop
export const desktopCardContainerStyles = {
  flexDirection: 'row',
  borderRadius: '16px',
  height: '400px'
};

export const desktopLeftPanelStyles = {
  flex: '0 0 55%',
  justifyContent: 'center',
  gap: '24px',
  p: '32px 48px',
  order: 1
};

export const desktopTitleOutlineStyles = {
  width: '434.79px',
  height: '58.44px',
  fontSize: '60.40px',
  lineHeight: '55.33px',
  mb: '16px'
};

export const desktopSubtitleStyles = {
  fontSize: '40px',
  lineHeight: '91.6%',
  height: '50.3px',
  mb: '32px'
};

export const desktopButtonWrapperStyles = {
  width: '50%',
  borderRadius: '31.04px',
  height: '62.1px',
  padding: '12.6px 16.1px',
  gap: '11.5px',
  fontSize: '18.39px',
  justifyContent: 'flex-start'
};

export const desktopIconWrapperStyles = {
  width: '44.9px',
  borderRadius: '22.79px',
  height: '44.9px',
  padding: '6.3px'
};

export const desktopIconStyles = {
  width: '24.5px',
  height: '24.5px'
};

export const desktopButtonTextStyles = {
  width: '126.5px',
  height: '25.3px',
  fontSize: '18.39px'
};

export const desktopRightPanelStyles = {
  flex: '0 0 45%',
  height: '400px',
  p: '30px',
  pr: '180px',
  order: 2
};

export const desktopImageStyles = {
  maxWidth: '90%',
  maxHeight: '90%',
  margin: 'auto',
  mr: '240px',
  p: 0
}; 