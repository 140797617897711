/**
 * MobileCarousel Component
 * Mobile-specific implementation of the BrandsCarousel with a 2-column grid layout
 * when useAlternativeLayout is true, otherwise uses a vertical layout
 */

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Box, Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import BrandCard from './BrandCard';

// Import styles directly from their source files
import { commonContainerStyles, commonSeoDescriptionStyles, commonTitleStyles } from '../styles/common';
import { 
  mobileContainerStyles, 
  mobileCarouselContainerStyles,
  mobileGridStyles,
  mobileTitleStyles
} from '../styles/mobile';

// Define original vertical layout styles
const mobileVerticalGridStyles = {
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  padding: '16px',
  alignItems: 'center',
  width: '100%'
};

const mobileVerticalBrandCardStyles = {
  width: '140px',
  height: '120px',
  padding: 0,
  marginBottom: '8px'
};

const MobileCarousel = ({ 
  brands, 
  showTitle = true, 
  useAlternativeLayout = true,
  isInBrandProductsPage = false,
  currentBrandId = null
}) => {
  const { t } = useTranslation();
  
  // Determine which grid style to use based on useAlternativeLayout prop
  const gridStyles = useAlternativeLayout ? mobileGridStyles : mobileVerticalGridStyles;
  
  return (
    <Box sx={{ ...commonContainerStyles, ...mobileContainerStyles }}>
      <Container maxWidth="lg" disableGutters>
        {showTitle && (
          <Typography 
            variant="h2" 
            component="h2" 
            sx={{ ...commonTitleStyles, ...mobileTitleStyles }}
          >
            {t('dashboard.brandsCarousel.title')}
          </Typography>
        )}
        
        {/* Hidden SEO description */}
        <Typography 
          component="p" 
          sx={commonSeoDescriptionStyles}
        >
          {t('dashboard.brandsCarousel.description')}
        </Typography>
        
        <Box sx={mobileCarouselContainerStyles}>
          <Box sx={gridStyles}>
            {brands.map((brand) => (
              <BrandCard 
                key={brand.id} 
                brand={brand} 
                deviceType="mobile"
                sx={!useAlternativeLayout ? mobileVerticalBrandCardStyles : {}}
                isCurrentBrand={currentBrandId === brand.id}
              />
            ))}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

MobileCarousel.propTypes = {
  brands: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      key: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired
    })
  ).isRequired,
  showTitle: PropTypes.bool,
  useAlternativeLayout: PropTypes.bool,
  isInBrandProductsPage: PropTypes.bool,
  currentBrandId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

MobileCarousel.defaultProps = {
  showTitle: true,
  useAlternativeLayout: true,
  isInBrandProductsPage: false,
  currentBrandId: null
};

export default memo(MobileCarousel);