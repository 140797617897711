/**
 * BrandHeader Tablet Styles
 * Estilos específicos para tablets
 */

export const tabletContainerStyles = {
  padding: 2,
};

export const tabletBrandImageStyles = {
  width: '80px',
  height: '80px',
  borderRadius: '4px',
};

export const tabletBrandTitleStyles = {
  fontSize: '1.25rem',
};

export const tabletBrandDescriptionStyles = {
  fontSize: '0.9rem',
  lineHeight: 1.5,
  maxWidth: '90%',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

export const tabletFiltersContainerStyles = {
  gap: 1.5,
  flexWrap: 'wrap',
  padding: '12px 0',
};

export const tabletFilterChipStyles = {
  fontSize: '0.85rem',
  height: '32px',
};

// Estilos específicos para diferentes modelos de tablet
export const getTabletSpecificStyles = (tabletType) => {
  switch(tabletType) {
    case 'iPadMini':
      return {
        brandImageStyles: {
          width: '75px',
          height: '75px',
        },
        titleStyles: {
          fontSize: '1.2rem',
        },
      };
    case 'iPadAir':
      return {
        brandImageStyles: {
          width: '80px',
          height: '80px',
        },
        titleStyles: {
          fontSize: '1.25rem',
        },
      };
    case 'iPadPro':
      return {
        brandImageStyles: {
          width: '85px',
          height: '85px',
        },
        titleStyles: {
          fontSize: '1.3rem',
        },
      };
    default:
      return {
        brandImageStyles: {
          width: '80px',
          height: '80px',
        },
        titleStyles: {
          fontSize: '1.25rem',
        },
      };
  }
};

// Estilos de tablet para el modal
export const tabletModalStyles = {
  width: '85%',
  maxWidth: '700px',
  padding: 3
};

export const tabletModalImageStyles = {
  width: '85px',
  height: '85px',
  marginRight: 2.5,
  marginBottom: 0,
  flexShrink: 0
};

export const tabletModalTitleStyles = {
  fontSize: '1.35rem'
};

export const tabletModalDescriptionStyles = {
  fontSize: '0.95rem',
  lineHeight: 1.7
};

export const tabletBannerContainerStyles = {
  width: '100%',
  position: 'relative',
  overflow: 'hidden',
  margin: '0 auto',
  marginTop: -2,
  borderRadius: 1,
};

export const tabletBannerImageStyles = {
  width: '100%',
  display: 'block',
  maxHeight: '350px',
  objectFit: 'contain',
  objectPosition: 'center center',
  backgroundColor: '#f5f5f5',
};

export const tabletBrandHeaderBoxStyles = {
  marginTop: 3,
}; 