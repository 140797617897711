import React from 'react';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PropTypes from 'prop-types';

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '15px',
    backgroundColor: 'white',
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent',
    },
  },
  '& .MuiOutlinedInput-input': {
    padding: '16px 20px',
  },
  '& .MuiInputAdornment-root': {
    marginRight: '8px',
  },
}));

export const EmailInput = ({ onChange, value, placeholder = "Enter email" }) => {
  return (
    <StyledTextField
      fullWidth
      placeholder={placeholder}
      variant="outlined"
      value={value}
      onChange={onChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton edge="end" color="primary">
              <ArrowForwardIosIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

EmailInput.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  placeholder: PropTypes.string
};

export default EmailInput; 