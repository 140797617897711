import React from 'react';
import { Box, Container, Typography, Grid, Paper, Button } from '@mui/material';
import SEO from '../../../shared/components/SEO';
import { generateLocalBusinessSchema } from '../../../shared/utils/seo/schemaGenerators';
import DashboardSection from '../../dashboard/components/DashboardSection';
import HeroBanner from '../../dashboard/components/HeroBanner';
import CategoriesCarousel from '../../dashboard/components/CategoriesCarousel';
import BrandsCarousel from '../../dashboard/components/BrandsCarousel';
import WhyChooseSYLLayout from '../../dashboard/components/WhyChooseSYLLayout';
import ReviewsLayout from '../../dashboard/components/ReviewsLayout';

// Schema específico para la ubicación de Orlando
const orlandoLocationSchema = generateLocalBusinessSchema({
  name: 'SYL Roofing Supply - Orlando (Winter Garden)',
  url: 'https://sylroofingsupply.com/locations/orlando',
  areaServed: [
    {
      '@type': 'City',
      'name': 'Orlando',
      'containedInPlace': {
        '@type': 'State',
        'name': 'Florida'
      }
    },
    {
      '@type': 'City',
      'name': 'Winter Garden',
      'containedInPlace': {
        '@type': 'State',
        'name': 'Florida'
      }
    }
  ],
  address: {
    '@type': 'PostalAddress',
    'streetAddress': '930 Carter Rd #303',
    'addressLocality': 'Winter Garden',
    'addressRegion': 'FL',
    'postalCode': '34787',
    'addressCountry': 'US'
  },
  geo: {
    '@type': 'GeoCoordinates',
    'latitude': 28.5647,
    'longitude': -81.5864
  }
});

const OrlandoLocationPage = () => {
  return (
    <>
      <SEO 
        // Título y metadatos en español
        titleEs="Distribuidor de Materiales para Techos en Orlando, FL" 
        descriptionEs="SYL Roofing Supply en Orlando: Su distribuidor de materiales para techos de alta calidad. Servicio personalizado en español, entrega al día siguiente y precios competitivos. Visítenos en Winter Garden."
        keywordsEs="distribuidor techos orlando, materiales para techos winter garden, envío rápido, atención en español, 34787, contratistas, mejores precios"
        
        // Título y metadatos en inglés
        titleEn="Roofing Materials Distributor in Orlando, FL"
        descriptionEn="SYL Roofing Supply in Orlando: Your high-quality roofing materials distributor. Next-day delivery and competitive prices. Visit us in Winter Garden."
        keywordsEn="orlando roofing supplies, roofing materials winter garden, wholesale roofing orlando fl, next-day delivery, contractors, 34787, competitive pricing"
        
        // Valores predeterminados (se usarán como fallback)
        canonicalUrl="/locations/orlando"
        location="Orlando (Winter Garden), FL"
        schema={orlandoLocationSchema}
      />

      <DashboardSection noPadding fullWidth>
        <HeroBanner banners={[
          {
            subtitle: 'El proveedor preferido por contratistas en el área de Orlando',
            title: 'Materiales para Techos de Calidad en Orlando',
            imageUrl: '/images/hero-banner/hero-banner-orlando.webp'
          }
        ]} />
      </DashboardSection>

      <DashboardSection background="#F7F7F7">
        <Container maxWidth="lg">
          <Typography 
            variant="h1" 
            component="h1" 
            sx={{ 
              fontWeight: 800, 
              fontSize: {xs: '32px', md: '40px'},
              color: '#0A3954',
              textAlign: 'center',
              mb: 2
            }}
          >
            Materiales para Techos en Orlando y Winter Garden, FL
          </Typography>
          
          <Typography 
            variant="h2" 
            component="h2" 
            sx={{ 
              fontWeight: 600, 
              fontSize: {xs: '22px', md: '28px'},
              color: '#0A3954',
              textAlign: 'center',
              mb: 4
            }}
          >
            Distribuidor líder en materiales de construcción para toda Florida Central
          </Typography>

          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Typography variant="body1" paragraph>
                <strong>SYL Roofing Supply</strong> en Orlando (Winter Garden) es su aliado para proyectos de construcción y remodelación. Ofrecemos una amplia gama de materiales para techos y accesorios de las mejores marcas, con precios competitivos y entrega rápida.
              </Typography>
              
              <Typography variant="body1" paragraph>
                Desde 2016, hemos estado sirviendo a la comunidad de contratistas y propietarios en Orlando y sus alrededores con <strong>servicio bilingüe (inglés/español)</strong> y un conocimiento profundo de las necesidades específicas de construcción en Florida.
              </Typography>
              
              <Typography variant="body1" paragraph>
                Visite nuestra ubicación en <strong>930 Carter Rd #303, Winter Garden, FL 34787</strong> o contáctenos al <strong>407-534-2698</strong> para obtener una cotización personalizada para su próximo proyecto.
              </Typography>
              
              <Button 
                variant="contained" 
                color="primary" 
                size="large"
                sx={{ mt: 2 }}
              >
                Contactar Ahora
              </Button>
            </Grid>
            
            <Grid item xs={12} md={6}>
              <Paper elevation={3} sx={{ p: 3, borderRadius: 2 }}>
                <Typography variant="h5" sx={{ mb: 2, fontWeight: 700 }}>
                  Ubicación en Orlando
                </Typography>
                
                <Typography variant="body1" sx={{ mb: 1 }}>
                  <strong>Dirección:</strong> 930 Carter Rd #303, Winter Garden, FL 34787
                </Typography>
                
                <Typography variant="body1" sx={{ mb: 1 }}>
                  <strong>Teléfono:</strong> 407-534-2698
                </Typography>
                
                <Typography variant="body1" sx={{ mb: 1 }}>
                  <strong>Email:</strong> quote@sylroofingsupply.com
                </Typography>
                
                <Typography variant="body1" sx={{ mb: 1 }}>
                  <strong>Horario:</strong>
                </Typography>
                
                <Typography variant="body2" sx={{ mb: 0.5 }}>
                  Lunes a Viernes: 7:00 am - 6:00 pm
                </Typography>
                
                <Typography variant="body2" sx={{ mb: 0.5 }}>
                  Sábado: 7:00 am - 3:00 pm
                </Typography>
                
                <Typography variant="body2">
                  Domingo: Cerrado
                </Typography>
                
                <Box
                  component="iframe"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.558896148815!2d-81.58640561744386!3d28.564699200000003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88e77ea235d1ab8d%3A0x5842f506d45ec81b!2s930%20Carter%20Rd%20%23303%2C%20Winter%20Garden%2C%20FL%2034787!5e0!3m2!1sen!2sus!4v1651234567890!5m2!1sen!2sus"
                  width="100%"
                  height="250"
                  style={{ border: 0, borderRadius: '8px', marginTop: '16px' }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  title="Ubicación de SYL Roofing Supply en Orlando (Winter Garden)"
                />
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </DashboardSection>

      <DashboardSection>
        <CategoriesCarousel />
      </DashboardSection>

      <DashboardSection>
        <BrandsCarousel />
      </DashboardSection>

      <DashboardSection noPadding fullWidth>
        <ReviewsLayout />
      </DashboardSection>

      <DashboardSection noPadding fullWidth>
        <WhyChooseSYLLayout />
      </DashboardSection>

      <DashboardSection background="#F7F7F7">
        <Container maxWidth="lg">
          <Typography 
            variant="h3" 
            component="h2" 
            sx={{ 
              fontWeight: 700, 
              fontSize: {xs: '24px', md: '32px'},
              color: '#0A3954',
              mb: 3
            }}
          >
            Materiales más solicitados en Orlando
          </Typography>
          
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Paper elevation={2} sx={{ p: 2, height: '100%' }}>
                <Typography variant="h6" sx={{ fontWeight: 600, mb: 1 }}>
                  Tejas Asfalticas
                </Typography>
                <Typography variant="body2">
                  Gran variedad de colores y estilos, con alta resistencia a los rayos UV y lluvias típicas de Orlando.
                </Typography>
              </Paper>
            </Grid>
            
            <Grid item xs={12} md={4}>
              <Paper elevation={2} sx={{ p: 2, height: '100%' }}>
                <Typography variant="h6" sx={{ fontWeight: 600, mb: 1 }}>
                  Aislantes Térmicos
                </Typography>
                <Typography variant="body2">
                  Soluciones para aumentar la eficiencia energética en residencias y negocios de Orlando, reduciendo costos de climatización.
                </Typography>
              </Paper>
            </Grid>
            
            <Grid item xs={12} md={4}>
              <Paper elevation={2} sx={{ p: 2, height: '100%' }}>
                <Typography variant="h6" sx={{ fontWeight: 600, mb: 1 }}>
                  Accesorios para Tejados
                </Typography>
                <Typography variant="body2">
                  Todos los complementos necesarios para instalaciones completas y profesionales en el área de Orlando.
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </DashboardSection>
    </>
  );
};

export default OrlandoLocationPage; 