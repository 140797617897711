/**
 * Categories utility
 * Manages the list of categories and their translations
 */

// Base categories structure - Reordenado según solicitud
export const categoryKeys = [
  {
    id: 1,
    key: 'architecturalShingle',
    image: '/images/categories/architectural-shingle.webp'
  },
  {
    id: 2,
    key: 'underlayment',
    image: '/images/categories/underlayments.webp'
  },
  {
    id: 3,
    key: 'nailsAndFasteners',
    image: '/images/categories/nails.webp'
  },
  {
    id: 4,
    key: 'cement',
    image: '/images/categories/cement.webp'
  },
  {
    id: 5,
    key: 'coating',
    image: '/images/categories/coating.webp'
  },
  {
    id: 6,
    key: 'lumber',
    image: '/images/categories/lumber.webp'
  },
  {
    id: 7,
    key: 'ventilations',
    image: '/images/categories/ventilations.webp'
  },
  {
    id: 8,
    key: 'accessories',
    image: '/images/categories/accessories.webp'
  },
  {
    id: 9,
    key: 'tpo',
    image: '/images/categories/tpo.webp'
  },
  {
    id: 10,
    key: 'tiles',
    image: '/images/categories/tiles.webp'
  }
];

/**
 * Get translated categories
 * @param {function} t - Translation function from useTranslation hook
 * @returns {Array} Array of categories with translations
 */
export const getTranslatedCategories = (t) => {
  return categoryKeys.map(category => ({
    ...category,
    name: t(`dashboard.categoriesCarousel.categories.${category.key}.name`),
    alt: t(`dashboard.categoriesCarousel.categories.${category.key}.alt`)
  }));
}; 