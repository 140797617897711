import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, Card, CardMedia, CardContent, IconButton, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import FlagIcon from '@mui/icons-material/Flag';
import PersonIcon from '@mui/icons-material/Person';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import LoopIcon from '@mui/icons-material/Loop';
import { getBlogPosts } from '../../services/dashboardService';
import InfoIcon from '@mui/icons-material/Info';
import { useTranslation } from 'react-i18next';

// Hidden SEO styles
const SeoText = styled(Typography)({
  position: 'absolute',
  width: '1px',
  height: '1px',
  padding: '0',
  margin: '-1px',
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap',
  borderWidth: '0'
});

const MainContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#0A3954',
  borderRadius: '16px',
  padding: '40px',
  color: 'white',
  [theme.breakpoints.down('md')]: {
    padding: '32px 16px',
    borderRadius: 0,
    width: '100vw',
    position: 'relative',
    left: '50%',
    right: '50%',
    marginLeft: '-50vw',
    marginRight: '-50vw'
  }
}));

const ValuePropositionContainer = styled(Box)(({ theme }) => ({
  marginBottom: '60px',
  maxWidth: '450px',
  [theme.breakpoints.down('md')]: {
    margin: '0 auto 48px auto',
    maxWidth: '600px'
  }
}));

const ValueDivider = styled(Box)({
  width: '100%',
  height: '1px',
  backgroundColor: 'white',
  margin: '12px 0'
});

const Logo = styled('img')({
  width: '200px',
  marginBottom: '40px',
});

const InfoItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  marginBottom: '16px',
  paddingBottom: '16px',
  maxWidth: '400px',
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%'
  },
  '& img': {
    width: '40px',
    height: '40px',
    marginRight: '16px',
    marginTop: '4px'
  }
}));

const InfoText = styled(Typography)({
  color: 'white',
  fontSize: '16px',
  lineHeight: '1.5',
  flex: 1
});

const StyledCard = styled(Card)(({ theme }) => ({
  height: '400px',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  borderRadius: '12px',
  overflow: 'hidden',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  backgroundColor: '#135C86',
  '&:hover': {
    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)',
    transform: 'translateY(-5px)',
    transition: 'all 0.3s ease'
  }
}));

const StyledCardMedia = styled(CardMedia)({
  height: '180px',
  position: 'relative'
});

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  flexGrow: 1,
  backgroundColor: '#135C86',
  color: 'white',
  padding: '20px',
  position: 'relative',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: '90px'
}));

const ArrowButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: 16,
  bottom: 16,
  color: 'white',
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.2)'
  }
}));

const PopularCategoriesContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#082E44',
  borderRadius: '16px',
  padding: '32px',
  border: '2px solid white',
  maxWidth: '400px',
  marginLeft: 'auto',
  [theme.breakpoints.down('md')]: {
    margin: '0 auto 48px auto',
    maxWidth: '600px'
  }
}));

const CategoryHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: '24px',
  justifyContent: 'center',
  '& img': {
    width: '80px',
    height: '65px',
    marginRight: '16px'
  }
}));

const Divider = styled(Box)({
  width: '100%',
  height: '2px',
  backgroundColor: 'white',
  margin: '24px 0'
});

const CategoryItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '24px',
  justifyContent: 'center',
  '& img': {
    width: '40px',
    height: '40px',
    marginRight: '16px'
  }
}));

const CategoryContent = styled(Box)({
  flex: 1,
});

const CategoryTitle = styled(Typography)({
  fontSize: '18px',
  fontWeight: 500,
  color: 'white',
  marginBottom: '4px'
});

const CategorySubtitle = styled(Typography)({
  fontSize: '14px',
  color: 'rgba(255, 255, 255, 0.7)'
});

const TitleText = styled(Typography)(({ theme }) => ({
  color: '#E7ECF3',
  fontSize: '39.10px',
  fontFamily: 'Open Sans',
  fontWeight: 800,
  marginBottom: '32px',
  lineHeight: 1.2,
  maxWidth: '420px',
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%'
  }
}));

const CardsContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    overflowX: 'auto',
    scrollbarWidth: 'none',  // Firefox
    '&::-webkit-scrollbar': {  // Chrome, Safari, Edge
      display: 'none'
    },
    '-ms-overflow-style': 'none',  // IE and Edge
    paddingBottom: '20px',
    margin: '0 -16px',  // Compensar el padding del contenedor principal
    padding: '0 16px'
  }
}));

const CardsWrapper = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gap: '24px',
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    gap: '16px',
    '& > *': {
      flex: '0 0 300px',
      maxWidth: '300px'
    }
  }
}));

const RecentNews = () => {
  const { t } = useTranslation();
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Define los beneficios utilizando traducciones
  const benefits = {
    onlineSupport: {
      title: t('dashboard.recentNews.valueProp.benefits.onlineSupport.title'),
      subtitle: t('dashboard.recentNews.valueProp.benefits.onlineSupport.subtitle'),
      description: t('dashboard.recentNews.valueProp.benefits.onlineSupport.description'),
      seoTitle: t('dashboard.recentNews.valueProp.benefits.onlineSupport.seoTitle'),
      seoDescription: t('dashboard.recentNews.valueProp.benefits.onlineSupport.seoDescription'),
      imageAlt: t('dashboard.recentNews.valueProp.benefits.onlineSupport.imageAlt'),
      icon: "/images/icons/user_icon.svg"
    },
    freeShipping: {
      title: t('dashboard.recentNews.valueProp.benefits.freeShipping.title'),
      subtitle: t('dashboard.recentNews.valueProp.benefits.freeShipping.subtitle'),
      description: t('dashboard.recentNews.valueProp.benefits.freeShipping.description'),
      seoTitle: t('dashboard.recentNews.valueProp.benefits.freeShipping.seoTitle'),
      seoDescription: t('dashboard.recentNews.valueProp.benefits.freeShipping.seoDescription'),
      imageAlt: t('dashboard.recentNews.valueProp.benefits.freeShipping.imageAlt'),
      icon: "/images/icons/truck-fast_icon.svg"
    },
    timelessShopping: {
      title: t('dashboard.recentNews.valueProp.benefits.timelessShopping.title'),
      subtitle: t('dashboard.recentNews.valueProp.benefits.timelessShopping.subtitle'),
      description: t('dashboard.recentNews.valueProp.benefits.timelessShopping.description'),
      seoTitle: t('dashboard.recentNews.valueProp.benefits.timelessShopping.seoTitle'),
      seoDescription: t('dashboard.recentNews.valueProp.benefits.timelessShopping.seoDescription'),
      imageAlt: t('dashboard.recentNews.valueProp.benefits.timelessShopping.imageAlt'),
      icon: "/images/icons/bag_icon.svg"
    },
    productReturns: {
      title: t('dashboard.recentNews.valueProp.benefits.productReturns.title'),
      subtitle: t('dashboard.recentNews.valueProp.benefits.productReturns.subtitle'),
      description: t('dashboard.recentNews.valueProp.benefits.productReturns.description'),
      seoTitle: t('dashboard.recentNews.valueProp.benefits.productReturns.seoTitle'),
      seoDescription: t('dashboard.recentNews.valueProp.benefits.productReturns.seoDescription'),
      imageAlt: t('dashboard.recentNews.valueProp.benefits.productReturns.imageAlt'),
      icon: "/images/icons/refresh_icon.svg"
    }
  };

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const blogPosts = await getBlogPosts();
        setPosts(blogPosts);
        setError(null);
      } catch (error) {
        console.error('Error fetching blog posts:', error);
        setError(t('dashboard.recentNews.errorText'));
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, [t]);

  if (loading) {
    return (
      <Box sx={{ p: 4, textAlign: 'center' }}>
        <CircularProgress />
        <Typography sx={{ mt: 2 }}>{t('dashboard.recentNews.loadingText')}</Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 4, textAlign: 'center', color: 'text.secondary' }}>
        <InfoIcon color="warning" sx={{ fontSize: 40, mb: 2 }} />
        <Typography>{error}</Typography>
      </Box>
    );
  }
  
  if (posts.length === 0) {
    return (
      <Box sx={{ p: 4, textAlign: 'center', color: 'text.secondary' }}>
        <Typography>{t('dashboard.recentNews.noNewsText')}</Typography>
      </Box>
    );
  }

  return (
    <MainContainer>
      {/* SEO Hidden Elements */}
      <SeoText variant="h1">{t('dashboard.recentNews.seoTitle')}</SeoText>
      <SeoText component="p">{t('dashboard.recentNews.seoDescription')}</SeoText>
      
      <Grid container spacing={4}>
        {/* Value Proposition y Popular Categories en la misma fila */}
        <Grid container item spacing={4}>
          <Grid item xs={12} md={6}>
            <ValuePropositionContainer>
              <TitleText>
                {t('dashboard.recentNews.valueProp.title')}
              </TitleText>
              
              <InfoItem>
                <img src="/images/icons/check_icon.svg" alt="Check" />
                <InfoText>
                  {t('dashboard.recentNews.subtitle')}
                </InfoText>
              </InfoItem>
              <ValueDivider />

              <InfoItem>
                <img src="/images/icons/credit_card_icon.svg" alt="Card" />
                <InfoText>
                  {t('dashboard.recentNews.subtitle')}
                </InfoText>
                {/* Hidden SEO text for this section */}
                <SeoText variant="h2">{t('dashboard.recentNews.subtitleSeo')}</SeoText>
                <SeoText component="p">{t('dashboard.recentNews.subtitleSeoDescription')}</SeoText>
              </InfoItem>
              <ValueDivider />

              <InfoItem>
                <img src="/images/icons/flag_fill_icon.svg" alt="Flag" />
                <InfoText>
                  {t('dashboard.recentNews.subtitle')}
                </InfoText>
              </InfoItem>
            </ValuePropositionContainer>
          </Grid>

          <Grid item xs={12} md={6}>
            <PopularCategoriesContainer>
              <CategoryHeader>
                <img 
                  src="/images/icons/icon_popular_categories.svg" 
                  alt={t('dashboard.recentNews.popularCategories.titleAlt')}
                />
                <Typography variant="h5" sx={{ color: 'white', fontWeight: 500 }}>
                  {t('dashboard.recentNews.popularCategories.title')}
                </Typography>
              </CategoryHeader>
              
              <Divider />

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <CategoryItem>
                    <img 
                      src={benefits.onlineSupport.icon} 
                      alt={benefits.onlineSupport.imageAlt}
                    />
                    <CategoryContent>
                      <CategoryTitle>{benefits.onlineSupport.title}</CategoryTitle>
                      <CategorySubtitle>{benefits.onlineSupport.subtitle}</CategorySubtitle>
                      {/* Hidden SEO Text */}
                      <SeoText variant="h3">{benefits.onlineSupport.seoTitle}</SeoText>
                      <SeoText component="p">{benefits.onlineSupport.seoDescription}</SeoText>
                    </CategoryContent>
                  </CategoryItem>
                </Grid>

                <Grid item xs={12}>
                  <CategoryItem>
                    <img 
                      src={benefits.freeShipping.icon} 
                      alt={benefits.freeShipping.imageAlt}
                    />
                    <CategoryContent>
                      <CategoryTitle>{benefits.freeShipping.title}</CategoryTitle>
                      <CategorySubtitle>{benefits.freeShipping.subtitle}</CategorySubtitle>
                      {/* Hidden SEO Text */}
                      <SeoText variant="h3">{benefits.freeShipping.seoTitle}</SeoText>
                      <SeoText component="p">{benefits.freeShipping.seoDescription}</SeoText>
                    </CategoryContent>
                  </CategoryItem>
                </Grid>

                <Grid item xs={12}>
                  <CategoryItem>
                    <img 
                      src={benefits.timelessShopping.icon} 
                      alt={benefits.timelessShopping.imageAlt}
                    />
                    <CategoryContent>
                      <CategoryTitle>{benefits.timelessShopping.title}</CategoryTitle>
                      <CategorySubtitle>{benefits.timelessShopping.subtitle}</CategorySubtitle>
                      {/* Hidden SEO Text */}
                      <SeoText variant="h3">{benefits.timelessShopping.seoTitle}</SeoText>
                      <SeoText component="p">{benefits.timelessShopping.seoDescription}</SeoText>
                    </CategoryContent>
                  </CategoryItem>
                </Grid>

                <Grid item xs={12}>
                  <CategoryItem>
                    <img 
                      src={benefits.productReturns.icon} 
                      alt={benefits.productReturns.imageAlt} 
                    />
                    <CategoryContent>
                      <CategoryTitle>{benefits.productReturns.title}</CategoryTitle>
                      <CategorySubtitle>{benefits.productReturns.subtitle}</CategorySubtitle>
                      {/* Hidden SEO Text */}
                      <SeoText variant="h3">{benefits.productReturns.seoTitle}</SeoText>
                      <SeoText component="p">{benefits.productReturns.seoDescription}</SeoText>
                    </CategoryContent>
                  </CategoryItem>
                </Grid>
              </Grid>
            </PopularCategoriesContainer>
          </Grid>
        </Grid>

        {/* Recent News Section */}
        <Grid item xs={12}>
          <Typography variant="h4" sx={{ mb: 4 }}>
            {t('dashboard.recentNews.ourRecentNews')}
          </Typography>
          <CardsContainer>
            <CardsWrapper>
              {posts.map((post) => (
                <StyledCard key={post.id}>
                  <StyledCardMedia
                    image={post.featured_media_url || '/images/default-blog-image.jpg'}
                    title={post.title.rendered}
                  />
                  <StyledCardContent>
                    <Typography 
                      variant="h6" 
                      gutterBottom 
                      component="div"
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                        marginBottom: 2,
                        minHeight: '64px'
                      }}
                    >
                      {post.title.rendered}
                    </Typography>
                    <Typography 
                      variant="body2" 
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: 3,
                        WebkitBoxOrient: 'vertical',
                        marginBottom: 2,
                        flex: 1,
                        maxHeight: '4.5em'
                      }}
                    >
                      {post.excerpt.rendered.replace(/<[^>]+>/g, '').slice(0, 120)}...
                    </Typography>
                    <ArrowButton
                      size="small"
                      onClick={() => window.open(post.link, '_blank')}
                      sx={{
                        position: 'absolute',
                        right: 16,
                        bottom: 16,
                        zIndex: 1
                      }}
                    >
                      <ArrowForwardIcon />
                    </ArrowButton>
                  </StyledCardContent>
                </StyledCard>
              ))}
            </CardsWrapper>
          </CardsContainer>
        </Grid>
      </Grid>
    </MainContainer>
  );
};

export default RecentNews; 