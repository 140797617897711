import React from 'react';
import { Box, Container, Typography, Button, useTheme, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import contactService from '../../../../shared/services/contactService';
import {
  containerStyles,
  contentWrapperStyles,
  titleStyles,
  subtitleStyles,
  stepsContainerStyles,
  stepItemStyles,
  stepImageWrapperStyles,
  stepImageStyles,
  stepNumberStyles,
  stepDescriptionStyles,
  buttonStyles,
  buttonWrapperStyles,
  srOnlyStyles
} from './styles';

/**
 * DeliveryProcessLayout Component
 * 
 * Displays the delivery process steps for SYL Roofing Supply in a responsive layout.
 * The component shows different steps of the delivery process with icons, numbers, and descriptions.
 * It adapts to different screen sizes (mobile, tablet, desktop) using responsive design.
 */
const DeliveryProcessLayout = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  // Define steps using translations
  const steps = [
    {
      id: 1,
      number: t('dashboard.deliveryProcess.steps.consultation.number'),
      description: t('dashboard.deliveryProcess.steps.consultation.description'),
      seoTitle: t('dashboard.deliveryProcess.steps.consultation.seoTitle'),
      seoDescription: t('dashboard.deliveryProcess.steps.consultation.seoDescription'),
      image: '/images/delivery-process/call_center_icon.webp',
      imageAlt: t('dashboard.deliveryProcess.steps.consultation.imageAlt')
    },
    {
      id: 2,
      number: t('dashboard.deliveryProcess.steps.recommendations.number'),
      description: t('dashboard.deliveryProcess.steps.recommendations.description'),
      seoTitle: t('dashboard.deliveryProcess.steps.recommendations.seoTitle'),
      seoDescription: t('dashboard.deliveryProcess.steps.recommendations.seoDescription'),
      image: '/images/delivery-process/thumb_up_icon.webp',
      imageAlt: t('dashboard.deliveryProcess.steps.recommendations.imageAlt')
    },
    {
      id: 3,
      number: t('dashboard.deliveryProcess.steps.delivery.number'),
      description: t('dashboard.deliveryProcess.steps.delivery.description'),
      seoTitle: t('dashboard.deliveryProcess.steps.delivery.seoTitle'),
      seoDescription: t('dashboard.deliveryProcess.steps.delivery.seoDescription'),
      image: '/images/delivery-process/mini_cargo_icon.webp',
      imageAlt: t('dashboard.deliveryProcess.steps.delivery.imageAlt')
    }
  ];

  /**
   * Renders a single delivery process step
   * @param {Object} step - The step object containing number, description, image, etc.
   */
  const renderStep = (step) => (
    <Box key={step.id} sx={stepItemStyles}>
      <Box sx={stepImageWrapperStyles}>
        <Box
          component="img"
          src={step.image}
          alt={step.imageAlt}
          sx={stepImageStyles}
        />
      </Box>
      <Typography sx={stepNumberStyles}>
        {step.number}
      </Typography>
      <Typography sx={stepDescriptionStyles}>
        {step.description}
      </Typography>
      
      {/* Hidden SEO optimized title for this step - accessible for screen readers */}
      <Typography sx={srOnlyStyles}>
        {step.seoTitle}
      </Typography>
      
      {/* Hidden SEO optimized description for this step - accessible for screen readers */}
      <Typography sx={srOnlyStyles}>
        {step.seoDescription}
      </Typography>
    </Box>
  );
  
  /**
   * Handle CTA button click to send WhatsApp message
   * Opens WhatsApp with a predefined message about roofing services
   */
  const handleCtaClick = () => {
    const message = t('dashboard.deliveryProcess.whatsappMessage');
    contactService.openWhatsApp(message);
  };
  
  return (
    <Box sx={containerStyles}>
      <Container maxWidth="lg">
        <Box sx={contentWrapperStyles}>
          {/* Main UX Title */}
          <Typography variant="h2" sx={titleStyles}>
            {t('dashboard.deliveryProcess.title')}
          </Typography>
          
          {/* Hidden SEO Title - accessible for screen readers */}
          <Typography sx={srOnlyStyles}>
            {t('dashboard.deliveryProcess.seoTitle')}
          </Typography>
          
          {/* Hidden SEO Description - accessible for screen readers */}
          <Typography sx={srOnlyStyles}>
            {t('dashboard.deliveryProcess.seoDescription')}
          </Typography>
          
          {/* Visible subtitle */}
          <Typography sx={subtitleStyles}>
            {t('dashboard.deliveryProcess.subtitle')}
          </Typography>
          
          {/* Hidden SEO subtitle - accessible for screen readers */}
          <Typography sx={srOnlyStyles}>
            {t('dashboard.deliveryProcess.subtitleSeo')}
          </Typography>
          
          {/* Hidden SEO subtitle description - accessible for screen readers */}
          <Typography sx={srOnlyStyles}>
            {t('dashboard.deliveryProcess.subtitleSeoDescription')}
          </Typography>

          <Box sx={stepsContainerStyles}>
            {steps.map(renderStep)}
          </Box>

          <Box sx={buttonWrapperStyles}>
            <Button
              variant="contained"
              sx={buttonStyles}
              fullWidth={isTablet}
              onClick={handleCtaClick}
              startIcon={isMobile ? <WhatsAppIcon /> : null}
            >
              {t('dashboard.deliveryProcess.ctaButton')}
            </Button>
            
            {/* Hidden SEO optimized CTA title - accessible for screen readers */}
            <Typography sx={srOnlyStyles}>
              {t('dashboard.deliveryProcess.ctaSeoTitle')}
            </Typography>
            
            {/* Hidden SEO optimized CTA description - accessible for screen readers */}
            <Typography sx={srOnlyStyles}>
              {t('dashboard.deliveryProcess.ctaSeoDescription')}
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default DeliveryProcessLayout; 