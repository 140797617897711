import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useAuthModal, STEPS } from '../../hooks/useAuthModal';
import { 
  StyledDialog,
  CloseButton,
  ContentContainer,
  LeftSection,
  RightSection
} from './styles';
import EmailCheckContent from './components/EmailCheckContent';
import RegisteredUserContent from './components/RegisteredUserContent';
import NewsletterSubscriptionContent from './components/NewsletterSubscriptionContent';
import NetsuiteUserContent from './components/NetsuiteUserContent';
import RightContent from './components/RightContent';

const LoginModal = ({ open, onClose, onLoginSuccess }) => {
  const {
    step,
    email,
    handleEmailCheck,
    handleLogin,
    handleSubscribe
  } = useAuthModal(onClose, onLoginSuccess);

  const renderContent = () => {
    switch (step) {
      case STEPS.EMAIL_CHECK:
        return (
          <EmailCheckContent
            onClose={onClose}
            onEmailCheck={handleEmailCheck}
          />
        );
      case STEPS.REGISTERED_USER:
        return (
          <RegisteredUserContent
            email={email}
            onClose={onClose}
            onLogin={handleLogin}
          />
        );
      case STEPS.NETSUITE_USER:
        return (
          <NetsuiteUserContent
            email={email}
            onClose={onClose}
            onLogin={handleLogin}
          />
        );
      case STEPS.NEW_USER:
        return (
          <NewsletterSubscriptionContent
            email={email}
            onClose={onClose}
            onSubscribe={handleSubscribe}
          />
        );
      default:
        return null;
    }
  };

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      aria-labelledby="login-dialog-title"
    >
      <CloseButton onClick={onClose} aria-label="close">
        <CloseIcon />
      </CloseButton>

      <ContentContainer>
        <LeftSection>
          {renderContent()}
        </LeftSection>

        <RightSection>
          <RightContent />
        </RightSection>
      </ContentContainer>
    </StyledDialog>
  );
};

export default LoginModal; 