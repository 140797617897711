import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getImageForLanguage } from '../utils/contentUtils';
import { getTranslatedContent } from '../utils/contentUtils';
import {
  mobileContainerStyles,
  mobileImageContainerStyles,
  mobileImageStyles,
  mobileContentContainerStyles,
  mobileSubtitleWrapperStyles,
  mobileSubtitleStyles,
  mobileTitleStyles,
  mobileButtonStyles
} from '../styles/mobile';

/**
 * Component to display the banner in mobile view
 * 
 * The mobile layout features:
 * - Full-width container without horizontal padding
 * - Full-width image at the top (no border radius for edge-to-edge design)
 * - Left-justified text content with proper horizontal padding
 * - A consistently positioned call-to-action button anchored at the bottom
 * 
 * @param {Object} props - Component properties
 * @param {Object} props.banner - Banner data to display
 * @param {number} props.index - Banner index in the carousel
 * @param {Function} props.onButtonClick - Function to execute when clicking the button
 * @returns {React.ReactElement} MobileView component
 */
const MobileView = ({ banner, index, onButtonClick }) => {
  const { t, i18n } = useTranslation();
  const isMobile = true; // This view is always mobile
  const translatedContent = getTranslatedContent(t, banner, index, isMobile);
  
  return (
    <Box sx={{
      ...mobileContainerStyles,
      position: 'relative',
      height: { xs: '620px' }, // Altura fija ligeramente reducida
      paddingBottom: '40px' // Espacio para el botón
    }}>
      {/* Mobile Image (Top) - Full width, edge-to-edge */}
      <Box sx={mobileImageContainerStyles}>
        <Box
          component="img"
          src={getImageForLanguage(banner, i18n.language)}
          alt={translatedContent.imageAlt}
          sx={mobileImageStyles}
        />
      </Box>
      
      {/* Mobile Content (Below Image) - With proper left padding */}
      <Box sx={mobileContentContainerStyles}>
        {/* Subtitle/Slogan with proper spacing */}
        <Box sx={mobileSubtitleWrapperStyles}>
          <Typography sx={mobileSubtitleStyles}>
            {translatedContent.subtitle}
          </Typography>
        </Box>
        
        {/* Title with proper spacing */}
        <Typography sx={mobileTitleStyles}>
          {translatedContent.title}
        </Typography>
      </Box>

      {/* Button - Absolute positioning to ensure consistent placement */}
      <Box sx={{
        position: 'absolute',
        bottom: 32, // Aumentado desde 16px a 32px para reducir el espacio
        left: 0,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        padding: '0 16px',
        boxSizing: 'border-box'
      }}>
        <Button 
          variant="contained"
          onClick={onButtonClick}
          sx={{
            ...mobileButtonStyles,
            display: 'inline-block',
            width: '100%',
            maxWidth: '350px'
          }}
        >
          {index === 2 
            ? t('dashboard.heroBanner3.buttonTextMobile')
            : t('dashboard.heroBanner.buttonText')}
        </Button>
      </Box>
    </Box>
  );
};

MobileView.propTypes = {
  banner: PropTypes.shape({
    id: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    imageUrlEn: PropTypes.string,
    imageUrlEs: PropTypes.string
  }).isRequired,
  index: PropTypes.number.isRequired,
  onButtonClick: PropTypes.func.isRequired
};

export default MobileView; 