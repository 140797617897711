/**
 * Mobile styles for the Header component
 * These styles are for mobile devices and extra small screens
 */

export const mobileAppBarStyles = {
  // Cuando el banner está visible, ajustamos la posición top en la función getAppBarSx
  boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.15)', // Sombra más pronunciada para mejor separación visual
  // No sobreescribimos propiedades críticas como position
};

export const mobileToolbarStyles = {
  padding: '0 8px !important',
  minHeight: '75px !important', // Ligeramente más pequeño en móvil
  display: 'flex',
  alignItems: 'center', // Asegurar que los elementos estén centrados verticalmente
  justifyContent: 'space-between',
};

export const mobileLogoWrapperStyles = {
  display: 'flex',
  alignItems: 'center', // Centrar verticalmente el logo
  justifyContent: 'flex-start', // Alinear al inicio (izquierda)
  height: '100%', // Ocupar toda la altura disponible
  paddingLeft: '12px', // Mantener un margen izquierdo similar al original
  '& img': {
    width: '150px', // Ligeramente más pequeño en móvil
    height: '50px', // Altura proporcional
    objectFit: 'contain', // Mantener proporción
  },
};

export const mobileDrawerStyles = {
  width: '100%', // Drawer a pantalla completa en móvil
};

export const mobileNavButtonStyles = {
  marginLeft: 1,
};

export const mobileLanguageSwitcherContainerStyles = {
  marginRight: 1,
  display: 'flex',
  alignItems: 'center', // Centrar verticalmente el selector de idioma
};

export const mobileDevButtonStyles = {
  marginRight: 1,
  fontSize: '0.75rem',
}; 