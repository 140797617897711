/**
 * Device detection utility
 * Provides hooks and functions for detecting device types and specific iPad models
 */

import { useMediaQuery, useTheme } from '@mui/material';

/**
 * Hook to detect device type and iPad model
 * @returns {Object} Object containing device detection flags
 */
export const useDeviceDetection = () => {
  const theme = useTheme();
  
  // Device type detection
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  
  // iPad model detection
  const isIpadMini = useMediaQuery('(min-width: 740px) and (max-width: 768px)');
  const isIpadAir = useMediaQuery('(min-width: 800px) and (max-width: 834px)');
  const isIpadPro = useMediaQuery('(min-width: 1000px) and (max-width: 1024px)');
  
  // Any iPad model
  const isAnyIpad = isIpadMini || isIpadAir || isIpadPro;
  
  // Get tablet type
  const tabletType = isIpadMini 
    ? 'iPadMini' 
    : isIpadAir 
      ? 'iPadAir' 
      : isIpadPro 
        ? 'iPadPro' 
        : 'generic';
  
  // Get device type
  const deviceType = isMobile 
    ? 'mobile' 
    : isTablet || isAnyIpad 
      ? 'tablet' 
      : 'desktop';
  
  return {
    isMobile,
    isTablet,
    isDesktop,
    isIpadMini,
    isIpadAir,
    isIpadPro,
    isAnyIpad,
    tabletType,
    deviceType
  };
}; 