/**
 * BrandProductsPage Component
 * Displays products for a specific brand
 */

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Container, Typography, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

import BrandProductList from '../components/BrandProductList';
import { getBrandProducts } from '../services/brandProductsService';

const BrandProductsPage = () => {
  const { brandId } = useParams(); // Cambiado de brandKey a brandId
  const { t } = useTranslation();
  const navigate = useNavigate();
  
  const [loading, setLoading] = useState(true);
  const [brand, setBrand] = useState(null);
  const [products, setProducts] = useState([]);
  const [error, setError] = useState(null);
  
  // Efecto para reiniciar el estado de carga cuando cambia el brandId
  useEffect(() => {
    // Cada vez que cambie el brandId, resetear el estado para mostrar el loader
    setLoading(true);
    setBrand(null);
    setProducts([]);
    setError(null);
  }, [brandId]);
  
  useEffect(() => {
    const fetchBrandProducts = async () => {
      try {
        // No necesitamos setLoading(true) aquí ya que lo hacemos en el efecto anterior
        const { brand, products } = await getBrandProducts(brandId);
        
        if (!brand) {
          setError(t('brands.brandNotFound'));
          return;
        }
        
        setBrand(brand);
        setProducts(products);
      } catch (err) {
        console.error('Error fetching brand products:', err);
        setError(t('brands.errorLoading'));
      } finally {
        setLoading(false);
      }
    };
    
    fetchBrandProducts();
  }, [brandId, t]);

  const handleBackToBrands = () => {
    navigate('/'); // Volver a la página principal donde están todas las marcas
  };
  
  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '50vh' }}>
        <CircularProgress />
      </Box>
    );
  }
  
  if (error || !brand) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <Container maxWidth="lg" sx={{ padding: { xs: 2, sm: 3, md: 4 } }}>
          <Box sx={{ py: 4, textAlign: 'center' }}>
            <Typography variant="h4" component="h1" color="error" gutterBottom>
              {t('brands.errorTitle')}
            </Typography>
            <Typography variant="body1">
              {error || t('brands.brandNotFound')}
            </Typography>
          </Box>
        </Container>
      </Box>
    );
  }
  
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <Container maxWidth="lg" sx={{ padding: { xs: 2, sm: 3, md: 4 } }}>
        <BrandProductList 
          brand={brand} 
          products={products}
          onBackToBrands={handleBackToBrands}
        />
      </Container>
    </Box>
  );
};

export default BrandProductsPage;
