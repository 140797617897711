// Tablet-specific styles
export const tabletCategoriesContainerStyles = {
  padding: theme => theme.spacing(1.5, 0),
  width: '100vw',
  maxWidth: '100vw',
  boxSizing: 'border-box',
  overflow: 'hidden',
  marginLeft: 0,
  marginRight: 0,
  paddingLeft: 0,
  paddingRight: 0
};

export const tabletTitleStyles = {
  display: 'none'
};

export const tabletCarouselContainerStyles = {
  paddingBottom: 1,
  paddingTop: 0.5,
  width: '100%',
  maxWidth: '100vw',
  paddingLeft: 0,
  paddingRight: 0,
  overflowX: 'auto',
  overflowY: 'hidden', // Prevent vertical scrolling
  scrollbarWidth: 'thin', // Better scrollbar for better UX with more cards
  '&::-webkit-scrollbar': {
    height: '6px' // Thinner scrollbar for webkit browsers
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(0,0,0,0.2)', // Visible but subtle scrollbar thumb
    borderRadius: '3px'
  }
};

export const tabletCarouselContentStyles = {
  display: 'flex',
  flexDirection: 'row', // Ensure horizontal alignment
  flexWrap: 'nowrap', // Prevent wrapping to new line
  gap: 2,
  paddingRight: 2, // Add padding to the right for better scrolling UX
  width: 'max-content', // Allow content to determine width for proper scrolling
  '& > :first-of-type': {
    marginLeft: 2
  }
};

export const tabletCategoryCardStyles = {
  width: '150px'
};

export const tabletCircleImageStyles = {
  width: '130px',
  height: '130px'
};

export const tabletCategoryNameStyles = {
  fontSize: '16px',
  maxWidth: '130px'
};

// iPad Mini specific styles
export const iPadMiniCategoryCardStyles = {
  width: '120px',
  gap: 1.2
};

export const iPadMiniCircleImageStyles = {
  width: '100px',
  height: '100px'
};

export const iPadMiniCategoryNameStyles = {
  fontSize: '14px',
  maxWidth: '110px'
};

export const iPadMiniCarouselContentStyles = {
  display: 'flex',
  flexDirection: 'row', // Ensure horizontal alignment
  flexWrap: 'nowrap', // Prevent wrapping to new line
  gap: 1.5,
  paddingRight: 2, // Add padding to the right for better scrolling UX
  width: 'max-content' // Allow content to determine width for proper scrolling
};

// iPad Air specific styles
export const iPadAirCategoryCardStyles = {
  width: '130px',
  gap: 1.3
};

export const iPadAirCircleImageStyles = {
  width: '110px',
  height: '110px'
};

export const iPadAirCategoryNameStyles = {
  fontSize: '15px',
  maxWidth: '120px'
};

export const iPadAirCarouselContentStyles = {
  display: 'flex',
  flexDirection: 'row', // Ensure horizontal alignment
  flexWrap: 'nowrap', // Prevent wrapping to new line
  gap: 2,
  paddingRight: 2, // Add padding to the right for better scrolling UX
  width: 'max-content' // Allow content to determine width for proper scrolling
};

// iPad Pro specific styles
export const iPadProCategoryCardStyles = {
  width: '140px',
  gap: 1.4
};

export const iPadProCircleImageStyles = {
  width: '120px',
  height: '120px'
};

export const iPadProCategoryNameStyles = {
  fontSize: '16px',
  maxWidth: '130px'
};

export const iPadProCarouselContentStyles = {
  display: 'flex',
  flexDirection: 'row', // Ensure horizontal alignment
  flexWrap: 'nowrap', // Prevent wrapping to new line
  gap: 3,
  paddingRight: 2, // Add padding to the right for better scrolling UX
  width: 'max-content' // Allow content to determine width for proper scrolling
}; 