import { GlobalStyles } from '@mui/material';

const globalStyles = (
  <GlobalStyles
    styles={{
      body: {
        margin: 0,
        fontFamily: 'Open Sans, sans-serif',
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale',
      },
      code: {
        fontFamily: 'source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace',
      },
      '.App': {
        textAlign: 'center',
      },
      '.App-logo': {
        height: '40vmin',
        pointerEvents: 'none',
      },
      '@media (prefers-reduced-motion: no-preference)': {
        '.App-logo': {
          animation: 'App-logo-spin infinite 20s linear',
        },
      },
      '.App-header': {
        backgroundColor: '#282c34',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 'calc(10px + 2vmin)',
        color: 'white',
      },
      '.App-link': {
        color: '#61dafb',
      },
      '@keyframes App-logo-spin': {
        from: {
          transform: 'rotate(0deg)',
        },
        to: {
          transform: 'rotate(360deg)',
        },
      },
    }}
  />
);

export default globalStyles;