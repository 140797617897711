/**
 * BrandsCarousel Component
 * A responsive carousel that showcases trusted roofing brand partners
 * with device-specific optimizations
 */

import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useDeviceDetection } from './utils/deviceDetection';
import { getTranslatedBrands } from './utils/brands';
import MobileCarousel from './components/MobileCarousel';
import TabletCarousel from './components/TabletCarousel';
import DesktopCarousel from './components/DesktopCarousel';

const BrandsCarousel = () => {
  const { deviceType, tabletType } = useDeviceDetection();
  const { t } = useTranslation();
  
  // Get translated brands data
  const brands = useMemo(() => getTranslatedBrands(t), [t]);
  
  // Render the appropriate carousel based on device type
  const renderCarousel = () => {
    if (deviceType === 'mobile') {
      return <MobileCarousel brands={brands} />;
    }
    
    if (deviceType === 'tablet') {
      return <TabletCarousel brands={brands} tabletType={tabletType} />;
    }
    
    return <DesktopCarousel brands={brands} />;
  };
  
  return renderCarousel();
};

export default BrandsCarousel; 