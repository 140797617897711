/**
 * MobileCarousel Component
 * Mobile-specific implementation of the BrandsCarousel with a 2-column grid layout
 */

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Container } from '@mui/material';
import { useTranslation } from 'react-i18next';

import BrandCard from './BrandCard';

// Import styles directly from their source files
import {
  commonContainerStyles,
  commonSeoDescriptionStyles,
  commonTitleStyles
} from '../styles/common';

import {
  mobileContainerStyles,
  mobileTitleStyles,
  mobileGridStyles
} from '../styles/mobile';

const MobileCarousel = ({ brands }) => {
  const { t } = useTranslation();
  
  return (
    <Box sx={{ ...commonContainerStyles, ...mobileContainerStyles }}>
      <Container maxWidth="lg" disableGutters>
        {/* Title */}
        <Typography 
          variant="h2" 
          component="h2" 
          sx={{ ...commonTitleStyles, ...mobileTitleStyles }}
        >
          {t('dashboard.brandsCarousel.title')}
        </Typography>
        
        {/* Hidden SEO description */}
        <Typography 
          component="p" 
          sx={commonSeoDescriptionStyles}
        >
          {t('dashboard.brandsCarousel.description')}
        </Typography>
        
        <Box sx={{ padding: '0 16px', width: '100%' }}>
          <Box sx={mobileGridStyles}>
            {brands.map((brand) => (
              <BrandCard 
                key={brand.id} 
                brand={brand} 
                deviceType="mobile"
              />
            ))}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

MobileCarousel.propTypes = {
  brands: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      key: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired
    })
  ).isRequired
};

export default memo(MobileCarousel); 