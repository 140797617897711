/**
 * BrandProductList Component
 * Displays a grid of products for a specific brand
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Container, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import BrandsCarousel from '../../../../shared/components/BrandsCarousel';
import BrandHeader from '../BrandHeader';
import ProductCard from '../ProductCard';

// Mock data para pruebas del BrandHeader
const mockBrandData = {
  id: 1,
  name: 'Lorem Ipsum',
  logo: 'https://via.placeholder.com/150x150/cccccc/666666?text=Logo',
  description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
  verified: true,
  rating: 4.5,
  reviewCount: 128,
  location: 'Lorem City, IP',
  categories: [
    {
      id: 101,
      name: 'Category A',
      productIds: [1, 3, 5]
    },
    {
      id: 102,
      name: 'Category B',
      productIds: [2, 4, 6]
    },
    {
      id: 103,
      name: 'Category C',
      productIds: [1, 2]
    },
    {
      id: 104,
      name: 'Category D',
      productIds: [3, 4]
    },
    {
      id: 105,
      name: 'Category E',
      productIds: [5, 6]
    }
  ]
};

// Styles for the grid container
const gridStyles = {
  display: 'flex',
  flexWrap: 'wrap',
  gap: { xs: '16px', sm: '20px', md: '24px' },
  justifyContent: 'center', // Centrar las cards horizontalmente
  alignItems: 'stretch',
  width: '100%',
  marginTop: { xs: 2, sm: 3, md: 4 }
};

// Estilos para el contenedor de cada card según el dispositivo
const getCardContainerStyles = (deviceType) => {
  // Desktop: 4 cards por fila (25% - gap)
  // Tablet: 3 cards por fila (33.33% - gap)
  // Mobile: 2 cards por fila (50% - gap)
  if (deviceType === 'mobile') {
    return {
      width: 'calc(50% - 8px)', // 2 cards por fila con gap de 16px
      maxWidth: 'calc(50% - 8px)',
      flexBasis: 'calc(50% - 8px)'
    };
  }
  
  if (deviceType === 'tablet') {
    return {
      width: 'calc(33.33% - 14px)', // 3 cards por fila con gap de 20px
      maxWidth: 'calc(33.33% - 14px)',
      flexBasis: 'calc(33.33% - 14px)'
    };
  }
  
  return {
    width: 'calc(25% - 18px)', // 4 cards por fila con gap de 24px
    maxWidth: 'calc(25% - 18px)',
    flexBasis: 'calc(25% - 18px)'
  };
};

const BrandProductList = ({ brand = mockBrandData, products, onBackToBrands = () => {} }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [deviceType, setDeviceType] = useState('desktop');
  const [tabletType, setTabletType] = useState('generic');
  const [selectedFilter, setSelectedFilter] = useState(null);
  
  // Media queries para detectar el tipo de dispositivo
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  
  // Media queries adicionales para tipos específicos de tablet
  const isIPadMini = useMediaQuery('(min-width: 768px) and (max-width: 820px)');
  const isIPadAir = useMediaQuery('(min-width: 821px) and (max-width: 912px)');
  const isIPadPro = useMediaQuery('(min-width: 913px) and (max-width: 1024px)');
  
  useEffect(() => {
    // Detectar tipo de dispositivo
    if (isMobile) {
      setDeviceType('mobile');
    } else if (isTablet) {
      setDeviceType('tablet');
      
      // Detectar tipo específico de tablet
      if (isIPadMini) {
        setTabletType('iPadMini');
      } else if (isIPadAir) {
        setTabletType('iPadAir');
      } else if (isIPadPro) {
        setTabletType('iPadPro');
      } else {
        setTabletType('generic');
      }
    } else {
      setDeviceType('desktop');
    }
  }, [isMobile, isTablet, isDesktop, isIPadMini, isIPadAir, isIPadPro]);

  // Función para manejar el cambio de filtro
  const handleFilterChange = (filterId) => {
    // Si ya está seleccionado, lo deseleccionamos
    if (selectedFilter === filterId) {
      setSelectedFilter(null);
    } else {
      setSelectedFilter(filterId);
    }
  };
  
  // Usar productos de mock si no se proporcionan
  const mockProducts = [
    { id: 1, name: 'Lorem Product 1', price: 99.99, image: 'https://via.placeholder.com/200x200/eeeeee/999999?text=Product+1' },
    { id: 2, name: 'Ipsum Product 2', price: 149.99, image: 'https://via.placeholder.com/200x200/eeeeee/999999?text=Product+2' },
    { id: 3, name: 'Dolor Product 3', price: 79.50, image: 'https://via.placeholder.com/200x200/eeeeee/999999?text=Product+3' },
    { id: 4, name: 'Sit Amet Product 4', price: 199.99, image: 'https://via.placeholder.com/200x200/eeeeee/999999?text=Product+4' },
    { id: 5, name: 'Consectetur Product 5', price: 59.99, image: 'https://via.placeholder.com/200x200/eeeeee/999999?text=Product+5' },
    { id: 6, name: 'Adipiscing Product 6', price: 129.99, image: 'https://via.placeholder.com/200x200/eeeeee/999999?text=Product+6' },
  ];
  
  const actualProducts = products || mockProducts;
  
  // Adaptar los datos de producto al formato esperado por ProductCard
  const adaptedProducts = actualProducts.map(product => ({
    id: product.id,
    name: product.name,
    price: product.price || 0,
    image: product.image
  }));
  
  // Filtrar productos si hay un filtro seleccionado
  const filteredProducts = selectedFilter 
    ? adaptedProducts.filter(product => 
        brand.categories?.find(cat => cat.id === selectedFilter)?.productIds?.includes(product.id)
      )
    : adaptedProducts;
  
  // Obtener los estilos del contenedor de card según el dispositivo actual
  const cardContainerStyles = getCardContainerStyles(deviceType);
  
  return (
    <Box sx={{ width: '100%' }}>
      <Box 
        sx={{ 
          mb: { xs: 2, sm: 3, md: 4 },
          width: '100%'
        }}
      >
        <BrandsCarousel 
          showTitle={false} 
          useAlternativeLayout={true} 
          isInBrandProductsPage={true}
          currentBrandId={brand.id}
        />
      </Box>
      
      <BrandHeader 
        brand={brand}
        onBack={onBackToBrands}
        selectedFilter={selectedFilter}
        onFilterChange={handleFilterChange}
        deviceType={deviceType}
        tabletType={tabletType}
      />
      
      <Container maxWidth="lg">
        <Box sx={gridStyles}>
          {filteredProducts.map(product => (
            <Box key={product.id} sx={cardContainerStyles}>
              <ProductCard
                product={product}
                deviceType={deviceType}
                tabletType={tabletType}
              />
            </Box>
          ))}
          
          {filteredProducts.length === 0 && (
            <Typography 
              variant="body1" 
              sx={{ 
                width: '100%', 
                textAlign: 'center', 
                py: 4, 
                color: 'text.secondary' 
              }}
            >
              {t('brands.noProductsFound')}
            </Typography>
          )}
        </Box>
      </Container>
    </Box>
  );
};

BrandProductList.propTypes = {
  brand: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
    logo: PropTypes.string,
    description: PropTypes.string,
    verified: PropTypes.bool,
    rating: PropTypes.number,
    reviewCount: PropTypes.number,
    location: PropTypes.string,
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        name: PropTypes.string.isRequired,
        productIds: PropTypes.arrayOf(
          PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        )
      })
    )
  }),
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      price: PropTypes.number
    })
  ),
  onBackToBrands: PropTypes.func
};

export default BrandProductList;
