/**
 * Mobile styles
 * Styles specific to mobile devices
 */

// Mobile-specific container styles
export const mobileContainerStyles = {
  py: 0
};

export const mobileContainerContentStyles = {
  px: 0
};

export const mobileCardsWrapperStyles = {
  gap: 2,
  px: 2
};

// Mobile-specific PromotionalCard styles
export const mobilePromotionalCardStyles = {
  flexDirection: 'column',
  borderRadius: 0
};

export const mobilePromotionalImageWrapperStyles = {
  width: '100%',
  height: 250,
  borderRadius: 0,
  m: 0,
  order: 0,
  padding: 0
};

export const mobilePromotionalContentStyles = {
  p: 0,
  order: 1,
  alignItems: 'flex-start'
};

export const mobilePromotionalTitleStyles = {
  width: '100%',
  fontSize: '36px',
  textAlign: 'left',
  height: 'auto',
  px: 2,
  pt: 2
};

export const mobilePromotionalSubtitleStyles = {
  width: '100%',
  fontSize: '20px',
  textAlign: 'left',
  height: 'auto',
  px: 2,
  py: 2
};

export const mobilePromotionalButtonWrapperStyles = {
  justifyContent: 'center',
  mt: 0,
  px: 2,
  pb: 2
};

export const mobilePromotionalButtonStyles = {
  width: '100%',
  fontSize: 12.02,
  py: 1,
  px: 4
};

// Mobile-specific OfferCard styles
export const mobileOfferCardStyles = {
  width: '140px',
  height: '190px'
};

export const mobileOfferCardMediaStyles = {
  height: '100px',
  p: 1
};

export const mobileOfferCardContentStyles = {
  p: 1.5,
  '&:last-child': {
    pb: 1.5
  }
};

export const mobileOfferCardTitleStyles = {
  mb: 0.5,
  fontSize: '0.75rem'
};

export const mobileOfferCardPriceStyles = {
  fontSize: '0.875rem'
}; 