/**
 * Estilos comunes compartidos entre todos los dispositivos
 */

// Contenedor principal del hero banner
export const containerStyles = {
  width: '100%',
  borderRadius: { xs: 0, sm: '16px', md: '24px' },
  overflow: 'hidden',
  backgroundColor: '#FFFFFF',
  position: 'relative',
  paddingBottom: { xs: '0', sm: '24px', md: '32px', lg: '40px' },
  marginTop: { xs: '60px', sm: '70px', md: '80px' },
  boxShadow: { sm: '0px 4px 20px rgba(0, 0, 0, 0.03)' },
  '& .swiper': {
    width: '100%'
  }
};

// Contenedor del carrusel
export const swiperContainerStyles = { 
  width: '100%', 
  position: 'relative',
  zIndex: 1
};

// Wrapper de contenido para cada slide
export const contentWrapperStyles = {
  display: 'flex',
  flexDirection: { xs: 'column', sm: 'row', md: 'row' },
  alignItems: { xs: 'flex-start', sm: 'center', md: 'center' },
  justifyContent: 'space-between',
  padding: { xs: 0, sm: 3, md: 4, lg: 6 },
  gap: { xs: 2, sm: 3, md: 4, lg: 6 },
  width: '100%',
  maxWidth: { md: '1280px', lg: '1440px' },
  margin: '0 auto'
};

// Estilos para la paginación del carrusel
export const paginationStyles = {
  position: 'static !important',
  marginTop: { xs: 2, sm: 3, md: 2 },
  display: 'flex',
  justifyContent: 'center',
  gap: '6px',
  '@media (min-width: 740px) and (max-width: 768px)': {
    marginTop: 2
  },
  '@media (min-width: 800px) and (max-width: 834px)': {
    marginTop: 2.5
  },
  '& .swiper-pagination-bullet': {
    width: { xs: '8px', sm: '10px', md: '8px' },
    height: { xs: '8px', sm: '10px', md: '8px' },
    backgroundColor: '#D9D9D9',
    opacity: 1,
    margin: '0 4px',
    cursor: 'pointer',
    '@media (min-width: 740px) and (max-width: 768px)': {
      width: '8px',
      height: '8px'
    },
    '@media (min-width: 800px) and (max-width: 834px)': {
      width: '9px',
      height: '9px'
    },
    '&.swiper-pagination-bullet-active': {
      backgroundColor: '#0A3954'
    }
  }
}; 