/**
 * Brands utility
 * Manages the list of brands and their translations
 */

// Base brands structure - Reordered according to requirements
export const brandKeys = [
  { id: 1, key: 'brand_tamko', image: '/images/brands/tamko_logo.webp' },
  { id: 2, key: 'brand_iko', image: '/images/brands/iko_logo.webp' },
  { id: 3, key: 'brand_gaf', image: '/images/brands/gaf_logo.webp' },
  // Renombramos la clave para que sea coherente con el nombre en inglés
  { id: 4, key: 'brand_certainteed', name: 'Certain Teed', image: '/images/brands/certainteed_logo.webp' },
  { id: 5, key: 'brand_owens', image: '/images/brands/owens_logo.webp' },
  { id: 6, key: 'brand_atlas', image: '/images/brands/atlas_logo.webp' },
];

/**
 * Get brands with translations
 * @param {Function} t - Translation function from i18next
 * @returns {Array} List of brands with translations
 */
export const getTranslatedBrands = (t) => {
  return brandKeys.map(brand => {
    // Si la marca ya tiene un nombre personalizado, lo usamos
    if (brand.name) {
      return {
        ...brand,
        alt: brand.name + ' - ' + (t('dashboard.brandsCarousel.altSuffix') || 'Materiales para techos de alta calidad')
      };
    }
    
    // Para el resto de marcas, extraemos la clave base (sin el prefijo "brand_")
    const baseKey = brand.key.replace('brand_', '');
    
    // Usamos la traducción con la clave base
    return {
      ...brand,
      name: t(`dashboard.brandsCarousel.brands.${baseKey}.name`),
      alt: t(`dashboard.brandsCarousel.brands.${baseKey}.alt`)
    };
  });
}; 