import { db } from '../../../shared/utils/firebase/firebase-config';
import { doc, getDoc } from 'firebase/firestore';

export const fetchUserNetSuiteId = async (firebaseId) => {
  try {
    const userDocRef = doc(db, 'users', firebaseId);
    const userDoc = await getDoc(userDocRef);

    if (!userDoc.exists()) {
      throw new Error('User not found in Firestore');
    }

    const userData = userDoc.data();
    return userData.netsuiteID; // Asumiendo que el campo se llama netSuiteId

  } catch (error) {
    console.error('Error fetching user from Firestore:', error);
    throw error;
  }
}; 