import React from 'react';
import { Box, Typography, Button, useTheme, useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';
import contactService from '../../../../../shared/services/contactService';
import {
  commonPromotionalCardStyles,
  commonPromotionalImageWrapperStyles,
  commonPromotionalImageStyles,
  commonPromotionalContentStyles,
  commonPromotionalTitleStyles,
  commonPromotionalSubtitleStyles,
  commonPromotionalButtonWrapperStyles,
  commonPromotionalButtonStyles,
  mobilePromotionalCardStyles,
  mobilePromotionalImageWrapperStyles,
  mobilePromotionalContentStyles,
  mobilePromotionalTitleStyles,
  mobilePromotionalSubtitleStyles,
  mobilePromotionalButtonWrapperStyles,
  mobilePromotionalButtonStyles,
  tabletPromotionalCardStyles,
  tabletPromotionalImageWrapperStyles,
  tabletPromotionalContentStyles,
  tabletPromotionalTitleStyles,
  tabletPromotionalSubtitleStyles,
  tabletPromotionalButtonWrapperStyles,
  tabletPromotionalButtonStyles,
  desktopPromotionalCardStyles,
  desktopPromotionalImageWrapperStyles,
  desktopPromotionalContentStyles,
  desktopPromotionalTitleStyles,
  desktopPromotionalSubtitleStyles,
  desktopPromotionalButtonWrapperStyles,
  desktopPromotionalButtonStyles,
  // Para compatibilidad con versiones anteriores
  promotionalCardStyles,
  promotionalImageWrapperStyles,
  promotionalImageStyles,
  promotionalContentStyles,
  promotionalTitleStyles,
  promotionalSubtitleStyles,
  promotionalButtonWrapperStyles,
  promotionalButtonStyles
} from '../styles';

/**
 * PromotionalCard Component
 * 
 * Displays a promotional card with an image, title, subtitle, and call-to-action button.
 * Responsive design adapts to different screen sizes.
 * @param {Object} props - Component props
 * @param {string} props.cardPromoTitleOne - Primary title text
 * @param {string} props.cardPromoTitleTwo - Secondary title text
 * @param {string} props.imageCard - URL of the promotional image
 * @param {string} [props.CTA] - Call to action message for WhatsApp (used on mobile)
 * @param {string} [props.buttonText] - Text to display on the button
 */
export const PromotionalCard = ({
  cardPromoTitleOne,
  cardPromoTitleTwo,
  imageCard,
  CTA,
  buttonText
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  const handleGetDiscount = () => {
    // Si estamos en móvil y tenemos un mensaje CTA, enviar por WhatsApp
    if (isMobile && CTA) {
      contactService.openWhatsApp(CTA);
    } else {
      console.log("get discount button tapped");
      // Aquí podrías redirigir a una página de promociones o mostrar un modal
    }
  };

  return (
    <Box sx={promotionalCardStyles}>
      <Box sx={promotionalImageWrapperStyles(theme)}>
        <Box
          component="img"
          src={imageCard}
          alt={cardPromoTitleOne}
          sx={promotionalImageStyles}
        />
      </Box>

      <Box sx={promotionalContentStyles}>
        <Typography sx={promotionalTitleStyles}>
          {cardPromoTitleOne}
        </Typography>
        <Typography sx={promotionalSubtitleStyles}>
          {cardPromoTitleTwo}
        </Typography>
        <Box sx={promotionalButtonWrapperStyles}>
          <Button
            variant="contained"
            onClick={handleGetDiscount}
            sx={{
              ...promotionalButtonStyles,
              transition: 'opacity 0.2s ease-in-out',
              '&:hover': {
                opacity: 0.9
              }
            }}
            fullWidth={true}
          >
            {buttonText || 'Shop Now'}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

PromotionalCard.propTypes = {
  cardPromoTitleOne: PropTypes.string.isRequired,
  cardPromoTitleTwo: PropTypes.string.isRequired,
  imageCard: PropTypes.string.isRequired,
  CTA: PropTypes.string,
  buttonText: PropTypes.string
};

PromotionalCard.defaultProps = {
  buttonText: 'Shop Now',
  CTA: ''
}; 