import React from 'react';
import { Box, Container, useTheme, useMediaQuery } from '@mui/material';
import { CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';
import { OfferCard } from './components/OfferCard';
import { PromotionalCard } from './components/PromotionalCard';
import {
  containerStyles,
  containerContentStyles,
  cardsContainerStyles,
  cardsWrapperStyles,
  // Estilos específicos por dispositivo
  mobileContainerStyles,
  mobileContainerContentStyles,
  mobileCardsWrapperStyles,
  tabletContainerStyles,
  tabletContainerContentStyles,
  tabletCardsWrapperStyles,
  desktopContainerStyles,
  desktopContainerContentStyles,
  desktopCardsWrapperStyles
} from './styles';

/**
 * SpecialPromotionLayout Component
 * 
 * A layout component that displays a promotional card and a horizontal scrollable list of offer cards.
 * Responsive design adapts to different screen sizes.
 */
const SpecialPromotionLayout = ({ offerCards = [], promotionalCard }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const renderOfferCards = () => {
    return (
      <Box sx={cardsContainerStyles}>
        <Box sx={cardsWrapperStyles}>
          {offerCards.map((card) => (
            <CSSTransition key={card.id} timeout={300} classNames="fade">
              <OfferCard {...card} />
            </CSSTransition>
          ))}
        </Box>
      </Box>
    );
  };

  return (
    <Box sx={containerStyles}>
      <Container 
        maxWidth="lg" 
        sx={containerContentStyles}
        disableGutters={isMobile}
      >
        {promotionalCard && (
          <CSSTransition in={true} appear={true} timeout={300} classNames="fade">
            <Box sx={{ width: '100%' }}>
              <PromotionalCard {...promotionalCard} />
            </Box>
          </CSSTransition>
        )}
        {offerCards.length > 0 && renderOfferCards()}
      </Container>
    </Box>
  );
};

SpecialPromotionLayout.propTypes = {
  offerCards: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      cardTitleOne: PropTypes.string.isRequired,
      cardTitleTwo: PropTypes.string,
      imageCard: PropTypes.string.isRequired,
      CTA: PropTypes.string
    })
  ),
  promotionalCard: PropTypes.shape({
    cardPromoTitleOne: PropTypes.string.isRequired,
    cardPromoTitleTwo: PropTypes.string.isRequired,
    imageCard: PropTypes.string.isRequired,
    CTA: PropTypes.string,
    buttonText: PropTypes.string
  })
};

export default SpecialPromotionLayout; 