import React from 'react';
import { Box, Container, Typography, Button, Grid, useTheme, useMediaQuery } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { useTranslation } from 'react-i18next';
import contactService from '../../../../shared/services/contactService';
import {
  containerStyles,
  contentWrapperStyles,
  titleStyles,
  subtitleStyles,
  benefitItemStyles,
  benefitTitleStyles,
  benefitDescriptionStyles,
  buttonStyles,
  imageContainerStyles,
  checkIconStyles
} from './styles';

const ValuePropositionLayout = () => {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  // Define benefits using translations
  const benefits = [
    {
      id: 'fast-delivery',
      title: t('dashboard.valueProposition.benefits.fastDelivery.title'),
      description: t('dashboard.valueProposition.benefits.fastDelivery.description'),
      seoTitle: t('dashboard.valueProposition.benefits.fastDelivery.seoTitle'),
      seoDescription: t('dashboard.valueProposition.benefits.fastDelivery.seoDescription')
    },
    {
      id: 'personalized-support',
      title: t('dashboard.valueProposition.benefits.personalizedSupport.title'),
      description: t('dashboard.valueProposition.benefits.personalizedSupport.description'),
      seoTitle: t('dashboard.valueProposition.benefits.personalizedSupport.seoTitle'),
      seoDescription: t('dashboard.valueProposition.benefits.personalizedSupport.seoDescription')
    },
    {
      id: 'quality-products',
      title: t('dashboard.valueProposition.benefits.qualityProducts.title'),
      description: t('dashboard.valueProposition.benefits.qualityProducts.description'),
      seoTitle: t('dashboard.valueProposition.benefits.qualityProducts.seoTitle'),
      seoDescription: t('dashboard.valueProposition.benefits.qualityProducts.seoDescription')
    },
    {
      id: 'competitive-pricing',
      title: t('dashboard.valueProposition.benefits.competitivePricing.title'),
      description: t('dashboard.valueProposition.benefits.competitivePricing.description'),
      seoTitle: t('dashboard.valueProposition.benefits.competitivePricing.seoTitle'),
      seoDescription: t('dashboard.valueProposition.benefits.competitivePricing.seoDescription')
    }
  ];
  
  // Predefined WhatsApp messages
  const whatsappMessages = {
    en: "Hi, I'm interested in your roofing solutions. I saw you offer fast delivery, personalized support, top-quality products, and competitive pricing. Could you tell me more about how SYL Roofing Supply can help with my project? Thanks!",
    es: "Hola, estoy interesado en sus soluciones para techos. Vi que ofrecen entrega rápida, soporte personalizado, productos de alta calidad y precios competitivos. ¿Podrían contarme más sobre cómo SYL Roofing Supply puede ayudar con mi proyecto? ¡Gracias!"
  };
  
  const handleGetQuote = () => {
    if (isMobile) {
      // Determinar qué mensaje usar según el idioma actual
      const currentLanguage = i18n.language.substring(0, 2).toLowerCase();
      const message = whatsappMessages[currentLanguage] || whatsappMessages.en;
      
      // Abrir WhatsApp con el mensaje predefinido
      contactService.openWhatsApp(message);
    } else {
      console.log('CTA clicked: Get Your Free Quote from Value Proposition Section');
      // Aquí podrías añadir otra funcionalidad para desktop/tablet
      // Como abrir un modal de contacto, scroll a una sección de formulario, etc.
    }
  };

  const buttonText = isMobile 
    ? t('dashboard.valueProposition.buttonTextMobile') 
    : t('dashboard.valueProposition.buttonText');

  return (
    <Box sx={containerStyles}>
      <Container maxWidth="lg">
        <Box sx={contentWrapperStyles}>
          {/* Left Content */}
          <Box sx={{ 
            flex: 1, 
            pr: { md: 4 }, 
            width: { xs: '100%', sm: '100%', md: 'auto' },
            px: { xs: 0, sm: 2, md: 0 },
            py: { xs: 0, sm: 2, md: 0 }
          }}>
            {/* Main UX Title */}
            <Typography variant="h2" sx={titleStyles}>
              {t('dashboard.valueProposition.title')}
            </Typography>
            
            {/* Hidden SEO Title - accessible for screen readers */}
            <Typography 
              sx={{ 
                position: 'absolute', 
                width: '1px', 
                height: '1px', 
                padding: 0, 
                margin: '-1px', 
                overflow: 'hidden', 
                clip: 'rect(0, 0, 0, 0)', 
                whiteSpace: 'nowrap', 
                borderWidth: 0 
              }}
            >
              {t('dashboard.valueProposition.seoTitle')}
            </Typography>
            
            {/* Hidden SEO Description - accessible for screen readers */}
            <Typography 
              sx={{ 
                position: 'absolute', 
                width: '1px', 
                height: '1px', 
                padding: 0, 
                margin: '-1px', 
                overflow: 'hidden', 
                clip: 'rect(0, 0, 0, 0)', 
                whiteSpace: 'nowrap', 
                borderWidth: 0 
              }}
            >
              {t('dashboard.valueProposition.seoDescription')}
            </Typography>
            
            <Typography sx={subtitleStyles}>
              {t('dashboard.valueProposition.subtitle')}
            </Typography>

            <Grid container spacing={isTablet ? 4 : 3} sx={{ mt: isTablet ? 5 : 4 }}>
              {benefits.map((benefit) => (
                <Grid item xs={12} sm={6} key={benefit.id}>
                  <Box sx={benefitItemStyles}>
                    <CheckCircleIcon sx={checkIconStyles} />
                    <Box>
                      {/* Visible benefit title */}
                      <Typography sx={benefitTitleStyles}>
                        {benefit.title}
                      </Typography>
                      
                      {/* Visible benefit description */}
                      <Typography sx={benefitDescriptionStyles}>
                        {benefit.description}
                      </Typography>
                      
                      {/* Hidden SEO optimized title for this benefit - accessible for screen readers */}
                      <Typography 
                        sx={{ 
                          position: 'absolute', 
                          width: '1px', 
                          height: '1px', 
                          padding: 0, 
                          margin: '-1px', 
                          overflow: 'hidden', 
                          clip: 'rect(0, 0, 0, 0)', 
                          whiteSpace: 'nowrap', 
                          borderWidth: 0 
                        }}
                      >
                        {benefit.seoTitle}
                      </Typography>
                      
                      {/* Hidden SEO optimized description for this benefit - accessible for screen readers */}
                      <Typography 
                        sx={{ 
                          position: 'absolute', 
                          width: '1px', 
                          height: '1px', 
                          padding: 0, 
                          margin: '-1px', 
                          overflow: 'hidden', 
                          clip: 'rect(0, 0, 0, 0)', 
                          whiteSpace: 'nowrap', 
                          borderWidth: 0 
                        }}
                      >
                        {benefit.seoDescription}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>

            <Box sx={{ 
              width: '100%', 
              display: 'flex', 
              justifyContent: { xs: 'center', sm: 'center', md: 'flex-start' } 
            }}>
              <Button
                variant="contained"
                endIcon={isMobile ? <WhatsAppIcon /> : <ArrowForwardIcon />}
                onClick={handleGetQuote}
                sx={buttonStyles}
                fullWidth={isTablet}
              >
                {buttonText}
              </Button>
            </Box>
          </Box>

          {/* Right Content - Images */}
          <Box sx={imageContainerStyles}>
            <Box
              component="img"
              src="/images/value-proposition/value-proposition-hero-image.webp"
              alt={t('dashboard.valueProposition.imageAlt')}
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
                maxHeight: isTablet ? '550px' : 'none'
              }}
            />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default ValuePropositionLayout; 