/**
 * BrandHeader Component
 * Componente principal para mostrar información de la marca y filtros rápidos
 */

import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, Container } from '@mui/material';
import { useTranslation } from 'react-i18next';

// Componentes
import BrandInfo from './components/BrandInfo';
import QuickFilters from './components/QuickFilters';

// Estilos
import { 
  containerStyles, 
  mobileContainerStyles, 
  tabletContainerStyles, 
  desktopContainerStyles 
} from './styles/index';

// Utilidades
import { extractQuickFilters, formatBrandData } from './utils';

const BrandHeader = ({ 
  brand, 
  onBack, 
  filters, 
  selectedFilter, 
  onFilterChange, 
  deviceType, 
  tabletType 
}) => {
  const { t } = useTranslation();
  
  // Formatear datos de la marca
  const formattedBrand = useMemo(() => formatBrandData(brand), [brand]);
  
  // Generar filtros si no se proporcionan explícitamente
  const availableFilters = useMemo(() => 
    filters || extractQuickFilters(brand), 
    [filters, brand]
  );
  
  // Determinar estilos del contenedor según el dispositivo
  const containerResponsiveStyles = useMemo(() => {
    const baseStyles = containerStyles;
    
    if (deviceType === 'mobile') {
      return { ...baseStyles, ...mobileContainerStyles };
    } else if (deviceType === 'tablet') {
      return { ...baseStyles, ...tabletContainerStyles };
    } else {
      return { ...baseStyles, ...desktopContainerStyles };
    }
  }, [deviceType]);
  
  return (
    <Container maxWidth="lg">
      <Box sx={containerResponsiveStyles}>
        <BrandInfo 
          brand={formattedBrand} 
          deviceType={deviceType} 
          tabletType={tabletType} 
        />
        
        {availableFilters.length > 0 && (
          <QuickFilters 
            filters={availableFilters}
            selectedFilter={selectedFilter}
            onFilterChange={onFilterChange}
            deviceType={deviceType}
          />
        )}
      </Box>
    </Container>
  );
};

BrandHeader.propTypes = {
  // Datos de la marca
  brand: PropTypes.shape({
    name: PropTypes.string.isRequired,
    logo: PropTypes.string,
    image: PropTypes.string,
    description: PropTypes.string,
    verified: PropTypes.bool,
    rating: PropTypes.number,
    reviewCount: PropTypes.number,
    location: PropTypes.string,
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        name: PropTypes.string.isRequired
      })
    )
  }).isRequired,
  
  // Navegación
  onBack: PropTypes.func.isRequired,
  
  // Filtros
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired
    })
  ),
  selectedFilter: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onFilterChange: PropTypes.func,
  
  // Responsividad
  deviceType: PropTypes.oneOf(['mobile', 'tablet', 'desktop']).isRequired,
  tabletType: PropTypes.oneOf(['generic', 'iPadMini', 'iPadAir', 'iPadPro'])
};

BrandHeader.defaultProps = {
  tabletType: 'generic',
  onFilterChange: () => {},
  filters: null,
  selectedFilter: null
};

export default BrandHeader;
