import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Typography,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  IconButton
} from '@mui/material';
import { StyledTextField, ActionButton, StyledTypography } from '../../styles';
import LoginHouseIllustration from '../../../../../../assets/login_house.webp';
import { subscribeToNewsletter, registerUser } from '../../../../services/authService';
import { useAuth } from '../../../../../../shared/context/AuthProvider';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const NetsuiteUserContent = ({ email, onClose }) => {
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const { login } = useAuth();
  const passwordInputRef = useRef(null);
  
  // Enfocar el campo de contraseña al montar el componente
  useEffect(() => {
    if (passwordInputRef.current) {
      passwordInputRef.current.focus();
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!password) {
      setError('Please enter your password');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      // Attempt to login with the provided credentials
      await login(email, password);
      onClose(); // Close the modal on successful login
    } catch (err) {
      setError(err.message || 'Failed to login. Please check your credentials.');
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    if (error) setError(null);
  };

  const handleTogglePassword = () => {
    setShowPassword(prevState => !prevState);
  };

  return (
    <Box sx={{ p: 3, maxWidth: 480 }}>
      <Box sx={{ mb: 3, textAlign: 'center' }}>
        <img 
          src={LoginHouseIllustration} 
          alt="Login" 
          style={{ maxWidth: '100%', height: 'auto', maxHeight: 200 }}
        />
      </Box>

      <StyledTypography variant="h5" sx={{ mb: 1, textAlign: 'center' }}>
        Welcome Back!
      </StyledTypography>
      
      <Typography variant="body1" sx={{ mb: 3, textAlign: 'center' }}>
        Please enter your password to continue
      </Typography>

      <Box component="form" onSubmit={handleSubmit}>
        <StyledTextField
          fullWidth
          type={showPassword ? 'text' : 'password'}
          label="Password"
          variant="outlined"
          value={password}
          onChange={handlePasswordChange}
          error={!!error}
          helperText={error}
          inputRef={passwordInputRef}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleTogglePassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{ mb: 2 }}
        />

        <ActionButton
          type="submit"
          fullWidth
          variant="contained"
          disabled={loading}
          sx={{ mt: 2 }}
        >
          {loading ? <CircularProgress size={24} /> : 'Login'}
        </ActionButton>
      </Box>
    </Box>
  );
};

export default NetsuiteUserContent; 