/**
 * Tablet styles for the Header component
 * These styles are for tablet devices and medium-sized screens
 */

export const tabletAppBarStyles = {
  // Si el banner está visible, considerar la altura del banner en tablet (75px)
  // Ver el componente NotificationBanner
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.12)', // Sombra sutil
};

export const tabletToolbarStyles = {
  padding: '0 16px !important',
};

export const tabletLogoWrapperStyles = {
  '& img': {
    width: '180px',
    height: '60px',
  },
};

export const tabletDrawerStyles = {
  width: '400px', // Ancho fijo para tablet
};

export const tabletDevButtonStyles = {
  marginRight: 1.5,
  fontSize: '0.8rem',
}; 