import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getImageForLanguage, getTranslatedContent } from '../utils/contentUtils';
import {
  desktopContainerStyles,
  desktopLeftContentStyles,
  desktopSubtitleWrapperStyles,
  desktopSubtitleStyles,
  desktopTitleStyles,
  desktopRightContentStyles,
  desktopImageStyles
} from '../styles/desktop';

/**
 * Componente para mostrar el banner en vista desktop
 * 
 * @param {Object} props - Propiedades del componente
 * @param {Object} props.banner - Datos del banner a mostrar
 * @param {number} props.index - Índice del banner en el carrusel
 * @returns {React.ReactElement} Componente DesktopView
 */
const DesktopView = ({ banner, index }) => {
  const { t, i18n } = useTranslation();
  const isMobile = false; // Esta vista nunca es móvil
  const translatedContent = getTranslatedContent(t, banner, index, isMobile);
  
  return (
    <Box sx={desktopContainerStyles}>
      {/* Left Content */}
      <Box sx={desktopLeftContentStyles}>
        <Box sx={desktopSubtitleWrapperStyles}>
          <Typography sx={desktopSubtitleStyles}>
            {translatedContent.subtitle}
          </Typography>
        </Box>
        
        <Typography sx={desktopTitleStyles}>
          {translatedContent.title}
        </Typography>
      </Box>

      {/* Right Content */}
      <Box sx={desktopRightContentStyles}>
        <Box
          component="img"
          src={getImageForLanguage(banner, i18n.language)}
          alt={translatedContent.imageAlt}
          sx={desktopImageStyles}
        />
      </Box>
    </Box>
  );
};

DesktopView.propTypes = {
  banner: PropTypes.shape({
    id: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    imageUrlEn: PropTypes.string,
    imageUrlEs: PropTypes.string
  }).isRequired,
  index: PropTypes.number.isRequired
};

export default DesktopView; 