import React from 'react';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  cardContainerStyles,
  reviewTextStyles,
  authorInfoStyles,
  authorNameStyles,
  locationStyles
} from './styles';

export const ReviewCard = ({ text, author, location }) => {
  const { t } = useTranslation();
  
  return (
    <Box 
      sx={cardContainerStyles} 
      role="article"
      aria-label={t('dashboard.reviewsLayout.reviewsAlt')}
    >
      <Typography sx={reviewTextStyles}>
        {text}
      </Typography>
      <Box sx={authorInfoStyles}>
        <Typography sx={authorNameStyles}>
          {author}
        </Typography>
        <Typography sx={locationStyles}>
          {location}
        </Typography>
      </Box>
    </Box>
  );
};

ReviewCard.propTypes = {
  text: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired
};

export default ReviewCard; 