/**
 * Common styles
 * Shared styles across all device types
 */

// Common styles for the root container
export const commonContainerStyles = {
  overflow: 'hidden',
  width: '100%',
  boxSizing: 'border-box'
};

// Common styles for the carousel container
export const commonCarouselContainerStyles = {
  width: '100%',
  boxSizing: 'border-box'
};

// Common styles for the brand cards
export const commonBrandCardStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#FFFFFF',
  borderRadius: '8px',
  padding: '12px',
  minWidth: '120px',
  maxWidth: '160px',
  height: '100px',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
  margin: '0 8px',
  boxSizing: 'border-box',
};

// Common styles for the brand image container
export const commonBrandImageContainerStyles = {
  width: '100%',
  height: '60px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: '8px',
};

// Common styles for the brand image
export const commonBrandImageStyles = {
  maxWidth: '100%',
  maxHeight: '100%',
  objectFit: 'contain',
};

// Common styles for the brand name container
export const commonBrandNameStyles = {
  width: '100%',
  textAlign: 'center',
};

// Common styles for the brand name typography
export const commonBrandTypographyStyles = {
  fontSize: '0.875rem',
  fontWeight: 500,
  color: '#1D232E',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 1,
  WebkitBoxOrient: 'vertical',
};

// Common styles for the title
export const commonTitleStyles = {
  display: 'none',
  visibility: 'hidden',
  height: 0,
  margin: 0,
  padding: 0
};

// Common styles for the hidden SEO description
export const commonSeoDescriptionStyles = {
  position: 'absolute',
  width: '1px',
  height: '1px',
  padding: 0,
  margin: '-1px',
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap',
  borderWidth: 0
}; 