import React, { useEffect, useState } from "react";
import { 
  Box, 
  CssBaseline, 
  List, 
  ListItem, 
  ListItemText, 
  ThemeProvider, 
  Typography, 
  createTheme, 
  Container, 
  Card, 
  CardContent, 
  CircularProgress 
} from "@mui/material";
import { useCustomerDetailContext } from "../../../shared/context/CustomerDetailContext";
import { useAuth } from "../../../shared/context/AuthProvider";
import { useNavigate } from "react-router-dom";

const CustomerDetail = () => {
  const { userDetail, loadDetails } = useCustomerDetailContext();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log('🔒 Verificando autenticación en CustomerDetail');
    
    // Detectar si es un dispositivo móvil
    const isMobile = window.innerWidth < 768;
    console.log('📱 ¿Es dispositivo móvil?', isMobile);
    
    if (!user) {
      console.log('❌ Usuario no autenticado, redirigiendo...');
      
      // En dispositivos móviles, limpiar cualquier dato residual
      if (isMobile) {
        console.log('📱 Limpiando datos residuales en dispositivo móvil');
        
        // Limpiar storage
        localStorage.clear();
        sessionStorage.clear();
        
        // Limpiar caché de React Query si está disponible
        if (window.queryClient) {
          try {
            window.queryClient.clear();
            console.log('✅ Caché de React Query limpiada');
          } catch (cacheError) {
            console.error('Error al limpiar caché:', cacheError);
          }
        }
        
        // Añadir un timestamp para evitar caché del navegador
        const timestamp = new Date().getTime();
        
        // Redirigir con reemplazo para evitar volver atrás
        setTimeout(() => {
          window.location.href = `/?not_authenticated=true&t=${timestamp}`;
        }, 100);
      } else {
        // En desktop, simplemente navegar
        navigate('/', { replace: true });
      }
      return;
    }
    
    // Si el usuario está autenticado, cargar los datos
    const fetchCustomerData = async () => {
      try {
        console.log('�� Cargando datos del cliente...');
        loadDetails();
        setLoading(false);
      } catch (error) {
        console.error('❌ Error al cargar datos del cliente:', error);
        setLoading(false);
      }
    };
    
    fetchCustomerData();
  }, [loadDetails, user, navigate]);

  if (!user || loading) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '50vh' }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          {!user ? 'Verificando autenticación...' : 'Cargando datos del cliente...'}
        </Typography>
        <CircularProgress />
      </Box>
    );
  }

  if (!userDetail) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
        <Typography>Loading...</Typography>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ThemeProvider theme={createTheme()}>
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}>
          <Card sx={{ maxWidth: '100%', mx: 'auto', my: 2, boxShadow: 3 }}>
            <CardContent>
              <Typography gutterBottom variant="h4" component="div" textAlign="center">
                Customer Detail
              </Typography>
              <List>
                <ListItem>
                  <ListItemText primary="Company Name" secondary={userDetail.companyName} />
                </ListItem>
                {userDetail.direction && (
                  <ListItem>
                    <ListItemText primary="Direction" secondary={userDetail.direction} />
                  </ListItem>
                )}
                <ListItem>
                  <ListItemText primary="Phone" secondary={userDetail.phone} />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Email" secondary={userDetail.email} />
                </ListItem>
              </List>
            </CardContent>
          </Card>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default CustomerDetail; 