/**
 * Desktop styles
 * Styles specific to desktop devices
 */

// Desktop-specific container styles
export const desktopContainerStyles = {
  py: 0
};

export const desktopContainerContentStyles = {
  px: 0
};

export const desktopCardsWrapperStyles = {
  gap: 3,
  px: 3
};

// Desktop-specific PromotionalCard styles
export const desktopPromotionalCardStyles = {
  flexDirection: 'row',
  borderRadius: 2
};

export const desktopPromotionalImageWrapperStyles = {
  width: '40%',
  height: 380,
  borderRadius: 3,
  m: 2,
  order: 'initial',
  padding: 0
};

export const desktopPromotionalContentStyles = {
  p: 2,
  ml: 1,
  order: 'initial',
  alignItems: 'flex-start',
  width: '50%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center'
};

export const desktopPromotionalTitleStyles = {
  width: '100%',
  fontSize: '48px',
  textAlign: 'left',
  height: 'auto',
  px: 0,
  pt: 0,
  mb: 2
};

export const desktopPromotionalSubtitleStyles = {
  width: '100%',
  fontSize: '24px',
  textAlign: 'left',
  height: 'auto',
  px: 0,
  py: 0,
  mb: 2
};

export const desktopPromotionalButtonWrapperStyles = {
  justifyContent: 'flex-start',
  mt: 1,
  px: 0,
  pb: 0
};

export const desktopPromotionalButtonStyles = {
  width: '250px',
  fontSize: 12.02,
  py: 1,
  px: 4
};

// Desktop-specific OfferCard styles
export const desktopOfferCardStyles = {
  width: '220px',
  height: '320px'
};

export const desktopOfferCardMediaStyles = {
  height: '180px',
  p: 2
};

export const desktopOfferCardContentStyles = {
  p: 2,
  '&:last-child': {
    pb: 2
  }
};

export const desktopOfferCardTitleStyles = {
  mb: 1,
  fontSize: '1rem'
};

export const desktopOfferCardPriceStyles = {
  fontSize: '1.1rem'
}; 