import React from 'react';
import { Typography, Box, Grid, Container } from '@mui/material';
import EmailInput from '../../../components/UI/EmailInput';
import { newsletterData } from '../utils/footerData';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

// Hidden SEO styles
const SeoText = styled(Typography)({
  position: 'absolute',
  width: '1px',
  height: '1px',
  padding: '0',
  margin: '-1px',
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap',
  borderWidth: '0'
});

export const NewsletterMobile = () => {
  const { t } = useTranslation();
  
  return (
    <Grid item xs={12} spacing={1} sx={{ mb: 3 }}>
      <Box>
        <Typography 
          sx={{ 
            color: '#E7ECF3', 
            fontSize: 18, 
            fontFamily: 'Open Sans', 
            fontWeight: '700', 
            wordWrap: 'break-word',
            mb: 1,
            textAlign: 'left'
          }}
        >
          {t(newsletterData.title)}
        </Typography>
        
        {/* Hidden SEO title and description */}
        <SeoText variant="h2">{t(newsletterData.seoTitle)}</SeoText>
        <SeoText component="p">{t(newsletterData.seoDescription)}</SeoText>
      </Box>
      <Typography 
        sx={{ 
          color: '#A7B0C0', 
          fontSize: 14, 
          fontFamily: 'Open Sans', 
          fontWeight: '400', 
          lineHeight: '21px', 
          wordWrap: 'break-word',
          mb: 2,
          textAlign: 'left'
        }}
      >
        {t(newsletterData.subtitle)}
      </Typography>
      
      {/* Hidden SEO for subtitle */}
      <SeoText variant="h3">{t(newsletterData.subtitleSeo)}</SeoText>
      <SeoText component="p">{t(newsletterData.subtitleSeoDescription)}</SeoText>
      
      <Box sx={{ display: 'flex', mt: 1, mb: 2, width: '100%' }}>
        <EmailInput placeholder={t(newsletterData.emailPlaceholder)} />
        
        {/* Hidden SEO for email field */}
        <SeoText variant="label">{t(newsletterData.emailPlaceholderSeo)}</SeoText>
        <SeoText component="p">{t(newsletterData.emailPlaceholderSeoDescription)}</SeoText>
      </Box>
    </Grid>
  );
};

export const NewsletterTablet = () => {
  const { t } = useTranslation();
  
  return (
    <Container maxWidth="sm" spacing={1}>
      <Box 
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center'
        }}
      >
        <Typography 
          variant="h3extrabold" 
          gutterBottom
        >
          {t(newsletterData.title)}
        </Typography>
        
        {/* Hidden SEO title and description */}
        <SeoText variant="h2">{t(newsletterData.seoTitle)}</SeoText>
        <SeoText component="p">{t(newsletterData.seoDescription)}</SeoText>
      </Box>
      <Box 
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center'
        }}
      >
        <Typography variant="h5semiBold" gutterBottom>
          {t(newsletterData.subtitle)}
        </Typography>
        
        {/* Hidden SEO for subtitle */}
        <SeoText variant="h3">{t(newsletterData.subtitleSeo)}</SeoText>
        <SeoText component="p">{t(newsletterData.subtitleSeoDescription)}</SeoText>
      </Box>
      <Box sx={{ display: 'flex', mt: 2, mb: 2 }}>
        <EmailInput placeholder={t(newsletterData.emailPlaceholder)} />
        
        {/* Hidden SEO for email field */}
        <SeoText variant="label">{t(newsletterData.emailPlaceholderSeo)}</SeoText>
        <SeoText component="p">{t(newsletterData.emailPlaceholderSeoDescription)}</SeoText>
      </Box>
    </Container>
  );
};

export const NewsletterDesktop = () => {
  const { t } = useTranslation();
  
  return (
    <Grid item xs={12} md={6} spacing={4}>
      <Box>
        <Typography variant="h3extrabold" gutterBottom>
          {t(newsletterData.title)}
        </Typography>
        
        {/* Hidden SEO title and description */}
        <SeoText variant="h2">{t(newsletterData.seoTitle)}</SeoText>
        <SeoText component="p">{t(newsletterData.seoDescription)}</SeoText>
      </Box>
      <Typography variant="h5semiBold" gutterBottom>
        {t(newsletterData.subtitle)}
      </Typography>
      
      {/* Hidden SEO for subtitle */}
      <SeoText variant="h3">{t(newsletterData.subtitleSeo)}</SeoText>
      <SeoText component="p">{t(newsletterData.subtitleSeoDescription)}</SeoText>
      
      <Box sx={{ display: 'flex', mt: 2 }}>
        <EmailInput placeholder={t(newsletterData.emailPlaceholder)} />
        
        {/* Hidden SEO for email field */}
        <SeoText variant="label">{t(newsletterData.emailPlaceholderSeo)}</SeoText>
        <SeoText component="p">{t(newsletterData.emailPlaceholderSeoDescription)}</SeoText>
      </Box>
    </Grid>
  );
}; 