import React from 'react';
import { AppBar, Toolbar, Container, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import LogoImage from '../../../assets/dashboard_logo.svg';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.secondary[700],
  color: theme.palette.text[200],
  height: '80px',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  zIndex: 1100,
}));

const StyledToolbar = styled(Toolbar)({
  height: '100%',
  minHeight: '80px !important',
  padding: '0 !important',
  justifyContent: 'center', // Center the logo
});

const LogoWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  '& img': {
    width: '200px',
    height: '70px',
    objectFit: 'contain',
  },
});

const ReviewHeader = () => {
  const { t } = useTranslation();

  return (
    <StyledAppBar position="sticky">
      <Container maxWidth="lg" sx={{ height: '100%' }}>
        <StyledToolbar>
          <Box>
            <LogoWrapper>
              <img 
                src={LogoImage} 
                alt={t('a11y.companyLogo')} 
                style={{ width: '200px', height: '70px' }}
              />
            </LogoWrapper>
          </Box>
        </StyledToolbar>
      </Container>
    </StyledAppBar>
  );
};

export default ReviewHeader; 