/**
 * DesktopCarousel component
 * Desktop-specific implementation of the CategoriesCarousel
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Container } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CategoryCard from './CategoryCard';
import {
  categoriesContainerStyles,
  desktopCategoriesContainerStyles,
  desktopTitleStyles,
  desktopCarouselContainerStyles,
  desktopCarouselContentStyles,
  visuallyHiddenStyles
} from '../styles';

const DesktopCarousel = ({ categories }) => {
  const { t } = useTranslation();
  
  return (
    <Box sx={{ ...categoriesContainerStyles, ...desktopCategoriesContainerStyles }}>
      <Container maxWidth={false} disableGutters={true} sx={{ px: 2, width: '100%', maxWidth: '100%' }}>
        {/* Title (visible only on desktop) */}
        <Typography variant="h2" sx={desktopTitleStyles}>
          {t('dashboard.categoriesCarousel.title')}
        </Typography>
        
        {/* SEO description (visually hidden) */}
        <Typography sx={visuallyHiddenStyles}>
          {t('dashboard.categoriesCarousel.description')}
        </Typography>
        
        <Box sx={desktopCarouselContainerStyles}>
          <Box sx={desktopCarouselContentStyles}>
            {categories.map((category, index) => (
              <CategoryCard
                key={category.id}
                category={category}
                deviceType="desktop"
                isFirstItem={index === 0}
              />
            ))}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

DesktopCarousel.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      key: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired
    })
  ).isRequired
};

export default DesktopCarousel; 