import React from 'react';
import { Box, Container, Grid, Typography, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { DarkBoxTablet } from './StyledComponents';
import { QuickLinksTablet } from './QuickLinks';
import { HoursTablet } from './HoursOfOperation';
import { ContactTablet } from './ContactInfo';
import { AppStoreLogos, SocialMediaIcons } from './Logos';
import { CopyrightSection } from './Copyright';
import { NewsletterTablet } from './Newsletter';
import { appPromoText } from '../utils/footerData';

// Componente para textos SEO ocultos pero accesibles para lectores de pantalla
const SeoTextComponent = styled.span`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
`;

const TabletFooterContent = () => {
  const { t } = useTranslation();
  
  return (
    <Box>
      <Box sx={{ 
        bgcolor: 'text.200',
        display: 'flex', 
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        height: '200px'
      }}>
        <Container maxWidth="md">
          <Typography
            variant="h4semiBold"
            gutterBottom
            sx={{ 
              textAlign: 'center'
            }}
          >
            {t(appPromoText.title)}
            <SeoTextComponent>{t(appPromoText.seoTitle)}</SeoTextComponent>
            <SeoTextComponent>{t(appPromoText.seoDescription)}</SeoTextComponent>
          </Typography>
          <AppStoreLogos />
          <SocialMediaIcons spacing={2} color="primary" iconSize={26} />
        </Container>
      </Box>
      <DarkBoxTablet>
        <Container maxWidth="lg" sx={{ padding: 0 }}>
          <NewsletterTablet />
          <Grid container spacing={1} mt={5}>
            <QuickLinksTablet />
            <HoursTablet />
            <ContactTablet />
          </Grid>
          <CopyrightSection />
        </Container>
      </DarkBoxTablet>
    </Box>
  );
};

export default TabletFooterContent; 