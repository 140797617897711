/**
 * Estilos específicos para vista desktop
 */

// Contenedor principal para desktop
export const desktopContainerStyles = { 
  display: { xs: 'none', sm: 'none', md: 'flex' },
  flexDirection: 'row',
  width: '100%',
  gap: 6
};

// Contenedor izquierdo de contenido
export const desktopLeftContentStyles = {
  flex: '0.45',
  paddingTop: '2rem',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: { md: 3 }
};

// Wrapper del subtítulo
export const desktopSubtitleWrapperStyles = {
  borderLeft: '4px solid #FF5F25',
  padding: '0px 15px',
  marginBottom: '1.5rem',
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  boxSizing: 'border-box'
};

// Estilos del subtítulo
export const desktopSubtitleStyles = {
  fontSize: '1.25rem',
  lineHeight: 1.4,
  fontWeight: 600,
  color: '#0A3954',
  fontFamily: 'Open Sans',
  width: 'auto',
  maxWidth: '100%'
};

// Estilos del título
export const desktopTitleStyles = {
  fontSize: '2.5rem',
  fontWeight: 800,
  lineHeight: 1.2,
  marginBottom: '2.5rem',
  pr: 2,
  color: '#0A3954',
  fontFamily: 'Open Sans',
  width: '100%',
  maxWidth: '100%'
};

// Contenedor derecho para la imagen
export const desktopRightContentStyles = {
  flex: '0.55',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
  borderRadius: '12px',
  height: { md: '380px', lg: '420px' },
  width: '100%',
  position: 'relative',
  maxWidth: { md: '50%', lg: '55%' },
  maxHeight: '450px',
  backgroundColor: 'white',
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)'
};

// Estilos de la imagen
export const desktopImageStyles = {
  width: '100%',
  height: '100%',
  objectFit: 'contain',
  objectPosition: 'center',
  borderRadius: '12px',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.02)'
  }
}; 