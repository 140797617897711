// Estilos específicos para tablets (sm)
export const tabletContainerStyles = {
  py: 6,
  px: 3
};

export const tabletTitleStyles = {
  fontSize: '28px',
  mb: 2.5
};

export const tabletSubtitleStyles = {
  fontSize: '17px',
  mb: 5,
  maxWidth: '700px',
  px: 2
};

export const tabletStepsContainerStyles = {
  flexDirection: 'row',
  gap: 3,
  mb: 5
};

export const tabletStepItemStyles = {
  px: 2
};

export const tabletStepImageWrapperStyles = {
  width: '160px',
  height: '160px',
  mb: 2.5
};

export const tabletStepNumberStyles = {
  fontSize: '18px',
  mb: 1.5
};

export const tabletStepDescriptionStyles = {
  fontSize: '15px',
  maxWidth: '220px',
  lineHeight: 1.5
};

export const tabletButtonWrapperStyles = {
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  px: '32px'
};

export const tabletButtonStyles = {
  fontSize: '16px',
  py: 1.75,
  px: 3,
  width: 'calc(100% - 64px)',
  mx: 'auto'
}; 