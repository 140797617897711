// Estilos específicos para dispositivos móviles (xs)
export const mobileContainerStyles = {
  py: 4,
  px: 2
};

export const mobileTitleStyles = {
  fontSize: '24px',
  mb: 2
};

export const mobileSubtitleStyles = {
  fontSize: '16px',
  mb: 4,
  maxWidth: '100%',
  px: 0
};

export const mobileStepsContainerStyles = {
  flexDirection: 'column',
  gap: 4,
  mb: 4
};

export const mobileStepItemStyles = {
  px: 0
};

export const mobileStepImageWrapperStyles = {
  width: '180px',
  height: '180px',
  mb: 3
};

export const mobileStepNumberStyles = {
  fontSize: '18px',
  mb: 1
};

export const mobileStepDescriptionStyles = {
  fontSize: '14px',
  maxWidth: '250px',
  lineHeight: 1.4
};

export const mobileButtonWrapperStyles = {
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  px: 0
};

export const mobileButtonStyles = {
  fontSize: '16px',
  py: 1.5,
  px: 4
}; 