// Estilos específicos para tablets
export const tabletContainerStyles = {
  mt: '30px',
  borderRadius: '16px'
};

export const tabletSlideWrapperStyles = {
  maxWidth: '900px',
  padding: '24px'
};

export const tabletSwiperStyles = {
  paddingBottom: '24px'
};

// Estilos del OfferCard para tablets
export const tabletCardContainerStyles = {
  flexDirection: 'row',
  borderRadius: '12px',
  height: '320px'
};

export const tabletLeftPanelStyles = {
  flex: '0 0 55%',
  justifyContent: 'center',
  gap: '16px',
  p: '24px 32px',
  order: 1
};

export const tabletTitleOutlineStyles = {
  width: '350px',
  height: '45px',
  fontSize: '42px',
  lineHeight: '42px',
  mb: '8px'
};

export const tabletSubtitleStyles = {
  fontSize: '28px',
  lineHeight: '92%',
  height: '40px',
  mb: '20px'
};

export const tabletButtonWrapperStyles = {
  width: '60%',
  borderRadius: '31.04px',
  height: '48px',
  padding: '8px 12px',
  gap: '8px',
  fontSize: '16px',
  justifyContent: 'flex-start'
};

export const tabletIconWrapperStyles = {
  width: '36px',
  borderRadius: '18px',
  height: '36px',
  padding: '5px'
};

export const tabletIconStyles = {
  width: '20px',
  height: '20px'
};

export const tabletButtonTextStyles = {
  width: '100px',
  height: '20px',
  fontSize: '16px'
};

export const tabletRightPanelStyles = {
  flex: '0 0 45%',
  height: '320px',
  p: '20px',
  pr: '120px',
  order: 2
};

export const tabletImageStyles = {
  maxWidth: '90%',
  maxHeight: '90%',
  margin: 'auto',
  mr: '160px',
  p: 0
}; 