import { apiClient } from '../../../shared/services/apiClient';

const handleAuthError = () => {
  // Limpiar sessionStorage
  sessionStorage.removeItem('authUser');
  
  // Recargar la página para forzar el estado de no autenticado
  window.location.reload();
};

export const getCustomerDetail = async () => {
  try {
    console.log('📊 Fetching customer details...');
    
    // Obtener datos de autenticación del sessionStorage
    const authUser = sessionStorage.getItem('authUser');
    if (!authUser) {
      console.error('❌ No authentication data found');
      handleAuthError();
      throw new Error('No authentication data found');
    }

    const { uid } = JSON.parse(authUser);
    if (!uid) {
      console.error('❌ No Firebase uid found in auth data');
      handleAuthError();
      throw new Error('No Firebase uid found in auth data');
    }

    const response = await apiClient.get(`/customer/detail/${uid}`);
    console.log('✅ Customer details fetched successfully:', response.data);
    return response.data;
  } catch (error) {
    console.error('❌ Error fetching customer details:', error);
    
    // Si el error es 401 (Unauthorized) o 403 (Forbidden), manejamos como error de autenticación
    if (error.response?.status === 401 || error.response?.status === 403) {
      handleAuthError();
    }
    
    throw new Error(error.response?.data?.message || 'Error fetching customer details');
  }
}; 