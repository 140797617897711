import React from 'react';
import {
  Box,
  Typography,
  Button,
  Divider,
  CircularProgress,
  Paper,
  Avatar
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import { useQuery } from '@tanstack/react-query';
import { getCustomerDetail } from '../../services/customerService';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

const InfoLabel = ({ children }) => (
  <Typography 
    variant="caption" 
    sx={{ 
      color: '#FF9800',
      fontSize: '0.75rem',
      fontWeight: 400,
      mb: 0.5,
      display: 'block'
    }}
  >
    {children}
  </Typography>
);

const InfoValue = ({ children }) => (
  <Typography 
    variant="body1" 
    sx={{ 
      color: '#1E293B',
      fontSize: '1rem',
      fontWeight: 500,
      mb: 1
    }}
  >
    {children}
  </Typography>
);

const InfoSection = ({ label, value }) => (
  <Box sx={{ width: '100%' }}>
    <InfoLabel>{label}</InfoLabel>
    <InfoValue>{value}</InfoValue>
  </Box>
);

const getInitials = (companyName, email) => {
  if (companyName) {
    const words = companyName.split(" ");
    
    if (words.length >= 2) {
      const firstInitial = words[0].charAt(0);
      const secondInitial = words[1].charAt(0);
      return (firstInitial + secondInitial).toUpperCase();
    } else if (words.length === 1) {
      return words[0].substring(0, 2).toUpperCase();
    }
  }
  
  if (email) {
    const username = email.split("@")[0];
    const words = username.split(".");
    
    if (words.length >= 2) {
      const firstInitial = words[0].charAt(0);
      const secondInitial = words[1].charAt(0);
      return (firstInitial + secondInitial).toUpperCase();
    } else {
      return username.substring(0, 2).toUpperCase();
    }
  }
  
  return "UN";
};

const ProfileContent = ({ onLogout, isDrawer = false, onClose }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { data: customerData, isLoading, error } = useQuery({
    queryKey: ['customerDetail'],
    queryFn: getCustomerDetail
  });

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography color="error" sx={{ p: 2 }}>
        Error loading profile information. Please try again later.
      </Typography>
    );
  }

  const handleLogout = async () => {
    try {
      console.log('🔒 ProfileContent: handleLogout iniciado');
      console.log('📱 ¿Es drawer?', isDrawer);
      
      // Si la función es llamada desde el drawer móvil, dejar que el drawer maneje el proceso
      if (isDrawer) {
        console.log('📱 Logout desde drawer móvil, delegando al drawer');
        if (onLogout) {
          await onLogout();
          // No hacer nada más, ya que el drawer se encargará de todo
          return;
        }
      }
      
      // Si no es desde el drawer o no hay función onLogout, manejar el proceso aquí
      console.log('🖥️ Logout desde modal normal o fallback');
      
      // 1. Limpiar completamente localStorage y sessionStorage
      localStorage.clear();
      sessionStorage.clear();
      console.log('✅ Storage completamente limpiado');
      
      // 2. Cerrar el modal si está abierto
      if (onClose) {
        onClose();
        console.log('✅ Modal cerrado');
      }
      
      // 3. Llamar a la función de logout proporcionada si existe
      if (onLogout) {
        await onLogout();
        console.log('✅ Función onLogout ejecutada');
        // Si hay onLogout, dejar que esa función maneje la redirección
        return;
      }
      
      // 4. Si no hay onLogout, manejar la redirección aquí
      // Forzar un evento de storage para sincronizar otras pestañas
      try {
        window.dispatchEvent(new Event('storage'));
        console.log('✅ Evento storage disparado');
      } catch (eventError) {
        console.error('Error al disparar evento storage:', eventError);
      }
      
      // 5. Limpiar caché de React Query si está disponible
      if (window.queryClient) {
        try {
          window.queryClient.clear();
          console.log('✅ Caché de React Query limpiada');
        } catch (cacheError) {
          console.error('Error al limpiar caché:', cacheError);
        }
      }
      
      // 6. Añadir un timestamp para evitar caché del navegador
      const timestamp = new Date().getTime();
      
      // 7. Redirigir a la página principal con recarga completa
      console.log('🔄 Redirigiendo a la página principal...');
      setTimeout(() => {
        window.location.href = `/?logout=true&t=${timestamp}`;
      }, 300);
    } catch (error) {
      console.error('❌ Error durante el logout:', error);
      // En caso de error, intentar una redirección forzada
      window.location.href = '/?error=true&t=' + new Date().getTime();
    }
  };

  return (
    <Box sx={{ 
      p: 3,
      px: '15px',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      ...(isDrawer && isMobile && {
        pb: 20
      }),
      ...(isDrawer && {
        backgroundColor: theme.palette.secondary[700],
        color: 'white'
      })
    }}>
      <Box sx={{ position: 'relative', width: '100%', mb: 2 }}>
        {isDrawer && (
          <Avatar 
            sx={{ 
              position: 'absolute',
              left: 20,
              top: -40,
              width: 100, 
              height: 100, 
              bgcolor: '#5D6470',
              color: 'white',
              fontSize: '1.75rem',
              fontWeight: 500,
              border: '4px solid #F8FAFD',
              zIndex: 1
            }}
          >
            {getInitials(customerData?.companyName, customerData?.email)}
          </Avatar>
        )}

        <Paper 
          elevation={0}
          sx={{ 
            width: '100%',
            bgcolor: 'white',
            borderRadius: 2,
            overflow: 'hidden'
          }}
        >
          <Box sx={{ 
            px: 2,
            ...(isDrawer && { pt: 7 })
          }}>
            <Box sx={{ p: 2 }}>
              <InfoSection label="Company name" value={customerData?.companyName} />
              {customerData?.firstName && customerData?.lastName && (
                <Typography 
                  sx={{ 
                    color: '#64748B',
                    fontSize: '1rem',
                    fontWeight: 400,
                    mb: 2
                  }}
                >
                  {`${customerData.firstName} ${customerData.lastName}`}
                </Typography>
              )}
            </Box>

            <Divider sx={{ bgcolor: '#1D232E', mx: 2 }} />

            <Box sx={{ p: 2 }}>
              <InfoSection label="email" value={customerData?.email} />
            </Box>

            <Divider sx={{ bgcolor: '#1D232E', mx: 2 }} />

            <Box sx={{ p: 2 }}>
              <InfoSection label="Phone number" value={customerData?.phone} />
            </Box>
          </Box>
        </Paper>
      </Box>

      <Button
        fullWidth
        variant="contained"
        onClick={handleLogout}
        sx={{
          mb: 2,
          backgroundColor: '#E0E0E0',
          color: '#424242',
          textTransform: 'none',
          fontSize: '1rem',
          fontWeight: 400,
          '&:hover': {
            backgroundColor: '#BDBDBD',
          }
        }}
      >
        log out
      </Button>

      <Button
        fullWidth
        variant="outlined"
        startIcon={<SettingsIcon />}
        sx={{
          borderColor: isDrawer ? 'rgba(255, 255, 255, 0.3)' : theme.palette.secondary[700],
          color: isDrawer ? 'white' : theme.palette.secondary[700],
          textTransform: 'none',
          fontSize: '1rem',
          fontWeight: 400,
          '&:hover': {
            borderColor: isDrawer ? 'rgba(255, 255, 255, 0.5)' : theme.palette.secondary[800],
            backgroundColor: 'transparent'
          }
        }}
      >
        Give feedback
      </Button>
    </Box>
  );
};

export default ProfileContent; 