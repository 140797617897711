import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const FormContainer = styled(Box)(({ theme, isMobile }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: isMobile ? theme.spacing(1) : theme.spacing(2),
  height: '100%',
  width: '100%',
  justifyContent: 'flex-start',
  overflowY: 'auto',
  position: 'relative',
  paddingTop: isMobile ? theme.spacing(4) : 0,
  paddingBottom: isMobile ? theme.spacing(4) : 0,
  paddingLeft: isMobile ? theme.spacing(2) : theme.spacing(3),
  paddingRight: isMobile ? theme.spacing(2) : theme.spacing(3),
  ...(isMobile && {
    minHeight: '100vh',
    marginTop: '10vh',
    '&:focus-within': {
      marginTop: 0,
      transition: 'margin-top 0.3s ease-out'
    }
  })
}));

export const HeaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: { xs: 'column', sm: 'row' },
  alignItems: 'center',
  gap: { xs: theme.spacing(1), sm: theme.spacing(4) }
}));

export const IllustrationContainer = styled(Box)(({ theme }) => ({
  width: { xs: '80px', sm: '100px', md: '120px' },
  height: { xs: '80px', sm: '100px', md: '120px' },
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  order: { xs: -1, sm: 2 },
  marginRight: { xs: 0, sm: '-8px', md: '-0' }
}));

export const IllustrationImage = styled('img')({
  width: '100%',
  height: '100%',
  maxWidth: '100%',
  maxHeight: '100%',
  objectFit: 'contain',
  transform: 'scale(0.9)',
  padding: '4px'
});

export const ContentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  flex: 1,
  textAlign: { xs: 'center', sm: 'left' }
})); 