import { signInWithEmailAndPassword } from 'firebase/auth';
import { CustomerDetailModel } from '../../models/CustomerDetailModel';

export const getCustomerByeEmail = async (email) => {
    try {
        console.log(`http://127.0.0.1:8001/customer/users/${email}`);
        const response = await fetch(`http://127.0.0.1:8001/customer/users/${email}`);
        if (!response.ok) {
            console.error("Customer fetch fail", response);
            const errorData = await response.json();
            throw new Error(JSON.stringify(errorData.detail));
        }
        const jsonResponse = await response.json();
        return jsonResponse;
    } catch(error) {
        console.error("Error in customerService", error);
        const errorMessage = JSON.parse(error.message);
        throw new Error(errorMessage.errorCode);
    }
}

export const createCustomer = async (companyName, email, phone, fbId) => {
    try {
        console.log("http://127.0.0.1:8000/customer/create");
        const body = JSON.stringify({
            "customer": {
                "companyName": companyName,
                "phone": phone,
                "email": email,
            },
            "fbId": fbId
        });
        console.log('BODY: ', body);
        const response = await fetch("http://127.0.0.1:8000/customer/create", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: body
        });
        if (!response.ok) {
            console.error("Customer fetch fail", response);
            const errorData = await response.json();
            throw new Error(JSON.stringify(errorData.detail));
        }
        const jsonResponse = await response.json()
        return jsonResponse;
    } catch(error) {
        console.error("Error in customerService", error);
        const errorMessage = JSON.parse(error.message);
        throw new Error(errorMessage.errorCode);
    }
};

export const login = async (auth, email, password) => {
    try {
        console.log("SIGN FIREBASE");
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        const body = JSON.stringify({
            "token": userCredential.user.uid
        });
        console.log("BODY", body);
        await fetch("http://127.0.0.1:8000/login", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: body
        });
        return userCredential.user.uid
    } catch (error) {
        console.error("ERROR IN CUSTOMER SERVICE", error);
        throw error;
    }
}

export const getCustomerDetail = async (uid) => {
    try {
        const userDetail = await fetch(`http://127.0.0.1:8000/customer/detail/${uid}`);
        const responseJSON = await userDetail.json();
        console.info("RESPONSE USER DETAIL", responseJSON);
        return new CustomerDetailModel(responseJSON);
    } catch (error) {
        return error;
    }
}