/**
 * Obtiene la imagen correcta según el idioma actual
 * 
 * @param {Object} banner - Objeto del banner con urls de imágenes
 * @param {string} language - Código de idioma actual
 * @returns {string} URL de la imagen según el idioma
 */
export const getImageForLanguage = (banner, language) => {
  // Si es español, usar la imagen en español
  if (language === 'es' || language.startsWith('es-')) {
    return banner.imageUrlEs || banner.imageUrl;
  }
  
  // Para inglés u otros idiomas, usar la imagen en inglés
  return banner.imageUrlEn || banner.imageUrl;
};

/**
 * Obtiene el contenido traducido para cada banner según el índice
 * 
 * @param {Function} t - Función de traducción de i18next
 * @param {Object} banner - Objeto del banner
 * @param {number} index - Índice del banner en el array
 * @param {boolean} isMobile - Si se está en vista móvil
 * @returns {Object} Objeto con el contenido traducido
 */
export const getTranslatedContent = (t, banner, index, isMobile) => {
  if (index === 0) {
    // First banner
    return {
      subtitle: isMobile ? t('dashboard.heroBanner1.sloganMobile') : t('dashboard.heroBanner1.slogan'),
      title: isMobile ? t('dashboard.heroBanner1.mainMessageMobile') : t('dashboard.heroBanner1.mainMessage'),
      imageAlt: t('dashboard.heroBanner1.imageAlt'),
      // SEO fields available if needed:
      // sloganSeo: t('dashboard.heroBanner1.sloganSeo'),
      // sloganSeoDescription: t('dashboard.heroBanner1.sloganSeoDescription'),
      // titleSeo: t('dashboard.heroBanner1.mainMessageSeo'),
      // titleSeoDescription: t('dashboard.heroBanner1.mainMessageSeoDescription'),
      // imageAltSeo: t('dashboard.heroBanner1.imageAltSeo')
    };
  } else if (index === 1) {
    // Second banner
    return {
      subtitle: isMobile ? t('dashboard.heroBanner2.sloganMobile') : t('dashboard.heroBanner2.slogan'),
      title: isMobile ? t('dashboard.heroBanner2.titleMobile') : t('dashboard.heroBanner2.title'),
      imageAlt: t('dashboard.heroBanner2.imageAlt'),
      // SEO fields available if needed:
      // sloganSeo: t('dashboard.heroBanner2.sloganSeo'),
      // sloganSeoDescription: t('dashboard.heroBanner2.sloganSeoDescription'),
      // titleSeo: t('dashboard.heroBanner2.titleSeo'),
      // titleSeoDescription: t('dashboard.heroBanner2.titleSeoDescription'),
      // imageAltSeo: t('dashboard.heroBanner2.imageAltSeo')
    };
  } else {
    return {
      subtitle: isMobile ? t('dashboard.heroBanner3.sloganMobile') : t('dashboard.heroBanner3.slogan'),
      title: isMobile ? t('dashboard.heroBanner3.titleMobile') : t('dashboard.heroBanner3.title'),
      imageAlt: t('dashboard.heroBanner3.imageAlt', 'Delivery truck with roofing materials'),
      // SEO fields available if needed:
      // sloganSeo: t('dashboard.heroBanner3.sloganSeo'),
      // sloganSeoDescription: t('dashboard.heroBanner3.sloganSeoDescription'),
      // titleSeo: t('dashboard.heroBanner3.titleSeo'),
      // titleSeoDescription: t('dashboard.heroBanner3.titleSeoDescription'),
      // imageAltSeo: t('dashboard.heroBanner3.imageAltSeo')
    };
  }
}; 