import { styled } from '@mui/material/styles';
import { Box, TextField } from '@mui/material';

export const StyledContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  height: '100%',
  justifyContent: 'center',
  [theme.breakpoints.down('sm')]: {
    marginTop: '10vh',
    justifyContent: 'flex-start',
    paddingTop: theme.spacing(4)
  }
}));

export const ContentContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: 4
});

export const TextContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: 2
});

export const ImageContainer = styled(Box)(({ theme }) => ({
  width: '300px',
  height: '300px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.down('sm')]: {
    width: '200px',
    height: '200px'
  }
}));

export const StyledImage = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'contain'
});

export const StyledTypography = {
  title: {
    fontSize: 30,
    fontFamily: 'Open Sans',
    fontWeight: 600,
    color: '#F3F3F3',
    wordWrap: 'break-word',
    marginBottom: 1,
    '& .highlight': {
      color: '#E96D25',
      fontSize: 30,
      fontWeight: 600,
      fontFamily: 'Open Sans',
      wordWrap: 'break-word'
    }
  },
  subtitle: {
    color: '#F3F3F3',
    fontSize: 15,
    fontFamily: 'Open Sans',
    fontWeight: 600,
    wordWrap: 'break-word'
  }
};

export const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderWidth: 1,
      borderColor: theme.palette.primary.main
    }
  },
  '& .MuiOutlinedInput-input': {
    padding: '12px 16px'
  }
})); 