import { styled } from '@mui/material/styles';
import { Dialog, Box, TextField, Button } from '@mui/material';

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    maxWidth: '1200px',
    width: '90%',
    margin: '32px',
    borderRadius: '24px',
    overflow: 'hidden',
    backgroundColor: theme.palette.secondary[700],
    [theme.breakpoints.down('md')]: {
      maxWidth: '600px',
      margin: '16px',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      width: '100%',
      height: '100%',
      margin: 0,
      borderRadius: 0,
    }
  },
  [theme.breakpoints.down('sm')]: {
    '& .MuiDialog-container': {
      alignItems: 'flex-start',
    }
  }
}));

export const CloseButton = styled('button')(({ theme }) => ({
  position: 'absolute',
  right: '24px',
  top: '24px',
  color: 'white',
  zIndex: 1,
  backgroundColor: 'transparent',
  border: 'none',
  cursor: 'pointer',
  padding: '8px',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
  [theme.breakpoints.down('sm')]: {
    top: '16px',
    right: '16px',
  }
}));

export const ContentContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  minHeight: '600px',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    minHeight: 'auto',
  },
  [theme.breakpoints.down('sm')]: {
    minHeight: '100vh',
    height: '100%',
  }
}));

export const LeftSection = styled(Box)(({ theme }) => ({
  flex: 1,
  padding: theme.spacing(6),
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  backgroundColor: theme.palette.secondary[700],
  color: 'white',
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(4),
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(3),
    minHeight: '100vh',
    justifyContent: 'center',
  }
}));

export const RightSection = styled(Box)(({ theme }) => ({
  flex: 1,
  backgroundColor: '#f5f5f5',
  padding: theme.spacing(6),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  position: 'relative',
  borderTopRightRadius: '24px',
  borderBottomRightRadius: '24px',
  [theme.breakpoints.down('md')]: {
    display: 'none', // Ocultar en tablet y móvil
  }
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(0),
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    borderRadius: '8px',
    '& fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.2)',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.3)',
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
  '& .MuiOutlinedInput-input': {
    padding: '16px',
    color: 'white',
    '&::placeholder': {
      color: 'rgba(255, 255, 255, 0.7)',
      opacity: 1,
    },
  },
  // Estilos específicos para móvil
  [theme.breakpoints.down('sm')]: {
    '& .MuiOutlinedInput-root': {
      fontSize: '16px', // Previene el zoom automático en iOS
    },
    '& .MuiOutlinedInput-input': {
      padding: '12px 16px', // Ajuste del padding para mejor usabilidad en móvil
    },
    '& .MuiInputLabel-root': {
      fontSize: '16px', // Previene el zoom automático en iOS para el label
    },
    '& .MuiFormHelperText-root': {
      fontSize: '14px', // Ajuste del tamaño del texto de ayuda para móvil
    }
  }
}));

export const ActionButton = styled(Button)(({ theme }) => ({
  borderRadius: '8px',
  padding: '16px',
  fontSize: '16px',
  backgroundColor: theme.palette.primary.main,
  color: 'white',
  marginTop: theme.spacing(0),
  textTransform: 'none',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  }
}));

export const StyledTypography = {
  title: {
    fontWeight: 400,
    fontSize: { xs: '22px', sm: '25px', md: '25px' },
    mb: 1,
    '& .highlight': {
      color: '#E96D25',
    }
  },
  subtitle: {
    opacity: 0.9,
    mb: 1,
    maxWidth: '400px',
    fontSize: { xs: '14px', sm: '15px', md: '15px' },
    color: '#F3F3F3'
  },
  link: {
    mt: 2,
    textAlign: 'center',
    color: 'rgba(255, 255, 255, 0.7)',
    cursor: 'pointer',
    '&:hover': {
      color: 'white'
    }
  },
  highlightedTitle: {
    fontSize: { xs: '16px', sm: '18px', md: '18px' },
    fontWeight: 600,
    color: '#E99B25'
  }
}; 