/**
 * BrandCard Component
 * Displays a single brand card with image and name
 */

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

// Import styles directly from their source files
// Common styles
import {
  commonBrandCardStyles,
  commonBrandImageContainerStyles,
  commonBrandImageStyles,
  commonBrandNameStyles,
  commonBrandTypographyStyles
} from '../styles/common';

// Mobile styles
import {
  mobileBrandCardStyles,
  mobileBrandImageContainerStyles,
  mobileBrandNameStyles,
  mobileBrandTypographyStyles
} from '../styles/mobile';

// Tablet styles
import {
  tabletBrandCardStyles,
  tabletBrandImageContainerStyles,
  tabletBrandNameStyles,
  tabletBrandTypographyStyles,
  // iPad specific styles
  iPadMiniBrandCardStyles,
  iPadMiniBrandImageContainerStyles,
  iPadMiniBrandTypographyStyles,
  iPadAirBrandCardStyles,
  iPadAirBrandImageContainerStyles,
  iPadAirBrandTypographyStyles,
  iPadProBrandCardStyles,
  iPadProBrandImageContainerStyles,
  iPadProBrandTypographyStyles
} from '../styles/tablet';

// Desktop styles
import {
  desktopBrandCardStyles,
  desktopBrandImageContainerStyles,
  desktopBrandNameStyles,
  desktopBrandTypographyStyles
} from '../styles/desktop';

const BrandCard = ({ brand, deviceType, tabletType, sx = {} }) => {
  
  const navigate = useNavigate();
  
  // Función para manejar el clic en la marca
  const handleBrandClick = () => {
    navigate(`/brands/${brand.key}`);
  };
  
  // Get device-specific styles
  const getBrandCardStyles = () => {
    if (deviceType === 'mobile') {
      return { 
        ...commonBrandCardStyles, 
        ...mobileBrandCardStyles,
        minWidth: 0, // Ensure cards can shrink properly in flexbox
        maxWidth: '100%' // Ensure cards don't overflow container
      };
    }
    
    if (deviceType === 'tablet') {
      if (tabletType === 'iPadMini') {
        return { 
          ...commonBrandCardStyles, 
          ...tabletBrandCardStyles, 
          ...iPadMiniBrandCardStyles,
          minWidth: 0,
          maxWidth: '100%'
        };
      }
      if (tabletType === 'iPadAir') {
        return { 
          ...commonBrandCardStyles, 
          ...tabletBrandCardStyles, 
          ...iPadAirBrandCardStyles,
          minWidth: 0,
          maxWidth: '100%'
        };
      }
      if (tabletType === 'iPadPro') {
        return { 
          ...commonBrandCardStyles, 
          ...tabletBrandCardStyles, 
          ...iPadProBrandCardStyles,
          minWidth: 0,
          maxWidth: '100%'
        };
      }
      return { 
        ...commonBrandCardStyles, 
        ...tabletBrandCardStyles,
        minWidth: 0,
        maxWidth: '100%'
      };
    }
    
    return { ...commonBrandCardStyles, ...desktopBrandCardStyles };
  };
  
  const getBrandImageContainerStyles = () => {
    if (deviceType === 'mobile') {
      return { ...commonBrandImageContainerStyles, ...mobileBrandImageContainerStyles };
    }
    
    if (deviceType === 'tablet') {
      if (tabletType === 'iPadMini') {
        return { ...commonBrandImageContainerStyles, ...tabletBrandImageContainerStyles, ...iPadMiniBrandImageContainerStyles };
      }
      if (tabletType === 'iPadAir') {
        return { ...commonBrandImageContainerStyles, ...tabletBrandImageContainerStyles, ...iPadAirBrandImageContainerStyles };
      }
      if (tabletType === 'iPadPro') {
        return { ...commonBrandImageContainerStyles, ...tabletBrandImageContainerStyles, ...iPadProBrandImageContainerStyles };
      }
      return { ...commonBrandImageContainerStyles, ...tabletBrandImageContainerStyles };
    }
    
    return { ...commonBrandImageContainerStyles, ...desktopBrandImageContainerStyles };
  };
  
  const getBrandNameStyles = () => {
    if (deviceType === 'mobile') {
      return { ...commonBrandNameStyles, ...mobileBrandNameStyles };
    }
    
    if (deviceType === 'tablet') {
      return { ...commonBrandNameStyles, ...tabletBrandNameStyles };
    }
    
    return { ...commonBrandNameStyles, ...desktopBrandNameStyles };
  };
  
  const getBrandTypographyStyles = () => {
    if (deviceType === 'mobile') {
      return { ...commonBrandTypographyStyles, ...mobileBrandTypographyStyles };
    }
    
    if (deviceType === 'tablet') {
      if (tabletType === 'iPadMini') {
        return { ...commonBrandTypographyStyles, ...tabletBrandTypographyStyles, ...iPadMiniBrandTypographyStyles };
      }
      if (tabletType === 'iPadAir') {
        return { ...commonBrandTypographyStyles, ...tabletBrandTypographyStyles, ...iPadAirBrandTypographyStyles };
      }
      if (tabletType === 'iPadPro') {
        return { ...commonBrandTypographyStyles, ...tabletBrandTypographyStyles, ...iPadProBrandTypographyStyles };
      }
      return { ...commonBrandTypographyStyles, ...tabletBrandTypographyStyles };
    }
    
    return { ...commonBrandTypographyStyles, ...desktopBrandTypographyStyles };
  };
  
  // Apply styles
  const cardStyles = {
    ...getBrandCardStyles(),
    ...sx, // Aplicar estilos personalizados
    cursor: 'pointer', // Añadir cursor pointer para indicar que es clickeable
    transition: 'transform 0.2s',
    '&:hover': {
      transform: 'translateY(-4px)',
    }
  };
  
  return (
    <Box sx={cardStyles} onClick={handleBrandClick}>
      <Box sx={getBrandImageContainerStyles()}>
        <Box 
          component="img"
          src={brand.image} 
          alt={brand.alt} 
          sx={commonBrandImageStyles}
        />
      </Box>
      <Box sx={getBrandNameStyles()}>
        <Typography 
          variant="h6" 
          sx={getBrandTypographyStyles()}
        >
          {brand.name}
        </Typography>
      </Box>
    </Box>
  );
};

BrandCard.propTypes = {
  brand: PropTypes.shape({
    id: PropTypes.number.isRequired,
    key: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired
  }).isRequired,
  deviceType: PropTypes.oneOf(['mobile', 'tablet', 'desktop']).isRequired,
  tabletType: PropTypes.oneOf(['iPadMini', 'iPadAir', 'iPadPro', 'generic']),
  sx: PropTypes.object
};

BrandCard.defaultProps = {
  tabletType: 'generic',
  sx: {}
};

export default memo(BrandCard);