export const containerStyles = {
  width: '100%',
  position: 'relative',
  py: { xs: 4, sm: 7, md: 8 },
  px: { xs: 2, sm: 4, md: 8 },
  backgroundColor: '#0A3954',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    height: '35px',
    backgroundColor: '#E96D25',
    zIndex: 0
  }
};

export const contentWrapperStyles = {
  backgroundColor: '#FFFFFF',
  borderRadius: '24px',
  p: { xs: 3, sm: 5, md: 4 },
  display: 'flex',
  flexDirection: { xs: 'column', md: 'row' },
  gap: { xs: 4, sm: 6, md: 6 },
  alignItems: 'center',
  overflow: 'hidden',
  position: 'relative',
  zIndex: 1
};

export const titleStyles = {
  color: '#0A3954',
  fontSize: { xs: '2rem', sm: '2.25rem', md: '2.5rem' },
  fontWeight: 'bold',
  mb: { xs: 2, sm: 3, md: 2 },
  lineHeight: 1.2
};

export const subtitleStyles = {
  color: '#4A5568',
  fontSize: { xs: '1rem', sm: '1.125rem', md: '1.25rem' },
  mb: { xs: 4, sm: 5, md: 4 },
  maxWidth: '600px'
};

export const benefitItemStyles = {
  display: 'flex',
  alignItems: 'flex-start',
  gap: { xs: 2, sm: 2.5, md: 2 },
  mb: { xs: 2, sm: 3, md: 2 }
};

export const benefitTitleStyles = {
  color: '#E96D25',
  fontSize: { xs: '1.1rem', sm: '1.15rem', md: '1.25rem' },
  fontWeight: 'bold',
  mb: { xs: 0.5, sm: 1, md: 0.5 }
};

export const benefitDescriptionStyles = {
  color: '#4A5568',
  fontSize: '1rem',
  lineHeight: { xs: 1.4, sm: 1.5, md: 1.4 }
};

export const buttonStyles = {
  mt: { xs: 4, sm: 6, md: 4 },
  backgroundColor: '#E96D25',
  color: 'white',
  fontSize: '1rem',
  fontWeight: '600',
  py: { xs: 1.5, sm: 2, md: 1.5 },
  px: { xs: 4, sm: 3, md: 4 },
  width: { xs: 'auto', sm: 'calc(100% - 48px)', md: 'auto' },
  borderRadius: '8px',
  textTransform: 'none',
  mx: { xs: 0, sm: 'auto', md: 0 },
  display: { xs: 'inline-flex', sm: 'flex', md: 'inline-flex' },
  justifyContent: 'center',
  '&:hover': {
    backgroundColor: '#d54d0d'
  }
};

export const imageContainerStyles = {
  flex: { xs: '1', md: '0 0 45%' },
  position: 'relative',
  minHeight: { xs: '300px', sm: '450px', md: '300px' },
  maxHeight: { sm: '600px', md: 'none' },
  width: { xs: '100%', sm: '90%', md: '100%' },
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& img': {
    maxWidth: '100%',
    height: 'auto',
    objectFit: 'contain'
  }
};

export const checkIconStyles = {
  color: '#E96D25',
  fontSize: { xs: '24px', sm: '28px', md: '24px' }
}; 