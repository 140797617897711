import React from 'react';
import { Box, Container, Typography, useTheme, useMediaQuery } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import { useTranslation } from 'react-i18next';
import 'swiper/css';
import 'swiper/css/pagination';
import { ReviewCardPropTypes } from './types';
import { testReviews } from './mockData';
import ReviewCard from './ReviewCard';
import {
  containerStyles,
  slideWrapperStyles,
  swiperStyles,
  paginationStyles,
  titleStyles
} from './styles';

const ReviewsLayout = ({ reviews = testReviews }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box sx={containerStyles}>
      <Container 
        maxWidth="lg" 
        disableGutters={isMobile}
        sx={{ 
          overflow: 'hidden',
          px: { xs: 0, md: 2 }
        }}
      >
        {/* Title Section */}
        <Typography 
          variant="h2" 
          sx={{ 
            ...titleStyles,
            marginBottom: 4, 
            color: '#0A3954',
            fontWeight: 700,
            fontSize: '32px',
            paddingLeft: { xs: 2, md: 0 },
            display: { xs: 'none', sm: 'none', md: 'block' }
          }}
        >
          {t('dashboard.reviewsLayout.title')}
        </Typography>

        {/* Hidden SEO description */}
        <Typography 
          sx={{ 
            position: 'absolute', 
            width: '1px', 
            height: '1px', 
            padding: 0, 
            margin: '-1px', 
            overflow: 'hidden', 
            clip: 'rect(0, 0, 0, 0)', 
            whiteSpace: 'nowrap', 
            borderWidth: 0 
          }}
        >
          {t('dashboard.reviewsLayout.seoDescription')}
        </Typography>

        {/* Hidden alt text for accessibility */}
        <Typography 
          sx={{ 
            position: 'absolute', 
            width: '1px', 
            height: '1px', 
            padding: 0, 
            margin: '-1px', 
            overflow: 'hidden', 
            clip: 'rect(0, 0, 0, 0)', 
            whiteSpace: 'nowrap', 
            borderWidth: 0 
          }}
          role="img"
          aria-label={t('dashboard.reviewsLayout.reviewsAlt')}
        >
          {t('dashboard.reviewsLayout.reviewsAlt')}
        </Typography>

        <Swiper
          modules={[Pagination, Autoplay]}
          slidesPerView={isMobile ? 1.1 : "auto"}
          centeredSlides={!isMobile}
          spaceBetween={isMobile ? 16 : 30}
          initialSlide={0}
          pagination={{
            clickable: true,
            el: '.swiper-pagination',
            type: 'bullets'
          }}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          loop={false}
          style={{
            ...swiperStyles,
            overflow: isMobile ? 'hidden' : 'visible',
          }}
          slidesOffsetBefore={isMobile ? 16 : 0}
          slidesOffsetAfter={isMobile ? 16 : 0}
        >
          {reviews.map((review, index) => (
            <SwiperSlide 
              key={review.id || index}
              style={{ 
                width: isMobile ? '90%' : 'auto',
                maxWidth: isMobile ? '90%' : '80%'
              }}
            >
              <Box sx={slideWrapperStyles}>
                <ReviewCard {...review} />
              </Box>
            </SwiperSlide>
          ))}
          <Box 
            className="swiper-pagination" 
            sx={paginationStyles}
          />
        </Swiper>
      </Container>
    </Box>
  );
};

ReviewsLayout.propTypes = {
  reviews: ReviewCardPropTypes
};

export default ReviewsLayout; 