/**
 * Desktop styles for the Header component
 * These styles are for desktop devices and large screens
 */

export const desktopAppBarStyles = {
  // Si el banner está visible, considerar la altura del banner en desktop (55px)
  // Ver el componente NotificationBanner
};

export const desktopToolbarStyles = {
  padding: '0 24px !important',
};

export const desktopLogoWrapperStyles = {
  '& img': {
    width: '200px',
    height: '65px',
  },
};

export const desktopNavButtonStyles = {
  marginRight: 3,
};

export const desktopLanguageSwitcherContainerStyles = {
  marginRight: 3,
};

export const desktopDevButtonStyles = {
  marginRight: 2,
  fontSize: '0.875rem',
}; 