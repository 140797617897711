export const testLimitedTimeOffers = [
  {
    id: '1',
    title: '$1490',
    subtitle: 'Air Compressor',
    imageCard: 'images/mockedImage/compressor-test.webp'
  },
  {
    id: '2',
    title: '$990',
    subtitle: 'Power Tools Set $990',
    imageCard: 'images/mockedImage/compressor-test.webp'
  },
  {
    id: '3',
    title: '$2490',
    subtitle: 'Industrial Equipment $2490',
    imageCard: 'images/mockedImage/compressor-test.webp'
  }
]; 