/**
 * Utilidades para generar esquemas estructurados JSON-LD para SEO
 * Basados en schema.org
 */

/**
 * Genera un schema para LocalBusiness específico para SYL Roofing Supply
 * @param {Object} customData - Datos personalizados para sobrescribir valores por defecto
 * @returns {Object} Schema estructurado para LocalBusiness
 */
export const generateLocalBusinessSchema = (customData = {}) => {
  const baseSchema = {
    '@context': 'https://schema.org',
    '@type': 'RoofingContractor',
    'name': 'SYL Roofing Supply',
    'url': 'https://sylroofingsupply.com/',
    'logo': 'https://sylroofingsupply.com/images/logo.png',
    'image': 'https://sylroofingsupply.com/images/store-front.jpg',
    'description': 'Distribuidor Mayorista de Materiales para Techos en Tampa y Orlando, FL | Envío al Día Siguiente',
    'priceRange': '$$',
    'telephone': '407-534-2698',
    'email': 'quote@sylroofingsupply.com',
    'areaServed': [
      {
        '@type': 'City',
        'name': 'Tampa',
        'containedInPlace': {
          '@type': 'State',
          'name': 'Florida'
        }
      },
      {
        '@type': 'City',
        'name': 'Orlando',
        'containedInPlace': {
          '@type': 'State',
          'name': 'Florida'
        }
      }
    ],
    'address': [
      {
        '@type': 'PostalAddress',
        'streetAddress': '2005 N 43 St',
        'addressLocality': 'Tampa',
        'addressRegion': 'FL',
        'postalCode': '33605',
        'addressCountry': 'US'
      },
      {
        '@type': 'PostalAddress',
        'streetAddress': '930 Carter Rd #303',
        'addressLocality': 'Winter Garden',
        'addressRegion': 'FL',
        'postalCode': '34787',
        'addressCountry': 'US'
      }
    ],
    'openingHoursSpecification': [
      {
        '@type': 'OpeningHoursSpecification',
        'dayOfWeek': ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
        'opens': '07:00',
        'closes': '18:00'
      },
      {
        '@type': 'OpeningHoursSpecification',
        'dayOfWeek': ['Saturday'],
        'opens': '07:00',
        'closes': '15:00'
      }
    ]
  };

  return { ...baseSchema, ...customData };
};

/**
 * Genera un schema para producto específico para materiales de techo
 * @param {Object} productData - Datos del producto
 * @returns {Object} Schema estructurado para Product
 */
export const generateProductSchema = (productData) => {
  if (!productData || !productData.name) {
    console.error('Se requiere al menos el nombre del producto para generar el schema');
    return null;
  }

  return {
    '@context': 'https://schema.org',
    '@type': 'Product',
    'name': productData.name,
    'image': productData.image || 'https://sylroofingsupply.com/images/default-product.jpg',
    'description': productData.description || `Materiales para techos de alta calidad - ${productData.name}`,
    'brand': {
      '@type': 'Brand',
      'name': productData.brand || 'SYL Roofing Supply'
    },
    'offers': {
      '@type': 'Offer',
      'url': productData.url || 'https://sylroofingsupply.com/products',
      'priceCurrency': 'USD',
      'price': productData.price || '0',
      'itemCondition': 'https://schema.org/NewCondition',
      'availability': productData.availability || 'https://schema.org/InStock',
      'seller': {
        '@type': 'Organization',
        'name': 'SYL Roofing Supply'
      }
    }
  };
};

/**
 * Genera un schema para artículo tipo Blog o Noticia
 * @param {Object} articleData - Datos del artículo
 * @returns {Object} Schema estructurado para Article
 */
export const generateArticleSchema = (articleData) => {
  if (!articleData || !articleData.headline) {
    console.error('Se requiere al menos el título del artículo para generar el schema');
    return null;
  }

  return {
    '@context': 'https://schema.org',
    '@type': 'Article',
    'headline': articleData.headline,
    'image': articleData.image || 'https://sylroofingsupply.com/images/default-article.jpg',
    'datePublished': articleData.datePublished || new Date().toISOString(),
    'dateModified': articleData.dateModified || new Date().toISOString(),
    'author': {
      '@type': 'Organization',
      'name': 'SYL Roofing Supply',
      'url': 'https://sylroofingsupply.com/about'
    },
    'publisher': {
      '@type': 'Organization',
      'name': 'SYL Roofing Supply',
      'logo': {
        '@type': 'ImageObject',
        'url': 'https://sylroofingsupply.com/images/logo.png'
      }
    },
    'description': articleData.description || `Artículo sobre techos y materiales de construcción - ${articleData.headline}`,
    'mainEntityOfPage': {
      '@type': 'WebPage',
      '@id': articleData.url || 'https://sylroofingsupply.com/blog'
    }
  };
};

/**
 * Genera un schema para FAQ (Preguntas Frecuentes)
 * @param {Array} faqItems - Array de objetos con preguntas y respuestas
 * @returns {Object} Schema estructurado para FAQPage
 */
export const generateFAQSchema = (faqItems) => {
  if (!faqItems || !Array.isArray(faqItems) || faqItems.length === 0) {
    console.error('Se requiere un array de preguntas y respuestas para generar el schema de FAQ');
    return null;
  }

  return {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    'mainEntity': faqItems.map(item => ({
      '@type': 'Question',
      'name': item.question,
      'acceptedAnswer': {
        '@type': 'Answer',
        'text': item.answer
      }
    }))
  };
};

/**
 * Genera un schema para reseñas de clientes
 * @param {Array} reviews - Array de objetos con reseñas
 * @returns {Object} Schema estructurado para Review
 */
export const generateAggregateRatingSchema = (reviews) => {
  if (!reviews || !Array.isArray(reviews) || reviews.length === 0) {
    console.error('Se requiere un array de reseñas para generar el schema de AggregateRating');
    return null;
  }

  // Calcular la calificación promedio
  const ratingSum = reviews.reduce((sum, review) => sum + review.rating, 0);
  const averageRating = (ratingSum / reviews.length).toFixed(1);

  return {
    '@context': 'https://schema.org',
    '@type': 'AggregateRating',
    'itemReviewed': {
      '@type': 'LocalBusiness',
      'name': 'SYL Roofing Supply',
      'image': 'https://sylroofingsupply.com/images/store-front.jpg',
      'address': {
        '@type': 'PostalAddress',
        'addressLocality': 'Tampa & Orlando',
        'addressRegion': 'FL'
      }
    },
    'ratingValue': averageRating,
    'bestRating': '5',
    'worstRating': '1',
    'ratingCount': reviews.length,
    'reviewCount': reviews.length
  };
}; 