import * as yup from 'yup';

/**
 * Esquema de validación para el formulario de inicio de sesión
 */
export const loginSchema = yup.object().shape({
  email: yup
    .string()
    .email('Por favor ingresa un email válido')
    .required('El email es obligatorio')
    .trim(),
  password: yup
    .string()
    .required('La contraseña es obligatoria')
    .min(8, 'La contraseña debe tener al menos 8 caracteres')
});

/**
 * Esquema de validación para el formulario de registro
 */
export const registerSchema = yup.object().shape({
  firstName: yup
    .string()
    .required('El nombre es obligatorio')
    .trim(),
  lastName: yup
    .string()
    .required('El apellido es obligatorio')
    .trim(),
  email: yup
    .string()
    .email('Por favor ingresa un email válido')
    .required('El email es obligatorio')
    .trim(),
  password: yup
    .string()
    .required('La contraseña es obligatoria')
    .min(8, 'La contraseña debe tener al menos 8 caracteres')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/,
      'La contraseña debe contener al menos una letra mayúscula, una minúscula, un número y un carácter especial'
    ),
  confirmPassword: yup
    .string()
    .required('Debes confirmar tu contraseña')
    .oneOf([yup.ref('password'), null], 'Las contraseñas deben coincidir'),
  acceptTerms: yup
    .boolean()
    .oneOf([true], 'Debes aceptar los términos y condiciones')
});

/**
 * Esquema de validación para el formulario de recuperación de contraseña
 */
export const forgotPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .email('Por favor ingresa un email válido')
    .required('El email es obligatorio')
    .trim()
});

/**
 * Esquema de validación para el formulario de cambio de contraseña
 */
export const resetPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .required('La contraseña es obligatoria')
    .min(8, 'La contraseña debe tener al menos 8 caracteres')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/,
      'La contraseña debe contener al menos una letra mayúscula, una minúscula, un número y un carácter especial'
    ),
  confirmPassword: yup
    .string()
    .required('Debes confirmar tu contraseña')
    .oneOf([yup.ref('password'), null], 'Las contraseñas deben coincidir')
});

/**
 * Esquema de validación para el formulario de suscripción al newsletter
 */
export const newsletterSchema = yup.object().shape({
  email: yup
    .string()
    .email('Por favor ingresa un email válido')
    .required('El email es obligatorio')
    .trim(),
  newsletter_subscriber: yup
    .boolean()
    .required()
    .default(true),
  subscription_source: yup
    .string()
    .required()
    .default('website')
}); 