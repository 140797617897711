import emailjs from '@emailjs/browser';

// Constants
const DEFAULT_PHONE_NUMBER = '13213309934'; // Replace with your actual business phone
const EMAIL_SERVICE_ID = 'service_id'; // Replace with your EmailJS service ID
const EMAIL_TEMPLATE_ID = 'template_id'; // Replace with your template ID
const EMAIL_PUBLIC_KEY = 'public_key'; // Replace with your public key

/**
 * Detects if the current device is a mobile device
 * @returns {boolean} - True if the device is mobile, false otherwise
 */
const isMobileDevice = () => {
  return /Android|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};

/**
 * Detects the type of device
 * @returns {string} - 'mobile', 'tablet', or 'desktop'
 */
const detectDeviceType = () => {
  const userAgent = navigator.userAgent;
  
  if (/Android|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)) {
    return 'mobile';
  }
  
  if (/iPad|tablet|Tablet/i.test(userAgent)) {
    return 'tablet';
  }
  
  return 'desktop';
};

/**
 * Contact Service Class
 * Handles different contact methods based on device type
 */
class ContactService {
  constructor(phoneNumber = DEFAULT_PHONE_NUMBER) {
    this.phoneNumber = phoneNumber;
    this.emailServiceId = EMAIL_SERVICE_ID;
    this.emailTemplateId = EMAIL_TEMPLATE_ID;
    this.emailPublicKey = EMAIL_PUBLIC_KEY;
  }

  /**
   * Set WhatsApp phone number
   * @param {string} phoneNumber - The phone number to send messages to
   */
  setPhoneNumber(phoneNumber) {
    this.phoneNumber = phoneNumber;
  }

  /**
   * Configure EmailJS credentials
   * @param {Object} config - EmailJS configuration
   * @param {string} config.serviceId - EmailJS service ID
   * @param {string} config.templateId - EmailJS template ID
   * @param {string} config.publicKey - EmailJS public key
   */
  configureEmailJS({ serviceId, templateId, publicKey }) {
    if (serviceId) this.emailServiceId = serviceId;
    if (templateId) this.emailTemplateId = templateId;
    if (publicKey) this.emailPublicKey = publicKey;
  }

  /**
   * Create a formatted message from form data
   * @param {Object} formData - The form data with contact information
   * @returns {string} - Formatted message string
   */
  createMessage(formData) {
    const { firstName, email, phoneNumber, message } = formData;
    
    return `Hello SYL Roofing Supply! I'm interested in getting a quote.\n\nName: ${firstName}\nEmail: ${email}\nPhone: ${phoneNumber}${message ? `\n\nMessage: ${message}` : ''}`;
  }

  /**
   * Open WhatsApp with the given message
   * @param {string} message - The message to send
   * @returns {boolean} - True if successful, false otherwise
   */
  openWhatsApp(message) {
    try {
      const encodedMessage = encodeURIComponent(message);
      const whatsappUrl = `https://wa.me/${this.phoneNumber}?text=${encodedMessage}`;
      
      // On mobile devices, try to use the WhatsApp app directly
      if (isMobileDevice()) {
        // For mobile, we try to open in the WhatsApp app
        window.location.href = whatsappUrl;
      } else {
        // For desktop, open in a new tab
        window.open(whatsappUrl, '_blank');
      }
      return true;
    } catch (error) {
      console.error('Error opening WhatsApp:', error);
      return false;
    }
  }

  /**
   * Send an email using EmailJS
   * @param {Object} formData - The form data with contact information
   * @returns {Promise<boolean>} - Promise resolving to true if successful, false otherwise
   */
  async sendEmail(formData) {
    try {
      // Prepare template parameters
      const templateParams = {
        from_name: formData.firstName,
        from_email: formData.email,
        phone_number: formData.phoneNumber,
        message: formData.message || 'I am interested in getting a quote.',
      };

      // Send email using EmailJS
      const response = await emailjs.send(
        this.emailServiceId,
        this.emailTemplateId,
        templateParams,
        this.emailPublicKey
      );

      console.log('Email sent successfully:', response);
      return true;
    } catch (error) {
      console.error('Error sending email:', error);
      return false;
    }
  }

  /**
   * Get the best contact method based on device type
   * @returns {string} - The recommended contact method ('whatsapp', 'email')
   */
  getBestContactMethod() {
    const deviceType = detectDeviceType();
    
    switch (deviceType) {
      case 'mobile':
        return 'whatsapp';
      case 'tablet':
      case 'desktop':
      default:
        return 'email';
    }
  }
}

// Export singleton instance
const contactService = new ContactService();
export default contactService; 