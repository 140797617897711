// Estilos comunes para todos los dispositivos
export const containerStyles = {
  width: '100%',
  backgroundColor: '#FFFFFF'
};

export const contentWrapperStyles = {
  maxWidth: '1200px',
  margin: '0 auto',
  textAlign: 'center'
};

export const titleStyles = {
  fontWeight: 700,
  color: '#0A3954'
};

export const subtitleStyles = {
  color: '#4A5568',
  margin: '0 auto'
};

export const stepsContainerStyles = {
  display: 'flex',
  justifyContent: 'space-between'
};

export const stepItemStyles = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center'
};

export const stepImageWrapperStyles = {
};

export const stepImageStyles = {
  width: '100%',
  height: '100%',
  objectFit: 'contain'
};

export const stepNumberStyles = {
  fontWeight: 600,
  color: '#0A6EB5'
};

export const stepDescriptionStyles = {
  color: '#4A5568',
  margin: '0 auto'
};

export const buttonStyles = {
  backgroundColor: '#E96D25',
  color: '#FFFFFF',
  fontWeight: 600,
  textTransform: 'none',
  borderRadius: '32px',
  '&:hover': {
    backgroundColor: '#d54d0d'
  }
};

// Estilos para elementos SEO ocultos pero accesibles para lectores de pantalla
export const srOnlyStyles = { 
  position: 'absolute', 
  width: '1px', 
  height: '1px', 
  padding: 0, 
  margin: '-1px', 
  overflow: 'hidden', 
  clip: 'rect(0, 0, 0, 0)', 
  whiteSpace: 'nowrap', 
  borderWidth: 0 
}; 