/**
 * BrandHeader Common Styles
 * Estilos compartidos entre todas las plataformas
 */

export const containerStyles = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 3,
  padding: 2,
  backgroundColor: 'background.paper',
  borderRadius: 1,
  boxShadow: 1,
};

export const headerWrapperStyles = {
  display: 'flex',
  width: '100%',
  position: 'relative',
};

export const brandInfoContainerStyles = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
};

export const brandImageStyles = {
  objectFit: 'contain',
  marginRight: 2,
  borderRadius: 0.5,
};

export const brandContentStyles = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
};

export const brandTitleStyles = {
  color: '#0A3954',
  fontSize: 18,
  fontFamily: 'Open Sans',
  fontWeight: 700,
  lineHeight: '38.80px',
  wordWrap: 'break-word',
};

export const brandDescriptionStyles = {
  color: 'text.secondary',
  marginBottom: 1,
};

export const filtersContainerStyles = {
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  marginTop: 2,
  gap: 1,
  borderTop: '1px solid',
  borderColor: 'divider',
  paddingTop: 2,
};

export const filterChipStyles = {
  cursor: 'pointer',
};

export const backButtonStyles = {
  position: 'absolute',
  left: 0,
  top: 0,
  color: 'primary.main',
};

export const brandMetaContainerStyles = {
  display: 'flex',
  alignItems: 'center',
  marginTop: 1,
};

export const brandMetaItemStyles = {
  display: 'flex',
  alignItems: 'center',
  marginRight: 2,
  color: 'text.secondary',
  fontSize: '0.875rem',
};

export const iconStyles = {
  fontSize: '1rem',
  marginRight: 0.5,
  color: 'primary.main',
};

// Estilos para el botón "More"
export const moreButtonStyles = {
  position: 'absolute',
  bottom: -16,
  right: 0,
  padding: '0 8px',
  minWidth: 'unset',
  fontSize: '0.75rem',
  color: '#0A3954',
  fontWeight: 600,
  textTransform: 'none',
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: 'rgba(10, 57, 84, 0.04)',
  }
};

// Estilos para el modal
export const modalStyles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 2,
  p: 4,
  outline: 'none',
  maxHeight: '90vh',
  overflow: 'auto',
  width: '80%',
  maxWidth: '800px'
};

export const modalHeaderStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  mb: 3,
  pb: 1.5,
  borderBottom: '1px solid',
  borderColor: 'divider'
};

export const modalImageStyles = {
  borderRadius: '8px',
  objectFit: 'contain',
  width: '100px',
  height: '100px',
  mr: 3,
  mb: 0,
  flexShrink: 0
};

export const modalTitleStyles = {
  fontSize: '1.5rem',
  fontWeight: 700,
  color: '#0A3954'
};

export const modalDescriptionStyles = {
  lineHeight: 1.8,
};

// Estilos para la descripción truncada
export const truncatedDescriptionStyles = {
  WebkitLineClamp: 2,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
};

export const brandBannerContainerStyles = {
  width: '100%',
  position: 'relative',
  overflow: 'hidden',
  marginLeft: -2,
  marginRight: -2,
  marginTop: -2,
  '@media (max-width: 600px)': {
    width: '100vw',
    marginLeft: 'calc(-50vw + 50%)',
    marginRight: 'calc(-50vw + 50%)',
    marginTop: 0,
  }
};

export const brandBannerImageStyles = {
  width: '100%',
  display: 'block',
  maxHeight: '450px',
  objectFit: 'contain',
  objectPosition: 'center center',
  backgroundColor: '#f5f5f5',
}; 