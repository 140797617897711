import { apiClient } from '../../../shared/services/apiClient';
import { fetchUserNetSuiteId } from './firestoreService';

/**
 * Fetches the list of invoices for a user
 * @param {string} firebaseId - The Firebase ID of the user
 * @returns {Promise<Object>} The invoice data containing items and pagination info
 * @example Response format:
 * {
 *   links: [{ rel: string, href: string }],
 *   count: number,
 *   hasMore: boolean,
 *   offset: number,
 *   totalResults: number,
 *   offsetNext: number,
 *   items: [{
 *     billingstatus: string,
 *     email: string,
 *     id: string,
 *     recordtype: string,
 *     trandate: string,
 *     tranid: string,
 *     transactionnumber: string
 *   }]
 * }
 */
export const fetchInvoicesList = async (firebaseId) => {
  try {
    // Primero obtenemos el netSuiteId del usuario
    const netSuiteId = await fetchUserNetSuiteId(firebaseId);
    
    // Luego hacemos la llamada al endpoint de invoices
    const response = await apiClient.post(`/invoices/list/${netSuiteId}`);
    
    return response.data;
  } catch (error) {
    console.error('Error fetching invoices:', error);
    throw error;
  }
};

/**
 * Fetches the detail of a specific invoice
 * @param {string} invoiceId - The ID of the invoice to fetch
 * @returns {Promise<Object>} The invoice detail data
 */
export const fetchInvoiceDetail = async (invoiceId) => {
  try {
    const response = await apiClient.get(`/invoices/detail/${invoiceId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching invoice detail:', error);
    throw error;
  }
};
