/**
 * Common styles for the NotificationBanner component
 * These styles are shared across all devices
 */

export const containerStyles = {
  width: '100%',
  backgroundColor: '#E96D25', // Primary-700
  color: '#F8FAFD', // Text-100
  position: 'sticky',
  top: 0,
  left: 0,
  right: 0,
  py: 1,
  px: 2,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '60px',
  zIndex: 1300, // Aumentado para asegurar que esté por encima del header (1100)
  overflow: 'hidden', // Prevenir desbordamiento
};

export const contentWrapperStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  width: '100%',
  maxWidth: '1200px',
  mx: 'auto',
  pr: { xs: 6, sm: 4, md: 4 }, // Space for close button - Más espacio en móviles
};

export const titleStyles = {
  color: '#F8FAFD', // Text-100
  textAlign: 'center',
  fontFeatureSettings: "'liga' off, 'clig' off",
  fontFamily: '"Open Sans", sans-serif',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 800, // Extrabold
  lineHeight: 'normal',
  mb: 0.5,
  width: '100%',
};

export const descriptionStyles = {
  color: '#F8FAFD', // Text-100
  textAlign: 'center',
  fontFeatureSettings: "'liga' off, 'clig' off",
  fontFamily: '"Open Sans", sans-serif',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 600, // Semibold
  lineHeight: 'normal',
  width: '100%',
  mx: 'auto',
};

export const closeButtonStyles = {
  position: 'absolute',
  right: 8,
  top: '50%',
  transform: 'translateY(-50%)',
  color: '#F8FAFD', // Text-100
  p: 0.5,
  minWidth: 'unset',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
}; 