/**
 * TabletCarousel Component
 * Tablet-specific implementation of the BrandsCarousel
 */

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Box, Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import BrandCard from './BrandCard';

// Import styles directly from their source files
import { commonContainerStyles, commonSeoDescriptionStyles, commonTitleStyles } from '../styles/common';
import { 
  tabletContainerStyles, 
  tabletGridStyles,
  tabletTitleStyles
} from '../styles/tablet';

// Define additional styles needed for the carousel
const tabletCarouselContainerStyles = {
  marginTop: '16px',
  width: '100%',
  overflow: 'hidden'
};

const tabletCarouselGridStyles = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: '20px',
  justifyContent: 'flex-start',
  padding: '0 16px',
  width: '100%'
};

const TabletCarousel = ({ 
  brands, 
  tabletType = 'generic', 
  showTitle = true, 
  useAlternativeLayout = true,
  isInBrandProductsPage = false,
  currentBrandId = null
}) => {
  const { t } = useTranslation();
  
  return (
    <Box sx={{ ...commonContainerStyles, ...tabletContainerStyles }}>
      <Container maxWidth="lg" disableGutters>
        {showTitle && (
          <Typography 
            variant="h2" 
            component="h2" 
            sx={{ ...commonTitleStyles, ...tabletTitleStyles }}
          >
            {t('dashboard.brandsCarousel.title')}
          </Typography>
        )}
        
        {/* Hidden SEO description */}
        <Typography 
          component="p" 
          sx={commonSeoDescriptionStyles}
        >
          {t('dashboard.brandsCarousel.description')}
        </Typography>
        
        <Box sx={tabletCarouselContainerStyles}>
          <Box 
            sx={useAlternativeLayout ? tabletCarouselGridStyles : tabletGridStyles}
          >
            {brands.map((brand, index) => (
              <BrandCard 
                key={brand.id} 
                brand={brand} 
                deviceType="tablet"
                tabletType={tabletType}
                isFirstItem={index === 0}
                useAlternativeLayout={useAlternativeLayout}
                isCurrentBrand={currentBrandId === brand.id}
              />
            ))}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

TabletCarousel.propTypes = {
  brands: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      key: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired
    })
  ).isRequired,
  tabletType: PropTypes.oneOf(['iPadMini', 'iPadAir', 'iPadPro', 'generic']),
  showTitle: PropTypes.bool,
  useAlternativeLayout: PropTypes.bool,
  isInBrandProductsPage: PropTypes.bool,
  currentBrandId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

TabletCarousel.defaultProps = {
  tabletType: 'generic',
  showTitle: true,
  useAlternativeLayout: true,
  isInBrandProductsPage: false,
  currentBrandId: null
};

export default memo(TabletCarousel);