export const testReviews = [
  {
    id: '1',
    text: "Luis always goes above and beyond for me. I highly recommend to anyone. I have accounts with gulf eagle and beacon roofing supply but never have I ever experienced a situation where my material can't be delivered. He always keeps his word and in this business very hard to find now a days. Thanks again Luis and to your supply company",
    author: 'Alfredo',
    location: 'Tangerang'
  },
  {
    id: '2',
    text: 'Exceptional service and reliability. The team consistently delivers quality materials on time. Their professionalism and attention to detail make them stand out in the industry.',
    author: 'Michael',
    location: 'Orlando'
  },
  {
    id: '3',
    text: 'Best supplier in the area! Their commitment to customer satisfaction is unmatched. Always going the extra mile to ensure we have what we need for our projects.',
    author: 'Sarah',
    location: 'Tampa'
  }
]; 