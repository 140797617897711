import { createTheme } from '@mui/material/styles';

const customTheme = createTheme({
  palette: {
    primary: {
      main: '#E99B25',
      light: '#EDBEA2',
      dark: '#E96D25',
      500: '#E99B25',
      300: '#EDBEA2',
      700: '#E96D25',
    },
    secondary: {
      main: '#135C86',
      light: '#9EAEB7',
      dark: '#0A3954',
      700: '#0A3954',
      500: '#135C86',
      300: '#9EAEB7',
    },
    grey: {
      100: '#F0F3F5',
      300: '#A4B5C0',
      500: '#5B7A90',
      700: '#2D4E67',
    },
    text: {
      200: "#E7ECF3",
      500: "#1D232E"
    },
  },
  typography: {
    fontFamily: '"Open Sans", "Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontFamily: '"Open Sans", "Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: '2.5rem',
      fontWeight: 600,
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 500,
    },
    h3extrabold: {
      fontFamily: '"Open Sans", "Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: '32px',
      fontWeight: 800,
    },
    h4semiBold: {
      fontFamily: '"Open Sans", "Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: '24px',
      fontWeight: 600,
    },
    h5semiBold: {
      fontFamily: '"Open Sans", "Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: '18px',
      fontWeight: 600,
    },
    h6Bold: {
      fontFamily: '"Open Sans", "Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: '16px'
    },
    body1: {
      fontSize: '1rem',
    },
  },
  spacing: 8,
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 400;
          src: url(https://fonts.gstatic.com/s/opensans/v28/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4gaVc.ttf) format('truetype');
        }
      `,
    },
  }
});

export default customTheme;