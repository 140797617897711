// Datos para los enlaces rápidos
export const quickLinks = [
  { title: 'About Us', url: '#' },
  { title: 'Help Center', url: '#' }
];

// Datos para los horarios de operación
export const hoursOfOperation = [
  { day: 'Monday-Friday', hours: '7:00 am – 6:00 pm' },
  { day: 'Saturday', hours: '7:00 am – 3:00 pm' },
  { day: 'Sunday', hours: 'Closed' }
];

// Datos para la información de contacto
export const contactInfo = {
  email: 'quote@sylroofingsupply.com',
  phone: '+1 (321) 330-9934',
  locations: [
    { name: 'Tampa', address: '2005 N 43 St Tampa FL 33605' },
    { name: 'Orlando', address: '930 Carter Rd #303, Winter Garden, FL 34787' }
  ]
};

// Texto de copyright
export const copyrightText = '© 2024 Roofing Supplies in Tampa & Orlando - Quality Materials Nearby. All Rights Reserved.';

// Texto de promoción de la app con claves i18n
export const appPromoText = {
  title: 'footer.appPromo.title',
  seoTitle: 'footer.appPromo.seoTitle',
  seoDescription: 'footer.appPromo.seoDescription'
};

// Datos del newsletter con claves i18n
export const newsletterData = {
  title: 'footer.newsletter.title', // Clave de traducción
  subtitle: 'footer.newsletter.subtitle', // Clave de traducción
  emailPlaceholder: 'footer.newsletter.emailPlaceholder', // Clave de traducción para el placeholder
  // Claves SEO
  seoTitle: 'footer.newsletter.seoTitle',
  seoDescription: 'footer.newsletter.seoDescription',
  subtitleSeo: 'footer.newsletter.subtitleSeo',
  subtitleSeoDescription: 'footer.newsletter.subtitleSeoDescription',
  emailPlaceholderSeo: 'footer.newsletter.emailPlaceholderSeo',
  emailPlaceholderSeoDescription: 'footer.newsletter.emailPlaceholderSeoDescription'
}; 