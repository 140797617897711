import React from 'react';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

/**
 * SEO component for managing all document head metadata
 * Optimized for SYL Roofing Supply with geolocation-specific SEO
 * and multilingual support
 */
const SEO = ({
  title,
  titleEn,
  titleEs,
  description,
  descriptionEn,
  descriptionEs,
  keywords,
  keywordsEn,
  keywordsEs,
  canonicalUrl,
  ogType = 'website',
  ogImage = '/images/og-image.jpg',
  location = 'Tampa and Orlando, FL',
  schema = null,
  children
}) => {
  // Use react-i18next for language detection
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language || 'en';
  const isSpanish = currentLanguage.startsWith('es');
  
  // Determine title based on language
  let baseTitle;
  if (isSpanish) {
    // Spanish title with fallbacks
    baseTitle = titleEs || title || 'Materiales Premium para Techos';
  } else {
    // English title with fallbacks
    baseTitle = titleEn || title || 'Premium Roofing Materials & Supplies';
  }
  
  // Determine location text based on language
  const locationText = isSpanish ? 'Tampa y Orlando, FL' : 'Tampa and Orlando, FL';
  
  // Company name based on language
  const companyName = 'SYL Roofing Supply';
  
  // Full title with location for better SEO
  const fullTitle = `${baseTitle} | ${locationText} | ${companyName}`;
  
  // Determine description based on language
  let baseDescription;
  if (isSpanish) {
    // Spanish description with fallbacks
    baseDescription = descriptionEs || description || 
      'SYL Roofing Supply: Su distribuidor líder en materiales para techos en Florida. Atención personalizada en español, entrega al día siguiente y precios competitivos para contratistas.';
  } else {
    // English description with fallbacks
    baseDescription = descriptionEn || description || 
      'SYL Roofing Supply: Your leading distributor of roofing materials in Florida. Same-day delivery, competitive prices, and excellent customer service for contractors.';
  }
  
  // Default keywords combined with custom ones - language specific
  let defaultKeywords;
  let allKeywords;
  
  if (isSpanish) {
    defaultKeywords = 'materiales para techos, Florida, Tampa, Orlando, distribuidor mayorista, proveedor de materiales, atención en español';
    allKeywords = keywordsEs || keywords ? `${defaultKeywords}, ${keywordsEs || keywords}` : defaultKeywords;
  } else {
    defaultKeywords = 'roofing supplies, roofing materials, Florida roofing, Tampa roofing, Orlando roofing, wholesale roofing, contractor supplies';
    allKeywords = keywordsEn || keywords ? `${defaultKeywords}, ${keywordsEn || keywords}` : defaultKeywords;
  }

  // Generate structured data for LocalBusiness
  const baseSchema = {
    '@context': 'https://schema.org',
    '@type': 'RoofingContractor',
    'name': 'SYL Roofing Supply',
    'url': 'https://sylroofingsupply.com/',
    'logo': 'https://sylroofingsupply.com/images/logo.png',
    'image': 'https://sylroofingsupply.com/images/store-front.jpg',
    'description': baseDescription,
    'priceRange': '$$',
    'telephone': '407-534-2698',
    'email': 'quote@sylroofingsupply.com',
    'areaServed': [
      {
        '@type': 'City',
        'name': 'Tampa',
        'containedInPlace': {
          '@type': 'State',
          'name': 'Florida'
        }
      },
      {
        '@type': 'City',
        'name': 'Orlando',
        'containedInPlace': {
          '@type': 'State',
          'name': 'Florida'
        }
      }
    ],
    'address': [
      {
        '@type': 'PostalAddress',
        'streetAddress': '2005 N 43 St',
        'addressLocality': 'Tampa',
        'addressRegion': 'FL',
        'postalCode': '33605',
        'addressCountry': 'US'
      },
      {
        '@type': 'PostalAddress',
        'streetAddress': '930 Carter Rd #303',
        'addressLocality': 'Winter Garden',
        'addressRegion': 'FL',
        'postalCode': '34787',
        'addressCountry': 'US'
      }
    ],
    'openingHoursSpecification': [
      {
        '@type': 'OpeningHoursSpecification',
        'dayOfWeek': ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
        'opens': '07:00',
        'closes': '18:00'
      },
      {
        '@type': 'OpeningHoursSpecification',
        'dayOfWeek': ['Saturday'],
        'opens': '07:00',
        'closes': '15:00'
      }
    ]
  };

  // Use custom schema if provided, otherwise use base schema
  const finalSchema = schema || baseSchema;

  return (
    <Helmet>
      {/* Title */}
      <title>{fullTitle}</title>
      
      {/* Meta tags */}
      <meta name="description" content={baseDescription} />
      <meta name="keywords" content={allKeywords} />
      <meta name="author" content="SYL Roofing Supply" />
      
      {/* Geo tags */}
      <meta name="geo.region" content="US-FL" />
      <meta name="geo.placename" content="Tampa, Orlando, Florida" />
      <meta name="geo.position" content="28.0395;-82.5071" />
      <meta name="ICBM" content="28.0395, -82.5071" />
      
      {/* Language alternates */}
      <link 
        rel="alternate" 
        href={`https://sylroofingsupply.com${canonicalUrl || ''}`} 
        hrefLang="en-us" 
      />
      <link 
        rel="alternate" 
        href={`https://sylroofingsupply.com/es${canonicalUrl || ''}`} 
        hrefLang="es-us" 
      />
      
      {/* Canonical URL */}
      {canonicalUrl && (
        <link rel="canonical" href={`https://sylroofingsupply.com${canonicalUrl}`} />
      )}
      
      {/* Open Graph */}
      <meta property="og:title" content={fullTitle} />
      <meta property="og:description" content={baseDescription} />
      <meta property="og:url" content={canonicalUrl ? `https://sylroofingsupply.com${canonicalUrl}` : 'https://sylroofingsupply.com'} />
      <meta property="og:type" content={ogType} />
      <meta property="og:image" content={`https://sylroofingsupply.com${ogImage}`} />
      <meta property="og:locale" content={isSpanish ? 'es_US' : 'en_US'} />
      <meta property="og:locale:alternate" content={isSpanish ? 'en_US' : 'es_US'} />
      <meta property="og:site_name" content="SYL Roofing Supply" />
      
      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={fullTitle} />
      <meta name="twitter:description" content={baseDescription} />
      <meta name="twitter:image" content={`https://sylroofingsupply.com${ogImage}`} />
      
      {/* Structured Data / JSON-LD */}
      <script type="application/ld+json">
        {JSON.stringify(finalSchema)}
      </script>
      
      {/* Additional tags */}
      {children}
    </Helmet>
  );
};

SEO.propTypes = {
  title: PropTypes.string,
  titleEn: PropTypes.string,
  titleEs: PropTypes.string,
  description: PropTypes.string,
  descriptionEn: PropTypes.string,
  descriptionEs: PropTypes.string,
  keywords: PropTypes.string,
  keywordsEn: PropTypes.string,
  keywordsEs: PropTypes.string,
  canonicalUrl: PropTypes.string,
  ogType: PropTypes.string,
  ogImage: PropTypes.string,
  location: PropTypes.string,
  schema: PropTypes.object,
  children: PropTypes.node
};

export default SEO; 