import React from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { 
  Container, 
  Box, 
  Typography, 
  Paper, 
  Grid, 
  Link,
  Divider
} from '@mui/material';
import RegisterForm from '../components/RegisterForm';
import { isAuthenticated } from '../services/authService';

const RegisterPage = () => {
  const navigate = useNavigate();

  // Redirigir si ya está autenticado
  React.useEffect(() => {
    if (isAuthenticated()) {
      navigate('/dashboard');
    }
  }, [navigate]);

  const handleRegisterSuccess = () => {
    navigate('/dashboard');
  };

  return (
    <Container maxWidth="lg">
      <Grid container spacing={4} sx={{ minHeight: '100vh', py: 4 }}>
        <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ mb: 4, textAlign: { xs: 'center', md: 'left' } }}>
              <img 
                src="/logo.png" 
                alt="SYL Roofing Supply" 
                style={{ maxWidth: '200px', height: 'auto' }} 
              />
            </Box>
            
            <Typography variant="h4" component="h1" gutterBottom>
              Crea tu cuenta
            </Typography>
            
            <Typography variant="body1" color="text.secondary" paragraph>
              Regístrate para acceder a precios exclusivos, seguimiento de pedidos y más.
            </Typography>
            
            <Paper elevation={3} sx={{ p: 4, borderRadius: 2, mt: 4 }}>
              <RegisterForm onSuccess={handleRegisterSuccess} />
              
              <Divider sx={{ my: 3 }}>
                <Typography variant="body2" color="text.secondary">
                  O
                </Typography>
              </Divider>
              
              <Box sx={{ textAlign: 'center' }}>
                <Typography variant="body2" color="text.secondary">
                  ¿Ya tienes una cuenta?{' '}
                  <Link component={RouterLink} to="/login" underline="hover">
                    Inicia sesión
                  </Link>
                </Typography>
              </Box>
            </Paper>
          </Box>
        </Grid>
        
        <Grid 
          item 
          xs={12} 
          md={6} 
          sx={{ 
            display: { xs: 'none', md: 'flex' }, 
            alignItems: 'center', 
            justifyContent: 'center' 
          }}
        >
          <Box 
            sx={{ 
              width: '100%', 
              height: '100%', 
              backgroundImage: 'url(/images/register-background.jpg)',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              borderRadius: 2,
              boxShadow: 3
            }}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default RegisterPage; 