/**
 * Styles index
 * Exports all styles for the SpecialPromotionLayout component
 */

// Import all styles
import * as commonStyles from './common';
import * as mobileStyles from './mobile';
import * as tabletStyles from './tablet';
import * as desktopStyles from './desktop';

// Re-export all styles
export * from './common';
export * from './mobile';
export * from './tablet';
export * from './desktop';

// Export combined styles for backward compatibility
export const containerStyles = {
  ...commonStyles.containerStyles,
  py: { xs: mobileStyles.mobileContainerStyles.py, md: desktopStyles.desktopContainerStyles.py }
};

export const containerContentStyles = {
  ...commonStyles.containerContentStyles,
  px: { xs: mobileStyles.mobileContainerContentStyles.px, md: desktopStyles.desktopContainerContentStyles.px }
};

export const cardsWrapperStyles = {
  ...commonStyles.cardsWrapperStyles,
  gap: { xs: mobileStyles.mobileCardsWrapperStyles.gap, sm: tabletStyles.tabletCardsWrapperStyles.gap, md: desktopStyles.desktopCardsWrapperStyles.gap },
  px: { xs: mobileStyles.mobileCardsWrapperStyles.px, sm: tabletStyles.tabletCardsWrapperStyles.px, md: desktopStyles.desktopCardsWrapperStyles.px }
};

// PromotionalCard combined styles
export const promotionalCardStyles = {
  ...commonStyles.commonPromotionalCardStyles,
  flexDirection: { xs: mobileStyles.mobilePromotionalCardStyles.flexDirection, sm: tabletStyles.tabletPromotionalCardStyles.flexDirection, md: desktopStyles.desktopPromotionalCardStyles.flexDirection },
  borderRadius: { xs: mobileStyles.mobilePromotionalCardStyles.borderRadius, sm: tabletStyles.tabletPromotionalCardStyles.borderRadius, md: desktopStyles.desktopPromotionalCardStyles.borderRadius }
};

// Usamos una función regular para el estilo del wrapper de imagen que necesita el theme
export const promotionalImageWrapperStyles = theme => ({
  ...commonStyles.commonPromotionalImageWrapperStyles,
  width: { 
    xs: '100%', 
    sm: tabletStyles.tabletPromotionalImageWrapperStyles.width,
    md: desktopStyles.desktopPromotionalImageWrapperStyles.width 
  },
  height: theme.breakpoints.up('md') ? desktopStyles.desktopPromotionalImageWrapperStyles.height : 
         theme.breakpoints.between('sm', 'md') ? tabletStyles.tabletPromotionalImageWrapperStyles.height : 
         mobileStyles.mobilePromotionalImageWrapperStyles.height,
  borderRadius: { 
    xs: mobileStyles.mobilePromotionalImageWrapperStyles.borderRadius, 
    sm: tabletStyles.tabletPromotionalImageWrapperStyles.borderRadius,
    md: desktopStyles.desktopPromotionalImageWrapperStyles.borderRadius 
  },
  m: { 
    xs: mobileStyles.mobilePromotionalImageWrapperStyles.m, 
    sm: tabletStyles.tabletPromotionalImageWrapperStyles.m,
    md: desktopStyles.desktopPromotionalImageWrapperStyles.m 
  },
  order: { 
    xs: mobileStyles.mobilePromotionalImageWrapperStyles.order, 
    sm: tabletStyles.tabletPromotionalImageWrapperStyles.order,
    md: desktopStyles.desktopPromotionalImageWrapperStyles.order 
  },
  padding: { 
    xs: mobileStyles.mobilePromotionalImageWrapperStyles.padding, 
    sm: tabletStyles.tabletPromotionalImageWrapperStyles.padding, 
    md: desktopStyles.desktopPromotionalImageWrapperStyles.padding 
  },
  paddingBottom: { 
    xs: mobileStyles.mobilePromotionalImageWrapperStyles.paddingBottom, 
    sm: tabletStyles.tabletPromotionalImageWrapperStyles.paddingBottom, 
    md: desktopStyles.desktopPromotionalImageWrapperStyles.paddingBottom 
  }
});

export const promotionalImageStyles = commonStyles.commonPromotionalImageStyles;

export const promotionalContentStyles = {
  ...commonStyles.commonPromotionalContentStyles,
  width: { 
    xs: '100%', 
    sm: tabletStyles.tabletPromotionalContentStyles.width,
    md: desktopStyles.desktopPromotionalContentStyles.width 
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: { 
    xs: 'flex-start', 
    sm: 'center',
    md: 'center' 
  },
  p: { 
    xs: mobileStyles.mobilePromotionalContentStyles.p, 
    sm: tabletStyles.tabletPromotionalContentStyles.p, 
    md: desktopStyles.desktopPromotionalContentStyles.p 
  },
  ml: { 
    xs: 0,
    sm: 0,
    md: desktopStyles.desktopPromotionalContentStyles.ml 
  },
  order: { 
    xs: mobileStyles.mobilePromotionalContentStyles.order, 
    sm: tabletStyles.tabletPromotionalContentStyles.order,
    md: desktopStyles.desktopPromotionalContentStyles.order 
  },
  alignItems: { 
    xs: mobileStyles.mobilePromotionalContentStyles.alignItems, 
    sm: tabletStyles.tabletPromotionalContentStyles.alignItems, 
    md: desktopStyles.desktopPromotionalContentStyles.alignItems 
  }
};

export const promotionalTitleStyles = {
  ...commonStyles.commonPromotionalTitleStyles,
  width: { 
    xs: mobileStyles.mobilePromotionalTitleStyles.width, 
    sm: tabletStyles.tabletPromotionalTitleStyles.width,
    md: desktopStyles.desktopPromotionalTitleStyles.width 
  },
  fontSize: { 
    xs: mobileStyles.mobilePromotionalTitleStyles.fontSize, 
    sm: tabletStyles.tabletPromotionalTitleStyles.fontSize,
    md: desktopStyles.desktopPromotionalTitleStyles.fontSize 
  },
  textAlign: { 
    xs: mobileStyles.mobilePromotionalTitleStyles.textAlign, 
    sm: tabletStyles.tabletPromotionalTitleStyles.textAlign, 
    md: desktopStyles.desktopPromotionalTitleStyles.textAlign 
  },
  height: { 
    xs: mobileStyles.mobilePromotionalTitleStyles.height, 
    sm: tabletStyles.tabletPromotionalTitleStyles.height,
    md: desktopStyles.desktopPromotionalTitleStyles.height 
  },
  px: { 
    xs: mobileStyles.mobilePromotionalTitleStyles.px, 
    sm: tabletStyles.tabletPromotionalTitleStyles.px,
    md: desktopStyles.desktopPromotionalTitleStyles.px 
  },
  pt: { 
    xs: mobileStyles.mobilePromotionalTitleStyles.pt, 
    sm: tabletStyles.tabletPromotionalTitleStyles.pt,
    md: desktopStyles.desktopPromotionalTitleStyles.pt 
  },
  mb: { 
    xs: 1, 
    sm: tabletStyles.tabletPromotionalTitleStyles.mb, 
    md: desktopStyles.desktopPromotionalTitleStyles.mb 
  }
};

export const promotionalSubtitleStyles = {
  ...commonStyles.commonPromotionalSubtitleStyles,
  width: { 
    xs: mobileStyles.mobilePromotionalSubtitleStyles.width, 
    sm: tabletStyles.tabletPromotionalSubtitleStyles.width,
    md: desktopStyles.desktopPromotionalSubtitleStyles.width 
  },
  fontSize: { 
    xs: mobileStyles.mobilePromotionalSubtitleStyles.fontSize, 
    sm: tabletStyles.tabletPromotionalSubtitleStyles.fontSize,
    md: desktopStyles.desktopPromotionalSubtitleStyles.fontSize 
  },
  textAlign: { 
    xs: mobileStyles.mobilePromotionalSubtitleStyles.textAlign, 
    sm: tabletStyles.tabletPromotionalSubtitleStyles.textAlign, 
    md: desktopStyles.desktopPromotionalSubtitleStyles.textAlign 
  },
  height: { 
    xs: mobileStyles.mobilePromotionalSubtitleStyles.height, 
    sm: tabletStyles.tabletPromotionalSubtitleStyles.height,
    md: desktopStyles.desktopPromotionalSubtitleStyles.height 
  },
  px: { 
    xs: mobileStyles.mobilePromotionalSubtitleStyles.px, 
    sm: tabletStyles.tabletPromotionalSubtitleStyles.px,
    md: desktopStyles.desktopPromotionalSubtitleStyles.px 
  },
  py: { 
    xs: mobileStyles.mobilePromotionalSubtitleStyles.py, 
    sm: tabletStyles.tabletPromotionalSubtitleStyles.py,
    md: desktopStyles.desktopPromotionalSubtitleStyles.py 
  },
  mb: { 
    xs: 1, 
    sm: tabletStyles.tabletPromotionalSubtitleStyles.mb, 
    md: desktopStyles.desktopPromotionalSubtitleStyles.mb 
  }
};

export const promotionalButtonWrapperStyles = {
  ...commonStyles.commonPromotionalButtonWrapperStyles,
  justifyContent: { 
    xs: mobileStyles.mobilePromotionalButtonWrapperStyles.justifyContent, 
    sm: tabletStyles.tabletPromotionalButtonWrapperStyles.justifyContent, 
    md: desktopStyles.desktopPromotionalButtonWrapperStyles.justifyContent 
  },
  mt: { 
    xs: mobileStyles.mobilePromotionalButtonWrapperStyles.mt, 
    sm: tabletStyles.tabletPromotionalButtonWrapperStyles.mt,
    md: desktopStyles.desktopPromotionalButtonWrapperStyles.mt 
  },
  px: { 
    xs: mobileStyles.mobilePromotionalButtonWrapperStyles.px, 
    sm: tabletStyles.tabletPromotionalButtonWrapperStyles.px, 
    md: desktopStyles.desktopPromotionalButtonWrapperStyles.px 
  },
  pb: { 
    xs: mobileStyles.mobilePromotionalButtonWrapperStyles.pb, 
    sm: tabletStyles.tabletPromotionalButtonWrapperStyles.pb,
    md: desktopStyles.desktopPromotionalButtonWrapperStyles.pb 
  }
};

export const promotionalButtonStyles = {
  ...commonStyles.commonPromotionalButtonStyles,
  width: { 
    xs: mobileStyles.mobilePromotionalButtonStyles.width, 
    sm: tabletStyles.tabletPromotionalButtonStyles.width, 
    md: desktopStyles.desktopPromotionalButtonStyles.width 
  },
  fontSize: mobileStyles.mobilePromotionalButtonStyles.fontSize,
  py: mobileStyles.mobilePromotionalButtonStyles.py,
  px: mobileStyles.mobilePromotionalButtonStyles.px
};

// OfferCard combined styles
export const offerCardStyles = {
  ...commonStyles.commonOfferCardStyles,
  width: { 
    xs: mobileStyles.mobileOfferCardStyles.width, 
    sm: tabletStyles.tabletOfferCardStyles.width, 
    md: desktopStyles.desktopOfferCardStyles.width 
  },
  height: { 
    xs: mobileStyles.mobileOfferCardStyles.height, 
    sm: tabletStyles.tabletOfferCardStyles.height, 
    md: desktopStyles.desktopOfferCardStyles.height 
  }
};

export const offerCardMediaStyles = {
  ...commonStyles.commonOfferCardMediaStyles,
  height: { 
    xs: mobileStyles.mobileOfferCardMediaStyles.height, 
    sm: tabletStyles.tabletOfferCardMediaStyles.height, 
    md: desktopStyles.desktopOfferCardMediaStyles.height 
  },
  p: { 
    xs: mobileStyles.mobileOfferCardMediaStyles.p, 
    sm: tabletStyles.tabletOfferCardMediaStyles.p, 
    md: desktopStyles.desktopOfferCardMediaStyles.p 
  }
};

export const offerCardContentStyles = {
  ...commonStyles.commonOfferCardContentStyles,
  p: { 
    xs: mobileStyles.mobileOfferCardContentStyles.p, 
    sm: tabletStyles.tabletOfferCardContentStyles.p, 
    md: desktopStyles.desktopOfferCardContentStyles.p 
  },
  '&:last-child': {
    pb: { 
      xs: mobileStyles.mobileOfferCardContentStyles['&:last-child'].pb, 
      sm: tabletStyles.tabletOfferCardContentStyles['&:last-child'].pb, 
      md: desktopStyles.desktopOfferCardContentStyles['&:last-child'].pb 
    }
  }
};

export const offerCardTitleStyles = {
  ...commonStyles.commonOfferCardTitleStyles,
  mb: { 
    xs: mobileStyles.mobileOfferCardTitleStyles.mb, 
    sm: tabletStyles.tabletOfferCardTitleStyles.mb, 
    md: desktopStyles.desktopOfferCardTitleStyles.mb 
  },
  fontSize: { 
    xs: mobileStyles.mobileOfferCardTitleStyles.fontSize, 
    sm: tabletStyles.tabletOfferCardTitleStyles.fontSize, 
    md: desktopStyles.desktopOfferCardTitleStyles.fontSize 
  }
};

export const offerCardPriceStyles = {
  ...commonStyles.commonOfferCardPriceStyles,
  fontSize: { 
    xs: mobileStyles.mobileOfferCardPriceStyles.fontSize, 
    sm: tabletStyles.tabletOfferCardPriceStyles.fontSize, 
    md: desktopStyles.desktopOfferCardPriceStyles.fontSize 
  }
}; 