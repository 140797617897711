/**
 * Brand Products Service
 * Handles brand products data management
 */

import gafData from '../../../data/gafProducts.json';
// Asumiendo que los otros JSON están en la misma ubicación
import tamkoData from '../../../data/tamkoProducts.json';
import ikoData from '../../../data/ikoProducts.json';

// Mapa de datos por marca
const brandDataMap = {
  'brand_gaf': gafData,
  'brand_tamko': tamkoData,
  'brand_iko': ikoData
};

/**
 * Get brand products
 * @param {string} brandId - The brand ID (e.g., 'brand_gaf')
 * @returns {Promise<Object>} - Brand and products data
 */
export const getBrandProducts = async (brandId) => {
  try {
    // Verificar si el brandId es válido antes de proceder
    if (!brandId) {
      console.error('No brand ID provided');
      return { brand: null, products: [] };
    }

    // Registrar la solicitud para fines de diagnóstico
    console.log(`Fetching products for brand: ${brandId}`);
    
    // Obtener los datos de la marca
    const brandData = brandDataMap[brandId];
    
    if (!brandData) {
      console.error(`No data found for brand ID: ${brandId}. Available brands: ${Object.keys(brandDataMap).join(', ')}`);
      return { brand: null, products: [] };
    }

    // Extraer la información de la marca y productos
    const [brand] = brandData.brands;
    const { products } = brandData;

    // Adaptar los datos al formato esperado por el componente
    const formattedBrand = {
      ...brand,
      key: brandId // Asegurar que tenemos la key para la navegación
    };

    const formattedProducts = products.map(product => ({
      id: product.id,
      name: product.name,
      price: product.price,
      image: product.images[0], // Usar la primera imagen como principal
      description: product.description,
      stock: product.stock,
      rating: product.rating,
      reviewCount: product.reviewCount,
      specifications: product.specifications,
      isTopSeller: product.isTopSeller
    }));

    return {
      brand: formattedBrand,
      products: formattedProducts
    };
  } catch (error) {
    console.error(`Error loading brand products for ${brandId}:`, error);
    return { brand: null, products: [] };
  }
};
