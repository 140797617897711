/**
 * Desktop styles
 * Styles specific to desktop devices for the ProductCard component
 */

// Desktop-specific styles for the product card
export const desktopProductCardStyles = {
  width: '100%',
  height: '100%',
  marginBottom: '8px'
};

// Desktop-specific styles for the product image
export const desktopProductImageStyles = {
  height: 'auto',
  width: '100%'
};

// Desktop-specific styles for the content container
export const desktopContentContainerStyles = {
  height: 'auto',
  padding: '16px 18px'
};

// Desktop-specific styles for the product name
export const desktopProductNameStyles = {
  fontSize: '1.05rem'
};

// Desktop-specific styles for the product price
export const desktopProductPriceStyles = {
  fontSize: '1.125rem'
};

// Desktop-specific styles for the product details
export const desktopProductDetailsStyles = {
  padding: '8px 0'
}; 