/**
 * QuickFilters Component
 * Muestra filtros rápidos para los productos de la marca
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Box, Chip } from '@mui/material';

import { 
  filtersContainerStyles, 
  filterChipStyles,
  mobileFiltersContainerStyles
} from '../styles/index';

const QuickFilters = ({ filters, selectedFilter, onFilterChange, deviceType }) => {
  // Si no hay filtros, no renderizar nada
  if (!filters || filters.length === 0) {
    return null;
  }
  
  // Determinar los estilos según el dispositivo
  const containerStyles = {
    ...filtersContainerStyles,
    ...(deviceType === 'mobile' && mobileFiltersContainerStyles)
  };
  
  return (
    <Box>
      <Box sx={containerStyles}>
        {filters.map((filter) => (
          <Chip
            key={filter.id}
            label={filter.name}
            clickable
            color={selectedFilter === filter.id ? 'primary' : 'default'}
            variant={selectedFilter === filter.id ? 'filled' : 'outlined'}
            onClick={() => onFilterChange(filter.id)}
            sx={filterChipStyles}
          />
        ))}
      </Box>
    </Box>
  );
};

QuickFilters.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired,
  selectedFilter: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onFilterChange: PropTypes.func.isRequired,
  deviceType: PropTypes.oneOf(['mobile', 'tablet', 'desktop']).isRequired
};

export default QuickFilters; 