export const containerStyles = {
  width: '100%', 
  position: 'relative',
  mt: { xs: 0, sm: '30px', md: '50px' },
  borderRadius: { xs: 0, sm: '16px', md: '24px' },
  overflow: 'hidden'
};

export const swiperStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};

export const slideWrapperStyles = {
  width: '100%',
  maxWidth: { xs: '100%', sm: '900px', md: '1200px' },
  margin: '0 auto',
  padding: { xs: '0', sm: '24px', md: '34px' },
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};

// Estilos para la versión móvil en forma de card
export const mobileCardStyles = {
  width: '180px',
  height: '280px',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: 2,
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
  backgroundColor: '#082E44',
  overflow: 'hidden',
  mx: 'auto'
};

export const mobileCardMediaStyles = {
  height: '140px',
  width: '100%',
  objectFit: 'contain',
  backgroundColor: '#082E44',
  p: 1
};

export const mobileCardContentStyles = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  p: 1.5,
  '&:last-child': {
    pb: 1.5
  }
};

export const mobilePriceStyles = {
  fontWeight: 900,
  fontSize: '1.5rem',
  color: 'white',
  mb: 0.5
};

export const mobileProductStyles = {
  fontWeight: 600,
  fontSize: '0.9rem',
  color: 'white',
  mb: 1
};

// Estilos originales para desktop y tablet
export const cardContainerStyles = {
  width: '100%',
  display: 'flex',
  flexDirection: { xs: 'column', sm: 'row', md: 'row' },
  borderRadius: { xs: 0, sm: '12px', md: '16px' },
  overflow: 'hidden',
  backgroundColor: '#082E44',
  height: { xs: 'auto', sm: '320px', md: '400px' }
};

export const leftPanelStyles = {
  flex: { xs: '1', sm: '0 0 55%', md: '0 0 55%' },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: { xs: 'flex-start', sm: 'center', md: 'center' },
  gap: { xs: '8px', sm: '16px', md: '24px' },
  p: { xs: '16px 20px', sm: '24px 32px', md: '32px 48px' },
  color: 'white',
  order: { xs: 2, sm: 1, md: 1 }
};

export const titleOutlineStyles = {
  width: { xs: '100%', sm: '350px', md: '434.79px' },
  height: { xs: 'auto', sm: '45px', md: '58.44px' },
  color: 'white',
  fontSize: { xs: '28px', sm: '42px', md: '60.40px' },
  fontFamily: 'Montserrat',
  fontStyle: 'italic',
  fontWeight: 900,
  lineHeight: { xs: '1.1', sm: '42px', md: '55.33px' },
  wordWrap: 'break-word',
  display: 'inline-block',
  position: 'relative',
  mb: { xs: '4px', sm: '8px', md: '16px' },
  maxWidth: '100%',
  textAlign: 'left'
};

export const subtitleStyles = {
  width: '100%',
  position: 'relative',
  fontSize: { xs: '18px', sm: '28px', md: '40px' },
  lineHeight: { xs: '1.2', sm: '92%', md: '91.6%' },
  display: 'inline-block',
  fontWeight: 900,
  fontFamily: 'Montserrat',
  color: '#fff',
  whiteSpace: 'pre-wrap',
  textAlign: 'left',
  height: { xs: 'auto', sm: '40px', md: '50.3px' },
  mb: { xs: '12px', sm: '20px', md: '32px' }
};

export const buttonWrapperStyles = {
  width: { xs: '80%', sm: '60%', md: '50%' },
  position: 'relative',
  borderRadius: { xs: '31.04px', sm: '31.04px', md: '31.04px' },
  backgroundColor: '#fff',
  height: { xs: '32px', sm: '48px', md: '62.1px' },
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: { xs: 'flex-start', sm: 'flex-start', md: 'flex-start' },
  padding: { xs: '4px 8px', sm: '8px 12px', md: '12.6px 16.1px' },
  boxSizing: 'border-box',
  gap: { xs: '6px', sm: '8px', md: '11.5px' },
  textAlign: 'left',
  fontSize: { xs: '12px', sm: '16px', md: '18.39px' },
  color: '#0a3954',
  fontFamily: 'Open Sans',
  mb: { xs: 0, md: 0 },
  ml: { xs: 0, md: 0 }
};

export const iconWrapperStyles = {
  width: { xs: '24px', sm: '36px', md: '44.9px' },
  borderRadius: { xs: '12px', sm: '18px', md: '22.79px' },
  backgroundColor: '#e96d25',
  height: { xs: '24px', sm: '36px', md: '44.9px' },
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: { xs: '4px', sm: '5px', md: '6.3px' },
  boxSizing: 'border-box'
};

export const iconStyles = {
  width: { xs: '16px', sm: '20px', md: '24.5px' },
  height: { xs: '16px', sm: '20px', md: '24.5px' },
  color: 'white'
};

export const buttonTextStyles = {
  width: { xs: 'auto', sm: '100px', md: '126.5px' },
  position: 'relative',
  fontWeight: 600,
  display: 'inline-block',
  height: { xs: 'auto', sm: '20px', md: '25.3px' },
  flexShrink: 0,
  fontSize: { xs: '12px', sm: '16px', md: '18.39px' }
};

export const rightPanelStyles = {
  flex: { xs: '1', sm: '0 0 45%', md: '0 0 45%' },
  position: 'relative',
  height: { xs: '180px', sm: '320px', md: '400px' },
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#082E44',
  p: { xs: 0, sm: '20px', md: '30px' },
  pr: { sm: '120px', md: '180px' },
  order: { xs: 1, sm: 2, md: 2 }
};

export const imageStyles = {
  width: '100%',
  height: '100%',
  maxWidth: { xs: '100%', sm: '90%', md: '90%' },
  maxHeight: { xs: '100%', sm: '90%', md: '90%' },
  objectFit: { xs: 'contain', sm: 'contain', md: 'contain' },
  display: 'block',
  margin: { xs: 0, sm: 'auto', md: 'auto' },
  mr: { sm: '160px', md: '240px' },
  p: { xs: 2, sm: 0, md: 0 }
}; 