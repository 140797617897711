import React, { useState } from 'react';
import {
  Box,
  Typography,
  Paper,
  Collapse,
  Button,
  CircularProgress,
  useTheme,
  useMediaQuery,
  Divider
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { fetchInvoiceDetail } from '../../services/invoiceService';
import InvoiceItems from '../InvoiceItems';
import { useTranslation } from 'react-i18next';

const InvoiceCard = ({ invoice }) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { data: invoiceDetail, isLoading } = useQuery({
    queryKey: ['invoiceDetail', invoice.id],
    queryFn: () => fetchInvoiceDetail(invoice.id),
    enabled: expanded,
  });

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Box sx={{ mb: isMobile ? 0 : 2 }}>
      <Paper
        elevation={0}
        sx={{
          borderRadius: isMobile ? 0 : expanded ? '16px 16px 0 0' : '16px',
          overflow: 'hidden',
          bgcolor: 'white',
          border: '1px solid #E2E8F0',
          boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)',
          ...(isMobile && {
            borderLeft: 'none',
            borderRight: 'none',
            boxShadow: 'none'
          })
        }}
      >
        {/* Main Content */}
        <Box sx={{ p: { xs: 2, sm: 3 } }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: { xs: 'flex-start', sm: 'flex-start' },
              width: '100%',
              mb: 2
            }}
          >
            {/* Top Section for Mobile */}
            <Box sx={{ 
              display: 'flex', 
              width: '100%', 
              justifyContent: 'space-between',
              mb: { xs: 2, sm: 0 }
            }}>
              {/* Invoice ID and Date */}
              <Box>
                <Typography
                  variant="h6"
                  sx={{
                    color: '#1E293B',
                    fontSize: { xs: '1rem', sm: '1.25rem' },
                    fontWeight: 600,
                    mb: 0.5
                  }}
                >
                  {invoice.tranid}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: '#64748B',
                    fontSize: '0.875rem'
                  }}
                >
                  {new Date(invoice.trandate).toLocaleDateString('en-US', {
                    month: 'short',
                    day: '2-digit',
                    year: 'numeric'
                  })}
                </Typography>
              </Box>

              {/* Payment Status for Mobile */}
              {isMobile && (
                <Box sx={{ textAlign: 'right' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography
                      variant="body2"
                      sx={{
                        color: '#0369A1',
                        fontSize: '0.875rem',
                        fontWeight: 500,
                        mr: 1
                      }}
                    >
                      {t('invoice.paymentStatus.paidFull')}
                    </Typography>
                    <CheckCircleIcon
                      sx={{
                        color: '#22C55E',
                        fontSize: '1.25rem'
                      }}
                    />
                  </Box>
                </Box>
              )}
            </Box>

            {isMobile && <Divider sx={{ width: '100%', my: 2 }} />}

            {/* Ship Address - Oculto temporalmente */}
            {/* 
            <Box sx={{ 
              flex: 1, 
              ml: { sm: 4 },
              width: '100%',
              order: { xs: 2, sm: 1 }
            }}>
              <Typography
                variant="caption"
                sx={{
                  color: '#94A3B8',
                  fontSize: '0.75rem',
                  display: 'block',
                  mb: 0.5
                }}
              >
                {t('invoice.shipAddress')}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: '#1E293B',
                  fontSize: '0.875rem'
                }}
              >
                {invoice.shipaddress}
              </Typography>
            </Box>
            */}

            {/* Total Amount - Oculto temporalmente */}
            {/*
            <Box sx={{ 
              mx: { sm: 4 },
              mt: { xs: 2, sm: 0 },
              order: { xs: 1, sm: 2 }
            }}>
              <Typography
                variant="caption"
                sx={{
                  color: '#94A3B8',
                  fontSize: '0.75rem',
                  display: 'block',
                  mb: 0.5
                }}
              >
                {t('invoice.totalAmount')}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: '#F97316',
                  fontSize: '1.25rem',
                  fontWeight: 600
                }}
              >
                ${Number(invoice.amount).toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}
              </Typography>
            </Box>
            */}

            {/* Payment Status for Desktop */}
            {!isMobile && (
              <Box sx={{ 
                textAlign: 'right',
                order: 3
              }}>
                <Typography
                  variant="caption"
                  sx={{
                    color: '#94A3B8',
                    fontSize: '0.75rem',
                    display: 'block',
                    mb: 0.5
                  }}
                >
                  {t('invoice.payment')}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography
                    variant="body2"
                    sx={{
                      color: '#0369A1',
                      fontSize: '0.875rem',
                      fontWeight: 500,
                      mr: 1
                    }}
                  >
                    {t('invoice.paymentStatus.paidFull')}
                  </Typography>
                  <CheckCircleIcon
                    sx={{
                      color: '#22C55E',
                      fontSize: '1.25rem'
                    }}
                  />
                </Box>
              </Box>
            )}
          </Box>
        </Box>

        {/* Items Button */}
        <Button
          fullWidth
          onClick={handleExpandClick}
          sx={{
            bgcolor: '#F8FAFC',
            color: '#1E293B',
            textTransform: 'none',
            py: 2,
            borderRadius: 0,
            justifyContent: 'space-between',
            borderTop: '1px solid #E2E8F0',
            '&:hover': {
              bgcolor: '#F1F5F9'
            }
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <ReceiptIcon sx={{ mr: 1 }} />
            {t('invoice.items')}
          </Box>
          <KeyboardArrowDownIcon
            sx={{
              transform: expanded ? 'rotate(180deg)' : 'none',
              transition: 'transform 0.3s'
            }}
          />
        </Button>
      </Paper>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Box
          sx={{
            bgcolor: '#F8FAFC',
            borderRadius: isMobile ? 0 : '0 0 16px 16px',
            border: '1px solid #E2E8F0',
            borderTop: 'none',
            ...(isMobile && {
              borderLeft: 'none',
              borderRight: 'none',
              boxShadow: 'none'
            }),
            ...(!isMobile && {
              boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)'
            })
          }}
        >
          <Box sx={{ p: { xs: 2, sm: 3 } }}>
            {isLoading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                <CircularProgress />
              </Box>
            ) : invoiceDetail?.items ? (
              <Paper
                elevation={0}
                sx={{
                  borderRadius: isMobile ? 0 : '12px',
                  bgcolor: 'white',
                  p: { xs: 2, sm: 3 }
                }}
              >
                <InvoiceItems items={invoiceDetail.items} />
              </Paper>
            ) : (
              <Typography>{t('invoice.noItemsAvailable')}</Typography>
            )}
          </Box>
        </Box>
      </Collapse>
    </Box>
  );
};

export default InvoiceCard; 