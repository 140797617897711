import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const ImageContainer = styled(Box)(({ theme }) => ({
  width: 'auto', 
  height: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'transparent', 
  borderRadius: '0px', 
  marginBottom: theme.spacing(3),
  overflow: 'visible',
  boxShadow: 'none',
  transition: 'none',
  '&:hover': {
    transform: 'none'
  },
  [theme.breakpoints.down('sm')]: {
    height: 'auto',
  },
  [theme.breakpoints.up('md')]: {
    height: 'auto',
  },
  [theme.breakpoints.up('lg')]: {
    height: 'auto',
  }
}));

export const ContentContainer = styled(Box)({
  textAlign: 'center'
});

export const AppButtonsContainer = styled(Box)(({ theme }) => ({
  display: 'flex', 
  gap: theme.spacing(2), 
  marginTop: theme.spacing(3),
  justifyContent: 'center'
}));

export const AppButton = styled('img')({
  height: 40, 
  cursor: 'pointer',
  transition: 'transform 0.2s ease',
  '&:hover': {
    transform: 'scale(1.05)'
  }
}); 