import React from 'react';
import { Box, Typography, Skeleton, useTheme, useMediaQuery } from '@mui/material';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import { useStyles } from '../styles';

const ProductCard = ({ product, index }) => {
  const [imageLoaded, setImageLoaded] = React.useState(false);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: index * 0.1 }}
      style={{ 
        height: '100%',
        width: '100%',
        display: 'flex'
      }}
    >
      <Box
        sx={{
          bgcolor: 'background.paper',
          borderRadius: 3,
          boxShadow: 1,
          width: '100%',
          maxWidth: isDesktop ? '100%' : 'none',
          mx: 'auto',
          display: 'flex',
          flexDirection: 'column',
          cursor: 'pointer',
          overflow: 'hidden',
          '&:hover': {
            boxShadow: 3,
            transform: 'translateY(-4px)',
            transition: 'all 0.3s ease-in-out',
          },
        }}
      >
        <Box 
          sx={{ 
            position: 'relative', 
            width: '100%', 
            pt: { 
              xs: '75%', 
              md: isDesktop ? '100%' : '100%'
            },
            flexShrink: 0
          }}
        >
          {!imageLoaded && (
            <Skeleton
              variant="rectangular"
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
              }}
            />
          )}
          <Box
            component="img"
            src={product.images[0]}
            alt={product.name}
            onLoad={() => setImageLoaded(true)}
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              objectFit: 'cover'
            }}
          />
        </Box>
        <Box 
          sx={{ 
            p: { xs: 2, md: isDesktop ? 1.5 : 3 },
            display: 'flex',
            flexDirection: 'column',
            gap: { xs: 1, md: 1 },
            flexGrow: 1
          }}
        >
          <Typography 
            variant="subtitle1"
            fontWeight="bold"
            sx={{
              fontSize: { xs: '1rem', md: '1rem' },
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              lineHeight: 1.2,
              minHeight: '2.4em'
            }}
          >
            {product.name}
          </Typography>
          <Typography 
            variant="h6" 
            color="text.secondary" 
            sx={{ 
              fontSize: { xs: '0.875rem', md: '1.25rem' },
              fontWeight: 'bold'
            }}
          >
            ${product.price.toFixed(2)}
          </Typography>
        </Box>
      </Box>
    </motion.div>
  );
};

const ProductGrid = ({ products, sx }) => {
  if (!products?.length) return null;

  return (
    <Box sx={sx}>
      {products.map((product, index) => (
        <ProductCard key={product.id} product={product} index={index} />
      ))}
    </Box>
  );
};

ProductGrid.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
      description: PropTypes.string,
      images: PropTypes.arrayOf(PropTypes.string).isRequired,
    })
  ),
  sx: PropTypes.object,
};

export default ProductGrid; 