/**
 * Tablet styles
 * Styles specific to tablet devices
 */

// Tablet-specific container styles
export const tabletContainerStyles = {
  py: 0
};

export const tabletContainerContentStyles = {
  px: 0
};

export const tabletCardsWrapperStyles = {
  gap: 2.5,
  px: 2.5
};

// Tablet-specific PromotionalCard styles
export const tabletPromotionalCardStyles = {
  flexDirection: 'row',
  borderRadius: 1
};

export const tabletPromotionalImageWrapperStyles = {
  width: '45%',
  height: 320,
  borderRadius: 1,
  m: 1,
  order: 0,
  padding: '16px',
  paddingBottom: '16px'
};

export const tabletPromotionalContentStyles = {
  p: 2,
  order: 1,
  alignItems: 'flex-start',
  width: '50%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center'
};

export const tabletPromotionalTitleStyles = {
  width: '100%',
  fontSize: '32px',
  textAlign: 'left',
  height: 'auto',
  px: 1,
  pt: 1,
  mb: 1.5
};

export const tabletPromotionalSubtitleStyles = {
  width: '100%',
  fontSize: '18px',
  textAlign: 'left',
  height: 'auto',
  px: 1,
  py: 1,
  mb: 1.5
};

export const tabletPromotionalButtonWrapperStyles = {
  justifyContent: 'flex-start',
  mt: 0,
  px: '16px',
  pb: 1
};

export const tabletPromotionalButtonStyles = {
  width: '90%',
  fontSize: 12.02,
  py: 1,
  px: 3
};

// Tablet-specific OfferCard styles
export const tabletOfferCardStyles = {
  width: '180px',
  height: '240px'
};

export const tabletOfferCardMediaStyles = {
  height: '130px',
  p: 1.5
};

export const tabletOfferCardContentStyles = {
  p: 1.75,
  '&:last-child': {
    pb: 1.75
  }
};

export const tabletOfferCardTitleStyles = {
  mb: 0.75,
  fontSize: '0.875rem'
};

export const tabletOfferCardPriceStyles = {
  fontSize: '1rem'
}; 