import React from 'react';
import { Box } from '@mui/material';
import useResponsiveFooter from './utils/useResponsiveFooter';
import MobileFooterContent from './components/MobileFooterContent';
import TabletFooterContent from './components/TabletFooterContent';
import DesktopFooterContent from './components/DesktopFooterContent';

/**
 * Componente Footer principal que muestra diferentes layouts según el tamaño de pantalla
 * Utiliza componentes modulares para cada sección y vista
 */
const Footer = () => {
  const { isMobile, isTablet } = useResponsiveFooter();

  return (
    <Box>
      {isMobile ? (
        <MobileFooterContent />
      ) : isTablet ? (
        <TabletFooterContent />
      ) : (
        <DesktopFooterContent />
      )}
    </Box>
  );
};

export default Footer; 