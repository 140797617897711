/**
 * Tablet styles
 * Styles specific to tablet devices, including iPad Mini, iPad Air, and iPad Pro
 */

// Tablet-specific styles for the container
export const tabletContainerStyles = {
  padding: '24px 0',
  width: '100%',
  backgroundColor: '#FFFFFF',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
};

// Tablet-specific styles for the title
export const tabletTitleStyles = {
  fontSize: '1.75rem',
  fontWeight: 600,
  textAlign: 'center',
  marginBottom: '24px',
  color: '#0A3954',
  width: '100%',
  padding: '0 24px'
};

// Tablet-specific styles for the grid layout
export const tabletGridStyles = {
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  padding: '20px',
  alignItems: 'center',
  width: '100%'
};

// Tablet-specific styles for brand cards
export const tabletBrandCardStyles = {
  width: '160px',
  height: '130px',
  padding: 0,
  marginBottom: '8px'
};

// Tablet-specific styles for brand image container
export const tabletBrandImageContainerStyles = {
  height: '90px'
};

// Tablet-specific styles for brand name container
export const tabletBrandNameStyles = {
  padding: '8px'
};

// Tablet-specific styles for brand typography
export const tabletBrandTypographyStyles = {
  fontSize: '0.875rem'
};

// iPad Mini specific styles
export const iPadMiniBrandCardStyles = {
  width: '150px',
  height: '125px'
};

export const iPadMiniBrandImageContainerStyles = {
  height: '85px'
};

export const iPadMiniBrandTypographyStyles = {
  fontSize: '0.8125rem'
};

// iPad Air specific styles
export const iPadAirBrandCardStyles = {
  width: '160px',
  height: '130px'
};

export const iPadAirBrandImageContainerStyles = {
  height: '90px'
};

export const iPadAirBrandTypographyStyles = {
  fontSize: '0.875rem'
};

// iPad Pro specific styles
export const iPadProBrandCardStyles = {
  width: '180px',
  height: '140px'
};

export const iPadProBrandImageContainerStyles = {
  height: '100px'
};

export const iPadProBrandTypographyStyles = {
  fontSize: '0.9375rem'
}; 