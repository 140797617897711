import { getFirestore, doc, getDoc } from 'firebase/firestore';

const db = getFirestore();

export const fetchDashboardData = async () => {
  const docRef = doc(db, "dashboard", "1");
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    throw new Error("Dashboard data not found");
  }
};