/**
 * TabletCarousel Component
 * Tablet-specific implementation of the BrandsCarousel with a 3-column grid layout
 */

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Container } from '@mui/material';
import { useTranslation } from 'react-i18next';

import BrandCard from './BrandCard';

// Import styles directly from their source files
import {
  commonContainerStyles,
  commonSeoDescriptionStyles,
  commonTitleStyles
} from '../styles/common';

import {
  tabletContainerStyles,
  tabletTitleStyles,
  tabletGridStyles
} from '../styles/tablet';

const TabletCarousel = ({ brands, tabletType }) => {
  const { t } = useTranslation();
  
  return (
    <Box sx={{ ...commonContainerStyles, ...tabletContainerStyles }}>
      <Container maxWidth="lg" disableGutters>
        {/* Title */}
        <Typography 
          variant="h2" 
          component="h2" 
          sx={{ ...commonTitleStyles, ...tabletTitleStyles }}
        >
          {t('dashboard.brandsCarousel.title')}
        </Typography>
        
        {/* Hidden SEO description */}
        <Typography 
          component="p" 
          sx={commonSeoDescriptionStyles}
        >
          {t('dashboard.brandsCarousel.description')}
        </Typography>
        
        <Box sx={{ padding: '0 24px', width: '100%' }}>
          <Box sx={tabletGridStyles}>
            {brands.map((brand) => (
              <BrandCard 
                key={brand.id} 
                brand={brand} 
                deviceType="tablet"
                tabletType={tabletType}
              />
            ))}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

TabletCarousel.propTypes = {
  brands: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      key: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired
    })
  ).isRequired,
  tabletType: PropTypes.oneOf(['iPadMini', 'iPadAir', 'iPadPro', 'generic'])
};

TabletCarousel.defaultProps = {
  tabletType: 'generic'
};

export default memo(TabletCarousel); 