// Estilos específicos para dispositivos móviles
export const mobileContainerStyles = {
  mt: 0,
  borderRadius: 0
};

export const mobileSlideWrapperStyles = {
  maxWidth: '100%',
  padding: '0'
};

export const mobileSwiperStyles = {
  paddingBottom: '16px',
  paddingLeft: '16px'
};

export const mobileSwiperSlideStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: '-15px'
};

// Estilos para la versión móvil en forma de card
export const mobileCardStyles = {
  width: '180px',
  height: '280px',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: 2,
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
  backgroundColor: '#082E44',
  overflow: 'hidden',
  mx: 'auto'
};

export const mobileCardMediaStyles = {
  height: '110px',
  width: '100%',
  objectFit: 'contain',
  backgroundColor: '#082E44',
  p: 1
};

export const mobileCardContentStyles = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  p: '16px',
  '&:last-child': {
    pb: '16px'
  }
};

export const mobilePriceStyles = {
  fontWeight: 900,
  fontSize: '1.5rem',
  color: 'white',
  lineHeight: 1.2,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  minHeight: '45px'
};

export const mobileProductStyles = {
  fontWeight: 600,
  fontSize: '0.9rem',
  color: 'white',
  lineHeight: 1.2,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  minHeight: '36px'
};

// Versiones móviles de los estilos comunes para el OfferCard
export const mobileLeftPanelStyles = {
  flex: '1',
  justifyContent: 'flex-start',
  gap: '8px',
  p: '16px 20px',
  order: 2
};

export const mobileTitleOutlineStyles = {
  width: '100%',
  height: 'auto',
  fontSize: '28px',
  lineHeight: '1.1',
  mb: '4px'
};

export const mobileSubtitleStyles = {
  fontSize: '18px',
  lineHeight: '1.2',
  height: 'auto',
  mb: '12px'
};

export const mobileButtonWrapperStyles = {
  width: '80%',
  borderRadius: '31.04px',
  height: '32px',
  padding: '4px 8px',
  gap: '6px',
  fontSize: '12px',
  mb: '0',
  ml: 0,
  justifyContent: 'flex-start',
  cursor: 'pointer',
  transition: 'opacity 0.2s ease-in-out',
  '&:hover': {
    opacity: 0.9
  },
  '&:active': {
    opacity: 0.7
  }
};

export const mobileIconWrapperStyles = {
  width: '24px',
  borderRadius: '12px',
  height: '24px',
  padding: '4px'
};

export const mobileIconStyles = {
  width: '16px',
  height: '16px'
};

export const mobileButtonTextStyles = {
  width: 'auto',
  height: 'auto',
  fontSize: '12px'
};

export const mobileRightPanelStyles = {
  flex: '1',
  height: '180px',
  p: 0,
  order: 1
};

export const mobileImageStyles = {
  maxWidth: '100%',
  maxHeight: '100%',
  margin: 0,
  p: 2
}; 