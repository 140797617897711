import React, { createContext, useState, useContext } from "react";
import { emailRegex } from "../utils/validations";
import {createCustomer} from "../services/customerService";
import { registerUser } from '../../modules/auth/services/authService';
import { useNavigate } from "react-router-dom";
import { useCustomerDetailContext } from "./CustomerDetailContext";

const FormContext = createContext();

export const FormProvider = ({children}) => {
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const navigate = useNavigate();
    const { setUID } = useCustomerDetailContext();

    const [validateFormData, setValidateFormData] = useState({
        isCompanyNameEmpty: false,
        isEmailValid: true,
        isPasswordEmpty: false,
        isSecondPasswordEmpty: false,
        isPasswordMatch:true
    });
    const [formData, setFormData] = useState({
        companyName: "",
        email: "",
        phone: "",
        password: "",
        confirmPassword: ""
    });

    const handleChange = (event) => {
        const {name, value} = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        const data = new FormData(event.currentTarget);
        const companyName = data.get("companyName");
        const email = data.get("email");
        const phone = data.get("phone");
        const password = data.get("password");
        const confirmPassword = data.get("confirmPassword")

        setValidateFormData({
            ...validateFormData,
            isCompanyNameEmpty: companyName === "",
            isEmailValid: emailRegex.test(email),
            isPasswordEmpty: password === "",
            isSecondPasswordEmpty: confirmPassword === "",
            isPasswordMatch: password === confirmPassword
        })
       postNewCustomer(email, companyName, phone, password);
    };

    const postNewCustomer = async (email, companyName, phone, password) => {
        try {
            //const response = await createCustomer(companyName, email);
            const firebaseUser = await registerUser(email, password);
            console.log("FIREBASE USER UID: ", firebaseUser.uid);
            const netsuiteUser = await createCustomer(companyName, email, phone, firebaseUser.uid);
            console.log(netsuiteUser);
            setUID(firebaseUser.uid);
            navigate("/customerDetail");
            setLoading(false);
            setSuccess(true);
        } catch {
            console.error("Error in create User")
            setLoading(false);
            setSuccess(false);
        }
    };

    return (
        <FormContext.Provider value={{ formData, handleChange, handleSubmit, validateFormData, loading, success }}>
            {children}
        </FormContext.Provider>
    );
};

export const useFormContext = () => useContext(FormContext);