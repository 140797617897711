import React from 'react';
import { Box, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import AppStoreLogo from '../../../../assets/appstore.svg';
import GooglePlayLogo from '../../../../assets/googleplay.svg';
import GrowflLogo from '../../../../assets/WOC_Logo.svg';
import PlantOrangeLogo from '../../../../assets/WOC_Logo.svg';
import useDeviceDetection from '../utils/useDeviceDetection';

export const AppStoreLogos = ({ spacing = 2 }) => {
  const { shouldShowOnlyAppleStore, isAndroidDevice } = useDeviceDetection();

  // Determine if we're showing only one logo
  const showingOnlyOneLogo = (shouldShowOnlyAppleStore || isAndroidDevice);

  // If it's an Apple device, only show the App Store logo
  // If it's an Android device, only show the Google Play logo
  // Otherwise, show both
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: showingOnlyOneLogo ? 'center' : 'flex-start',
        alignItems: 'center',
        gap: spacing,
        mt: 2,
        mb: 4,
        width: '100%',
      }}
    >
      {(!isAndroidDevice || shouldShowOnlyAppleStore) && (
        <img src={AppStoreLogo} alt="App Store" height="40" />
      )}
      
      {(!shouldShowOnlyAppleStore || isAndroidDevice) && (
        <img src={GooglePlayLogo} alt="Google Play" height="40" />
      )}
    </Box>
  );
};

AppStoreLogos.propTypes = {
  spacing: PropTypes.number
};

/**
 * SocialMediaIcons Component
 * Displays social media icons in a horizontally centered stack
 * Uses custom images from /public/images/footer/ directory
 * 
 * @param {Object} props - Component props
 * @param {number} props.spacing - Spacing between icons (default: 2)
 * @param {number} props.iconSize - Size of the icons in pixels (default: 24)
 * @param {string} props.color - Not used with custom images, kept for backwards compatibility
 * @returns {JSX.Element} - Rendered component
 */
export const SocialMediaIcons = ({ spacing = 2, iconSize = 24, color = 'primary' }) => {
  // Social media links with the correct URLs
  const socialLinks = {
    facebook: 'https://www.facebook.com/sylroofingsupplyinc/',
    twitter: 'https://x.com/sylroofingsuply',
    instagram: 'https://www.instagram.com/sylroofingsupply',
    linkedin: 'https://www.linkedin.com/company/syl-roofing-suply'
  };

  // Rutas a las imágenes personalizadas (el usuario debe añadirlas en esta carpeta)
  const iconPaths = {
    facebook: '/images/footer/meta_icon.webp',
    twitter: '/images/footer/X_icon.webp',
    instagram: '/images/footer/instagram_icon.webp',
    linkedin: '/images/footer/linkedin_icon.webp'
  };

  return (
    <Box
      sx={{
        width: '100%',
        mt: 2,
        mb: 4
      }}
    >
      <Stack 
        direction="row" 
        spacing={spacing} 
        justifyContent="center" 
        alignItems="center"
      >
        <a href={socialLinks.facebook} target="_blank" rel="noopener noreferrer" aria-label="Visit our Facebook page">
          <img src={iconPaths.facebook} alt="Facebook" width={iconSize} height={iconSize} />
        </a>
        <a href={socialLinks.twitter} target="_blank" rel="noopener noreferrer" aria-label="Visit our X (Twitter) page">
          <img src={iconPaths.twitter} alt="X (Twitter)" width={iconSize} height={iconSize} />
        </a>
        <a href={socialLinks.instagram} target="_blank" rel="noopener noreferrer" aria-label="Visit our Instagram page">
          <img src={iconPaths.instagram} alt="Instagram" width={iconSize} height={iconSize} />
        </a>
        <a href={socialLinks.linkedin} target="_blank" rel="noopener noreferrer" aria-label="Visit our LinkedIn page">
          <img src={iconPaths.linkedin} alt="LinkedIn" width={iconSize} height={iconSize} />
        </a>
      </Stack>
    </Box>
  );
};

SocialMediaIcons.propTypes = {
  spacing: PropTypes.number,
  iconSize: PropTypes.number,
  color: PropTypes.string
};

export const BrandLogos = ({ height = 80, marginLeft = '10px', isMobile = false }) => (
  <Box sx={{ mt: 2, textAlign: isMobile ? 'center' : 'left' }}>
    <img src={GrowflLogo} alt="GROWFL" height={height} />
    <img src={PlantOrangeLogo} alt="Plant Orange" height={height} style={{ marginLeft }} />
  </Box>
);

BrandLogos.propTypes = {
  height: PropTypes.number,
  marginLeft: PropTypes.string,
  isMobile: PropTypes.bool
}; 