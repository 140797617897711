/**
 * Desktop styles
 * Styles specific to desktop devices
 */

// Desktop-specific styles for the container
export const desktopContainerStyles = {
  padding: 0,
  width: '100%',
  backgroundColor: '#FFFFFF',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
};

// Desktop-specific styles for the title
export const desktopTitleStyles = {
  fontSize: '2rem',
  fontWeight: 600,
  textAlign: 'center',
  marginBottom: '32px',
  color: '#0A3954',
  width: '100%'
};

// Desktop-specific styles for the carousel container
export const desktopCarouselContainerStyles = {
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(140px, 1fr))',
  gap: '16px',
  width: '100%',
  maxWidth: '100%',
  margin: '0 auto',
  padding: '24px 8px'
};

// Desktop-specific styles for brand cards
export const desktopBrandCardStyles = {
  width: '140px',
  height: '120px',
  padding: 0
};

// Desktop-specific styles for brand image container
export const desktopBrandImageContainerStyles = {
  height: '80px'
};

// Desktop-specific styles for brand name container
export const desktopBrandNameStyles = {
  padding: '8px'
};

// Desktop-specific styles for brand name typography
export const desktopBrandTypographyStyles = {
  fontSize: '0.875rem'
}; 